import React from "react";

const CustomCheckbox = ({ value, setValue, label, key, id = false }) => {
    let handleChange = (e) => {
      //  setValue(e.target.checked);
      setValue(e.target.checked);
    };
  return (
    <div className="form-group">
      <input
        type="checkbox"
        id={id || 'html'}
        checked={value}
        onChange={handleChange}
      />
      <label htmlFor={id || 'html'}>{label}</label>
    </div>
  );
};

export default CustomCheckbox;