import React from "react";
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { useNavigate } from "react-router-dom";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

const PrevBtn = (props) => {
    let {click} = props;
    const history = useNavigate();

    const variable_5 = variableLanguage({ keyPage: "groupsList", keyVariable: "variable_5" });
    return (
        <div
            className="btn_prev"
            style={{ cursor: "pointer" }}
            onClick={click}
        >
            <Arrow />
            <div> {variable_5}</div>
        </div>
    );
};

export default PrevBtn;