export const skillsData = [
    {
        id: 1,
        name: "adobe photoshop",
        stages_count: 7,
        text_1: "План обучения по навыку \"Adobe Photoshop\":\n" +
            "\n" +
            "1. Основы использования Photoshop:\n" +
            "   - Знакомство с интерфейсом программы\n" +
            "   - Изучение основных инструментов и панелей\n" +
            "   - Создание нового документа и настройка его параметров\n" +
            "   - Работа с слоями и их настройка\n" +
            "   - Использование основных инструментов выделения и настройка параметров выделения\n" +
            "   - Изучение базовых настроек и корректировка изображений\n" +
            "\n" +
            "2. Работа с изображениями и цветом:\n" +
            "   - Редактирование и корректировка цветового баланса\n" +
            "   - Изменение яркости и контрастности изображения\n" +
            "   - Применение фильтров для создания различных эффектов\n" +
            "   - Использование инструментов клонирования и ретуширования для удаления нежелательных элементов\n" +
            "   - Работа с широкой цветовой гаммой (работа с RAW-файлами)\n" +
            "\n" +
            "3. Текст и графика:\n" +
            "   - Создание и редактирование текстовых слоев\n" +
            "   - Применение текстур и стилей к тексту\n" +
            "   - Работа с формами и векторными объектами\n" +
            "   - Использование инструментов рисования для создания и редактирования графики\n" +
            "   - Изучение возможностей масок для создания сложных эффектов\n" +
            "\n" +
            "4. Ретушь фотографий и реставрация изображений:\n" +
            "   - Удаление дефектов с помощью инструментов ретуширования и клонирования\n" +
            "   - Коррекция и улучшение портретов (редактирование кожи, удаление морщин и пятен)\n" +
            "   - Восстановление старых и поврежденных фотографий\n" +
            "   - Работа с слоями смешивания для создания эффектов и стилей\n" +
            "\n" +
            "5. Дизайн и композиция:\n" +
            "   - Создание макетов для веб-страниц и рекламных материалов\n" +
            "   - Использование графических элементов и фоновых текстур для создания уникального дизайна\n" +
            "   - Работа с шрифтами и типографикой\n" +
            "   - Создание логотипов и брендинга\n" +
            "   - Композиция изображений и создание коллажей\n" +
            "\n" +
            "6. Продвинутые техники и специальные эффекты:\n" +
            "   - Обработка и редактирование фотографий в стиле HDR\n" +
            "   - Создание панорамных фотографий\n" +
            "   - Использование инструментов манипулирования и искажения для создания фантастических эффектов\n" +
            "   - Работа с фильтрами и плагинами для создания специальных эффектов и стилизации изображений\n" +
            "   - Изучение возможностей работы с 3D-графикой\n" +
            "\n" +
            "7. Практика и проекты:\n" +
            "   - Решение практических задач и создание собственных проектов\n" +
            "   - Участие в соревнованиях и контекстах на базе Photoshop\n" +
            "   - Отработка навыков и повышение производительности работы с программой\n" +
            "   - Поиск и изучение дополнительных инструкций, уроков и ресурсов для совершенствования навыков Photoshop",
        text_2: "План обучения по навыку \"Adobe Photoshop\":\n" +
            "\n" +
            "1. Ознакомление с интерфейсом:\n" +
            "   - Инструменты и панели управления.\n" +
            "   - Рабочие области и настройка интерфейса.\n" +
            "   - Основные команды и горячие клавиши.\n" +
            "\n" +
            "2. Работа с изображениями:\n" +
            "   - Открытие, сохранение и экспорт файлов.\n" +
            "   - Понимание разрешения и размера изображений.\n" +
            "   - Управление цветовыми режимами и профилями.\n" +
            "   - Использование слоев и масок.\n" +
            "\n" +
            "3. Редактирование изображений:\n" +
            "   - Использование инструментов выделения и кадрирования.\n" +
            "   - Регулировка яркости, контрастности и цветового баланса.\n" +
            "   - Применение фильтров и эффектов.\n" +
            "   - Инструменты ретуширования и восстановления.\n" +
            "\n" +
            "4. Создание композиций и дизайна:\n" +
            "   - Работа с текстом и шрифтами.\n" +
            "   - Использование форм и градиентов.\n" +
            "   - Создание форм и векторных объектов.\n" +
            "   - Использование слоев стиля.\n" +
            "\n" +
            "5. Работа с растровой графикой:\n" +
            "   - Рисование с помощью кистей и карандашей.\n" +
            "   - Использование инструментов растушевки и размытия.\n" +
            "   - Создание и редактирование градиентных карт.\n" +
            "   - Преобразование и обработка фотографий.\n" +
            "\n" +
            "6. Продвинутые техники и функции:\n" +
            "   - Работа с текстурами и узорами.\n" +
            "   - Создание и редактирование анимации.\n" +
            "   - Использование 3D-инструментов.\n" +
            "   - Работа с программным обеспечением синхронизации и облаком.\n" +
            "\n" +
            "7. Практические задания:\n" +
            "   - Создание логотипа или баннера.\n" +
            "   - Редактирование фотографий с использованием различных техник.\n" +
            "   - Составление композиции из нескольких элементов.\n" +
            "\n" +
            "8. Углубленное изучение и самостоятельное развитие:\n" +
            "   - Изучение специализированных инструментов и плагинов.\n" +
            "   - Ознакомление с дополнительными ресурсами и уроками.\n" +
            "   - Участие в сообществах и форумах пользователей Photoshop.\n" +
            "\n" +
            "Примечание: Плен обучения может быть адаптирован в зависимости от уровня знаний и потребностей учащегося.",
        text_3: "План обучения по навыку \"Adobe Photoshop\":\n" +
            "\n" +
            "1. Введение в Adobe Photoshop\n" +
            "   - Ознакомление с интерфейсом программы\n" +
            "   - Изучение основных инструментов и панелей\n" +
            "   - Создание нового проекта и настройка параметров\n" +
            "\n" +
            "2. Работа с базовыми инструментами\n" +
            "   - Изучение инструментов выделения и кадрирования\n" +
            "   - Освоение инструментов кисти, карандаша и карандаша для штриховки\n" +
            "   - Практика работы с инструментами клонирования и ретуширования\n" +
            "\n" +
            "3. Использование слоев и масок\n" +
            "   - Понимание концепции слоев и их использование\n" +
            "   - Создание и редактирование слоев\n" +
            "   - Применение масок для нелинейной редакции изображений\n" +
            "\n" +
            "4. Работа с текстом и эффектами\n" +
            "   - Добавление и форматирование текста на изображении\n" +
            "   - Применение различных эффектов для создания интересных текстовых элементов\n" +
            "   - Изучение инструментов для работы с тенями, светом и градиентами\n" +
            "\n" +
            "5. Ретушь и цветокоррекция\n" +
            "   - Улучшение качества фотографий с помощью ретуши\n" +
            "   - Применение инструментов для исправления дефектов кожи, удаления объектов и ретуширования фона\n" +
            "   - Освоение инструментов цветокоррекции для изменения насыщенности, контрастности и оттенков\n" +
            "\n" +
            "6. Создание композиций и коллажей\n" +
            "   - Использование инструментов для вырезания и объединения изображений\n" +
            "   - Композиция и аранжировка элементов на холсте для создания коллажей\n" +
            "   - Применение фильтров и эффектов для достижения желаемого стиля\n" +
            "\n" +
            "7. Работа с графикой для веба и социальных сетей\n" +
            "   - Изучение оптимального размера и разрешения изображений для различных целей\n" +
            "   - Создание баннеров, заголовков и других графических элементов для веб-страниц и социальных сетей\n" +
            "   - Оптимизация изображений для сохранения качества и оптимизации загрузки\n" +
            "\n" +
            "8. Дополнительные возможности и инструменты\n" +
            "   - Изучение дополнительных функций и инструментов Adobe Photoshop, таких как фильтры, панель действий и плагины\n" +
            "   - Практика работы с расширенными функциями, такими как 3D-редактирование и редактирование видео\n" +
            "\n" +
            "9. Проекты и практика\n" +
            "   - Создание собственных проектов, включающих различные техники и инструменты Adobe Photoshop\n" +
            "   - Практика регулярной работы с программой для закрепления навыков\n" +
            "   - Поиск и изучение дополнительных уроков и ресурсов для расширения знаний\n" +
            "\n" +
            "10. Самообразование и развитие\n" +
            "   - Посещение онлайн-семинаров, мастер-классов и форумов, посвященных Adobe Photoshop\n" +
            "   - Чтение книг, журналов и веб-ресурсов о графическом дизайне и редактировании фотографий\n" +
            "   - Экспериментирование с новыми техниками и приемами, чтобы постоянно улучшать свои навыки в Adobe Photoshop\n" +
            "\n" +
            "Обратите внимание, что этот план обучения рекомендуется пройти поэтапно, постепенно освоив каждый этап перед переходом к следующему.",
        text_4: "План обучения по навыку \"Adobe Photoshop\":\n" +
            "\n" +
            "1. Ознакомительный этап:\n" +
            "   - Изучение интерфейса Photoshop.\n" +
            "   - Понимание основных инструментов и панелей.\n" +
            "   - Знакомство с основными понятиями, такими как слои, маски, настройки цвета и т.д.\n" +
            "   - Ознакомление с основными горячими клавишами и советами по повышению производительности.\n" +
            "\n" +
            "2. Работа с изображениями:\n" +
            "   - Импорт и экспорт изображений в Photoshop.\n" +
            "   - Основы редактирования изображений, включая изменение размера, обрезку и поворот.\n" +
            "   - Использование инструментов выделения для выделения объектов на изображении.\n" +
            "   - Работа с слоями и масками для более точного контроля над редактированием.\n" +
            "\n" +
            "3. Работа с цветом и коррекция изображений:\n" +
            "   - Использование инструментов коррекции цвета для изменения яркости, контраста и оттенков.\n" +
            "   - Применение фильтров для создания различных эффектов.\n" +
            "   - Использование инструментов клонирования и восстановления для исправления дефектов на изображении.\n" +
            "\n" +
            "4. Работа с текстом и эффектами:\n" +
            "   - Создание и редактирование текста на изображении.\n" +
            "   - Применение стилей слоя для создания различных текстовых эффектов.\n" +
            "   - Использование фильтров, масок и наложения текстуры для создания уникальных эффектов.\n" +
            "\n" +
            "5. Работа с графикой и монтаж изображений:\n" +
            "   - Источники графических ресурсов: иконки, текстуры, шрифты и прочее.\n" +
            "   - Создание макетов и композиций, объединение нескольких изображений в одно.\n" +
            "   - Использование инструмента \"Content-Aware Fill\" для удаления нежелательных объектов на фотографиях.\n" +
            "   - Создание коллажей и эффектов ретуши и обработки портретов.\n" +
            "\n" +
            "6. Создание графических проектов:\n" +
            "   - Работа с инструментами для создания иллюстраций и векторных изображений.\n" +
            "   - Использование сложных техник и функций программы для создания профессиональных дизайнерских проектов.\n" +
            "   - Интеграция Photoshop с другими приложениями Adobe, такими как Illustrator и InDesign.\n" +
            "\n" +
            "7. Продвинутые техники и особенности:\n" +
            "   - Изучение высококачественной ретуши фотографий.\n" +
            "   - Создание эффектов света и тени для дополнительного реализма.\n" +
            "   - Работа с фильтрами и плагинами сторонних разработчиков для расширения возможностей Photoshop.\n" +
            "   - Использование расширенных функций и инструментов, например, 3D и анимации.\n" +
            "\n" +
            "8. Практика и самостоятельные проекты:\n" +
            "   - Применение полученных знаний и навыков к созданию собственных графических проектов.\n" +
            "   - Регулярная практика и эксперименты с различными инструментами и техниками.\n" +
            "   - Участие в сообществе пользователей Photoshop, обмен опытом и получение обратной связи.\n" +
            "\n" +
            "Обратите внимание, что этот план является общим ориентиром и может быть адаптирован в соответствии с вашими конкретными потребностями и уровнем знаний. Удачи в изучении Adobe Photoshop!"
    },
    {
        name: "1с: бухгалтерия",
        stages_count: 7,
        text_1: "План обучения по навыку \"1С: Бухгалтерия\" может быть составлен следующим образом:\n" +
            "\n" +
            "1. Основы бухгалтерии:\n" +
            "   - Изучение основных понятий и принципов бухгалтерии.\n" +
            "   - Знакомство с основными документами и их содержанием (накладные, счета-фактуры, акты и т.д.).\n" +
            "   - Понимание основных методов учета (двойная запись, прямая запись и др.).\n" +
            "   - Практические навыки заполнения основных учетных документов.\n" +
            "\n" +
            "2. Работа с программой 1С: Бухгалтерия:\n" +
            "   - Ознакомление с интерфейсом программы и ее основными функциями.\n" +
            "   - Создание и настройка учетной базы данных.\n" +
            "   - Ввод и обработка данных в программе (добавление контрагентов, товаров, создание документов и т.д.).\n" +
            "   - Создание отчетов и анализ финансовой информации.\n" +
            "\n" +
            "3. Учет операций:\n" +
            "   - Учет приобретения и реализации товаров/услуг.\n" +
            "   - Расчет заработной платы и начисление налогов.\n" +
            "   - Учет расходов и доходов.\n" +
            "   - Учет финансовых операций (кредиты, ссуды и т.д.).\n" +
            "\n" +
            "4. Налоговый учет:\n" +
            "   - Знакомство с принципами налогообложения и основными видами налогов.\n" +
            "   - Подготовка и представление налоговых деклараций.\n" +
            "   - Методы оптимизации налогообложения.\n" +
            "\n" +
            "5. Анализ финансовой информации:\n" +
            "   - Понимание основных показателей финансовой отчетности.\n" +
            "   - Интерпретация и анализ финансовых показателей.\n" +
            "   - Построение финансовых моделей и прогнозирование результатов.\n" +
            "\n" +
            "6. Работа с дополнительными модулями и настройками:\n" +
            "   - Изучение возможностей и функционала дополнительных модулей 1С: Бухгалтерия.\n" +
            "   - Настройка программы под конкретные требования предприятия или организации.\n" +
            "   - Интеграция 1С: Бухгалтерия с другими программными продуктами и системами.\n" +
            "\n" +
            "7. Практическое применение и самостоятельная работа:\n" +
            "   - Решение практических задач по бухгалтерскому учету с использованием программы 1С: Бухгалтерия.\n" +
            "   - Составление отчетности и анализ финансовой информации на основе полученных данных.\n" +
            "   - Самостоятельная работа с программой и поиск дополнительной информации для расширения знаний.\n" +
            "\n" +
            "Важным элементом обучения является постепенное углубление и повторение пройденного материала, чтобы учащийся мог укрепить свои знания и получить практические навыки работы с программой 1С: Бухгалтерия.",
        text_2: "План обучения по навыку \"1C: Бухгалтерия\":\n" +
            "\n" +
            "1. Основы работы в программе 1C: Бухгалтерия:\n" +
            "   - Знакомство с интерфейсом программы\n" +
            "   - Создание и настройка информационной базы данных\n" +
            "   - Работа с главным меню и основными функциями программы\n" +
            "\n" +
            "2. Основные бухгалтерские операции:\n" +
            "   - Создание счетов и подсчет начальных остатков\n" +
            "   - Внесение первичных документов (накладные, счета-фактуры и т.д.)\n" +
            "   - Осуществление проводок и выполнение регистрации хозяйственных операций\n" +
            "\n" +
            "3. Учет зарплаты и кадров:\n" +
            "   - Создание и настройка справочников организации и сотрудников\n" +
            "   - Расчет заработной платы и начисление налогов\n" +
            "   - Формирование и сдача отчетности в соответствии с законодательством о труде и налоговыми правилами\n" +
            "\n" +
            "4. Учет материальных ценностей:\n" +
            "   - Ведение учета остатков и движения товаров/материалов\n" +
            "   - Создание карточек товаров и номенклатурных позиций\n" +
            "   - Формирование отчетов о стоимости запасов\n" +
            "\n" +
            "5. Учет финансовых операций:\n" +
            "   - Обработка платежных документов (приходные и расходные ордера, платежные поручения и т.д.)\n" +
            "   - Формирование банковских выписок и их обработка в программе\n" +
            "   - Анализ финансовой деятельности предприятия на основе данных 1C: Бухгалтерии\n" +
            "\n" +
            "6. Отчетность и налоговое планирование:\n" +
            "   - Формирование и сдача отчетности в налоговые органы (НДС, налог на прибыль и т.д.)\n" +
            "   - Работа с налоговыми разделами в программе 1C: Бухгалтерия\n" +
            "   - Анализ и оптимизация налоговой нагрузки предприятия\n" +
            "\n" +
            "7. Автоматизация бухгалтерских процессов:\n" +
            "   - Настройка автоматической обработки и проведения документов\n" +
            "   - Использование регламентированных заданий для автоматизации рутиных операций\n" +
            "   - Интеграция 1C: Бухгалтерии с другими информационными системами\n" +
            "\n" +
            "8. Анализ данных и контроль:\n" +
            "   - Построение отчетов и аналитических выкладок\n" +
            "   - Контроль за исполнением бухгалтерских операций и своевременностью проведения\n" +
            "   - Использование инструментов анализа для принятия управленческих решений\n" +
            "\n" +
            "9. Сопровождение и обновление программы:\n" +
            "   - Установка обновлений и патчей программы 1C: Бухгалтерия\n" +
            "   - Резервное копирование и восстановление базы данных\n" +
            "   - Поддержка справочной документации и поиск дополнительной информации",
        text_3: "План обучения по навыку \"1С: Бухгалтерия\":\n" +
            "\n" +
            "1. Основы бухгалтерии:\n" +
            "   - Ознакомление с основными понятиями и принципами бухгалтерии.\n" +
            "   - Понимание структуры и функций учетных записей.\n" +
            "   - Освоение общей схемы бухгалтерского учета в программе 1С: Бухгалтерия.\n" +
            "\n" +
            "2. Настройка программы:\n" +
            "   - Установка и настройка программы 1С: Бухгалтерия на компьютере.\n" +
            "   - Создание базы данных для ведения бухгалтерии.\n" +
            "   - Знакомство с интерфейсом программы и его основными функциями.\n" +
            "\n" +
            "3. Ввод и обработка первичных документов:\n" +
            "   - Освоение способов ввода первичных документов в программу.\n" +
            "   - Практические навыки заполнения документов, таких как акты, счета-фактуры, платежные поручения и т.д.\n" +
            "   - Использование функций программы для обработки и анализа введенных данных.\n" +
            "\n" +
            "4. Учет операций:\n" +
            "   - Запись операций покупки и продажи товаров или услуг в учетной программе.\n" +
            "   - Учет расходов и доходов предприятия.\n" +
            "   - Регистрация и анализ движения денежных средств.\n" +
            "\n" +
            "5. Отчетность и анализ данных:\n" +
            "   - Получение отчетов и формирование нужной отчетности (баланс, отчет о прибылях и убытках и т.д.).\n" +
            "   - Использование аналитических функций программы для анализа данных и выявления финансовых показателей.\n" +
            "\n" +
            "6. Налоговый учет:\n" +
            "   - Освоение правил и принципов налогообложения.\n" +
            "   - Учет налоговых операций и формирование налоговой отчетности.\n" +
            "   - Понимание методов оптимизации налоговых платежей.\n" +
            "\n" +
            "7. Автоматизация процессов:\n" +
            "   - Использование возможностей автоматизации бухгалтерии в программе 1С.\n" +
            "   - Настройка автоматического формирования документов и отчетности.\n" +
            "   - Разработка и внедрение собственных алгоритмов и функций для оптимизации бухгалтерских процессов.\n" +
            "\n" +
            "8. Практическое применение:\n" +
            "   - Решение практических задач с использованием программы 1С: Бухгалтерия.\n" +
            "   - Работа с реальными бухгалтерскими документами и ситуациями.\n" +
            "   - Обучение практическим навыкам бухгалтера в реальных условиях.\n" +
            "\n" +
            "9. Следующий уровень:\n" +
            "   - Экспериментирование и исследование специализированных модулей программы 1С: Бухгалтерия.\n" +
            "   - Изучение дополнительных функций и инструментов для более глубокого понимания и применения системы учета.\n" +
            "\n" +
            "10. Постоянное обновление знаний:\n" +
            "    - Ознакомление с последними изменениями и обновлениями программы 1С: Бухгалтерия.\n" +
            "    - Участие в профессиональных курсах и тренингах для повышения квалификации и расширения знаний в области бухгалтерии.",
        text_4: "План обучения по навыку \"1С: Бухгалтерия\":\n" +
            "\n" +
            "1. Основы бухгалтерии:\n" +
            "   - Введение в бухгалтерию и ее роль в организации\n" +
            "   - Основные понятия и принципы бухгалтерии\n" +
            "   - Правовые основы бухгалтерского учета\n" +
            "   - Основные документы бухгалтерского учета\n" +
            "\n" +
            "2. Знакомство с программой \"1С: Бухгалтерия\":\n" +
            "   - Установка программы и ее настройки\n" +
            "   - Основные функции и возможности программы\n" +
            "   - Создание базы данных и настройка учетных регистров\n" +
            "   - Знакомство с интерфейсом программы и его основными элементами\n" +
            "\n" +
            "3. Постановка бухгалтерского учета:\n" +
            "   - Ввод и обработка первичных документов в программе \"1С: Бухгалтерия\"\n" +
            "   - Отражение операций расчетного учета\n" +
            "   - Ведение кассовых операций в программе\n" +
            "   - Отражение расчетов с поставщиками и покупателями\n" +
            "\n" +
            "4. Отчетность и аналитика:\n" +
            "   - Формирование и представление отчетности в программе\n" +
            "   - Анализ финансовых результатов и показателей\n" +
            "   - Построение отчетов и аналитических срезов\n" +
            "   - Использование бухгалтерской информации для принятия управленческих решений\n" +
            "\n" +
            "5. Автоматизация бухгалтерского учета:\n" +
            "   - Импорт и экспорт данных в программу \"1С: Бухгалтерия\"\n" +
            "   - Интеграция с другими программными продуктами (например, CRM системами)\n" +
            "   - Планирование и автоматическое формирование документов\n" +
            "   - Использование шаблонов и настройка автоматических операций\n" +
            "\n" +
            "6. Углубленные темы в бухгалтерии:\n" +
            "   - Налоговый учет и отчетность\n" +
            "   - Учет основных средств и нематериальных активов\n" +
            "   - Составление бухгалтерского баланса и отчета о прибылях и убытках\n" +
            "   - Взаимодействие с налоговыми органами и аудиторскими проверками\n" +
            "\n" +
            "7. Практическое применение навыков бухгалтерии:\n" +
            "   - Решение практических задач и кейсов\n" +
            "   - Работа над реальными проектами и ситуациями\n" +
            "   - Самостоятельное ведение бухгалтерии с использованием программы \"1С: Бухгалтерия\"\n" +
            "   - Тренировка навыков работы в программе и анализ полученной отчетности\n" +
            "\n" +
            "8. Оценка результата обучения и дальнейшее развитие:\n" +
            "   - Проведение проверочных заданий и тестирования\n" +
            "   - Анализ сильных и слабых сторон в освоении навыков бухгалтерии\n" +
            "   - План дальнейшего развития и совершенствования навыков\n" +
            "   - Изучение дополнительных материалов и ресурсов для расширения знаний"
    },
    {
        name: "Управление проектами",
        stages_count: 8,
        text_1: "План обучения по навыку \"Управление проектами\":\n" +
            "\n" +
            "1. Основы управления проектами\n" +
            "   - Введение в управление проектами и его роль в организации\n" +
            "   - Основные термины и понятия, связанные с управлением проектами\n" +
            "   - Основные процессы управления проектами (инициирование, планирование, выполнение, контроль, завершение)\n" +
            "   - Роли и ответственности в проектном управлении\n" +
            "\n" +
            "2. Планирование проекта\n" +
            "   - Составление проектного плана и структуры разбиения работ (Work Breakdown Structure, WBS)\n" +
            "   - Определение и управление ресурсами проекта\n" +
            "   - Определение и управление расписанием проекта\n" +
            "   - Разработка бюджета проекта и управление финансами\n" +
            "   - Риск-менеджмент в проекте: идентификация, анализ и планирование мер по снижению рисков\n" +
            "\n" +
            "3. Исполнение проекта\n" +
            "   - Организация команды проекта и коммуникационные стратегии\n" +
            "   - Управление выполнением задач и контроль прогресса проекта\n" +
            "   - Управление изменениями в проекте и контроль качества\n" +
            "   - Управление заинтересованными сторонами (стейкхолдерами) проекта\n" +
            "   - Управление конфликтами и решение проблем\n" +
            "\n" +
            "4. Мониторинг и контроль проекта\n" +
            "   - Управление рисками и принятие соответствующих мер\n" +
            "   - Контроль выполнения плана и внесение необходимых корректировок\n" +
            "   - Отчетность о статусе проекта и коммуникация с заинтересованными сторонами\n" +
            "   - Оценка результатов и достижение целей проекта\n" +
            "   - Уроки, извлеченные из проекта, и их применение в будущих проектах\n" +
            "\n" +
            "5. Завершение проекта\n" +
            "   - Формальное завершение проекта и проверка достижения целей\n" +
            "   - Оценка проекта и подведение итогов\n" +
            "   - Организационное управление изменениями после завершения проекта\n" +
            "   - Архивирование и документирование проектных результатов\n" +
            "   - Уроки, извлеченные из проекта, и обратная связь для улучшения процессов управления проектами\n" +
            "\n" +
            "6. Применение инструментов и методологий управления проектами\n" +
            "   - Ознакомление с распространенными инструментами управления проектами, такими как Microsoft Project, Jira, Basecamp и другими\n" +
            "   - Изучение различных методологий управления проектами, таких как Waterfall, Agile, Scrum и Kanban\n" +
            "   - Адаптация инструментов и методологий для конкретных проектных потребностей\n" +
            "\n" +
            "7. Практическое опытное обучение\n" +
            "   - Участие в реальных или симуляционных проектах, чтобы применить полученные знания и навыки\n" +
            "   - Работа в команде проекта и выполнение конкретных ролей\n" +
            "   - Реализация проектов от начала до конца, включая планирование, выполнение, контроль и завершение\n" +
            "\n" +
            "8. Продолжающееся образование и самообучение\n" +
            "   - Чтение специализированной литературы по управлению проектами и отраслевым практикам\n" +
            "   - Участие в вебинарах, семинарах и конференциях, посвященных управлению проектами\n" +
            "   - Получение сертификаций, таких как Project Management Professional (PMP) или Certified ScrumMaster (CSM)\n" +
            "\n" +
            "Этот план обучения по управлению проектами включает основные этапы и темы, необходимые для освоения этого навыка. Однако, рекомендуется настраивать план в соответствии с индивидуальными потребностями и целями обучения каждого студента.",
        text_2: "План обучения по навыку \"Управление проектами\":\n" +
            "\n" +
            "1. Введение в управление проектами:\n" +
            "   - Определение проекта и его характеристики;\n" +
            "   - Основные цели и принципы управления проектами;\n" +
            "   - Различные методологии управления проектами (например, Waterfall, Agile, Scrum).\n" +
            "\n" +
            "2. Создание проектного плана:\n" +
            "   - Формулировка целей и требований проекта;\n" +
            "   - Определение задач и их приоритетность;\n" +
            "   - Определение ресурсов проекта (люди, бюджет, оборудование);\n" +
            "   - Разработка графика выполнения работ и майлстоунов.\n" +
            "\n" +
            "3. Управление рисками проекта:\n" +
            "   - Идентификация и анализ рисков;\n" +
            "   - Планирование мер по управлению рисками;\n" +
            "   - Мониторинг и контроль рисков в течение проекта.\n" +
            "\n" +
            "4. Менеджмент команды и коммуникации:\n" +
            "   - Работа в команде и распределение обязанностей;\n" +
            "   - Управление конфликтами и мотивация членов команды;\n" +
            "   - Эффективное общение и отчетность.\n" +
            "\n" +
            "5. Мониторинг и контроль проекта:\n" +
            "   - Отслеживание выполнения задач и сроков;\n" +
            "   - Мониторинг бюджета и контроль затрат;\n" +
            "   - Оценка качества работ и результатов проекта.\n" +
            "\n" +
            "6. Завершение проекта и оценка успеха:\n" +
            "   - Формальное закрытие проекта и оценка результатов;\n" +
            "   - Анализ проведенного проекта и выявление уроков;\n" +
            "   - Отчетность и представление проекта заинтересованным сторонам.\n" +
            "\n" +
            "7. Применение инструментов управления проектами:\n" +
            "   - Внедрение проектного менеджмента в практическую деятельность;\n" +
            "   - Использование программного обеспечения для управления проектами;\n" +
            "   - Оптимизация рабочих процессов и автоматизация рутинных задач.\n" +
            "\n" +
            "8. Применение лидерских навыков в управлении проектами:\n" +
            "   - Развитие лидерских качеств и навыков;\n" +
            "   - Управление изменениями и адаптация к новым условиям;\n" +
            "   - Развитие инновационного мышления и поиск креативных решений.\n" +
            "\n" +
            "9. Кейс-студии и практическое применение:\n" +
            "   - Анализ реальных проектов и применение полученных знаний;\n" +
            "   - Развитие навыков принятия решений и решение сложных ситуаций;\n" +
            "   - Моделирование и оптимизация процессов управления проектами.\n" +
            "\n" +
            "10. Сертификация и дальнейшее развитие:\n" +
            "    - Получение сертификации в области управления проектами (например, PRINCE2, PMP);\n" +
            "    - Участие в конференциях, семинарах и тренингах для обмена опытом и дальнейшего развития.",
        text_3: "План обучения по управлению проектами:\n" +
            "\n" +
            "1. Основы управления проектами:\n" +
            "   - Введение в управление проектами: определение проекта, его характеристики и основные цели.\n" +
            "   - Роли и обязанности управляющей команды проекта: проектный менеджер, специалист по планированию, руководитель проектной группы и т.д.\n" +
            "   - Определение требований и создание устава проекта: определение целей проекта, его ограничений и достижимости. \n" +
            "   - Формирование команды проекта: найм и назначение участников, определение ролей и установление коммуникационных каналов.\n" +
            "\n" +
            "2. Планирование проекта:\n" +
            "   - Разработка структуры разделения работ (WBS): создание иерархической структуры проекта.\n" +
            "   - Оценка затрат и ресурсов: определение необходимых ресурсов для выполнения работ и оценка временных и материальных затрат.\n" +
            "   - Разработка графика проекта: создание временной оси проекта с учетом сроков и зависимостей задач.\n" +
            "   - Управление рисками проекта: идентификация и анализ возможных рисков, разработка стратегий и планов по их управлению.\n" +
            "\n" +
            "3. Выполнение и контроль проекта:\n" +
            "   - Организация и мобилизация ресурсов: распределение задач и ответственностей между участниками проекта, контроль выполнения работ.\n" +
            "   - Мониторинг и контроль прогресса проекта: проверка выполнения работ в соответствии с графиком, контроль бюджета и качества.\n" +
            "   - Решение проблем и управление изменениями: анализ и решение возникающих проблем, адаптация плана проекта к изменениям и новым требованиям.\n" +
            "   - Коммуникация и отчетность: регулярное информирование заинтересованных сторон о прогрессе проекта, проведение совещаний и отчетность.\n" +
            "\n" +
            "4. Завершение проекта:\n" +
            "   - Подготовка к завершению проекта: проверка выполнения всех задач, оценка достижения целей и степени удовлетворения заказчика.\n" +
            "   - Завершение проекта: формальное закрытие проекта, анализ выполнения, документация и сохранение опыта проекта.\n" +
            "   - Оценка проекта и улучшение процесса: анализ успешности проекта, выявление уроков и рекомендаций для улучшения проектного управления.\n" +
            "\n" +
            "5. Дополнительные навыки по управлению проектами:\n" +
            "   - Использование проектного программного обеспечения: знакомство с современными инструментами для планирования, контроля и отчетности по проектам.\n" +
            "   - Лидерство и командообразование: развитие навыков руководства группой, мотивации и координации команды проекта.\n" +
            "   - Управление конфликтами: разработка стратегий разрешения конфликтов в проектной среде.\n" +
            "   - Управление изменениями: основы управления изменениями в рамках проекта и взаимосвязь с управлением рисками.\n" +
            "\n" +
            "Обратите внимание, что данный план обучения является общим ориентиром и может быть адаптирован в соответствии с потребностями и целями конкретного обучения по управлению проектами.",
        text_4: "План обучения по навыку \"Управление проектами\":\n" +
            "\n" +
            "1. Основные понятия и принципы управления проектами:\n" +
            "   - Введение в управление проектами\n" +
            "   - Основные роли и ответственности в проекте\n" +
            "   - Жизненный цикл проекта\n" +
            "   - Проектный менеджмент и его составляющие\n" +
            "   - Определение целей и объема проекта\n" +
            "\n" +
            "2. Планирование проекта:\n" +
            "   - Составление иерархии задач в проекте\n" +
            "   - Определение временных рамок проекта\n" +
            "   - Распределение ресурсов\n" +
            "   - Разработка графика проекта\n" +
            "   - Оценка рисков и разработка стратегий управления рисками\n" +
            "\n" +
            "3. Управление командой проекта:\n" +
            "   - Подбор и формирование команды проекта\n" +
            "   - Мотивация и развитие членов команды\n" +
            "   - Коммуникация и эффективное взаимодействие в команде\n" +
            "   - Разрешение конфликтов в проектной команде\n" +
            "\n" +
            "4. Мониторинг и контроль проекта:\n" +
            "   - Оценка выполнения задач и контроль прогресса проекта\n" +
            "   - Использование методов и инструментов для отслеживания выполнения проекта\n" +
            "   - Регулярное обновление плана проекта и решение возникающих проблем\n" +
            "   - Управление изменениями и контроль качества проекта\n" +
            "\n" +
            "5. Завершение проекта:\n" +
            "   - Оценка и анализ выполненного проекта\n" +
            "   - Документирование результатов проекта\n" +
            "   - Архивирование проектной документации\n" +
            "   - Обратная связь и учет полученного опыта для улучшения будущих проектов\n" +
            "\n" +
            "6. Развитие лидерских навыков в управлении проектами:\n" +
            "   - Развитие эффективного руководителя проекта\n" +
            "   - Управление временем и приоритетами в проекте\n" +
            "   - Развитие навыков презентации и общения\n" +
            "   - Улучшение навыков принятия решений и управления конфликтами\n" +
            "\n" +
            "7. Применение инструментов и программного обеспечения для управления проектами:\n" +
            "   - Использование программных инструментов для планирования и контроля проектов (например, MS Project)\n" +
            "   - Применение методологий управления проектами, таких как Agile или Waterfall\n" +
            "   - Использование онлайн-систем для управления проектами и взаимодействия команды\n" +
            "\n" +
            "8. Практическое применение знаний и навыков управления проектами:\n" +
            "   - Развитие навыков через участие в реальном или симулированном проекте\n" +
            "   - Опытное освоение управленческих задач и принятие ответственности за результаты\n" +
            "\n" +
            "Этот план обучения охватывает основные аспекты управления проектами и предоставляет структурированный подход для освоения навыков на различных этапах развития. Однако помните, что обучение проектному управлению наиболее эффективно сочетает теорию с практическим применением в реальных ситуациях."
    }
]