import React, { useEffect, useState } from "react";
import requests from "../../../axios/requests";
import moment from "moment";

import { Modal, Pagination, Spin } from "antd";
import { CourseItem } from "../../MyCourse/MyCourse";
import variableLanguage from '../../../utils/hooks/hookGetVariablesLanguage';

import { ReactComponent as Arrow } from "../../../../images/arrow.svg";
import close_tag from "../../../../images/search_profession/close_tag.svg";
import it_icon from "../../../../images/icons/it_icon.png";
import medical_icon from "../../../../images/icons/medical_icon.png";
import languages_icon from "../../../../images/icons/languages_icon.png";
import menegement_icon from "../../../../images/icons/menegement_icon.png";
import finance_icon from "../../../../images/icons/finance_icon.png";
import design_icon from "../../../../images/icons/design_icon.png";
import sport_icon from "../../../../images/icons/sport_icon.png";
import close_modal from "../../../../images/search_profession/close_modal-course.svg";
import './ModalCourse.scss';

const ModalCourse = (props) => {
    let {
        flagModalCourse, closeModalCourse, skillsUserTraektory,
        handleRemoveSkillBasket
    } = props;
    const variable_1 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_1'});
    const variable_2 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_2'});
    const variable_3 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_3'});
    const variable_4 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_4'});
    const variable_5 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_5'});
    const variable_6 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_6'});
    const variable_7 = variableLanguage({keyPage: 'modalCourse', keyVariable: 'variable_7'});

    const variables_36 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_36'});
    const variables_31 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_31'});
    const variables_33 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_33'});
    const variables_30 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_30'});
    const variables_32 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_32'});
    const variables_34 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_34'});
    const variables_35 = variableLanguage({keyPage: 'authorization', keyVariable: 'variables_35'});

    const [activeSort, setActiveSort] = useState(0);
    const [courses, setCourses] = useState([]);
    const [flagDropDown, setFlagDropDown] = useState(false);
    const [originalCourse, setOriginalCourse] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const sortList = [variable_1, variable_2, variable_3];
    const category = [
        <div className="category" style={{backgroundColor: "#FF5937"}}>
            <img width={16} height={16} src={sport_icon}/>
            <span>{variables_36}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#3077FF"}}>
            <img width={19} height={19} src={medical_icon}/>
            <span>{variables_31}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#FFD23B"}}>
            <img width={18} height={18} src={menegement_icon}/>
            <span style={{color: "#343843"}}>{variables_33}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#8146FF"}}>
            <img width={17} height={15} src={it_icon}/>
            <span>{variables_30}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#30E0A1"}}>
            <img width={26} height={12} src={languages_icon}/>
            <span style={{color: "#343843"}}>{variables_32}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#FF3434"}}>
            <img width={16} height={16} src={design_icon}/>
            <span>{variables_35}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#FF47ED"}}>
            <img width={14} height={14} src={finance_icon}/>
            <span>{variables_34}</span>
        </div>
    ];

    let handleCloseModal = () => {
        closeModalCourse();
    };
    let customPagination = (array) => {
        if (array) {
            const contentPerPage = 12;
            const lastIndex = currentPage * contentPerPage; // 3
            const firstIndex = lastIndex - contentPerPage; // 0
            return array.slice(firstIndex, lastIndex);
        }
        return null;
    };
    let itemRender = (_, type, originalElement) => {
        if (type === "prev")
            return null;
        if (type === "next") {
            return;
        }
        return originalElement;
    };

    useEffect(() => {
        if (originalCourse && originalCourse.length > 0) {
            let newList = [...originalCourse];
            setCourses(newList);
        }
    }, [originalCourse]);
    useEffect(() => {
        switch (activeSort) {
            case 0:
                if (originalCourse && originalCourse.length > 0)
                    return setCourses(originalCourse);
                return;
            case 1:
                let sortListCost = [...courses];
                sortListCost = sortListCost.sort((a, b) => {
                    if (a.participateprice < b.participateprice)
                        return -1;
                    if (a.participateprice > b.participateprice)
                        return 1;
                    return 0;
                });
                setCourses(sortListCost);
                break;
            case 2:
                let sortListDate = [...courses];
                sortListDate = sortListDate.sort((a, b) => {
                    if (moment(a.period.split(" ")[1], "DD/MM/YYYY").valueOf() < moment(b.period.split(" ")[1], "DD/MM/YYYY").valueOf()) {
                        return -1;
                    }
                    if (moment(a.period.split(" ")[1], "DD/MM/YYYY").valueOf() > moment(b.period.split(" ")[1], "DD/MM/YYYY").valueOf()) {
                        return 1;
                    }
                    return 0;
                });
                setCourses(sortListDate);
                break;
        }
    }, [activeSort]);
    useEffect(() => {
        customPagination();
    }, [currentPage]);
    useEffect(() => {
        setLoading(true);
        let str = "";
        if (skillsUserTraektory && skillsUserTraektory.length > 0) {
            for (let i = 0; i < skillsUserTraektory.length; i++) {
                if (i === skillsUserTraektory.length - 1) {
                    str = str + `${skillsUserTraektory[i].id}`;
                } else {
                    str = str + `${skillsUserTraektory[i].id}, `;
                }
            }
        }
        requests.quests.get_quest_quest(str, currentPage, 12)
            .then(res => setOriginalCourse(res.data.results))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, []);
    return (
        <Modal
            visible={flagModalCourse}
            footer={null}
            className={"modal_course"}
            /* width={"auto"}*/
            closable={false}
            // centered={true}
            onCancel={handleCloseModal}
            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
            bodyStyle={{padding: "40px 40px 10px 40px", backgroundColor: "#201E26", borderRadius: 40}}
        >
            <div className="title">
                <span>{variable_4}</span>
                <div className="close_icon">
                    <img
                        onClick={handleCloseModal}
                        className={"close_title"}
                        width={40}
                        height={40}
                        src={close_modal}
                    />
                </div>
            </div>
            <div className="title_info">{variable_5}</div>
            <div className="sort">
                <ul>
                    <li style={{color: "white", cursor: "initial"}}>{variable_6}:</li>
                    {sortList.map((elem, indx) =>
                        <li
                            onClick={() => setActiveSort(indx)}
                            key={indx}
                            className={activeSort === indx ? "active" : ""}
                        >
                            {sortList[indx]}
                        </li>
                    )}
                </ul>
            </div>
            <div
                onClick={() => setFlagDropDown(!flagDropDown)}
                className={flagDropDown ? "sort_mobile active" : "sort_mobile"}
                style={{display: "none"}}
            >
                <span>{variable_6}:</span> <span
                style={{color: "#A1A3B2", marginLeft: 5}}>{sortList[activeSort]} </span>
                <Arrow className={flagDropDown ? "active" : ""}/>
            </div>
            <div className={flagDropDown ? "sort_mobile-drop-down active" : "sort_mobile-drop-down"}>
                <ul>
                    {sortList.map((elem, indx) =>
                        <li
                            key={indx}
                            onClick={() => {
                                if (!flagDropDown) {
                                    return;
                                }
                                setFlagDropDown(false);
                                setActiveSort(indx);
                            }}
                        >
                            {elem}
                        </li>
                    )}
                </ul>
            </div>
            <div className="traektory_title">{variable_7}</div>
            <div className="traektory_basket">
                {skillsUserTraektory && skillsUserTraektory.length > 0 &&
                    skillsUserTraektory.map((tag, indx) => {
                        return (
                            <div className="skills" key={indx}>
                                <div className="icon_traektory">
                                    <span>{tag.name}</span>
                                    <img src={close_tag} onClick={() => handleRemoveSkillBasket(tag)}/>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            {/* <div className="course_list_modal">
                <ul style={{position: "relative"}}>
                    {(courses && courses.length > 0) && courses.length <= 12
                        ? courses.map((course, indx) => {
                                return (
                                    <li key={indx} className={"reuse_course-item"}>
                                        <CourseItem
                                            course={course}
                                            isCourseList={true}
                                            redirectUrl={course.id}
                                            cost={true}
                                            dateCourse={true}
                                        />
                                    </li>
                                );
                            }
                        )
                        : customPagination(courses).map((course, indx) => {
                                return (
                                    <li key={indx} className={"reuse_course-item"}>
                                        <CourseItem
                                            course={course}
                                            category={category}
                                            isCourseList={true}
                                            redirectUrl={course.id}
                                            cost={true}
                                            dateCourse={true}
                                        />
                                    </li>
                                );
                            }
                        )}
                    {loading &&
                        <Spin style={{position: "absolute"}}/>
                    }
                </ul>
                {courses && courses.length > 12 &&
                    <div className="pagination_all">
                        <Pagination
                            itemRender={itemRender}
                            defaultCurrent={1}
                            defaultPageSize={12}
                            total={courses.length}
                            onChange={(e) => setCurrentPage(e)}
                            showTitle={false}
                        />
                    </div>
                }
            </div> */}
        </Modal>
    );
};

export default ModalCourse;