import React from "react";
import { ReactComponent as Arrow } from "../../../images/arrow.svg";

const ReuseDropDown = (props) => {
  let { flagOpen, setFlagOpen, arrList, activeArrList, setActiveArrList,onBlur = false,  arrow = true } = props;

  return (
    <div
      className={flagOpen ? "reuse_drop-down open" : "reuse_drop-down"}
      onClick={() => setFlagOpen(!flagOpen)}
      style={!arrow ? {cursor: 'auto'} : {} }
      tabIndex={0}
      onBlur={() => {
          if(onBlur) onBlur()
      }}
    >
     <span>{arrList.length > 0 && arrList[activeArrList].name || arrList[activeArrList]}</span>
        {arrow && <Arrow className="svg" /> }
      <div className={flagOpen ? "drop_down-category open" : "drop_down-category"}>
        <ul>
          {arrList.map((elem, indx) =>
            <li
              key={indx}
              onClick={() => {
                setFlagOpen(false);
                setActiveArrList(indx);
              }}
              className={activeArrList === indx ? "active" : ""}
            >
              {elem.name || elem}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ReuseDropDown;