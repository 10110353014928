import React from 'react';
import fake_photos from "../../../../images/icons/fake_photos.png";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import requests from "../../../axios/requests";

import { ReactComponent as TestIcon } from "../../../../images/icons/test_icon-mobile.svg";

const TestWork = (props) => {
    const {
        stagesQuiz, setQuizResult, activeStageQuiz, tasksQuiz,
        activeTaskTest, quizResult, parseDate, currentQuest,
        setActiveTaskTest, setActiveStageQuiz, history
    } = props;
    const variable_22 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_22"});
    const variable_25 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_25"});
    const variable_26 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_26"});
    const variable_27 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_27"});
    const variable_28 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_28"});
    const variable_35 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_35"});
    const variable_36 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_36"});
    const variable_37 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_37"});
    const variable_38 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_38"});
    const variable_39 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_39"});

    let getApiTestId = (id, words = "", last = "") => {
        requests.quiz.get_quiz_result(id, "", true)
            .then((res) => {
                setQuizResult(res.data);
            });
    };
    return (
        <div className={"test_work"}>
            {stagesQuiz && stagesQuiz.length > 0
                ? <>
                    <div className="stage_block">
                        <div className="title">{variable_35}</div>
                        {stagesQuiz.map((elem) =>
                            <div
                                className={activeStageQuiz === elem.id ? "stage active" : "stage"}
                                key={elem.id}
                                onClick={() => {
                                    setActiveTaskTest(0);
                                    setActiveStageQuiz(elem.id);
                                }}
                            >
                                <span> {variable_22} — {elem.sortOrder} {elem.name}</span>
                            </div>
                        )
                        }
                    </div>
                    <div className="matherial_block">
                        <div className="title">{variable_36}</div>
                        {tasksQuiz && tasksQuiz.length > 0 && activeStageQuiz !== null
                            ? tasksQuiz.map((elem, indx) => {
                                    if (elem.stage.id === activeStageQuiz)
                                        return (
                                            <div
                                                onClick={() => {
                                                    setActiveTaskTest(elem.id);
                                                    getApiTestId(elem.id, "", true);

                                                }}
                                                className={activeTaskTest === elem.id ? "matherial active" : "matherial"}
                                                key={indx}
                                            >
                                                <div className="img">
                                                    <TestIcon/>
                                                </div>
                                                <span>{elem.title}</span>
                                            </div>
                                        );
                                }
                            )
                            : <></>
                        }
                        {activeStageQuiz === 0 &&
                            <div style={{textAlign: 'center'}}>
                                {variable_37}
                            </div>
                        }
                    </div>
                    <div className="work_block">
                        <div className="title">
                            {variable_38}
                        </div>
                        {quizResult && quizResult.length > 0 && activeTaskTest
                            ? quizResult.map((elem, indx) =>
                                <div
                                    className="row_study"
                                    onClick={() => {
                                        if (elem?.is_lms)
                                            history(`/backoffice/check-test-scorm/${elem.quiz.id}/${elem.user.id}`);
                                        else
                                            history(`/backoffice/check-test/${elem.quiz.id}/${elem.user.id}`);
                                    }}
                                    style={{cursor: "pointer"}}
                                    key={indx}
                                >
                                    <div className="top">
                                        <img
                                            src={elem.user.profile && elem.user.profile.avatar || fake_photos}/>
                                        <div className="border"/>
                                        <div
                                            className="name">{elem.user.profile && elem.user.profile.name}</div>
                                        <div className="border"/>
                                        <div className="name_matherial">
                                            {elem?.is_lms
                                                ? "SCORM"
                                                : <>{!elem.quiz.attempts ? variable_25 : variable_26}</>
                                            }

                                        </div>
                                        <div className="border"/>
                                        <div className="date">
                                            {parseDate(elem.time)}
                                        </div>
                                    </div>
                                    <div
                                        className="middle"
                                        style={{marginBottom: 0}}
                                    >
                                        {!elem.is_lms &&
                                            <>
                                                <div
                                                    className={elem.correct_answers_count !== elem.questions_count
                                                        ? "count false"
                                                        : "count"}
                                                >
                                                    <span>{elem.correct_answers_count}</span>
                                                    <span style={{margin: "0 2px"}}>/</span>
                                                    <span>{elem.questions_count}</span>
                                                </div>
                                                <div className="border"/>
                                                {elem.quiz.attempts &&
                                                    <div className="attempts">
                                                        <span>{variable_27} </span>
                                                        <span>{elem.attempt_number}</span>
                                                        <span style={{margin: "0 3px"}}>/</span>
                                                        <span style={{color: "#757A88"}}>{elem.quiz.attempts}</span>
                                                    </div>
                                                }
                                                {!elem.quiz.attempts &&
                                                    <div className="attempts">
                                                        <span>{variable_27} </span>
                                                        <span>{elem.attempt_number}</span>
                                                    </div>
                                                }
                                                <div className="border"/>
                                            </>
                                        }
                                        <span>{currentQuest.name}</span>
                                    </div>
                                </div>
                            )
                            : <></>
                        }
                        {activeStageQuiz !== 0 && quizResult.length === 0 &&
                            <div style={{textAlign: 'center'}}>{variable_39}</div>
                        }
                    </div>
                </>
                : <div className={"no_content"}>
                    {variable_28}
                </div>
            }
        </div>
    );
};

export default TestWork;