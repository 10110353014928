import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import ru_RU from "antd/lib/locale/ru_RU";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import App from "./App";

import "antd/dist/antd.less";
import "draft-js/dist/Draft.css";
import "react-multi-carousel/lib/styles.css";
import "react-phone-number-input/style.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "intro.js/introjs.css";
import "./backoffice/styles/index.scss";

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={ru_RU}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ConfigProvider>
    </Provider>,
    document.getElementById("root")
);
