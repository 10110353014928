import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const ChartJs = (props) => {
    let {data, current, diff = 0 } = props;
    let options = {
        responsive: true,
        animation: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                displayColors: false,
                titleAlign: 'center',
                bodyAlign: 'center',
                bodyColor: Math.sign(diff) === -1 ?  'red' : '#30E0A1',
                callbacks: {
                    // use label callback to return the desired label
                    label: function(tooltipItem, data) {
                        return tooltipItem.formattedValue
                    },
                    labelColor: function(tooltipItem, chart) {
                        return {
                            backgroundColor: '#30E0A1'
                        }
                    },

                },
                borderColor: '#30E0A1',
            },
        },
        elements: {
            line: {
                tension: 0.4
            }
        },
        scales: {
            y: {
                display: false // Hide Y axis labels
            },
            x: {
                display: false // Hide X axis labels
            }
        },

        title: {
            display: false
        },
    };
   /* ChartJS.options.elements.line.tension = 0.4;*/

    let labels = data && data.data && data.data.length > 0 && data.data.map((elem) => elem.date) || [];
    let dataChart = {
        labels,
        datasets: [
            {
                label: '',
                data: data && data.data && data.data.length > 0 && data.data.map((elem) => elem[`${current}`]) || [],
                borderColor: Math.sign(diff) === -1 ?  'red' : '#30E0A1',
                backgroundColor: 'transparent',
            },
        ],
    };
    return (
        <div>
            <Line options={options} data={dataChart || []}/>
        </div>
    );
};

export default ChartJs;