import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

import { Modal } from "antd";

import feedback_mail from "../../../images/icons/feedback_mail.svg";
import feedback_phone from "../../../images/icons/feedback_phone.svg";
import requests from "../../axios/requests";
import { changeFocusOrganization, setEndTrialDate } from "../../../redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";

const Trial = (props) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const {trial_end_date, trial_status} = useSelector(state => state.Reducer);
    let {hideBtn = false} = props;

    const variable_10 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_14"});
    const variable_15 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_16"});
    const variable_18 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_18"});

    const [flagTrial, setFlagTrial] = useState(false);

    let renderCountDown = ({days, hours, minutes, seconds, completed}) => {
        return (
            <>
                <div className="timer_item">{variable_13}</div>
                <div className="timer_item">{days} {variable_14}</div>
                <div className="timer_item">{hours} {variable_15}</div>
                <div className="timer_item">{minutes} {variable_16}</div>
            </>
        );
    };
    let trialLogOut = () => {
        window.localStorage.removeItem("user_id");
        window.localStorage.removeItem("name");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("photo");
        history(`/backoffice/login`);
    };

    useEffect(() => {
        requests.auth.get_profile_new()
            .then(res => {
                if (res.data?.organization?.status === 1) {
                    if (res.data?.organization?.trial_end_date) {
                        dispatch(setEndTrialDate(res.data.organization.trial_end_date));
                    }
                }
            });
    }, []);
    useEffect(() => {
        if (trial_status === 0 && !window.location.href.includes("profile")) {
            setFlagTrial(true);
        }
    }, [trial_status])
    return (
        <>

            <div className="timer_parent">
               {/* <Countdown
                    date={"2022-12-07T17:58:00"}
                    renderer={renderCountDown}
                />*/}
                {flagTrial !== null && trial_end_date
                    ? <Countdown
                            date={trial_end_date}
                            renderer={renderCountDown}
                        />
                    : <></>
                }
                {!hideBtn &&
                    <div
                        className="redirect_profile"
                        onClick={() => {
                            history("/backoffice/profile");
                            setTimeout(() => {
                                dispatch(changeFocusOrganization(true));
                            }, 300);
                        }}
                    >Подтвердить аккаунт
                    </div>
                }
            </div>
            <>
                {flagTrial &&
                    <Modal
                        visible={flagTrial}
                        footer={null}
                        className={"modal_trial"}
                        closable={false}
                        onCancel={() => {
                            setFlagTrial(false)
                            history("/backoffice/profile")
                            dispatch(changeFocusOrganization(true))
                        }}
                        maskStyle={{background: "rgba(22, 21, 27, 0.8)"}}
                    >
                        <div className="title">{variable_10}</div>
                        <div className="info">{variable_11}</div>
                        <div className="info">{variable_18}</div>
                        <div className="feedback_row">
                            <div><img src={feedback_mail}/><span> e.karpova@skillometer.ru</span></div>
                            {/*<div><img src={feedback_phone}/><span>+7 924 176 20 72</span></div>*/}
                        </div>
                        <div
                            className="btn_access"
                            onClick={() => {
                                setFlagTrial(false)
                                history("/backoffice/profile")
                                dispatch(changeFocusOrganization(true))
                            }}
                        >{variable_12}
                        </div>
                    </Modal>
                }
            </>
        </>

    );
};

export default Trial;