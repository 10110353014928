import React from 'react';
import { ReactComponent as Arrow } from "../../../images/arrow.svg";

const ScrollUp = ({refProps}) => {
    let handlerScrollUp = (ref) => {
        if(ref?.current) {
            ref.current.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }
    return (
        <div
            className="btn_scroll-up"
            onClick={() => handlerScrollUp(refProps)}
        >
            <Arrow/>
        </div>
    );
};

export default ScrollUp;