import React, { useState } from 'react';
import { Modal } from "antd";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

import close_modal_grey from "../../../../images/icons/close_modal-grey.png";
import './ModalReject.scss';
import Button from "../../../components/reuseComponents/Button/Button";

const ModalReject = ({openModalReject, setOpenModalReject, handleSendReject}) => {
    const variable_6 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_6"});
    const variable_32 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_32"});
    const variable_33 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_33"});
    const variable_34 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_34"});

    const [text, setText] = useState("");
    return (
        <Modal
            visible={openModalReject}
            footer={null}
            className={"modal_reject"}
            closable={false}
            onCancel={() => setOpenModalReject(false)}
            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
        >
            <div className="title">
                <span>{variable_32}</span>
                <div
                    className="close"
                    onClick={() => {
                        setOpenModalReject(false);
                    }}
                >
                    <img src={close_modal_grey}/>
                </div>
            </div>
            <div className="label">
                {variable_33}
            </div>
            <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder={variable_34}
                rows="4"
            />
            <Button
                text={variable_6}
                click={() => {
                    handleSendReject(text)
                    setText("")
                }}
                backgroundColor={"#CF1A1A"}
                boxShadow={"none"}
                margin={"0"}
                zIndex={3}
            />
        </Modal>
    );
};

export default ModalReject;