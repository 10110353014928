import React, { useEffect, useState } from "react";
import requests from "../../../axios/requests";
import { useNavigate, useParams } from "react-router-dom";

import { Tooltip } from "antd";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import PrevBtn from "../../../components/reuseComponents/PrevBtn";
import { DemoRow } from "../../GroupsList/GroupsList";

import { ReactComponent as Test } from "../../../../images/icons/test_icon.svg";
import { ReactComponent as Article } from "../../../../images/icons/article_icon.svg";
import { ReactComponent as Video } from "../../../../images/icons/video_icon.svg";
import { ReactComponent as Home } from "../../../../images/icons/home_work.svg";
import { ReactComponent as Scorm } from "../../../../images/icons/lms_icon.svg";

import fake_photos from "../../../../images/icons/fake_photos.png";

const StudentCard = () => {
    const params = useParams();
    const history = useNavigate();

    const variable_21 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_21"});
    const variable_22 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_22"});

    const [userCard, setUserCard] = useState({});

    let getApiStudentCard = () => {
        requests.quests.get_student_progress(params.id)
            .then((res) => {
                // объединить скиллы в два массива
                if (res.data && res.data.length > 0) {
                    let userTags = {...res.data[0].user};
                    userTags.added_tags = userTags.added_tags.map((elem) => ({
                        ...elem,
                        ["status"]: "added"
                    })) || [];
                    let arr = userTags.learned_tags.concat([], userTags.added_tags) || [];
                    setUserCard((prev) => ({...prev, ["tags"]: arr}));
                    setUserCard((prev) => ({...prev, ["progress"]: res.data}));
                }
            });
    };
    let convertLmsToStr = (el) => {
        let returnStrStatus = "";
        switch (el.status) {
            case "passed":
                returnStrStatus = 'Пройден';
                break;
            case "completed":
                returnStrStatus = 'Завершен';
                break;
            case "failed":
                returnStrStatus = 'Провален';
                break;
            case "incomplete":
                returnStrStatus = 'Незавершен';
                break;
            case "browsed":
                returnStrStatus = 'Просмотрен';
                break;
            case "attempted":
                returnStrStatus = 'Не было попыток';
                break;
            default:
                returnStrStatus = ""
        }
        return (
            <span>
                {el.status ? `Статус: ${returnStrStatus}` : ""} <br/>
                {el.session_time ? `Время: ${el.session_time}` : ""} <br/>
                {el.score ? `Результат: ${el.score}%` : ""}
            </span>
        )
    }

    useEffect(() => {
        getApiStudentCard();
    }, []);
    return (
        <div className={"view_study-page"}>
            <div className="top_info">
                <div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 10
                    }}>
                        <PrevBtn
                            click={() => history(-1)}
                        />
                        <DemoRow/>
                    </div>
                    <div
                        className="full_name">
                        {(userCard?.progress?.length > 0 &&
                            userCard?.progress[0]?.user?.profile?.name)
                        }
                    </div>
                </div>
                <div className="group_avatar">
                    <img
                        src={(userCard?.progress?.length > 0 && userCard?.progress[0]?.user?.profile?.avatar) || fake_photos}/>
                    <div className="skills_study">
                        <span>{variable_21}</span>
                        <div className="skills">
                            {userCard.tags && userCard.tags.map((elem, indx) =>
                                <div
                                    className={elem.status === "added" ? "skill yellow" : "skill"}
                                    key={indx}
                                >
                                    {elem.name}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="stages_block">
                {userCard?.progress?.length > 0 &&
                    userCard.progress.map((elem, indx) =>
                        <div
                            key={indx}
                            className={"stage"}
                        >
                            <span className={"title"}>{variable_22} {indx + 1}</span>
                            <div className="h4">{elem.name}</div>
                            <div className="icons_block">
                                {elem.theories.map((el, ind) => {
                                        let getIcon = (type) => {
                                            switch (type) {
                                                case "Q":
                                                    return <Test width={40} height={40}/>;
                                                case "A":
                                                    return <Article width={40} height={40}/>;
                                                case "V":
                                                    return <Video width={40} height={40}/>;
                                                case "T":
                                                    return <Home width={40} height={40}/>;
                                                case "LMS":
                                                    return <Scorm width={40} height={40}/>;
                                            }
                                        };
                                        let getColorIconLms = (el) => {
                                            switch (el.status) {
                                                case"passed":
                                                    return "true";
                                                case"browsed":
                                                    return "true";
                                                case"completed":
                                                    return "true";
                                                case"incomplete":
                                                    return "incomplete";
                                                case"failed":
                                                    return "failed";
                                                default:
                                                    return ""
                                            }
                                        };
                                        if (el.theory_type === "LMS" && (el.status || el.score || el.session_time))
                                            return (
                                                <Tooltip
                                                    title={convertLmsToStr(el)}
                                                    placement="top"
                                                    color={"#343843"}
                                                >
                                                    <div
                                                        key={ind}
                                                        className={`icon_svg ${getColorIconLms(el)}`}
                                                    >
                                                        <Scorm width={40} height={40}/>
                                                    </div>
                                                </Tooltip>
                                            )
                                        else
                                            return (
                                                <div
                                                    key={ind}
                                                    className={el.passed
                                                        ? "icon_svg true"
                                                        : "icon_svg"}
                                                >
                                                    {getIcon(el.theory_type)}
                                                </div>
                                            )
                                    }
                                )}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default StudentCard;