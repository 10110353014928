import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import requests from "../../axios/requests";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

import { Modal, Pagination, Spin } from "antd";
import ReuseDropDown from "../../components/reuseComponents/ReuseDropDown";
import Trial from "../../components/reuseComponents/Trial";
import CourseCard from "../../components/CourseCard/CourseCard";
import ModalInfo from "../CourseManage/ModalInfo/ModalInfo";
import { setScrollUp } from "../../../redux/actions/actions";
import Input from "../../components/Input/Input";

import { ReactComponent as BtnAdd } from "../../../images/icons/add_course.svg";
import { ReactComponent as Logo } from "../../../images/icons/logo.svg";
import { ReactComponent as AddCourseMobile } from "../../../images/icons/add_course-mobile.svg";
import { ReactComponent as SearchCourseMobile } from "../../../images/icons/search_course-mobile.svg";
import { ReactComponent as SettingsMobile } from "../../../images/icons/settings_mobile.svg";
import { ReactComponent as Search } from "../../../images/icons/search_icon.svg";
import settings_mobile_category from "../../../images/icons/settings_mobile-category.png";
import close_modal_grey from "../../../images/icons/close_modal-grey.png";

const MyCourse = () => {
    // Если если используется в скилсете
    const insideSkillSet = document.location.pathname.includes('skill-set')
    // Если если используется в скилсете
    const variable_1 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_5"});
    const variable_7 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_8"});
    const variable__11 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_11"});
    const variable__12 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_12"});
    const variable__13 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_13"});
    const variable__14 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_14"});
    const variable__15 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_15"});
    const variable__16 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_16"});
    const variable__17 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_17"});

    const history = useNavigate();
    const dispatch = useDispatch();
    const {trial_status} = useSelector(state => state.Reducer);

    const [data, setData] = useState([]);
    const [activeCategory, setActiveCategory] = useState(0);
    const [searchCourseValue, setSearchCourseValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState("");
    const [countItemsPage] = useState(20);
    const [flagOpenCategory, setFlagOpenCategory] = useState(false);
    const [flagOpenSearchMobile, setFlagOpenSearchMobile] = useState(false);
    const [flagModalSettingsMobile, setFlagModalSettingsMobile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [onLoadPage, setOnLoadPage] = useState(false);
    const [baseAccess, setBaseAccess] = useState(false);
    const [openModalInfo, setOpenModalInfo] = useState(false);
    const categorySort = [
        variable_7, variable__17, variable__12,
        variable__14, variable__11,
        variable__13, variable__16,
        variable__15];

    let getApiBaseAccess = () => {
        requests.organization.has_base_acccess()
            .then(res => setBaseAccess(res.data))
    };
    let itemRender = (_, type, originalElement) => {
        if (type === "prev")
            return null;
        if (type === "next") {
            return;
        }
        return originalElement;
    };
    let getApiQuest = (activeCategory, searchValue, curentPage, count) => {
        requests.quests
            .get_quest_pagination(activeCategory, searchValue, curentPage, count)
            .then((data) => {
                if (data.status === 200) {
                    if (data.data === undefined || data.data === null) {
                        data.data = [];
                    }
                    setData(data.data.results);
                    setTotal(data.data.count);
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        setOnLoadPage(true);
        getApiBaseAccess();
    }, []);
    useEffect(() => {
        if (onLoadPage)
            getApiQuest(activeCategory, searchCourseValue, 1, countItemsPage);
    }, [activeCategory]);
    useEffect(() => {
        getApiQuest(activeCategory, searchCourseValue, currentPage, countItemsPage);
        dispatch(setScrollUp(true))
    }, [currentPage])
    useEffect(() => {
        getApiQuest(activeCategory, searchCourseValue, currentPage, countItemsPage);
    }, [searchCourseValue])
    return (
        <div className={"my_course"}>
            <div className="inner_block">
                {(trial_status === 0 || trial_status === 1) && <Trial/>}
                <div
                    className={flagOpenSearchMobile
                        ? "top_filter-mobile hidden"
                        : "top_filter-mobile"
                    }
                >
                    <div className="icon">
                        <Logo
                            width={38}
                            height={38}
                            onClick={() => window.open("https://skillometer.ru/", 'blank')}
                        />
                    </div>
                    <div className="filters_mobile">
                        <div
                            className="add_course"
                            onClick={() => history("/backoffice/current-course/")}
                        >
                            <AddCourseMobile/>
                        </div>
                        <div className="search_course-mobile"
                             onClick={() => setFlagOpenSearchMobile(true)}
                        >
                            <SearchCourseMobile/>
                        </div>
                    </div>
                    <div className={flagOpenSearchMobile ? "search_mobile active" : "search_mobile"}>
                        <div className={"svg"}>
                            <Search/>
                        </div>
                        <Input
                            className={"input_classic input_search-mobile"}
                            placeholder={""}
                            value={searchCourseValue}
                            onChange={(e) => {
                                setSearchCourseValue(e.target.value);
                                getApiQuest(activeCategory, searchCourseValue, currentPage, countItemsPage);
                            }}
                            // onKeyDown={(e) => handleSearchProfessionKeyDown(e)}
                        />
                        <span onClick={() => {
                            setFlagOpenSearchMobile(false);
                            setSearchCourseValue("");
                            getApiQuest(activeCategory, "", currentPage, countItemsPage);
                        }}>{variable_1}</span>
                    </div>
                    <SettingsMobile
                        className="settings"
                        onClick={() => {
                            setFlagModalSettingsMobile(true);
                            setFlagOpenSearchMobile(false);
                        }}
                    />
                </div>
                {!insideSkillSet && <div className="top">
                    <span>{variable_2}</span>
                    <div className="group">
                        <div className={"active"}>{variable_2}</div>
                        <div onClick={() => history("/backoffice/request-members")}>{variable_3}</div>
                        <div onClick={() => history("/backoffice/groups-list")}>{variable_4}</div>
                    </div>
                </div>}
                <div className={"course_block"}>
                    <div className={"course_top-filter"}>
                        {(trial_status === 0 || trial_status === 1)
                            ? <></>
                            : <div
                                style={{display: insideSkillSet ? 'none' : 'block'}}
                                className="btn_add button_classic"
                                onClick={() => history("/backoffice/current-course/")}
                            >
                                {variable_5}
                            </div>
                        }
                        <div className="search">
                            <Input
                                padding={"5px 96px 7px 46px"}
                                value={searchCourseValue}
                                setValue={setSearchCourseValue}
                                backgroundColor={"transparent"}
                                needFindBtn={true}
                            />
                            {/*<InputWsButton
                                value={searchCourseValue}
                                setValue={(e) => setSearchCourseValue(e)}
                            />*/}
                        </div>
                        <ReuseDropDown
                            flagOpen={flagOpenCategory}
                            setFlagOpen={(bool) => setFlagOpenCategory(bool)}
                            arrList={categorySort}
                            activeArrList={activeCategory}
                            setActiveArrList={(indx) => setActiveCategory(indx)}
                        />
                    </div>
                    <ul className="course_list">
                        {data && data.length > 0
                            ? data.map((elem, indx) =>
                                <li key={indx} className={"reuse_course-item"} style={{minWidth: insideSkillSet ? '270px' : '297px'}}>
                                    <CourseCard
                                        course={elem}
                                        dateCourse={true}
                                        cost={true}
                                        redirectUrl={elem.id}
                                        rating={true}
                                        popular={false}
                                        setCourse={(id, value) => {
                                            let newArr = [...data];
                                            let ind = data.findIndex(x => x.id === id);
                                            newArr[ind].isEnabled = value;
                                            setData(newArr);
                                        }}
                                        baseAccess={baseAccess}
                                        setOpenModalInfo={(value) => setOpenModalInfo(value)}
                                    />
                                </li>
                            )
                            : <></>
                        }
                        {loading &&
                            <Spin className={"spin"} style={{position: "absolute"}}/>
                        }
                    </ul>
                    {total && total > countItemsPage
                        ? <div className="pagination_all">
                            <Pagination
                                itemRender={itemRender}
                                defaultCurrent={1}
                                defaultPageSize={countItemsPage}
                                total={total}
                                onChange={(e) => setCurrentPage(e)}
                                showTitle={false}
                            />
                        </div>
                        : <></>
                    }
                    {!loading && (trial_status !== 0 && trial_status !== 1) &&
                        <div style={{width: "100%", display: insideSkillSet ? "none" : "flex", paddingRight: 15}}>
                            <BtnAdd
                                style={{margin: "0 auto", cursor: "pointer"}}
                                onClick={() => history("/backoffice/current-course/")}
                            />
                        </div>
                    }
                </div>
            </div>
            <>
                <Modal
                    visible={flagModalSettingsMobile}
                    footer={null}
                    className={"modal_settings-mobile"}
                    closable={false}
                    onCancel={() => {
                        setFlagModalSettingsMobile(false);
                    }}
                    maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                    bodyStyle={{padding: 30, backgroundColor: "#343843", borderRadius: 16}}
                >
                    <div className="title">
                        <div className="group_left">
                            <img src={settings_mobile_category}/>
                            <span>{variable_8}</span>
                        </div>
                        <div className="close" onClick={() => {
                            setFlagModalSettingsMobile(false);
                        }}
                        >
                            <img src={close_modal_grey}/>
                        </div>
                    </div>
                    <div className="list">
                        {categorySort.map((elem, indx) =>
                            <div
                                key={indx}
                                onClick={() => {
                                    setFlagModalSettingsMobile(false);
                                    setActiveCategory(indx);
                                }}
                            >
                                {elem}
                            </div>
                        )}
                    </div>
                </Modal>
            </>
            <ModalInfo
                openModalInfo={openModalInfo}
                setOpenModalInfo={() => setOpenModalInfo(false)}
            />
            <>
                <div
                    className={flagOpenCategory ? "overlay open" : "overlay"}
                    onClick={() => {
                        setFlagOpenCategory(false);
                    }}
                />
            </>
        </div>
    );
};

export default MyCourse;