let initialState = {
    language: "ru",
    activeTheme: false,
    trial_end_date: "",
    trial_status: null,
    focusOnOrganizationBlock: false,
    currentQuest: {},
    currentGroup: {},
    activeModules: [],
    listModules: [],
    myRegion: localStorage.getItem("region") || "Москва",
    userSkills: [],
    trajectorySkills: [],
    user: {},
    scrollDirection: null,
    config: null,
    collapse: true,
    flagSteps: false,
    showModalBuy: false,
    selectedTag: '',
    loadingGpt: false,
    loadingIndx: null,
};
let Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGE_LANGUAGE":
            return {
                ...state,
                language: action.payload
            };
        case "SET_TRIAL_DATE":
            return {
                ...state,
                trial_end_date: action.payload
            };
        case "SET_STATUS_TRIAL":
            return {
                ...state,
                trial_status: action.payload
            };
        case "CHANGE_FOCUS_ORGANIZATION":
            return {
                ...state,
                focusOnOrganizationBlock: action.payload
            };
        case "SET_CURRENT_QUEST":
            return {
                ...state,
                currentQuest: action.payload
            };
        case "SET_CURRENT_GROUP":
            return {
                ...state,
                currentGroup: action.payload
            };
        case "CHANGE_THEME":
            return {
                ...state,
                activeTheme: action.payload
            };
        case "SET_ACTIVE_MODULES":
            return {
                ...state,
                activeModules: action.payload
            };
        case "SET_USER_SKILLS":
            return {
                ...state,
                userSkills: action.payload
            };
        case 'SET_USER_TRAJECTORY':
            return {
                ...state,
                trajectorySkills: action.payload
            };
        case 'SET_USER_REGION':
            return {
                ...state,
                myRegion: action.payload
            };
        case "SET_USER":
            return {
                ...state,
                user: action.payload
            };
        case "SCROLL_DIRECTION":
            return {
                ...state,
                scrollDirection: action.payload
            };
        case "SET_CONFIG":
            return {
                ...state,
                config: action.payload
            };
        case "SET_COLLAPSE":
            return {
                ...state,
                collapse: action.payload
            };
        case "SET_FLAG_STEPS":
            return {
                ...state,
                flagSteps: action.payload
            };
        case "SET_LIST_MODULES":
            return {
                ...state,
                listModules: action.payload
            };
        case "SET_SCROLL_UP":
            return {
                ...state,
                scrollUp: action.payload
            };
        case "SET_SHOW_GENERATE_BUY":
            return {
                ...state,
                showModalBuy: action.payload
            };
        case "SET_SELECTED_TAG"://для скилсета
            return {
                ...state,
                selectedTag: action.payload
            };
        case "SET_LOADING_GPT"://для скилсета
            return {
                ...state,
                loadingGpt: action.payload
            };
        case "SET_LOADING_INDX"://для скилсета
            return {
                ...state,
                loadingIndx: action.payload
            };
        default:
            return state;
    }
};
export default Reducer;