import React, { useEffect, useState } from 'react';
import ReactPlayer from "react-player";

import ReuseDropDown from "../../../components/reuseComponents/ReuseDropDown";
import { StorageUploader } from "../../../data/StorageUploader";
import { message, Tooltip } from "antd";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import question_icon from "../../../../images/icons/question.svg";

const CreateOrEditCode = (props) => {
    let {
        stage_id, propsSetView, createCode, editCode, stageItems,
        videoKey, imageKey, getYandexKey, isEditComponent = false
    } = props;

    const variable_18 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_18"});
    const variable_24 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_24"});
    const variable_26 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_26"});
    const variable_27 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_27"});
    const variable_28 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_28"});
    const variable_29 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_29"});
    const variable_32 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_32"});
    const variable_33 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_33"});
    const variable_34 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_34"});
    const variable_35 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_35"});
    const variable_73 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_73"});
    const variable_74 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_74"});
    const variable_75 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_75"});
    const variable_80 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_80"});
    const variable_81 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_81"});
    const variable_85 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_85"});
    const variable_86 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_86"});
    const variable_87 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_87"});
    const variable_88 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_88"});
    const variable_89 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_89"});

    const [valuesObject, setValuesObject] = useState({
        stage: stage_id,
        theory_type: "T",
        task_type: 'code',
        title: '',
        text: '',
        image_file: '',
        image_file_name: '',
        video_file: '',
        video_file_name: '',
        youtube: '',
        url_refs: '',
        block_next: false,
        language: 'python'
    })
    const [fileProcess, setFileProcess] = useState(null);
    const [activeDemoVideo, setActiveDemoVideo] = useState(0)
    const [flagTypeLesson, setFlagTypeLesson] = useState(false);
    const [flagLanguageLesson, setFlagLanguageLesson] = useState(false);
    const languages = ["Python", "Java", "C++", "C#", "C", "Golang", "JavaScript"];

    let displayCurrentLanguage = () => {
        switch (valuesObject.language) {
            case 'python':
                return 0;
            case 'java':
                return 1;
            case 'c':
                return 2;
            case 'c++':
                return 3;
            case 'c#':
                return 4;
            case 'golang':
                return 5;
            case 'js':
                return 6;
        }
    }

    useEffect(() => {
        getYandexKey('T', 'imageKey');
        getYandexKey('T', 'videoKey');
    }, [])
    useEffect(() => {
        if (props.stageItems !== undefined) {
            setValuesObject(props.stageItems)
            if (!props.stageItems.youtube && props.stageItems.video_file) {
                setActiveDemoVideo(1)
            }
        }
    }, [props.stageItems])
    return (
        <div className="create_code">
            <div className="group_drop-down" style={{position: 'relative', zIndex: 10}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className="title"> {variable_75}</div>
                    <Tooltip
                        title={valuesObject.block_next === false ? variable_88 : variable_89}
                        align={"top"}
                    >
                        <img src={question_icon} style={{marginBottom: 5, marginLeft: 15, cursor: 'pointer'}}/>
                    </Tooltip>
                </div>
                <ReuseDropDown
                    flagOpen={flagTypeLesson}
                    setFlagOpen={(bool) => setFlagTypeLesson(bool)}
                    arrList={[variable_73, variable_74]}
                    activeArrList={valuesObject.block_next === false ? 0 : 1}
                    setActiveArrList={(indx) => setValuesObject(prev => ({
                        ...prev,
                        ['block_next']: indx === 0 ? false : true
                    }))}
                    arrow={true}
                    onBlur={() => setFlagTypeLesson(false)}
                />
            </div>
            <div className="group_drop-down">
                <div className="label"> {variable_87}</div>
                <ReuseDropDown
                    flagOpen={flagLanguageLesson}
                    setFlagOpen={(bool) => setFlagLanguageLesson(bool)}
                    arrList={languages}
                    activeArrList={displayCurrentLanguage()}
                    setActiveArrList={(indx) => {
                        let langStr = '';
                        switch (indx) {
                            case 0:
                                langStr = 'python';
                                break;
                            case 1:
                                langStr = 'java';
                                break;
                            case 2:
                                langStr = 'c';
                                break;
                            case 3:
                                langStr = 'c++';
                                break;
                            case 4:
                                langStr = 'c#';
                                break;
                            case 5:
                                langStr = 'golang';
                                break;
                            case 6:
                                langStr = 'js';
                                break;
                            default:
                                langStr = 'python'
                        }
                        setValuesObject(prev => ({...prev, ['language']: langStr}))
                    }}
                    arrow={true}
                    onBlur={() => setFlagLanguageLesson(false)}
                />
            </div>
            <div className="label">{variable_24}</div>
            <input
                value={valuesObject.title}
                onChange={(e) => setValuesObject(prev => ({...prev, ['title']: e.target.value}))}
                placeholder={variable_26}
                maxLength={99}
            />
            <div className="label">{variable_80}</div>
            <textarea
                value={valuesObject.text}
                onChange={(e) => setValuesObject(prev => ({...prev, ['text']: e.target.value}))}
                tabIndex={1}
                placeholder={variable_81}
                rows="5"
            />
            <div className="label">{variable_35}</div>
            <StorageUploader
                yandexKey={imageKey}
                count={1}
                fileTypes={"image/*"}
                setFileName={(name) => setValuesObject(prev => ({...prev, ['image_file_name']: name}))}
                fileWillBeUpload={(fileName) => {
                    setValuesObject(prev =>
                        ({
                            ...prev,
                            ['image_file']: 'https://storage.yandexcloud.net/skill-storage/' + imageKey + "/" + fileName.replace(/\s+/g, "_")
                        })
                    )
                }}
                setFileListProps={(value) => {
                    setFileProcess(value);
                    setValuesObject(prev => ({...prev, ['image_file_name']: value[0].name}))
                }}
                firstRenderImage={props.stageItems !== undefined && valuesObject.image_file && valuesObject.image_file_name
                    ? [{
                        uid: valuesObject.image_file,
                        name: valuesObject.image_file_name,
                        status: "done"
                    }]
                    : []
                }
                removeFile={() => {
                    setValuesObject(prev => ({...prev, ['image_file']: ''}))
                    setValuesObject(prev => ({...prev, ['image_file_name']: ''}))
                }}
            />
            <div className="demo_row">
                <span>{variable_27}</span>
                <div className="group">
                    <div
                        className={activeDemoVideo === 0 ? "active" : ""}
                        onClick={() => setActiveDemoVideo(0)}
                    >
                        {variable_28}
                    </div>
                    <div
                        className={activeDemoVideo === 1 ? "active" : ""}
                        onClick={() => setActiveDemoVideo(1)}
                    >
                        {variable_29}
                    </div>
                </div>
            </div>
            {activeDemoVideo === 0
                ? <input
                    value={valuesObject.youtube}
                    onChange={(e) => setValuesObject(prev => ({...prev, ['youtube']: e.target.value}))}
                    placeholder={variable_26}
                />
                : <StorageUploader
                    yandexKey={videoKey}
                    count={1}
                    fileTypes={"video/*"}
                    editable={true}
                    setFileName={(name) => {
                        setValuesObject(prev => ({...prev, ['video_file_name']: name}))
                        setValuesObject(prev => ({...prev, ['youtube']: ''}))
                    }}
                    fileWillBeUpload={(fileName) => {
                        setValuesObject(prev =>
                            ({
                                ...prev,
                                ['video_file']: 'https://storage.yandexcloud.net/skill-storage/' + videoKey + "/" + fileName.replace(/\s+/g, "_")
                            })
                        )
                    }}
                    setFileListProps={(value) => {
                        setFileProcess(value);
                        setValuesObject(prev => ({...prev, ['video_file_name']: value[0].name}))
                    }}
                    firstRenderFile={props.stageItems !== undefined && valuesObject.video_file && valuesObject.video_file_name
                        ? [{
                            uid: valuesObject.video_file,
                            name: valuesObject.video_file_name,
                            status: "done"
                        }]
                        : false
                    }
                    removeFile={() => {
                        setValuesObject(prev => ({...prev, ['video_file']: ''}))
                        setValuesObject(prev => ({...prev, ['video_file_name']: ''}))
                    }}
                />
            }
            {activeDemoVideo === 0 && valuesObject.youtube
                ? <div className="player-wrapper" style={{marginTop: 10}}>
                    <ReactPlayer
                        className="react-player"
                        controls={true}
                        url={valuesObject.youtube}
                        width="100%"
                        height="100%"
                    />
                </div>
                : <></>
            }
            <div className="label">{variable_32}</div>
            <input
                value={valuesObject.url_refs}
                onChange={(e) => setValuesObject(prev => ({...prev, ['url_refs']: e.target.value}))}
                placeholder={variable_32}
            />
            <div className="group_buttons">
                <div
                    className={fileProcess !== null && fileProcess[0].percent !== 100 ? "btn_save disabled" : "btn_save"}
                    onClick={() => {
                        if (fileProcess !== null && fileProcess[0].percent !== 100) return
                        if (!valuesObject.title) return message.error(variable_85)
                        if (!valuesObject.text) return message.error(variable_86)
                        if (isEditComponent) {
                            valuesObject.stage = stage_id;
                            return editCode(valuesObject)
                        }
                        createCode(valuesObject)
                    }}
                >
                    {isEditComponent ? variable_33 : variable_18}
                </div>
                <div
                    className="btn_cansel btn_save"
                    onClick={() => propsSetView()}
                >
                    {variable_34}
                </div>
            </div>
        </div>
    );
};

export default CreateOrEditCode;