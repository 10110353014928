import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import requests from "../../axios/requests";
import moment from "moment";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

import TextArea from "antd/es/input/TextArea";
import { message, Modal, Pagination } from "antd";
import Trial from "../../components/reuseComponents/Trial";

import { ReactComponent as Close } from "../../../images/icons/close_x.svg";
import { defaultFuncAndData } from "../../utils/defaultData";

const GetCurrentButton = ({elem, setHandleClick, variable_6, variable_7}) => {
    const buttons = [
        (<div className="btn" onClick={() => setHandleClick(elem)}>{variable_6}</div>),
        (<div className="btn show" onClick={() => setHandleClick(elem)}>{variable_7}</div>),
    ];
    if (elem.viewed)
        return buttons[1];
    else return buttons[0];
};
const Notification = () => {
    const {trial_status} = useSelector(state => state.Reducer);
    const history = useNavigate();
    const {pathname} = useLocation();

    const variable_1 = variableLanguage({keyPage: "notification", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "notification", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "notification", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "notification", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "notification", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "notification", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "notification", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "notification", keyVariable: "variable_8"});

    const [dataNotification, setDataNotification] = useState([]);
    const [activeNavigation, setActiveNavigation] = useState(0);
    const [currentItem, setCurrentItem] = useState(null);
    const [total, setTotal] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [scrollY, setscrollY] = useState(null);

    const [flagHiddenFotter, setFlagHiddenFooter] = useState(null);
    const [flagOpenModal, setFlagOpenModal] = useState(null);

    let itemRender = (_, type, originalElement) => {
        if (type === "prev")
            return null;
        if (type === "next") {
            return;
        }
        return originalElement;
    };
    let handleActiveModal = (elem) => {
        postApiViewNotification(elem.id);
        setCurrentItem(elem);
        setFlagOpenModal(true);
    };
    let getApiNotificationCount = () => {
        requests.notification.get_count_notification()
            .then((res) => {
                if (typeof res.data.count !== "undefined")
                    localStorage.setItem("count_notification", res.data.count);
            })
            .catch(err => console.error(err));
    };
    let getApiNotification = (currentPageProps = currentPage) => {
        let obj = {
            viewed: activeNavigation === 0 ? false : null,
            page: currentPage,
            size: 20
        };
        requests.notification.get_notification(obj)
            .then(res => {
                setDataNotification(res.data.items);
                setTotal(res.data.total);
            })
            .catch(err => {
                console.error(err);
                message.error(variable_8);
            });
    };
    let postApiViewNotification = (id) => {
        requests.notification.post_view_notification(id)
            .then(() => {
                let newArr = [...dataNotification];
                if (newArr.length > 0) {
                    let findIndx = newArr.findIndex(x => x.id === id);
                    setTimeout(() => {
                        newArr[findIndx].viewed = true;
                        setDataNotification(newArr);
                        getApiNotificationCount();
                    }, 500);
                    setTimeout(() => {
                        getApiNotification();
                    }, 1500);
                }
            })
            .catch(err => {
                console.error(err);
            });
    };

    useEffect(() => {
        getApiNotification();
    }, [currentPage]);
    useEffect(() => {
        getApiNotification(1);
        setCurrentPage(1);
    }, [activeNavigation]);
    return (
        <div className={"notification"}>
            <div className="inner_block">
                {(trial_status === 0 || trial_status === 1) && <Trial/>}
                <div className="top">
                    <span>{variable_1}</span>
                    <div className="group">
                        <div
                            className={activeNavigation === 0 ? "active" : ""}
                            onClick={() => setActiveNavigation(0)}
                        >{variable_2}
                        </div>
                        <div
                            className={activeNavigation === 1 ? "active" : ""}
                            onClick={() => setActiveNavigation(1)}
                        >{variable_3}
                        </div>
                    </div>
                </div>
                {dataNotification.length > 0 &&
                    <div className="notification_items">
                        {dataNotification.map((elem, indx) => {
                                if (elem.type === "user_question")
                                    return (
                                        <div
                                            className="item"
                                            key={indx}
                                        >
                                            <div
                                                className="type">{elem.type === "user_question" ? variable_4 : variable_5}</div>
                                            <div
                                                className="date">{moment(elem?.created_at).format("DD.MM.YY / HH:mm:ss")}</div>
                                            <div className="border"/>
                                            <div className="course_name">{elem?.extra?.quest?.name || ""}</div>
                                            <div className="border"/>
                                            <div className="student_name">{elem.extra?.sender_name || ""}</div>
                                            <div className="border"/>
                                            <div
                                                className="phone">{elem.extra?.phone ? elem.extra.phone.replace(/-/g, "").replace("(", "").replace(")", "").replace(/ /g, "") : ""}</div>
                                            <div className="border"/>
                                            <div
                                                className="message">{localStorage.getItem("language") === "en" ? elem.text_eng : elem.text}</div>
                                            <GetCurrentButton
                                                elem={elem}
                                                setHandleClick={handleActiveModal}
                                                variable_6={variable_6}
                                                variable_7={variable_7}
                                            />
                                        </div>
                                    );
                                return (
                                    <div
                                        className="item"
                                        key={indx}
                                    >
                                        <div
                                            className="type">{elem.type === "user_question" ? variable_4 : variable_5}</div>
                                        <div className="date">{moment(elem?.created_at).format("DD.MM.YY / HH:mm:ss")}</div>
                                        <div className="border"/>
                                        <div className="message"
                                             style={{maxWidth: 615}}>{localStorage.getItem("language") === "en" ? elem?.text_eng : elem?.text}</div>
                                        <GetCurrentButton
                                            elem={elem}
                                            setHandleClick={handleActiveModal}
                                            variable_6={variable_6}
                                            variable_7={variable_7}
                                        />
                                    </div>
                                );
                            }
                        )}
                    </div>
                }
                {total && total > 20
                    ? <div className="pagination_all">
                        <Pagination
                            itemRender={itemRender}
                            current={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={20}
                            total={total}
                            onChange={(e) => setCurrentPage(e)}
                            showTitle={false}
                        />
                    </div>
                    : <></>
                }
            </div>
            <>
                {flagOpenModal &&
                    <Modal
                        visible={flagOpenModal}
                        footer={null}
                        className={"modal_notification"}
                        closable={false}
                        onCancel={() => {
                            setFlagHiddenFooter(null);
                            setFlagOpenModal(false);
                            setCurrentItem(null);
                        }}
                        maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                    >
                        <div className="close_modal" onClick={() => setFlagOpenModal(false)}><Close/></div>
                        {currentItem.type === "user_question"
                            ? <>
                                <div className="title">{currentItem?.extra?.quest?.name || ""}</div>
                                <div className="name"><span>{currentItem?.extra?.sender_name || ""}</span></div>
                                <div className="phone"><span>{currentItem?.extra?.phone || ""}</span></div>
                                <TextArea
                                    value={(localStorage.getItem("language") === "en" ? currentItem?.text_eng : currentItem?.text) || ""}
                                    className={"textarea_antd"}
                                    disabled={true}
                                />
                            </>
                            : <>
                                <div
                                    className="title">
                                    {(localStorage.getItem("language") === "en"
                                        ? defaultFuncAndData.brChanger(currentItem?.text_eng)
                                        : defaultFuncAndData.brChanger(currentItem?.text))
                                    }
                                </div>
                                {(currentItem?.text.includes("выполнил задание") || currentItem?.text.includes("completed the hometask")) &&
                                Object.keys(currentItem?.extra).length > 0
                                    ? <div
                                        className="link_redirect"
                                        onClick={() => {
                                            if (currentItem.extra.task_type === "code")
                                                history(
                                                    `/backoffice/check-codework/` +
                                                    `${currentItem?.extra.id}/${currentItem?.extra.stage_theory_id}/${currentItem?.extra.stage_id}/false`
                                                );
                                            else
                                                history(
                                                    `/backoffice/check-homework/` +
                                                    `${currentItem?.extra.id}/${currentItem?.extra.stage_theory_id}/${currentItem?.extra.stage_id}/false`
                                                );
                                        }}
                                    >
                                        Перейти к проверке д\з
                                    </div>
                                    : <></>
                                }
                            </>
                        }
                    </Modal>
                }
            </>
        </div>
    );
};

export default Notification;