import React, { useEffect, useState } from "react";
import { Input, message, Steps, Tooltip } from "antd";
import requests from "../../../axios/requests";

import CommentCustom from "../../../components/reuseComponents/CommentCustom";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

import send_comment from "../../../../images/icons/send_comment.png";

const customDot = (dot) => <Tooltip>{dot}</Tooltip>;
const isAnswerCorrect = (item) => {
    return item.correct === true ? true : false;
};
const Quiz = (props) => {
    const {questions, id} = props;

    const variable_5 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_7"});
    const variable_10 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_10"});

    const [active, setActive] = useState(0);
    const labels = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    const [valueComment, setValueComment] = useState("");
    const [comments, setComments] = useState("");

    let getApiComments = () => {
        requests.comments.get_comments({
            contentType: 46,
            objectId: id
        })
            .then((res) => {
                setComments(res.data);
            })
            .catch((error) => console.log(error, "error get_comments"));
    };
    let sendComment = () => {
        if (!valueComment) {
            return;
        }
        let obj = {
            phone: props.profile.phone,
            user: {
                profile: {
                    avatar: props.profile.avatar || ""
                },
            },
            content_type: 46,
            object_id: id,
            text: valueComment
        };
        requests.comments.create_comment(obj)
            .then((res) => {
                setComments((prev) => [...prev, res.data]);
            })
            .catch((error) => console.log(error, "error add_comment"));
        setValueComment("");
    };
    let handleSetLikeDislake = (id, value) => {
        let obj = {
            "content_type": 86,
            "object_id": id,
            "value": value
        };
        requests.comments.like_dislike(obj)
            .then((res) => {
                getApiComments();
            })
            .catch((error) => console.log(error, "error set_like"))
            .finally();
    };
    let handleRemoveComment = (id) => {
        requests.comments.remove_comment(id)
            .then(() => {
                getApiComments();
                message.success(variable_7);
            })
            .catch(err => console.error(err));
    };

    useEffect(() => {
        getApiComments();
    }, [props.id]);
    return (
        <>
            <div className="steps-content">
                <h3 style={{color: '#A2AACA'}}>{questions[active]?.title}</h3>
                {questions.length < 1 &&
                    <div>{variable_10}</div>
                }
                <Steps direction="vertical">
                    {questions[active]?.answers.map((item, i) => (
                        <Steps.Step
                            key={i}
                            className={isAnswerCorrect(item) ? "greenStep" : ""}
                            description={item.text}
                            progressDot={() =>
                                customDot(
                                    labels[
                                        questions[active].answers
                                            .map((e) => e.id)
                                            .indexOf(item.id)
                                        ]
                                )
                            }
                            status="process"
                        />
                    ))}
                </Steps>
            </div>
            <div className="custom_steps">
                {questions && questions.length > 0 ?
                    questions.map((_, index) => (
                        <>
                            <div
                                onClick={() => setActive(index)}
                                className={active === index ? "step active" : "step"}
                                key={index}
                            >
                                <span> {index + 1}</span>
                            </div>
                            {index !== questions.length - 1 &&
                                <div className={"line"}/>
                            }
                        </>
                    ))
                    : <></>
                }
            </div>
            {props?.show_comments &&
                <div style={{width: "100%", marginTop: "auto"}}>
                    {comments && comments.length > 0 &&
                        <div className="comment_title">
                            {variable_5}
                        </div>
                    }
                    {comments && comments.length > 0 &&
                        comments.map((com, indx) =>
                            <div key={indx}>
                                <CommentCustom
                                    {...com}
                                    setLike={(id) => handleSetLikeDislake(id, true)}
                                    setDislake={(id) => handleSetLikeDislake(id, false)}
                                    removeComment={handleRemoveComment}
                                />
                            </div>
                        )
                    }
                    <div className="comment_input">
                        <Input
                            placeholder={variable_6}
                            className={"input_classic"}
                            value={valueComment}
                            onChange={(e) => setValueComment(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) sendComment();
                            }}
                        />
                        <img src={send_comment} onClick={sendComment}/>
                    </div>
                </div>
            }
        </>
    );
};

export default Quiz;
