import React from "react";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import { Input, Tooltip } from "antd";
import { ReactComponent as QuestionIcon } from "../../../images/icons/question.svg";

const InputCustom = (props) => {
    let {
        value, setValue, title, placeholder, onBlur, maxLen,
        required = false, info = false, type = "text",
    } = props;

    const variable_34 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_34"});

    return (
        <div className={"input_custom-parent"}>
            <div className="title_info">
                <span>{title}</span>
                {required &&
                    <span style={{marginLeft: 4, color: 'red'}}> *</span>
                }
                {info &&
                    <Tooltip title={info} align={"top"}>
                        <QuestionIcon/>
                    </Tooltip>
                }
            </div>
            <Input
                className={"input_custom input_classic"}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={placeholder || variable_34}
                onBlur={onBlur}
                maxLength={maxLen || undefined}
                aria-autocomplete={'both'}
                autoComplete="nope"
                type={type}
                onWheelCapture={e => {
                    e.target.blur()
                }}
            />
        </div>
    );
};

export default InputCustom;