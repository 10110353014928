import React from 'react';
import variableLanguage from '../../utils/hooks/hookGetVariablesLanguage';
const Error404 = () => {
    const variable_47 = variableLanguage({keyPage: 'landing', keyVariable: 'variable_47'})
    return (
        <div className="block_404">
            <div className="img">
                <span>{variable_47}</span>
            </div>
        </div>
    );
};

export default Error404;