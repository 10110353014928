import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import requests from "../../../axios/requests";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import { motion } from "framer-motion/dist/framer-motion";
import { defaultFuncAndData } from "../../../utils/defaultData";

import { Input, message, Popconfirm, Tooltip } from "antd";
import ReuseDropDownSelect from "../../../components/reuseComponents/ReuseDropDownSelect";
import CreateOrEditTask from "./CreateOrEditTask";
import CreateOrEditCode from "./CreateOrEditCode";
import CreateOrEditArticle from "./CreateOrEditArticle";
import CreateOrEditVideo from "./CreateOrEditVideo";
import CreateOrEditScorm from "./CreateOrEditScorm";
import CreateQuiz from "./Quiz/CreateQuiz";
import EditQuiz from "./Quiz/EditQuiz";

import video_icon from "../../../../images/icons/video_icon.svg";
import article_icon from "../../../../images/icons/article_icon.svg";
import test_icon from "../../../../images/icons/test_icon.svg";
import code_icon from "../../../../images/icons/code_icon.svg";
import home_work from "../../../../images/icons/home_work.svg";
import lms_icon from "../../../../images/icons/lms_icon.svg";
import add_step from "../../../../images/icons/add_step.png";

import { ReactComponent as Arrow } from "../../../../images/arrow.svg";
import { ReactComponent as RemoveQuestion } from "../../../../images/icons/remove_question.svg";
import { ReactComponent as RemoveMatherial } from "../../../../images/icons/remove_matherial.svg";

const ChangeLessonParent = (props) => {
    const {stage_id, lessonName, lessonNumber, propsSetView, activeProps, transitionClass} = props;

    const variable_1 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_2"});
    const variable_4 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_10"});
    const variable_12 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_14"});
    const variable_15 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_17"});
    const variable_18 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_18"});
    const variable_19 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_19"});
    const variable_20 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_20"});
    const variable_21 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_21"});
    const variable_22 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_22"});
    const variable_23 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_23"});
    const variable_54 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_54"});
    const variable_82 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_82"});
    const variable_91 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_91"});

    const arrTypeAddLess = [variable_9, variable_10, variable_82, variable_12, variable_91];
    const {activeModules} = useSelector(state => state.Reducer);

    const [active, setActive] = useState(activeProps);
    const [stageItems, setStageItems] = useState([]);
    const [activeNavigation, setActiveNavigation] = useState(1);
    const [isEdit, setIsEdit] = useState(true);
    const [activeAddLess, setActiveAddLess] = useState(0);
    const [lessonNameValue, setLessonNameValue] = useState(lessonName);
    const [flagOpenType, setFlagOpenType] = useState(false);
    const [zipFile, setZipFile] = useState("");
    const [fileProcessZip, setFileProcessZip] = useState(null);
    const [flagDisabledBtn, setFlagDisabledBtn] = useState(false);
    const [loadingLmsProgress, setLoadingLmsProgress] = useState(false);
    const [timeoutLmsProgress, setTimeoutLmsProgress] = useState(null);
    const [render, setRender] = useState(1);

    const [imageKey, setImageKey] = useState("");
    const [videoKey, setVideoKey] = useState("");
    const [taskKey, setTaskKey] = useState("");

    let createVideo = (obj) => {
        requests.stages
            .create_stages_metherial([obj])
            .then((response) => {
                if (response.status === 201) {
                    message.success(variable_4);
                    propsSetView();
                }
            })
            .catch((e) => console.error(e));
    };
    let createArticle = (obj) => {
        requests.stages
            .create_stages_metherial([obj])
            .then((response) => {
                if (response.status === 201) {
                    message.success(variable_5);
                    propsSetView();
                }
            })
            .catch((e) => console.error(e));
    };
    let createTask = (obj) => {
        requests.stages
            .create_stages_metherial([obj])
            .then((response) => {
                if (response.status === 201) {
                    message.success(variable_6);
                    propsSetView();
                }
            })
            .catch((e) => console.error(e));
    };
    let createCode = (obj) => {
        requests.stages
            .create_stages_metherial([obj])
            .then((response) => {
                if (response.status === 201) {
                    message.success(variable_6);
                    propsSetView();
                }
            })
            .catch((e) => console.error(e));
    }
    let uploadLmsProgress = (lmsId, limit, isEdit = false) => {
        setLoadingLmsProgress(true);
        requests.stages.get_upload_progress_lms(lmsId)
            .then(res => {
                if (res.data?.progress?.percent !== 100 && --limit) {
                    return setTimeoutLmsProgress(
                        setTimeout(() => uploadLmsProgress(lmsId, limit, isEdit), 2000)
                    );
                }
                clearTimeout(timeoutLmsProgress);
                setTimeout(() => propsSetView(), 1000)
                if (isEdit) message.success(variable_54);
                else message.success(variable_6);
            })
            .catch(err => {
                console.error(err)
                message.error("Ошибка распаковки курса SCORM")
                setFlagDisabledBtn(false)
                setLoadingLmsProgress(false);
            })
    }
    let createLmsLink = async (theory_id, isEdit) => {
        let lmsId;
        await requests.stages.scorm_api(zipFile, stage_id, theory_id, (progress) => {
            let percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            setFileProcessZip([{
                uid: zipFile.name,
                name: zipFile.name,
                percent: percentCompleted,
                status: (percentCompleted === 100) ? "done" : "uploading",
            }]);
        })
            .then((res) => {
                if (res.data?.task_id)
                    lmsId = res.data?.task_id;
            })
            .catch(() => {
                message.error("Ошибка загрузки курса SCORM")
            })
        uploadLmsProgress(lmsId, 100, isEdit);
    }
    let createScorm = (obj) => {
        setFlagDisabledBtn(true);
        requests.stages
            .create_stages_metherial([obj])
            .then((response) => {
                if (response.status === 201) {
                    createLmsLink(response.data[0].id)
                }
            })
            .catch((e) => {
                console.error(e)
                message.error("Ошибка создания материала.")
            });
    }
    let editCode = (obj) => {
        requests.theories.theory_edit(obj.id, obj)
            .then((response) => {
                if (response.status === 200) {
                    message.success(variable_6);
                    propsSetView();
                }
            })
            .catch((e) => console.error(e));
    }
    let editTask = (obj) => {
        requests.theories
            .theory_edit(obj.id, obj)
            .then((response) => {
                if (response.status === 200) {
                    message.success(variable_54);
                    props.propsSetView();
                }
            })
            .catch((e) => console.error(e));
    };
    let editVideo = (obj) => {
        requests.theories
            .theory_edit(obj.id, obj)
            .then((response) => {
                if (response.status === 200) {
                    message.success(variable_1);
                    propsSetView();
                }
            })
            .catch((e) => console.error(e));
    };
    let editArticle = (obj) => {
        requests.theories
            .theory_edit(obj.id, obj)
            .then((response) => {
                if (response.status === 200) {
                    message.success(variable_2);
                    propsSetView();
                }
            })
            .catch((e) => console.error(e));
    };
    let editScorm = (obj) => {
        setFlagDisabledBtn(true);
        requests.theories
            .theory_edit(obj.id, obj)
            .then((response) => {
                if (response.status === 200) {
                    if (zipFile)
                        createLmsLink(response.data.id, true)
                    else {
                        propsSetView();
                        setFlagDisabledBtn(false);
                    }
                }
            })
            .catch((e) => console.error(e));
    };

    let changeOrderMatherial = (id, obj) => {
        requests.stages.change_stages_matherial(id, obj)
            .catch(err => console.error(err));
    };

    let changeNameLesson = () => {
        requests.stages.stage_edit(stage_id, {name: lessonNameValue})
            .then((v) => {
                message.success(variable_8);
                propsSetView();
            });
    };
    let removeMatherial = () => {
        requests.theories.delete_stage_matherial(stageItems[active].id)
            .then((res) => {
                if (res.status === 204) {
                    message.success(variable_7);
                    propsSetView(0);
                }
                return res;
            });
    };
    let getApiTheoriesInfo = () => {
        requests.stages.get_stages_matherial(stage_id)
            .then((response) => {
                setStageItems(response.data);
            })
            .catch(err => console.error(err));
    };
    let getYandexKey = (currentType, currentKey) => {
        requests.storage.get_yandex_key({
            stage_id,
            theory_type: currentType
        }).then((res) => {
            if (currentKey === 'imageKey') setImageKey(res.data.key)
            if (currentKey === 'videoKey') setVideoKey(res.data.key)
            if (currentKey === 'taskKey') setTaskKey(res.data.key)
        });
    }

    useEffect(() => {
        getApiTheoriesInfo();
    }, []);
    return (
        <div className={`change_lesson-parent ${transitionClass}`}>
            {isEdit && stageItems && stageItems.length > 0
                ? <div className="img_remove">
                    <Popconfirm
                        title={variable_13}
                        onConfirm={removeMatherial}
                        okText={variable_14}
                        cancelText={variable_15}
                    >
                        <div className={"svg"}>
                            <RemoveMatherial/>
                        </div>
                    </Popconfirm>
                </div>
                : <></>
            }
            <div className="name_lesson">
                <Input
                    className={"lesson input_classic"}
                    value={lessonNameValue}
                    onChange={(e) => setLessonNameValue(e.target.value)}
                />
                <div className="number_lesson">{lessonNumber}.</div>
            </div>
            <div className="group_inner">
                <div className="stage_parent">
                    <div className="btn_save" onClick={changeNameLesson}>{variable_16}</div>
                    <div className={"stage_block"}>
                        <DragDropContext
                            onDragEnd={result => {
                                const {source, destination} = result;
                                if (!destination) {
                                    return;
                                }
                                let arr = stageItems;
                                const [remove] = arr.splice(source.index, 1);
                                arr.splice(destination.index, 0, remove);
                                setStageItems(arr);
                                if (arr && arr.length > 0) {
                                    for (let i = 0; i < arr.length; i++) {
                                        let obj = {
                                            title: arr[i].title,
                                            text: arr[i].text,
                                            theory_type: arr[i].type,
                                            sortOrder: i + 1
                                        };
                                        changeOrderMatherial(arr[i].id, obj);
                                    }
                                }
                                setRender(prev => prev + 1);
                            }}
                        >
                            <Droppable droppableId="d">
                                {provided => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {stageItems && stageItems.length > 0 &&
                                            stageItems.map((t, i) => (
                                                <Draggable
                                                    draggableId={t.id.toString()}
                                                    index={i}
                                                    key={t.id}
                                                >
                                                    {p => {
                                                        if (typeof p.draggableProps.onTransitionEnd === "function") {
                                                            queueMicrotask(() =>
                                                                p.draggableProps.onTransitionEnd?.({
                                                                    propertyName: "transform"
                                                                })
                                                            );
                                                        }
                                                        let getIconType = (elem) => {
                                                            switch (elem.theory_type) {
                                                                case "A":
                                                                    return article_icon;
                                                                case "V":
                                                                    return video_icon;
                                                                case "Q":
                                                                    return test_icon;
                                                                case "LMS":
                                                                    return lms_icon;
                                                                default:
                                                                    if (elem.task_type === "code")
                                                                        return code_icon
                                                                    else return home_work;
                                                            }
                                                        };
                                                        if (window.innerWidth < 820) {
                                                            return (
                                                                <div style={active !== i && active !== null
                                                                    ? {
                                                                        opacity: 0,
                                                                        zIndex: -1,
                                                                        display: "none",
                                                                        transform: "scale(0)"
                                                                    }
                                                                    : {display: "flex"}}
                                                                >
                                                                    <div
                                                                        className={active === i ? "stage active" : "stage"}
                                                                        key={t.id}
                                                                        onClick={() => {
                                                                            if (active === i) {
                                                                                setActive(null);
                                                                            } else {
                                                                                setActive(i);
                                                                            }
                                                                        }}
                                                                        /*  ref={p.innerRef}
                                                                          {...p.draggableProps}
                                                                          {...p.dragHandleProps}*/
                                                                    >
                                                                        <img src={getIconType(t)}/>
                                                                        <div className="info">{t.title}</div>
                                                                    </div>
                                                                    <div
                                                                        className={active === i ? "arrow_block active" : "arrow_block"}
                                                                        onClick={() => {
                                                                            if (active === i) {
                                                                                setActive(null);
                                                                            } else {
                                                                                setActive(i);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Arrow/>
                                                                    </div>
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <Tooltip
                                                                    placement="right"
                                                                    title={variable_17}
                                                                    key={t.id}
                                                                >
                                                                    <motion.div
                                                                        className={active === i ? "stage active" : "stage"}
                                                                        onClick={() => setActive(i)}
                                                                        variants={defaultFuncAndData.listAnimationDelay()}
                                                                        initial="hidden"
                                                                        animate="visible"
                                                                        custom={(i) * 0.2}
                                                                        {...p.draggableProps}
                                                                        {...p.dragHandleProps}
                                                                        ref={p.innerRef}
                                                                        key={t.id}
                                                                    >
                                                                        <img src={getIconType(t)}/>
                                                                        <div className="info">{t.title}</div>
                                                                        <Popconfirm
                                                                            title={variable_13}
                                                                            onConfirm={removeMatherial}
                                                                            okText={variable_14}
                                                                            cancelText={variable_15}
                                                                        >
                                                                            <div className="remove_icon">
                                                                                <RemoveQuestion/>
                                                                            </div>
                                                                        </Popconfirm>
                                                                    </motion.div>
                                                                </Tooltip>
                                                            );
                                                        }
                                                    }
                                                    }
                                                </Draggable>
                                            ))}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <div
                            className="btn_add"
                            onClick={() => setIsEdit(false)}
                        >
                            <img src={add_step}/>
                            <span>{variable_18}</span>
                        </div>
                    </div>
                </div>
                <div className="content_parent">
                    <div className="title">
                        {isEdit
                            ? <>
                                <span>{variable_19}</span>
                                <div className="group">
                                    <div
                                        className={activeNavigation === 0 ? "active" : ""}
                                        onClick={() => {
                                            propsSetView(active);
                                        }}
                                    >
                                        {variable_20}
                                    </div>
                                    <div
                                        className={activeNavigation === 1 ? "active" : ""}
                                        onClick={() => setActiveNavigation(1)}
                                    >
                                        {variable_21}
                                    </div>
                                </div>
                            </>
                            : <span>{variable_22}</span>
                        }
                    </div>
                    {isEdit &&
                        <>{/*редактирование материала*/}
                            {active !== null && stageItems && stageItems.length > 0 &&
                                <>
                                    {isEdit && stageItems[active].theory_type === "V" &&
                                        <CreateOrEditVideo
                                            stage_id={stage_id}
                                            stageItems={stageItems[active]}
                                            videoKey={videoKey}
                                            taskKey={taskKey}
                                            editVideo={editVideo}
                                            getYandexKey={getYandexKey}
                                            propsSetView={propsSetView}
                                            isEditComponent={true}
                                        />
                                    }
                                    {isEdit && stageItems[active].theory_type === "A" &&
                                        <CreateOrEditArticle
                                            stage_id={stage_id}
                                            stageItems={stageItems[active]}
                                            imageKey={imageKey}
                                            taskKey={taskKey}
                                            editArticle={editArticle}
                                            getYandexKey={getYandexKey}
                                            propsSetView={propsSetView}
                                            isEditComponent={true}
                                        />
                                    }
                                    {isEdit && stageItems[active].theory_type === "Q" &&
                                        <EditQuiz
                                            stage_id={stage_id}
                                            setView={() => {
                                                propsSetView();
                                                getApiTheoriesInfo();
                                            }}
                                            stageItems={stageItems[active]}
                                            lengthMatherial={stageItems && stageItems.length}
                                        />
                                    }
                                    {isEdit && stageItems[active].theory_type === "T" &&
                                        <>
                                            {stageItems[active].task_type === "code"
                                                ? <CreateOrEditCode
                                                    stage_id={stage_id}
                                                    stageItems={stageItems[active]}
                                                    imageKey={imageKey}
                                                    videoKey={videoKey}
                                                    getYandexKey={getYandexKey}
                                                    editCode={editCode}
                                                    propsSetView={() => propsSetView()}
                                                    isEditComponent={true}
                                                />
                                                : <CreateOrEditTask
                                                    stage_id={stage_id}
                                                    stageItems={stageItems[active]}
                                                    imageKey={imageKey}
                                                    taskKey={taskKey}
                                                    editTask={editTask}
                                                    getYandexKey={getYandexKey}
                                                    propsSetView={() => propsSetView()}
                                                    isEditComponent={true}
                                                />
                                            }
                                        </>
                                    }
                                    {isEdit && stageItems[active].theory_type === "LMS" &&
                                        <CreateOrEditScorm
                                            zipFile={zipFile}
                                            stage_id={stage_id}
                                            fileProcess={fileProcessZip}
                                            setZipFile={setZipFile}
                                            stageItems={stageItems[active]}
                                            taskKey={taskKey}
                                            editScorm={editScorm}
                                            getYandexKey={getYandexKey}
                                            propsSetView={propsSetView}
                                            isEditComponent={true}
                                            flagDisabledBtn={flagDisabledBtn}
                                            loadingLmsProgress={loadingLmsProgress}
                                        />
                                    }
                                </>
                            }
                        </>
                    }
                    {!isEdit &&
                        <>{/*создание материала*/}
                            <div className="group_drop-down select" style={{position: 'relative', zIndex: 11}}>
                                <div className="title"> {variable_23}</div>
                                <ReuseDropDownSelect
                                    flagOpen={flagOpenType}
                                    setFlagOpen={(bool) => setFlagOpenType(bool)}
                                    arrList={arrTypeAddLess}
                                    activeArrList={activeAddLess}
                                    setActiveArrList={(indx) => setActiveAddLess(indx)}
                                    onBlur={() => setFlagOpenType(false)}
                                    activeModules={activeModules}
                                />
                            </div>
                            {activeAddLess !== null && activeAddLess === 0 &&
                                <CreateOrEditVideo
                                    stage_id={stage_id}
                                    videoKey={videoKey}
                                    taskKey={taskKey}
                                    createVideo={createVideo}
                                    getYandexKey={getYandexKey}
                                    propsSetView={propsSetView}
                                />
                            }
                            {activeAddLess !== null && activeAddLess === 1 &&
                                <CreateOrEditArticle
                                    stage_id={stage_id}
                                    imageKey={imageKey}
                                    taskKey={taskKey}
                                    createArticle={createArticle}
                                    getYandexKey={getYandexKey}
                                    propsSetView={propsSetView}
                                />
                            }
                            {activeAddLess !== null && activeAddLess === 2 &&
                                <CreateOrEditTask
                                    stage_id={stage_id}
                                    imageKey={imageKey}
                                    taskKey={taskKey}
                                    createTask={createTask}
                                    getYandexKey={getYandexKey}
                                    propsSetView={() => propsSetView()}
                                />
                            }
                            {activeAddLess !== null && activeAddLess === 3 &&
                                <CreateQuiz
                                    stage_id={stage_id}
                                    setView={propsSetView}
                                />
                            }
                            {activeAddLess !== null && activeAddLess === 4 &&
                                <CreateOrEditCode
                                    stage_id={stage_id}
                                    imageKey={imageKey}
                                    videoKey={videoKey}
                                    createCode={createCode}
                                    getYandexKey={getYandexKey}
                                    propsSetView={() => propsSetView()}
                                />
                            }
                            {activeAddLess !== null && activeAddLess === 5 &&
                                <CreateOrEditScorm
                                    zipFile={zipFile}
                                    stage_id={stage_id}
                                    fileProcess={fileProcessZip}
                                    setZipFile={setZipFile}
                                    taskKey={taskKey}
                                    createScorm={createScorm}
                                    getYandexKey={getYandexKey}
                                    propsSetView={() => propsSetView()}
                                    flagDisabledBtn={flagDisabledBtn}
                                    loadingLmsProgress={loadingLmsProgress}
                                />
                            }
                        </>
                    }
                </div>
            </div>
            <div
                className="render"
                style={{opacity: 0, position: "absolute", top: 0}}
            >
                {render}
            </div>
        </div>
    );
};

export default ChangeLessonParent;