import React from "react";
import add_icon_step3 from "../../../../images/search_profession/add_icon-step3.svg";

const SkillAnimation = ({ myAnimationHidden, myAnimationFlagMove, currentSkillAddTraektory, coords }) => {
  return (
    <div
      className={myAnimationHidden
        ? " my_animation my_animation-hidden"
        : " my_animation "
      }
      style={{
        position: "absolute",
        top: `${coords.clientY}px`,
        left: `${coords.clientX - coords.widthElem}px`,
        color: "white",
        padding: "2px 5px 2px 10px",
        background: "rgba(22, 21, 27, 0.65)",
        borderRadius: 34,
        border: "1px solid rgba(207, 26, 26, 0.6)",
        display: myAnimationFlagMove ? "block" : "none"
      }}
    >
      {currentSkillAddTraektory && currentSkillAddTraektory}
      <img style={{ marginLeft: 5 }} src={add_icon_step3} />
    </div>
  );
};

export default SkillAnimation;