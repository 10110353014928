import React, { useEffect, useState } from 'react';
import { defaultFuncAndData } from "../../../utils/defaultData";
import requests from "../../../axios/requests";
import { Modal } from "antd";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

import add_icon_step3 from "../../../../images/search_profession/add_icon-step3.svg";
import close_modal_grey from "../../../../images/search_profession/close_modal-course.svg";
import './ModalProfessionInfo.scss';

const ModalProfessionInfo = (props) => {
    let {objActiveProfession, flagModalInfo, closeModalInfo, addTrajectory} = props;

    const [allJobsVacancy, setAllJobsVacancy] = useState([]);
    const [courses, setCourses] = useState({});
    const [minSalary, setMinSalary] = useState(0);
    const [averageSalary, setAverageSalary] = useState(0);
    const [maxSalary, setMaxSalary] = useState(0);
    const [loading, setLoading] = useState(true);

    const variables_1 = variableLanguage({keyPage: 'modalProfessionInfo', keyVariable: 'variables_1'});

    let handleCloseModal = () => {
        closeModalInfo();
        setLoading(true);
        setMinSalary(0);
        setMaxSalary(0);
        setAverageSalary(0);
        setAllJobsVacancy([]);
    };
    let getApiCatalog = () => {
        let arr = [];
        if (objActiveProfession?.extra?.length) {
            for (let i = 0; i < objActiveProfession.extra.length; i++) {
                arr = [...arr, objActiveProfession.extra[i].id]
            }
        } else {
            if (objActiveProfession?.tags?.length) {
                for (let i = 0; i < objActiveProfession.tags.length; i++) {
                    arr = [...arr, objActiveProfession.tags[i].id]
                }
            }
        }
        requests.quests.get_quest_quest(arr, 1, 20, 0)
            .then(res => {
                setCourses(res.data.results)
            })
            .catch((error) => console.error(error))
    }

    useEffect(() => {
        if (objActiveProfession?.extra?.length > 0 || objActiveProfession?.tags?.length > 0)
            getApiCatalog()
    }, [objActiveProfession])
    useEffect(() => {
        if (allJobsVacancy && allJobsVacancy.length > 0) {
            setMinSalary(defaultFuncAndData.calcSalarys(allJobsVacancy).minSalary);
            setMaxSalary(defaultFuncAndData.calcSalarys(allJobsVacancy).maxSalary);
            setAverageSalary(defaultFuncAndData.calcSalarys(allJobsVacancy).averageSalary);
            setLoading(false);
        }

    }, [allJobsVacancy]);
    return (
        <Modal
            visible={flagModalInfo}
            footer={null}
            className={'modal_profession-info'}
            closable={false}
            /* centered={true}*/
            onCancel={handleCloseModal}
            maskStyle={{backgroundColor: 'rgba(0, 0, 0, 0.10)'}}
            bodyStyle={{padding: 25, backgroundColor: '#201E26', borderRadius: 40}}
        >
            <div className="title">
                <span>{objActiveProfession.professionTitle}</span>
                <div className="close_modal" onClick={handleCloseModal}>
                    <img src={close_modal_grey}/>
                </div>
            </div>
            <pre className="description">{objActiveProfession.description}</pre>
            {objActiveProfession && objActiveProfession.extra && objActiveProfession.extra.length > 0 &&
                <>
                    <div className="title_popular">
                        {variables_1}
                    </div>
                    <div className="block_skill">
                        {objActiveProfession.extra.map((elem, indx) =>
                            <div
                                className="skill"
                                onClick={() => addTrajectory(elem)}
                                key={indx}
                            >
                                {elem.name}
                                <img src={add_icon_step3}/>
                            </div>
                        )}
                    </div>
                </>
            }
           {/* {(courses && courses.length > 0) &&
                <div className="recomendation_block">
                    <div className="title">
                        <div className="group_">
                            <div className="h3">{variables_2}</div>
                            <div className="text">{variables_3}
                            </div>
                        </div>
                        <div
                            className="btn_show"
                            onClick={() => history('/course-list')}
                        >
                            {variables_4}
                        </div>
                    </div>
                    <ul>
                        {courses.slice(0, 4).map((course, indx) => {
                            return (
                                <li key={indx} className={"reuse_course-item"}>
                                    <CourseItem
                                        course={course}
                                        dateCourse={true}
                                        cost={true}
                                        redirectUrl={course.id}
                                        isCourseList={true}
                                    />
                                </li>
                            );
                        })
                        }
                    </ul>
                </div>
            }*/}
            {/* <div className="title_price">Текущие зарплатные возможности</div>
            {loading ?
                <div style={{height: 200, position: 'relative', width: '100%'}}>
                    <Spin style={{position: 'absolute', left: '47%', top: '35%', color: 'blue'}}/>
                </div>
                :
                <ParentBlockProgressGorizontal>
                    <GorizontalProgressItem
                        topProgress={10}
                        leftMiddle={defaultFuncAndData.calcProcentague(maxSalary)}
                        procentague={defaultFuncAndData.calcProcentague(maxSalary)}
                        index={3}
                        indexSalary={maxSalary.toString()}
                        more200={maxSalary > 200000}
                    />
                    <GorizontalProgressItem
                        topProgress={10}
                        leftMiddle={defaultFuncAndData.calcProcentague(averageSalary)}
                        procentague={defaultFuncAndData.calcProcentague(averageSalary)}
                        index={2}
                        indexSalary={averageSalary.toString()}
                        more200={averageSalary > 200000}
                    />
                    <GorizontalProgressItem
                        topProgress={10}
                        leftMiddle={defaultFuncAndData.calcProcentague(minSalary)}
                        procentague={defaultFuncAndData.calcProcentague(minSalary)}
                        index={1}
                        indexSalary={minSalary.toString()}
                        more200={defaultFuncAndData.calcProcentague(minSalary) > 200000}
                    />
                </ParentBlockProgressGorizontal>
            }
            <div className="bottom_info">
                <div>1. <span>Cтартовая отметка </span>
                    заработной платы в сфере IT
                </div>
                <div>2. <span>Средняя </span>
                    заработная плата в сфере IT
                </div>
                <div>3. <span>Максимальная </span>
                    заработная плата в сфере IT
                </div>
            </div>*/}
        </Modal>
    );
};

export default ModalProfessionInfo;