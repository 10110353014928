import React from 'react';
import { message, Modal } from "antd";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

import close from "../../../../images/generate_course/close.svg"
import copy_link from "../../../../images/generate_course/copy_link.svg"
import whatsapp from "../../../../images/generate_course/whatsapp.svg"
import telegram from "../../../../images/generate_course/telegram.svg"
import  "./ModalInvite.scss";

const ModalInvite = ({openModalInvite, setOpenModalInvite, data}) => {
    const variable_26 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_26'});
    const variable_27 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_27'});
    const variable_37 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_37'});

    const url =  window.location.href
    const text = `Пройдите по ссылке, чтобы записаться на курс: ${data?.name}`

    let handleSendWhatsaspp = () => {
        window.open(
            `https://wa.me/?text=${text} ${(url)}`,
            "_blank"
        )
    }
    let handleSendTelegram = () => {
        window.open(
            `https://telegram.me/share/url?url=${url}&text=${text}`,
            "_blank"
        )
    }
    let copyLink = () => {
        setTimeout(async () => {
            await navigator.clipboard.writeText(url)
            message.success("Ссылка скопирована в буфер обмена")
        })
    }
    return (
        <Modal
            open={openModalInvite}
            footer={null}
            className={"modal_invite"}
            closable={false}
            onCancel={() => setOpenModalInvite(false)}
            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
            bodyStyle={{padding: "25px", borderRadius: 30}}
            destroyOnClose={true}
        >
            <div className="title">
                <div
                    className="close"
                    onClick={() => setOpenModalInvite(false)}
                >
                    <img src={close} alt="close"/>
                </div>
            </div>
            <div className="text">
                {variable_26}
            </div>
            <div
                className="copy_btn"
                onClick={copyLink}
            >
                <span>{variable_27}</span>
                <img
                    src={copy_link}
                    alt="copy_link"
                />
            </div>
            <div className="text">
                {variable_37}
            </div>
            <div className="socials">
                <img
                    src={whatsapp}
                    alt="whatsapp"
                    onClick={handleSendWhatsaspp}
                />
                <img
                    src={telegram}
                    alt="telegram"
                    onClick={handleSendTelegram}
                />
            </div>
        </Modal>
    );
};

export default ModalInvite;