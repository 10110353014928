import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import moment from "moment";

import { Button, Form, Input, message, Modal, Rate, Tooltip } from "antd";
import Error404 from "../../components/reuseComponents/Error404";
import requests from "../../axios/requests";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import { defaultFuncAndData } from '../../utils/defaultData';
import ModalInvite from "./ModalInvite/ModalInvite";

import slider_prev from "../../../images/landing/slider_prev.png";
import slider_next from "../../../images/landing/slider_next.png";
import format_study1 from "../../../images/landing/format_study1.png";
import format_study2 from "../../../images/landing/format_study2.png";
import format_study3 from "../../../images/landing/format_study3.png";
import format_study4 from "../../../images/landing/format_study4.png";
import format_study5 from "../../../images/landing/format_study5.svg";
import format_study6 from "../../../images/landing/format_study6.svg";
import checked from "../../../images/landing/checked.png";
import fake_photos from "../../../images/landing/fake_photos.png";
import checked_mobile from "../../../images/landing/checked_mobile.png";
import close_icon from "../../../images/icons/close_modal.svg";
import burger from "../../../images/landing/burger.svg";
import burger_close from "../../../images/landing/close_burger.svg";
import start_ from "../../../images/icons/start_.svg";
import end_ from "../../../images/icons/end_.svg";
import academic_hourse from "../../../images/landing/academic_hourse.svg";
import default_course from "../../../images/icons/default_course.png";

import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { ReactComponent as Logo } from "../../../images/landing/logo.svg";

const ReuseForm = (props) => {
    let {
        nameFeedback, phone, textFeedback, setPhone, setNameFeedback, setTextFeedback,
        flagDropDownPhone, setFlagDropDownPhone, variable_21, variable_22,
        variable_23, variable_24, variable_25, variable_26, variable_27, isModal = false
    } = props;
    if (phone)
        phone = phone.replace(/-/g, "").replace("(", "").replace(")", "").replace(/ /g, "");
    let { TextArea } = Input;
    return (
        <div className="reuse_form">
            {isModal
                ? <>
                    <div className="h1">{variable_21}</div>
                    <div className="info">{variable_22}</div>
                </>
                : <>
                    <div
                        className="h1"
                        style={{ color: "white" }}
                    >{variable_21}
                    </div>
                    <div
                        className="info"
                        style={{ color: "white" }}
                    >
                        {variable_22}
                    </div>
                </>
            }
            <Form
                className="feedbackForm"
                layout="vertical"
                size="large"
                style={{ position: "relative", zIndex: 3 }}
            >
                <Form.Item>
                    <Input
                        className={"input_classic"}
                        placeholder={variable_23}
                        value={nameFeedback || ""}
                        onChange={(e) => setNameFeedback(e.target.value)}
                        style={{ marginBottom: 15 }}
                    />
                </Form.Item>
                <Form.Item>
                    <Input
                        className={"input_classic"}
                        placeholder={variable_24}
                        value={phone || ""}
                        onChange={(e) => setPhone(e.target.value)}
                        style={{ marginBottom: 15 }}
                    />
                </Form.Item>
                <Form.Item name="text" validateTrigger={"onBlur"}>
                    <TextArea
                        className={"input_classic"}
                        style={{ resize: "none" }}
                        placeholder={variable_25}
                        value={textFeedback || ""}
                        onChange={(e) => setTextFeedback(e.target.value)}
                    />
                </Form.Item>
                <Form.Item>
                    {isModal
                        ? <div className="deal">{variable_26}</div>
                        : <div className="deal" style={{ color: "white" }}>{variable_26}</div>
                    }
                </Form.Item>
                <Form.Item
                >
                    <Button
                        htmlType="submit"
                        className={(!nameFeedback || !phone || !textFeedback) ? "btn_send disabled" : "btn_send "}
                        disabled={!nameFeedback || !phone || !textFeedback}
                    >
                        {variable_27}
                    </Button>
                </Form.Item>
                <div
                    className={flagDropDownPhone ? "arrow_phone active" : "arrow_phone"}
                    onClick={() => setFlagDropDownPhone(!flagDropDownPhone)}>
                    <Arrow />
                </div>
            </Form>
        </div>
    );
};
const ReuseDots = ({ activeSlide, setActiveSlide, countDots, color = false }) => {
    return (
        <div className="block_dots">
            {Array(countDots).fill(0).map((elem, indx) =>
                <div
                    key={indx}
                    onClick={() => setActiveSlide(indx)}
                    style={activeSlide === indx
                        ? {
                            width: 30,
                            height: 4,
                            background: color || "#A1A3B2",
                            borderRadius: 3,
                            marginRight: 8,
                            transition: "all .3s ease",
                            cursor: "pointer"
                        }
                        : {
                            backgroundColor: "transparent",
                            width: 20,
                            height: 4,
                            background: "#343843",
                            borderRadius: 3,
                            marginRight: 8,
                            transition: "all .3s ease",
                            cursor: "pointer"
                        }
                    } />
            )}
        </div>
    );
};
const LandingNew = (props) => {
    const variable_1 = variableLanguage({ keyPage: "landing", keyVariable: "variable_1" });
    const variable_2 = variableLanguage({ keyPage: "landing", keyVariable: "variable_2" });
    const variable_3 = variableLanguage({ keyPage: "landing", keyVariable: "variable_3" });
    const variable_4 = variableLanguage({ keyPage: "landing", keyVariable: "variable_4" });
    const variable_5 = variableLanguage({ keyPage: "landing", keyVariable: "variable_5" });
    const variable_6 = variableLanguage({ keyPage: "landing", keyVariable: "variable_6" });
    const variable_7 = variableLanguage({ keyPage: "landing", keyVariable: "variable_7" });
    const variable_8 = variableLanguage({ keyPage: "landing", keyVariable: "variable_8" });
    const variable_9 = variableLanguage({ keyPage: "landing", keyVariable: "variable_9" });
    const variable_10 = variableLanguage({ keyPage: "landing", keyVariable: "variable_10" });
    const variable_11 = variableLanguage({ keyPage: "landing", keyVariable: "variable_11" });
    const variable_12 = variableLanguage({ keyPage: "landing", keyVariable: "variable_12" });
    const variable_13 = variableLanguage({ keyPage: "landing", keyVariable: "variable_13" });
    const variable_14 = variableLanguage({ keyPage: "landing", keyVariable: "variable_14" });
    const variable_16 = variableLanguage({ keyPage: "landing", keyVariable: "variable_16" });
    const variable_17 = variableLanguage({ keyPage: "landing", keyVariable: "variable_17" });
    const variable_18 = variableLanguage({ keyPage: "landing", keyVariable: "variable_18" });
    const variable_19 = variableLanguage({ keyPage: "landing", keyVariable: "variable_19" });
    const variable_20 = variableLanguage({ keyPage: "landing", keyVariable: "variable_20" });
    const variable_21 = variableLanguage({ keyPage: "landing", keyVariable: "variable_21" });
    const variable_22 = variableLanguage({ keyPage: "landing", keyVariable: "variable_22" });
    const variable_23 = variableLanguage({ keyPage: "landing", keyVariable: "variable_23" });
    const variable_24 = variableLanguage({ keyPage: "landing", keyVariable: "variable_24" });
    const variable_25 = variableLanguage({ keyPage: "landing", keyVariable: "variable_25" });
    const variable_26 = variableLanguage({ keyPage: "landing", keyVariable: "variable_26" });
    const variable_27 = variableLanguage({ keyPage: "landing", keyVariable: "variable_27" });
    const variable_28 = variableLanguage({ keyPage: "landing", keyVariable: "variable_28" });
    const variable_30 = variableLanguage({ keyPage: "landing", keyVariable: "variable_30" });
    const variable_31 = variableLanguage({ keyPage: "landing", keyVariable: "variable_31" });
    const variable_32 = variableLanguage({ keyPage: "landing", keyVariable: "variable_32" });
    const variable_33 = variableLanguage({ keyPage: "landing", keyVariable: "variable_33" });
    const variable_39 = variableLanguage({ keyPage: "landing", keyVariable: "variable_39" });
    const variable_41 = variableLanguage({ keyPage: "landing", keyVariable: "variable_41" });
    const variable_43 = variableLanguage({ keyPage: "landing", keyVariable: "variable_43" });
    const variable_44 = variableLanguage({ keyPage: "landing", keyVariable: "variable_44" });
    const variable_45 = variableLanguage({ keyPage: "landing", keyVariable: "variable_45" });
    const variable_47 = variableLanguage({ keyPage: "landing", keyVariable: "variable_47" });
    const variable_48 = variableLanguage({ keyPage: "landing", keyVariable: "variable_48" });
    const variable_50 = variableLanguage({ keyPage: "landing", keyVariable: "variable_50" });

    const [data, setData] = useState(null);
    const [activeSlideSpikerMobile, setActiveSlideSpikerMobile] = useState(0);
    const [activeSlideFormatStudyMobile, setActiveSlideFormatStudyMobile] = useState(0);
    const [activeSlideFeedback, setActiveSlideFeedback] = useState(0);
    const [activeSlideFeedbackMobile, setActiveSlideFeedbackMobile] = useState(0);
    const [activeSlideWhoSuits, setActiveSlideWhoSuits] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [scrollY, setScrollY] = useState(null);

    const [nameFeedbackModal, setNameFeedbackModal] = useState("");
    const [nameFeedbackFooter, setNameFeedbackFooter] = useState("");
    const [phoneFooter, setPhoneFooter] = useState("");
    const [phoneModal, setPhoneModal] = useState("");
    const [textFeedbackFooter, setTextFeedbackFooter] = useState("");
    const [textFeedbackModal, setTextFeedbackModal] = useState("");
    const [arrNavBar, setArrNavbar] = useState([
        { name: "Каталог курсов", valid: true },
        { name: "Спикеры", valid: false },
        { name: "О курсе", valid: false },
        { name: "Кому подойдет", valid: false },
        { name: "Что получите", valid: false },
    ]);

    const [flagIsCourse, setFlagIsCourse] = useState(false);
    const [flagDropDownPhone, setFlagDropDownPhone] = useState(false);
    const [flagModalFeedback, setFlagModalFeedback] = useState(false);
    const [flagHiddenFotter, setFlagHiddenFotter] = useState(null);
    const [flagBurgerMobile, setFlagBurgerMobile] = useState(false);
    const [flagSuccessSendQuestion, setFlagSuccessSendQuestion] = useState(true);
    const [flagShow404, setFlagShow404] = useState(null);
    const [showTextCourseContains, setShowTextCourseContains] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    let quest_id;
    if (window.location.href.includes("/corp-preview")) {
        quest_id = window.location.href.split("/corp-preview?ses=")[1];
    } else quest_id = window.location.href.split("/course-preview")[1];
    const { language } = useSelector(state => state.Reducer);

    let currentButtonStr = () => {
        let str = "";
        if (data !== null) {
            if (data.participateprice) {
                if (data?.referral_url) {
                    str = variable_39;
                    return str
                }
                str = variable_39;
            } else
                str = variable_28;
        }
        return str;
    };
    let scrollIntoView = (ref) => {
        if (typeof ref?.current !== "undefined")
            ref.current.scrollIntoView({ block: "center", behavior: "smooth" });
    };
    let handleShowSkills = (tag = "") => {
        if (typeof skillRef?.current !== "undefined")
            skillRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
        else
            window.open(`https://www.google.com/search?q=${tag}`, "blank");
    };

    let PrevArrow = ({ className, style, onClick }) => {
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <img src={slider_prev} />
            </div>
        );
    };
    let NextArrow = ({ className, style, onClick }) => {
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <img src={slider_next} />
            </div>
        );
    };
    let handleChangeNameFeedback = (e, isFooter = false) => {
        const notNumber = e.replace(/[^a-z | _ | а-я]/gi, "");
        if (isFooter)
            setNameFeedbackFooter(notNumber);
        else
            setNameFeedbackModal(notNumber);
    };
    let createApiQuestion = (obj) => {
        requests.quests.post_user_question(obj)
            .then((res) => {
                message.success(variable_29);
                setFlagSuccessSendQuestion(false);
            })
            .catch((err) => console.error(err));
    };
    let createUserQuestionFooter = () => {
        if (!flagSuccessSendQuestion) return message.error(variable_30);
        if (!nameFeedbackFooter) return message.error(variable_31);
        if (!phoneFooter) return message.error(variable_32);
        if (!textFeedbackFooter) return message.error(variable_33);

        let obj = {
            ses: data.ses,
            name: nameFeedbackFooter,
            phone: phoneFooter,
            text: textFeedbackFooter
        };
        createApiQuestion(obj);
    };
    let createUserQuestionModal = () => {
        if (!flagSuccessSendQuestion) return message.error(variable_30);
        if (!nameFeedbackModal) return message.error(variable_31);
        if (!phoneModal) return message.error(variable_32);
        if (!textFeedbackModal) return message.error(variable_33);

        let obj = {
            ses: data.ses,
            name: nameFeedbackModal,
            phone: phoneModal,
            text: textFeedbackModal
        };
        createApiQuestion(obj);
        setFlagModalFeedback(false);
    };
    let countFormatMatherial = () => {
        let count = 0;
        if (data !== null && Object.keys(data).length !== 0) {
            if (data.count_video > 0) count += 1;
            if (data.count_quiz > 0) count += 1;
            if (data.count_article > 0) count += 1;
            if (data.count_task > 0) count += 1;
            if (data.count_code_task > 0) count += 1;
            if (data.quest_speakers?.length > 0) count += 1;
        }
        return count;
    };
    let getSpeakersApi = (id) => {
        requests.quests.get_quest_speakers(id)
            .then((res) => {
                setData((prev) => ({ ...prev, ["quest_speakers"]: res.data }))
                if (res.data && res.data.length > 0)
                    setArrNavbar((prev) => [...prev, prev[1].valid = true]);
            })
            .catch((err) => console.log(err, "err get_quest_speakers"));
    }
    let getRecomendationApi = (id) => {
        requests.quests.get_quest_suits(id)
            .then(res => {
                setData((prev) => ({ ...prev, ["quest_recommendations"]: res.data }));
                if (res.data && res.data.length > 0)
                    setArrNavbar((prev) => [...prev, prev[3].valid = true]);

            })
            .catch(err => console.error(err))
    }
    let getWhatLearnApi = (id) => {
        requests.quests.get_quest_learn(id)
            .then(res => {
                setData((prev) => ({ ...prev, ["quest_skills"]: res.data }));
                if (res.data && res.data.length > 0)
                    setArrNavbar((prev) => [...prev, prev[3].valid = true]);
            })
            .catch(err => console.error(err))
    }

    const settingsSpiker = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "spikers_slides",
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />
    };
    const settingsFeedback = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: window.innerWidth < 820 ? 1 : 3,
        slidesToScroll: 1,
        className: "feedback_slides",
        arrows: window.innerWidth < 820 ? false : true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        initialSlide: activeSlideFeedback
    };
    const settingsSpikerMobile = {
        /*dots: true,*/
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "spikers_slides-mobile",
        arrows: false,
        initialSlide: activeSlideSpikerMobile
    };
    const settingsFormatStudyMobile = {
        /*dots: true,*/
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "format_study-slides-mobile",
        arrows: false,
        initialSlide: activeSlideFormatStudyMobile
    };
    const settingsWhoSuitsMobile = {
        /*dots: true,*/
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "who_suits-slides-mobile",
        arrows: false,
        initialSlide: activeSlideWhoSuits
    };

    const history = useNavigate();
    const skillRef = useRef();
    const speakersRef = useRef();
    const formatStudyRef = useRef();
    const whoSuitsRef = useRef();
    const speakersRefMobile = useRef();
    const formatStudyRefMobile = useRef();
    const whoSuitsRefMobile = useRef();
    const textDescriptionRef = useRef(null);

    const carouselRef = useRef(null);
    const carouselRefFeedback = useRef(null);
    const carouselRefFormatStudy = useRef(null);
    const carouselRefWhoSuits = useRef(null);
    const carouselRefFeedbackMobile = useRef(null);
    const flagShowFormat = data !== null && Object.keys(data).length !== 0 &&
        (data?.count_video > 0 || data?.count_theories > 0
            || data?.count_task > 0 || data?.count_code_task > 0 ||
            data?.count_task > 0 || data?.count_quiz > 0 || data?.count_article > 0);

    useEffect(() => {
        if (props?.inCourseLanding) {
            setData(props?.data)
            setFlagShow404(false);
            setData((prev) => ({ ...prev, ["quest_speakers"]: props?.originalSpeakerProps }))
            setData((prev) => ({ ...prev, ["quest_recommendations"]: props?.originalSuitsProps }));
            setData((prev) => ({ ...prev, ["quest_skills"]: props?.originalLearnProps }));
            if (props?.originalSpeakerProps && props.originalSpeakerProps.length > 0)
                setArrNavbar((prev) => [...prev, prev[1].valid = true]);
            if (props?.originalSuitsProps && props.originalSuitsProps.length > 0)
                setArrNavbar((prev) => [...prev, prev[3].valid = true]);
            if (props?.originalLearnProps && props.originalLearnProps.length > 0)
                setArrNavbar((prev) => [...prev, prev[4].valid = true]);
        } else {
            requests.quests.get_quest_new(quest_id)
                .then((res) => {
                    setData(res.data);
                    if (res.data.quest_skills && res.data.quest_skills.length > 0) setArrNavbar((prev) => [...prev, prev[4].valid = true]);
                    setFlagShow404(false);
                    getSpeakersApi(res.data.id)
                    getRecomendationApi(res.data.id);
                    getWhatLearnApi(res.data.id);
                })
                .catch((err) => {
                    console.error(err.message, 'err')
                    if (err.message.includes("Request failed with status code 404"))
                        setFlagShow404(true);
                });
        }
        if (window.location.href.includes("/current-course/"))
            setFlagIsCourse(true)
    }, []);
    useEffect(() => {
        if (carouselRef.current?.slickGoTo)
            carouselRef.current.slickGoTo(activeSlideSpikerMobile);
    }, [activeSlideSpikerMobile]);
    useEffect(() => {
        if (carouselRefFormatStudy.current?.slickGoTo)
            carouselRefFormatStudy.current.slickGoTo(activeSlideFormatStudyMobile);
    }, [activeSlideFormatStudyMobile]);
    useEffect(() => {
        if (carouselRefWhoSuits.current?.slickGoTo)
            carouselRefWhoSuits.current.slickGoTo(activeSlideWhoSuits);
    }, [activeSlideWhoSuits]);
    useEffect(() => {
        if (carouselRefFeedbackMobile.current?.slickGoTo)
            carouselRefFeedbackMobile.current.slickGoTo(activeSlideFeedbackMobile);
    }, [activeSlideFeedbackMobile]);
    useEffect(() => {
        if (carouselRefFeedback.current?.slickGoTo)
            carouselRefFeedback.current.slickGoTo(activeSlideFeedback);
    }, [activeSlideFeedback]);
    useEffect(() => {
        if (data !== null) {
            requests.comments.get_quest_review(data.id)
                .then((res) => setReviews(res.data))
                .catch(err => console.error(err));
            if (data !== null && Object.keys(data).length !== 0 &&
                (data.count_video > 0 || data.count_theories > 0
                    || data.count_task > 0 || data.count_task > 0
                    || data.count_quiz > 0 || data.count_article > 0)
            ) {
                setArrNavbar((prev) => [...prev, prev[2].valid = true]);
            }
            let arr = [...arrNavBar];
            arr[0].name = variable_1;
            arr[1].name = variable_2;
            arr[2].name = variable_3;
            arr[3].name = variable_4;
            arr[4].name = variable_5;
            setArrNavbar(arr);
        }
    }, [data]);
    useEffect(() => {
        let newArr = [...arrNavBar];
        if (language === "en") {
            newArr[0].name = "Courses catalog";
            newArr[1].name = "Speakers";
            newArr[2].name = "About the course";
            newArr[3].name = "Who will suit";
            newArr[4].name = "What will you get";
        } else {
            newArr[0].name = "Каталог курсов";
            newArr[1].name = "Спикеры";
            newArr[2].name = "О курсе";
            newArr[3].name = "Кому подойдёт";
            newArr[4].name = "Что получите";
        }
        setArrNavbar(newArr);
    }, [language]);
    return (
        <>
            {!props?.inCourseLanding &&
                <div
                    className="btn_prev-landing"
                    onClick={() => history("/course-list")}><Arrow />
                    <span>{variable_41}</span>
                </div>
            }
            <div
                className={flagIsCourse
                    ? "parent_landing isCourse"
                    : "parent_landing"
                }
            >
                <header>
                    <div className="icon"
                        onClick={() => window.open("https://skillometer.ru", "blank")}>
                        <Logo />
                    </div>
                    <div className="nav_bar">
                        <ul>
                            {arrNavBar.map((elem, indx) => {
                                if (elem.valid) {
                                    return (
                                        <li
                                            key={indx}
                                            onClick={() => {
                                                if (indx === 0)
                                                    history("/course-list");
                                                if (elem.name === variable_2) scrollIntoView(speakersRef);
                                                if (elem.name === variable_3) scrollIntoView(formatStudyRef);
                                                if (elem.name === variable_4) scrollIntoView(whoSuitsRef);
                                                if (elem.name === variable_5) handleShowSkills(elem.name);
                                            }}
                                        >{elem.name}</li>
                                    );
                                }
                            }
                            )}
                        </ul>
                    </div>
                    <div
                        className="btn_redirect-mobile"
                        onClick={() => setFlagBurgerMobile(!flagBurgerMobile)}>
                        <img
                            src={flagBurgerMobile ? burger_close : burger}
                            style={flagBurgerMobile ? { top: 10, left: 10 } : {}}
                        />
                    </div>
                </header>
                <div
                    className={flagBurgerMobile
                        ? "drop_down-header active"
                        : "drop_down-header"
                    }
                >
                    <ul className={"list"}>
                        {arrNavBar.map((elem, indx) => {
                            if (elem.valid)
                                return (
                                    <li
                                        key={indx}
                                        onClick={() => {
                                            if (indx === 0) {
                                                history("/course-list");
                                            }
                                            if (elem.name === variable_2) scrollIntoView(window.innerWidth > 820 ? speakersRef : speakersRefMobile);
                                            if (elem.name === variable_3) scrollIntoView(window.innerWidth > 820 ? formatStudyRef : formatStudyRefMobile);
                                            if (elem.name === variable_4) scrollIntoView(window.innerWidth > 820 ? whoSuitsRef : whoSuitsRefMobile);
                                            if (elem.name === variable_5) handleShowSkills(elem.name);
                                        }}
                                    >{elem.name}</li>
                                );
                        }
                        )}
                    </ul>
                    <div
                        className="btn_redirect-burger"
                        onClick={() => history("/backoffice/login")}
                    >{variable_6}
                    </div>
                </div>
                {flagShow404 !== null
                    ? <>
                        {flagShow404
                            ? <Error404 />
                            : <>
                                <div
                                    className={!data?.banner
                                        ? (data?.speciality === 3 || data?.speciality === 5)
                                            ? "preview_course default dark"
                                            : "preview_course default"
                                        : "preview_course"
                                    }
                                    style={data?.banner
                                        ? { background: `url(${data?.banner}) no-repeat` }
                                        : { background: `url(${defaultFuncAndData.defaultImageCourse(data?.speciality - 1) || default_course}) no-repeat` }
                                    }
                                >
                                    {data?.banner_position !== null
                                        ? <div
                                            className={data.banner_position === "right"
                                                ? "info_course right"
                                                : "info_course"}
                                        >
                                            <div className="title">
                                                {window.innerWidth > 820
                                                    ? <Tooltip
                                                        overlayInnerStyle={{ maxHeight: 400, overflow: "auto" }}
                                                        placement="right"
                                                        title={data?.name || ""}
                                                    >
                                                        {defaultFuncAndData.spliceStr(data?.name, 50)}
                                                    </Tooltip>
                                                    : defaultFuncAndData.spliceStr(data?.name, 50)
                                                }
                                            </div>
                                            <div className="info"
                                            >
                                                {window.innerWidth > 820
                                                    ? <Tooltip
                                                        overlayInnerStyle={{ maxHeight: 400, overflow: "auto" }}
                                                        placement="right"
                                                        title={data?.description || ""}
                                                    >
                                                        {defaultFuncAndData.spliceStr(data?.description, 320)}
                                                    </Tooltip>
                                                    : defaultFuncAndData.spliceStr(data?.description, 320)
                                                }
                                            </div>
                                            <div className="btn_bottom">
                                                <div
                                                    className="record"
                                                    onClick={() => {
                                                        /*   window.localStorage.setItem("previous_course_link", window.location.href);
                                                           history("/student/auth");*/
                                                    }}
                                                >
                                                    {data?.participateprice ? variable_39 : variable_28}
                                                </div>
                                                {data?.participateprice > 0 &&
                                                    <div className="price">
                                                        {data.participateprice} ₽
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        : <>
                                            {data?.participateprice > 0 &&
                                                <div className="price_">
                                                    {data.participateprice} ₽
                                                </div>
                                            }
                                            <div className="btn_describe">
                                                {currentButtonStr()}
                                            </div>
                                        </>
                                    }
                                    <div
                                        className="btn_share"
                                        onClick={() => setOpenModal(true)}
                                    >
                                        <span>{variable_50}</span>
                                    </div>
                                    <ModalInvite
                                        openModalInvite={openModal}
                                        setOpenModalInvite={setOpenModal}
                                        data={data}
                                    />
                                </div>
                                {(
                                    data &&
                                    Object.keys(data).length > 0 &&
                                    data?.quest_speakers?.length === 0 &&
                                    data.speciality_tag?.length > 0) &&

                                    <div className="preview_skills">
                                        {data && data.speciality_tag.length > 0 &&
                                            data.speciality_tag.map((skill, indx) =>
                                                <div
                                                    onClick={() => handleShowSkills(skill.name)}
                                                    key={indx}
                                                    className={"skill"}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {skill.name}
                                                </div>
                                            )
                                        }
                                    </div>
                                }

                                {(data !== null && data?.demo_video || data?.demo_youtube) ?
                                    <>
                                        <div className="title_video">Видео</div>
                                        <div className="video">
                                            <ReactPlayer
                                                className="react-player"
                                                style={{ marginBottom: 15, width: 311, borderRadius: 30 }}
                                                controls={true}
                                                url={data?.demo_youtube ? data?.demo_youtube : data?.demo_video}
                                            />
                                        </div>
                                    </>
                                    : <></>
                                }

                                {data && Object.keys(data).length > 0 && data?.quest_speakers?.length > 0 &&
                                    <>
                                        <div className="title_speakers">{variable_2}</div>
                                        <div ref={speakersRef} className="spikers_new">
                                            <Slider
                                                {...settingsSpiker}
                                            >
                                                {data.quest_speakers.map((elem, indx) =>
                                                    <div key={indx} className={"slider_card"}>
                                                        <div
                                                            className="slider_image"
                                                            style={{
                                                                background:
                                                                    `url(${elem?.avatar || fake_photos}) no-repeat`
                                                            }}
                                                        />
                                                        <div className="info_speaker">
                                                            {/*<div className="title">
                                                                Ваши умения после прохождения курса
                                                            </div>
                                                            {data !== null && Object.keys(data).length !== 0 && data.speciality_tag?.length > 0 &&
                                                                <div className="skills_list">
                                                                    {data.speciality_tag.map((elem, indx) =>
                                                                        <div className="skill" key={indx}>
                                                                            <span>{elem.name}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            }*/}
                                                            <div className="name">
                                                                <span>{elem?.first_name || ""} {elem?.last_name || ""}</span>
                                                            </div>
                                                            <div className="position">
                                                                <span>
                                                                    {elem?.position || ""}
                                                                </span>
                                                            </div>
                                                            <pre className="description">
                                                                {elem?.description || ""}
                                                            </pre>
                                                        </div>
                                                    </div>
                                                )}
                                            </Slider>
                                        </div>
                                        <div className="spikers_mobile-new" ref={speakersRefMobile}>
                                            <div className="speakers_title-mobile">{variable_2}</div>
                                            <div className="spikers_mobile-inner">
                                                <div className="title">
                                                    Ваши умения после прохождения курса
                                                </div>
                                                {data !== null && Object.keys(data).length !== 0 && data.speciality_tag?.length > 0 &&
                                                    <div className="skills_list">
                                                        {data.speciality_tag.map((elem, indx) =>
                                                            <div className="skill" key={indx}>
                                                                <span>{elem.name}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                <Slider
                                                    {...settingsSpikerMobile}
                                                    afterChange={(e) => {
                                                        setActiveSlideSpikerMobile(e);
                                                    }}
                                                    ref={carouselRef}
                                                >
                                                    {data.quest_speakers.map((elem, indx) =>
                                                        <div key={indx}>
                                                            <img src={elem.avatar || fake_photos} />
                                                            <div
                                                                className="name">{elem.first_name || ""} {elem.last_name || ""}</div>
                                                            <div className="position">{elem.position || ""}</div>
                                                            <pre
                                                                className="description">{elem.description || ""}</pre>
                                                        </div>
                                                    )}
                                                </Slider>
                                            </div>
                                            <ReuseDots
                                                activeSlide={activeSlideSpikerMobile}
                                                setActiveSlide={(indx) => setActiveSlideSpikerMobile(indx)}
                                                countDots={data.quest_speakers.length}
                                            />
                                        </div>
                                    </>
                                }

                                {flagShowFormat
                                    ? <>
                                        <div ref={formatStudyRef} className="format_study">
                                            <div className="title">{variable_7}</div>
                                            <ul className="list">
                                                {data.count_video > 0 &&
                                                    <li>
                                                        <div className="top">
                                                            <div className="title">{variable_8}</div>
                                                            <div className="info">{variable_9}</div>
                                                        </div>
                                                        <div className="bottom">
                                                            <img src={format_study1} />
                                                        </div>
                                                    </li>
                                                }
                                                {data.count_quiz > 0 &&
                                                    <li>
                                                        <div className="top">
                                                            <div className="title">{variable_10}</div>
                                                            <div className="info">{variable_11}</div>
                                                        </div>
                                                        <div className="bottom">
                                                            <img src={format_study2} />
                                                        </div>
                                                    </li>
                                                }
                                                {data.count_article > 0 &&
                                                    <li>
                                                        <div className="top">
                                                            <div className="title">{variable_12}</div>
                                                            <div className="info">{variable_13}
                                                            </div>
                                                        </div>
                                                        <div className="bottom">
                                                            <img src={format_study3} />
                                                        </div>
                                                    </li>
                                                }
                                                {data.count_task > 0 &&
                                                    <li>
                                                        <div className="top">
                                                            <div className="title">{variable_14}</div>
                                                            <div className="info">{variable_44}
                                                            </div>
                                                        </div>
                                                        <div className="bottom">
                                                            <img src={format_study6} />
                                                        </div>
                                                    </li>
                                                }
                                                {data.count_code_task > 0 &&
                                                    <li>
                                                        <div className="top">
                                                            <div className="title">{variable_43}</div>
                                                            <div className="info">{variable_45}
                                                            </div>
                                                        </div>
                                                        <div className="bottom">
                                                            <img src={format_study5} />
                                                        </div>
                                                    </li>
                                                }
                                                {data?.quest_speakers?.length > 0 &&
                                                    <li>
                                                        <div className="top">
                                                            <div className="title">{variable_16}</div>
                                                            <div className="info">{variable_17}</div>
                                                        </div>
                                                        <div className="bottom">
                                                            <img src={format_study4} />
                                                        </div>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                        <div className="format_study-mobile" ref={formatStudyRefMobile}>
                                            <div className="title">{variable_7}</div>
                                            <Slider
                                                {...settingsFormatStudyMobile}
                                                afterChange={(e) => {
                                                    setActiveSlideFormatStudyMobile(e);
                                                }}
                                                ref={carouselRefFormatStudy}
                                            >
                                                {data.count_video > 0 &&
                                                    <div className={"parent_slider-item"}>
                                                        <div className="slider_item">
                                                            <div className="top">
                                                                <div className="title">{variable_8}</div>
                                                                <div className="info">{variable_9}</div>
                                                            </div>
                                                            <div className="bottom">
                                                                <img src={format_study1} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {data.count_quiz > 0 &&
                                                    <div className={"parent_slider-item"}>
                                                        <div className="slider_item">
                                                            <div className="top">
                                                                <div className="title">{variable_10}</div>
                                                                <div className="info">{variable_11}</div>
                                                            </div>
                                                            <div className="bottom">
                                                                <img src={format_study2} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {data.count_article > 0 &&
                                                    <div className={"parent_slider-item"}>
                                                        <div className="slider_item">
                                                            <div className="top">
                                                                <div className="title">{variable_12}</div>
                                                                <div className="info">{variable_13}</div>
                                                            </div>
                                                            <div className="bottom">
                                                                <img src={format_study3} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {data.count_task > 0 &&
                                                    <div className={"parent_slider-item"}>
                                                        <div className="slider_item">
                                                            <div className="top">
                                                                <div className="title">{variable_14}</div>
                                                                <div className="info">{variable_44}</div>
                                                            </div>
                                                            <div className="bottom">
                                                                <img src={format_study6} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {data.count_code_task > 0 &&
                                                    <div className={"parent_slider-item"}>
                                                        <div className="slider_item">
                                                            <div className="top">
                                                                <div className="title">{variable_43}</div>
                                                                <div className="info">{variable_45}</div>
                                                            </div>
                                                            <div className="bottom">
                                                                <img src={format_study5} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {data.quest_speakers?.length > 0 &&
                                                    <div className={"parent_slider-item"}>
                                                        <div className="slider_item">
                                                            <div className="top">
                                                                <div className="title">{variable_16}</div>
                                                                <div className="info">{variable_17}</div>
                                                            </div>
                                                            <div className="bottom">
                                                                <img src={format_study4} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </Slider>
                                            <ReuseDots
                                                activeSlide={activeSlideFormatStudyMobile}
                                                setActiveSlide={(indx) => setActiveSlideFormatStudyMobile(indx)}
                                                countDots={countFormatMatherial()}
                                            />
                                        </div>
                                    </>
                                    : <></>
                                }

                                {data !== null && Object.keys(data).length !== 0 &&
                                    (data?.count_video > 0 || data?.count_task > 0 || data?.count_task > 0
                                        || data?.count_quiz > 0 || data?.count_article > 0 || data?.content_description
                                    )
                                    ? <div className="course_contains">
                                        <div className="h1">Курс содержит</div>
                                        <div className="info_row">
                                            {(data?.count_video > 0 || data?.count_task > 0 || data?.count_task > 0
                                                || data?.count_quiz > 0 || data?.count_article > 0)
                                                ? <div className="matherial">
                                                    {data?.count_video
                                                        ? <div><span>{data?.count_video} видео</span></div>
                                                        : <></>
                                                    }
                                                    {data?.count_task
                                                        ? <div><span>{data?.count_task} д/з</span></div>
                                                        : <></>
                                                    }
                                                    {data?.count_quiz
                                                        ? <div>
                                                            <span>
                                                                {data.count_quiz}
                                                                {defaultFuncAndData.declension(data.count_quiz, [" тест", " теста", " тестов"])}
                                                            </span>
                                                        </div>
                                                        : <></>
                                                    }
                                                    {data?.count_article
                                                        ? <div>
                                                            <span>
                                                                {data?.count_article}
                                                                {defaultFuncAndData.declension(data?.count_article, [" статью", " статьи", " статей"])}
                                                            </span>
                                                        </div>
                                                        : <></>
                                                    }
                                                </div>
                                                : <></>
                                            }
                                            <div className="info">
                                                {data?.dateBegin && data?.dateEnd
                                                    ? <div className="date_row">
                                                        <div className="date">
                                                            <img src={start_} />
                                                            <span>с {moment(data.dateBegin).format("DD.MM.YY")}</span>
                                                            <img src={end_} />
                                                            <span>по {moment(data.dateEnd).format("DD.MM.YY")}</span>
                                                        </div>
                                                    </div>
                                                    : <></>
                                                }

                                                {data?.academicHours &&
                                                    <div className="academic_hours">
                                                        <img src={academic_hourse} alt="" />
                                                        <span>{data.academicHours} академ {defaultFuncAndData.declension(data.academicHours, [" час", " часа", "часов"])}</span>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                        {data?.content_description &&
                                            <>
                                                <div className="title_stages">
                                                    {variable_47}
                                                </div>
                                                <pre
                                                    className={showTextCourseContains ? "text_description open" : "text_description"}
                                                    ref={textDescriptionRef}
                                                >
                                                    {defaultFuncAndData.linkChanger(data?.content_description)}
                                                </pre>
                                                {textDescriptionRef?.current?.clientHeight > 199 &&
                                                    <div
                                                        className="show_all"
                                                        onClick={() => setShowTextCourseContains(!showTextCourseContains)}
                                                    >
                                                        <span>{showTextCourseContains ? "Скрыть описание" : "Смотреть описание"}</span>
                                                    </div>
                                                }
                                            </>
                                        }
                                        {data?.stages?.length > 0 &&
                                            <div className="stages">
                                                <div className="title_stages">
                                                    {variable_48}
                                                </div>
                                                {data.stages.map((elem, indx) =>
                                                    <div
                                                        className="name"
                                                        key={indx}
                                                    >
                                                        {elem.name}
                                                    </div>
                                                )}

                                            </div>
                                        }
                                    </div>
                                    : <></>
                                }

                                {data && data.quest_recommendations && data.quest_recommendations.length > 0 &&
                                    <>
                                        <div ref={whoSuitsRef} className="who_suits">
                                            <div className="title">
                                                {variable_18}
                                            </div>
                                            <ul className="list">
                                                {data && data.quest_recommendations && data.quest_recommendations.length > 0 &&
                                                    data.quest_recommendations.map((elem, indx) =>
                                                        <li key={indx}>
                                                            <div className="number"><span>{indx + 1}</span></div>
                                                            <div className="to_whom">{elem.title || ""}</div>
                                                            <div className="info">{elem.description || ""}</div>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        <div className="who_suits-mobile" ref={whoSuitsRefMobile}>
                                            <div className="title">{variable_18}</div>
                                            <Slider
                                                {...settingsWhoSuitsMobile}
                                                afterChange={(e) => {
                                                    setActiveSlideWhoSuits(e);
                                                }}
                                                ref={carouselRefWhoSuits}
                                            >
                                                {data && data.quest_recommendations && data.quest_recommendations.length > 0 &&
                                                    data.quest_recommendations.map((elem, indx) =>
                                                        <div key={indx}>
                                                            <div className={"slider_item"}>
                                                                <div className="number"><span>{indx + 1}</span>
                                                                </div>
                                                                <div className="to_whom">{elem.title || ""}</div>
                                                                <div className="info">{elem.description || ""}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </Slider>
                                            <ReuseDots
                                                activeSlide={activeSlideWhoSuits}
                                                setActiveSlide={(indx) => setActiveSlideWhoSuits(indx)}
                                                countDots={data && data.quest_recommendations && data.quest_recommendations.length}
                                                color={"#B1E5ED"}
                                            />
                                        </div>
                                    </>
                                }

                                {data && data.quest_skills && data.quest_skills.length > 0 &&
                                    <div ref={skillRef} className="what_learn">
                                        <div className="title"> {variable_19}</div>
                                        <ul className={"list"}>
                                            {data.quest_skills.map((elem, indx) =>
                                                <li key={indx}>
                                                    <div className="parent_check">
                                                        <div className="check_icon"><img src={checked} /></div>
                                                        <div className="check_icon-mobile"><img
                                                            src={checked_mobile} />
                                                        </div>
                                                        <div
                                                            className="skill">{elem.speciality_tag.name || ""}</div>
                                                        <pre className="info">{elem.description || ""}</pre>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                }
                                {reviews && reviews.length > 0
                                    ? <>
                                        <div className="feedback">
                                            <div className="title"> {variable_20}</div>
                                            <Slider
                                                {...settingsFeedback}
                                                afterChange={(e) => setActiveSlideFeedback(e)}
                                                ref={carouselRefFeedback}
                                            >
                                                {reviews.map((elem, indx) =>
                                                    <div className="card_feedback" key={indx}>
                                                        <div className="title">
                                                            <img width={70} height={70}
                                                                src={elem.avatar || fake_photos} />
                                                            <div className="right">
                                                                <div className="fio">
                                                                    {elem?.user?.profile?.name}
                                                                </div>
                                                                <div className="rate">
                                                                    <Rate
                                                                        disabled
                                                                        defaultValue={elem.rating}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="comment_feedback">{elem.text}</div>
                                                    </div>
                                                )
                                                }
                                            </Slider>
                                            <div className="dots">
                                                <ReuseDots
                                                    activeSlide={activeSlideFeedback}
                                                    setActiveSlide={(indx) => setActiveSlideFeedback(indx)}
                                                    countDots={reviews.length}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : <></>
                                }

                                <div
                                    className={data?.banner_position === "right" ? "footer right" : "footer"}
                                    style={
                                        data?.banner_position === null
                                            ? { background: `url(${defaultFuncAndData.defaultImageCourse(data?.speciality - 1) || default_course}) no-repeat` }
                                            : data?.banner
                                                ? { background: `url(${data.banner}) no-repeat` }
                                                : { background: `url(${defaultFuncAndData.defaultImageCourse(data?.speciality - 1) || default_course}) no-repeat` }
                                    }
                                >
                                    <ReuseForm
                                        nameFeedback={nameFeedbackFooter}
                                        phone={phoneFooter}
                                        textFeedback={textFeedbackFooter}
                                        setNameFeedback={(value => handleChangeNameFeedback(value, true))}
                                        setPhone={(value) => setPhoneFooter(value)}
                                        setTextFeedback={(value) => setTextFeedbackFooter(value)}
                                        setFlagDropDownPhone={setFlagDropDownPhone}
                                        flagDropDownPhone={flagDropDownPhone}
                                        createUserQuestion={createUserQuestionFooter}
                                        variable_21={variable_21}
                                        variable_22={variable_22}
                                        variable_23={variable_23}
                                        variable_24={variable_24}
                                        variable_25={variable_25}
                                        variable_26={variable_26}
                                        variable_27={variable_27}
                                    />
                                </div>
                            </>
                        }
                    </>
                    : <></>
                }
                <>
                    {flagModalFeedback &&
                        <Modal
                            visible={flagModalFeedback}
                            footer={null}
                            className={"modal_feedback"}
                            width={"550px"}
                            closable={false}
                            centered={true}
                            onCancel={() => setFlagModalFeedback(false)}
                            maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.10)" }}
                            bodyStyle={{ backgroundColor: "white", borderRadius: 40, padding: 40 }}
                        >
                            <div className="close" onClick={() => setFlagModalFeedback(false)}><img src={close_icon}
                                alt="" /></div>
                            <ReuseForm
                                nameFeedback={nameFeedbackModal}
                                phone={phoneModal}
                                textFeedback={textFeedbackModal}
                                setNameFeedback={(value => handleChangeNameFeedback(value, false))}
                                setPhone={(value) => setPhoneModal(value)}
                                setTextFeedback={(value) => setTextFeedbackModal(value)}
                                setFlagDropDownPhone={setFlagDropDownPhone}
                                flagDropDownPhone={flagDropDownPhone}
                                createUserQuestion={createUserQuestionModal}
                                isModal={true}
                                variable_21={variable_21}
                                variable_22={variable_22}
                                variable_23={variable_23}
                                variable_24={variable_24}
                                variable_25={variable_25}
                                variable_26={variable_26}
                                variable_27={variable_27}
                            />
                        </Modal>
                    }
                </>
            </div>
        </>
    );
};

export default LandingNew;