import React from "react";
import { Input } from "antd";
import { ReactComponent as Search } from "../../../images/icons/search_icon.svg";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

const InputButton = (props) => {
    let {value, setValue, search = false, keyDown = false, placeholder = ''} = props;

    const variable_6 = variableLanguage({keyPage: 'myCourse', keyVariable: 'variable_6'});
    const variable_9 = variableLanguage({keyPage: 'myCourse', keyVariable: 'variable_9'});

    return (
        <>
            <Input
                className={"input_classic input_search"}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={placeholder || variable_6}
                onKeyDown={(e) => {
                    if(keyDown) {
                        keyDown(e)
                    }
                }}
            />
            <div className={'svg'}><Search/></div>
            <div
                onClick={() => search()}
                className="btn_search"
            >{variable_9}
            </div>
        </>
    );
};

export default InputButton;