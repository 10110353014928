import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import requests from "../../axios/requests";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import { useSelector } from "react-redux";
import { defaultFuncAndData } from "../../utils/defaultData";

import { Input, Modal, Pagination, Spin } from "antd";
import InputWsButton from "../../components/reuseComponents/InputButton";
import ReuseDropDown from "../../components/reuseComponents/ReuseDropDown";
import Trial from "../../components/reuseComponents/Trial";
import StudentRow from "./StudentRow/StudentRow";
import ReuseStatisticBlock from "./Statiscticblock/StatisticBlock";

import settings_mobile_category from "../../../images/icons/settings_mobile-category.png";
import close_modal_grey from "../../../images/icons/close_modal-grey.png";

import { ReactComponent as Logo } from "../../../images/icons/logo.svg";
import { ReactComponent as SearchMobile } from "../../../images/icons/search_course-mobile.svg";
import { ReactComponent as SettingsMobile } from "../../../images/icons/settings_mobile.svg";
import { ReactComponent as InnerIcon } from "../../../images/icons/inner_list.svg";
import { ReactComponent as MembersIcon } from "../../../images/icons/members_icon.svg";
import { ReactComponent as RemoveIcon } from "../../../images/icons/remove_members.svg";
import { ReactComponent as Search } from "../../../images/icons/search_icon.svg";

const InnerRequestMember = () => {
    const history = useNavigate();
    const {language, trial_status} = useSelector(state => state.Reducer);

    const variable_1 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_4"});
    const variable_13 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_14"});
    const variable_15 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_17"});
    const variable_18 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_18"});
    const variable_19 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_19"});
    const variable_20 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_20"});
    const variable_21 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_21"});
    const variable_22 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_22"});
    const variable_23 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_23"});
    const variable_24 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_24"});
    const variable_25 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_25"});
    const variable_26 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_26"});
    const variable_27 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_27"});
    const variable_28 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_28"});
    const variable_29 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_29"});
    const variable_30 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_30"});

    const [activeStatisticTiming, setActiveStatisticTiming] = useState(0);
    const [activeStatisticCourse, setActiveStatisticCourse] = useState(0);
    const [courseListDropdown, setCourseListDropdown] = useState([]);
    const [displayListMembers, setDisplayListMembers] = useState([]);
    const [searchMembers, setSearchMembers] = useState("");
    const [countView, setCountView] = useState(9);
    const [buttonNavigate, setButtonNavigate] = useState(0);
    const [openGroupInList, setOpenGroupInList] = useState(null);
    const [activeGroupMember, setActiveGroupMember] = useState([]);
    const [currentQuestGroups, setCurrentQuestGroups] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPaginationAllMembers, setCountPaginationAllMembers] = useState(9);
    const [statisticData, setStatisticData] = useState("");
    const [idRemove, setIdRemove] = useState(null);

    const [flagOpenStatisticTiming, setFlagOpenStatisticTiming] = useState(false);
    const [flagOpenStatisticCourse, setFlagOpenStatisticCourse] = useState(false);
    const [hideTable, setHideTable] = useState(false);
    const [flagOpenSearchMobile, setFlagOpenSearchMobile] = useState(false);
    const [flagModalSettingsMobile, setFlagModalSettingsMobile] = useState(false);
    const [loading, setLoading] = useState(false);

    const statisticTiming = [variable_1, variable_2, variable_3, variable_4];
    const innerBlockRef = useRef();
    const inputSearchRefMobile = useRef();

    let statusMembers = "";
    if (buttonNavigate === 0) statusMembers = "?status=1";
    if (buttonNavigate === 1) statusMembers = "?status=0";
    if (buttonNavigate === 2) statusMembers = "?status=128";

    let handleSearchMembers = () => {
        if (searchMembers) {
            if (activeStatisticCourse === 0) {
                getApiIncomingMembers(`${statusMembers}&q=${searchMembers}`, setDisplayListMembers);
            } else {
                let courseId = courseListDropdown[activeStatisticCourse].id;
                getApiIncomingMembers(`${statusMembers}&quest=${courseId}&q=${searchMembers}`, setDisplayListMembers);
            }
        } else {
            if (activeStatisticCourse === 0) {
                getApiIncomingMembers(`${statusMembers}`, setDisplayListMembers);
            } else {
                let courseId = courseListDropdown[activeStatisticCourse].id;
                getApiIncomingMembers(`${statusMembers}&quest=${courseId}`, setDisplayListMembers);
            }
        }
    };
    let customPagination = (array) => {
        if (array) {
            const contentPerPage = countPaginationAllMembers;
            const lastIndex = currentPage * contentPerPage; // 3
            const firstIndex = lastIndex - contentPerPage; // 0
            return array.slice(firstIndex, lastIndex);
        }
        return null;
    };
    let itemRender = (_, type, originalElement) => {
        if (type === "prev")
            return null;
        if (type === "next")
            return;
        return originalElement;
    };
    let handleHideTable = () => {
        setHideTable(!hideTable);
        if (hideTable) {
            setCountView(9);
            setCountPaginationAllMembers(9);
        } else {
            setCountView(displayListMembers.length);
            setCountPaginationAllMembers(13);
        }
    };

    let handleRejectMember = (elem, text = "") => { // удаление юзера из входящих заявок
        requests.quests.change_incoming_member(elem.id, {
            status: 128,
            ...(text ? {denied_reason: text} : {})
        })
            .then((res) => {
                let newArr = [...displayListMembers];
                newArr = newArr.filter(x => x.id !== elem.id);
                setDisplayListMembers(newArr);
                setIdRemove(null);
            })
            .catch((err) => console.log(err, "error change_incoming_member"));
    };
    let handleInviteMember = () => { // принятие юзера в группу курса.
        if (activeGroupMember.length > 0) {
            requests.quests.change_incoming_member(activeGroupMember[0].memberId, activeGroupMember[1])
                .then((res) => {
                    setOpenGroupInList(null);
                    let newArr = [...displayListMembers];
                    newArr = newArr.filter(x => x.id !== activeGroupMember[0].memberId);
                    setDisplayListMembers(newArr);
                    setIdRemove(null);
                })
                .catch((err) => console.log(err, "error handleInviteMember"));
        }
        setOpenGroupInList(null);
    };
    let handleRejectMemberOld = (elem, text) => { // удаление юзера уже принятого ранее в группу, и состоящего в участниках
        requests.quests.change_incoming_member(elem.id, {
            status: 128,
            quest_group: null,
            ...(text ? {denied_reason: text} : {})
        })
            .then(() => {
                let newArr = [...displayListMembers];
                newArr = newArr.filter(x => x.id !== elem.id);
                setDisplayListMembers(newArr);
                setIdRemove(null);
            })
            .catch((err) => console.log(err, "error handleRejectMemberOld"));
    };
    let getApiIncomingMembers = (str, setData) => {
        requests.quests
            .get_incoming_requests_new(str)
            .then(({data}) => {
                setData(data);
                if (activeStatisticCourse === null) {
                    setActiveStatisticCourse(0);
                }
            })
            .finally(() => setCurrentPage(1));
    };
    let getApiQuestGroup = (elem) => {
        requests.quests.get_quest_groups(elem.quest.id)
            .then((res) => {
                setCurrentQuestGroups(res.data.results);
                /*    setCurrentQuestGroups(res.data);*/
            })
            .catch((err) => console.log(err, "err get_quest_groups"));
    };
    let getApiStats = (period) => {
        setLoading(true);
        let value;
        if (period === 0)
            value = "all";
        if (period === 1)
            value = "week";
        if (period === 2)
            value = "month";
        if (period === 3)
            value = "year";

        let idSend = null;
        if (activeStatisticCourse !== 0) {
            idSend = courseListDropdown[activeStatisticCourse].id;
        }
        requests.quests.get_stats(value, idSend)
            .then((res) => {
                setStatisticData(res.data);
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        requests.quests
            .get_org_quests()
            .then((data) => {
                if (data.status === 200) {
                    if (data.data === undefined || data.data === null) {
                        data.data = [];
                    }
                    let nameLocalization = language === "en" ? "All courses" : "Все курсы";
                    let arrStatisticCourse = [{id: null, name: nameLocalization}];
                    for (let i = 0; i < data.data.length; i++)
                        arrStatisticCourse = [...arrStatisticCourse, {name: data.data[i].name, id: data.data[i].id}];
                    setCourseListDropdown(arrStatisticCourse);
                }
            })
            .catch((err) => console.log(err, "err get_org_quests"));
    }, []);
    useEffect(() => {
        if (activeStatisticCourse === 0) {
            getApiIncomingMembers(`${statusMembers}`, setDisplayListMembers);
        } else {
            let courseId = courseListDropdown[activeStatisticCourse].id;
            getApiIncomingMembers(`${statusMembers}&quest=${courseId}`, setDisplayListMembers);
        }
        setSearchMembers("");
    }, [activeStatisticCourse]);
    useEffect(() => {
        setOpenGroupInList(null);
    }, [activeStatisticCourse, buttonNavigate]);
    useEffect(() => {
        setCurrentQuestGroups([]);
        setActiveGroupMember([]);
    }, [openGroupInList]);
    useEffect(() => {
        customPagination();
    }, [currentPage]);
    useEffect(() => {
        getApiStats(activeStatisticTiming);
    }, [activeStatisticTiming]);
    useEffect(() => {
        setDisplayListMembers([]);
        let status = "";
        if (buttonNavigate === 0) status = "?status=1";
        if (buttonNavigate === 1) status = "?status=0";
        if (buttonNavigate === 2) status = "?status=128";
        getApiIncomingMembers(status, setDisplayListMembers);
        setSearchMembers("");
    }, [buttonNavigate]);
    return (
        <div className={"inner_request"}>
            <div ref={innerBlockRef} className="inner_block">
                {(trial_status === 0 || trial_status === 1) && <Trial/>}
                <div className={flagOpenSearchMobile ? "top_filter-mobile hidden" : "top_filter-mobile"}>
                    <div className="icon">
                        <Logo
                            width={38}
                            height={38}
                            onClick={() => window.open("https://skillometer.ru/", 'blank')}
                        />
                    </div>
                    <div className="filters_mobile">
                        <div className="search_"
                             onClick={() => {
                                 setFlagOpenSearchMobile(true);
                             }}
                        >
                            <SearchMobile/>
                        </div>
                    </div>
                    <div className={flagOpenSearchMobile ? "search_mobile active" : "search_mobile"}>
                        <div className={"svg"}><Search/></div>
                        <Input
                            className={"input_classic input_search-mobile"}
                            autoFocus={true}
                            ref={inputSearchRefMobile}
                            placeholder={""}
                            value={searchMembers}
                            onChange={(e) => {
                                setSearchMembers(e.target.value);
                                handleSearchMembers();
                            }}
                            // onKeyDown={(e) => handleSearchProfessionKeyDown(e)}
                        />
                        <span onClick={() => {
                            setFlagOpenSearchMobile(false);
                            setSearchMembers("");
                            handleSearchMembers();
                        }}>{variable_13}</span>
                    </div>
                    <SettingsMobile
                        className="settings"
                        onClick={() => {
                            setFlagModalSettingsMobile(true);
                            setFlagOpenSearchMobile(false);
                        }}
                    />
                </div>
                <div className="top">
                    <span>{variable_14}</span>
                    <div className="group">
                        <div onClick={() => history("/backoffice/my-course")}>{variable_15}</div>
                        <div className={"active"}>{variable_14}</div>
                        <div onClick={() => history("/backoffice/groups-list")}>{variable_16}</div>
                    </div>
                </div>
                <div className={"middle_block"}>
                    <div className="top_sort">
                        <ReuseDropDown
                            flagOpen={flagOpenStatisticTiming}
                            setFlagOpen={(bool) => setFlagOpenStatisticTiming(bool)}
                            arrList={statisticTiming}
                            activeArrList={activeStatisticTiming}
                            setActiveArrList={(indx) => setActiveStatisticTiming(indx)}
                        />
                        <ReuseDropDown
                            flagOpen={flagOpenStatisticCourse}
                            setFlagOpen={(bool) => setFlagOpenStatisticCourse(bool)}
                            arrList={courseListDropdown}
                            activeArrList={activeStatisticCourse}
                            setActiveArrList={(indx) => setActiveStatisticCourse(indx)}
                        />
                        <div className="search">
                            <InputWsButton
                                value={searchMembers}
                                setValue={(e) => setSearchMembers(e)}
                                keyDown={(e) => {
                                    if (e.keyCode === 13)
                                        handleSearchMembers();
                                }}
                                search={() => handleSearchMembers()}
                                placeholder={variable_17}
                            />
                        </div>
                    </div>
                    {statisticData && Object.keys(statisticData).length > 0
                        ? <div className={hideTable ? "statistic_row hide" : "statistic_row"}>
                            <ReuseStatisticBlock
                                title={variable_18}
                                current={"hits_count"}
                                diff={statisticData?.diff?.hits_count || 0}
                                data={statisticData}
                                count={`${statisticData?.total && statisticData?.total?.hits_count || 0} ${variable_20}`}
                                procentague={statisticData?.diff && statisticData?.diff?.hits_count || 0}
                                indxSort={activeStatisticTiming}
                                up={Math.sign(statisticData?.diff?.hits_count) === -1 ? false : true}
                            />

                            <ReuseStatisticBlock
                                title={variable_19}
                                current={"requests_count"}
                                diff={statisticData?.diff?.requests_count || 0}
                                data={statisticData}
                                count={`${statisticData?.total && statisticData?.total?.requests_count || 0} ${variable_20}`}
                                procentague={statisticData?.diff && statisticData?.diff?.requests_count || 0}
                                indxSort={activeStatisticTiming}
                                up={Math.sign(statisticData?.diff?.requests_count) === -1 ? false : true}
                            />


                            <ReuseStatisticBlock
                                title={variable_21}
                                current={"close_to_finish_count"}
                                diff={statisticData?.diff?.close_to_finish_count || 0}
                                data={statisticData}
                                count={`${statisticData?.total && statisticData?.total?.close_to_finish_count || 0} ${variable_20}`}
                                procentague={statisticData?.diff && statisticData?.diff?.close_to_finish_count || 0}
                                indxSort={activeStatisticTiming}
                                up={Math.sign(statisticData?.diff?.close_to_finish_count) === -1 ? false : true}
                            />
                            <ReuseStatisticBlock
                                title={variable_22}
                                current={"reviews_count"}
                                diff={statisticData?.diff?.reviews_count}
                                data={statisticData}
                                count={`${statisticData?.total && statisticData?.total?.reviews_count || 0} ${
                                    language === "ru"
                                        ? defaultFuncAndData.declension(statisticData?.total?.reviews_count, ["отзыв", "отзыва", "отзывов"])
                                        : statisticData?.total?.reviews_count > 1
                                            ? "reviews"
                                            : "review"
                                }`}
                                procentague={statisticData?.diff && statisticData?.diff?.reviews_count || 0}
                                indxSort={activeStatisticTiming}
                                up={Math.sign(statisticData?.diff?.reviews_count) === -1 ? false : true}
                            />
                        </div>
                        : <></>
                    }
                    {loading &&
                        <div style={{width: "100%"}}>
                            <Spin className={"spin"} style={{position: "relative", margin: "10px auto"}}/>
                        </div>
                    }
                    <div className={hideTable ? "request_top mrg" : "request_top"}>
                        <div
                            onClick={() => setButtonNavigate(0)}
                            className={buttonNavigate === 0 ? "button_nav active" : "button_nav"}
                        >
                            <div><InnerIcon/></div>
                            <span>{variable_14}</span>
                        </div>
                        <div
                            onClick={() => setButtonNavigate(1)}
                            className={buttonNavigate === 1 ? "button_nav active" : "button_nav"}
                        >
                            <div><MembersIcon/></div>
                            <span>{variable_23}</span>
                        </div>
                        <div
                            onClick={() => setButtonNavigate(2)}
                            className={buttonNavigate === 2 ? "button_nav active" : "button_nav"}
                        >
                            <div><RemoveIcon/></div>
                            <span>{variable_24}</span>
                        </div>
                    </div>
                    {buttonNavigate === 0 &&
                        <>
                            {
                                displayListMembers && displayListMembers.length > 0
                                    ? <div className={hideTable ? "request_table max_width" : "request_table"}>
                                        {displayListMembers.length <= 9
                                            ? displayListMembers.map((elem, indx) =>
                                                <div key={indx}>
                                                    <StudentRow
                                                        openGroupInList={openGroupInList}
                                                        indx={indx}
                                                        elem={elem}
                                                        setOpenGroupInList={(bool) => setOpenGroupInList(bool)}
                                                        getApiQuestGroup={(elem) => getApiQuestGroup(elem)}
                                                        handleRejectMember={(elem, text) => handleRejectMember(elem, text)}
                                                        handleInviteMember={() => handleInviteMember()}
                                                        currentQuestGroups={currentQuestGroups}
                                                        activeGroupMember={activeGroupMember}
                                                        setActiveGroupMember={(arr) => setActiveGroupMember(arr)}
                                                        buttonNavigate={buttonNavigate}
                                                        setIdRemove={(id) => setIdRemove(id)}
                                                        idRemove={idRemove}
                                                    />
                                                </div>
                                            )
                                            : customPagination(displayListMembers).map((elem, indx) =>
                                                <div key={indx}>
                                                    <StudentRow
                                                        openGroupInList={openGroupInList}
                                                        indx={indx}
                                                        elem={elem}
                                                        setOpenGroupInList={(bool) => setOpenGroupInList(bool)}
                                                        getApiQuestGroup={(elem) => getApiQuestGroup(elem)}
                                                        handleRejectMember={(elem, text) => handleRejectMember(elem, text)}
                                                        handleInviteMember={() => handleInviteMember()}
                                                        currentQuestGroups={currentQuestGroups}
                                                        activeGroupMember={activeGroupMember}
                                                        setActiveGroupMember={(arr) => setActiveGroupMember(arr)}
                                                        buttonNavigate={buttonNavigate}
                                                        setIdRemove={(id) => setIdRemove(id)}
                                                        idRemove={idRemove}
                                                    />
                                                </div>
                                            )
                                        }
                                        {displayListMembers && displayListMembers.length > 9 && hideTable &&
                                            <div className="pagination_all">
                                                <Pagination
                                                    itemRender={itemRender}
                                                    defaultCurrent={1}
                                                    defaultPageSize={countPaginationAllMembers}
                                                    total={displayListMembers.length}
                                                    onChange={(e) => setCurrentPage(e)}
                                                    showTitle={false}
                                                />
                                            </div>
                                        }
                                        <div
                                            className="btn_show-table"
                                            onClick={handleHideTable}
                                        >
                                            {hideTable ? <span>{variable_25} </span> :
                                                <span>{variable_26}</span>}
                                        </div>
                                    </div>
                                    : <div className={"no_content"}>{variable_27}</div>
                            }
                        </>
                    }

                    {buttonNavigate === 1 &&
                        <>
                            {(displayListMembers && displayListMembers.length > 0)
                                ? <div className={hideTable ? "request_table max_width" : "request_table"}>
                                    {displayListMembers.length <= 9
                                        ? displayListMembers.map((elem, indx) =>
                                            <div key={indx}>
                                                <StudentRow
                                                    openGroupInList={openGroupInList}
                                                    indx={indx}
                                                    elem={elem}
                                                    setOpenGroupInList={(bool) => setOpenGroupInList(bool)}
                                                    getApiQuestGroup={(elem) => getApiQuestGroup(elem)}
                                                    handleRejectMember={(elem, text) => handleRejectMember(elem, text)}
                                                    handleInviteMember={(bool) => handleInviteMember(bool)}
                                                    currentQuestGroups={currentQuestGroups}
                                                    activeGroupMember={activeGroupMember}
                                                    setActiveGroupMember={(arr) => setActiveGroupMember(arr)}
                                                    handleRejectMemberOld={(elem, text) => handleRejectMemberOld(elem, text)}
                                                    buttonNavigate={buttonNavigate}
                                                    setIdRemove={(id) => setIdRemove(id)}
                                                    idRemove={idRemove}
                                                />
                                            </div>
                                        )
                                        : customPagination(displayListMembers).map((elem, indx) =>
                                            <div key={indx}>
                                                <StudentRow
                                                    openGroupInList={openGroupInList}
                                                    indx={indx}
                                                    elem={elem}
                                                    setOpenGroupInList={(bool) => setOpenGroupInList(bool)}
                                                    getApiQuestGroup={(elem) => getApiQuestGroup(elem)}
                                                    handleRejectMember={(elem, text) => handleRejectMember(elem,text)}
                                                    handleInviteMember={(bool) => handleInviteMember(bool)}
                                                    currentQuestGroups={currentQuestGroups}
                                                    activeGroupMember={activeGroupMember}
                                                    setActiveGroupMember={(arr) => setActiveGroupMember(arr)}
                                                    handleRejectMemberOld={(elem, text) => handleRejectMemberOld(elem, text)}
                                                    buttonNavigate={buttonNavigate}
                                                    setIdRemove={(id) => setIdRemove(id)}
                                                    idRemove={idRemove}
                                                />
                                            </div>
                                        )
                                    }
                                    {displayListMembers && displayListMembers.length > 9 && hideTable &&
                                        <div className="pagination_all">
                                            <Pagination
                                                itemRender={itemRender}
                                                defaultCurrent={1}
                                                defaultPageSize={countPaginationAllMembers}
                                                total={displayListMembers.length}
                                                onChange={(e) => setCurrentPage(e)}
                                                showTitle={false}
                                            />
                                        </div>}
                                    <div
                                        className="btn_show-table"
                                        onClick={handleHideTable}
                                    >
                                        {hideTable ? <span>{variable_25} </span> :
                                            <span>{variable_26}</span>}
                                    </div>
                                </div>
                                : <div className={"no_content"}>{variable_30}</div>
                            }
                        </>

                    }
                    {buttonNavigate === 2 &&
                        <>
                            {displayListMembers && displayListMembers.length > 0
                                ? <div className={hideTable ? "request_table max_width" : "request_table"}>
                                    {
                                        displayListMembers.slice(0, countView).map((elem, indx) =>
                                            <div key={indx}>
                                                <StudentRow
                                                    openGroupInList={openGroupInList}
                                                    indx={indx}
                                                    elem={elem}
                                                    setOpenGroupInList={(bool) => setOpenGroupInList(bool)}
                                                    getApiQuestGroup={(elem) => getApiQuestGroup(elem)}
                                                    handleRejectMember={(elem, text) => handleRejectMember(elem, text)}
                                                    handleInviteMember={(bool) => handleInviteMember(bool)}
                                                    currentQuestGroups={currentQuestGroups}
                                                    activeGroupMember={activeGroupMember}
                                                    setActiveGroupMember={(arr) => setActiveGroupMember(arr)}
                                                    buttonNavigate={buttonNavigate}
                                                    setIdRemove={(id) => setIdRemove(id)}
                                                    idRemove={idRemove}
                                                />
                                            </div>
                                        )
                                    }
                                    <div
                                        className="btn_show-table"
                                        onClick={handleHideTable}
                                    >
                                        {hideTable ? <span>{variable_25} </span> :
                                            <span>{variable_26}</span>}
                                    </div>
                                </div>
                                : <div className={"no_content"}>{variable_28}</div>
                            }
                        </>
                    }
                </div>
            </div>
            <>
                {flagModalSettingsMobile &&
                    <Modal
                        visible={flagModalSettingsMobile}
                        footer={null}
                        className={"modal_settings-mobile"}
                        closable={false}
                        onCancel={() => {
                            setFlagModalSettingsMobile(false);
                        }}
                        maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                        bodyStyle={{padding: 15, backgroundColor: "#343843", borderRadius: 16}}
                    >
                        <div className="title">
                            <div className="group_left">
                                <img src={settings_mobile_category}/>
                                <span>{variable_29}</span>
                            </div>
                            <div
                                className="close"
                                onClick={() => {
                                    setFlagModalSettingsMobile(false);
                                }}
                            >
                                <img src={close_modal_grey}/>
                            </div>
                        </div>
                        <div className="list">
                            {[variable_14, variable_23, variable_24].map((elem, indx) =>
                                <div
                                    key={indx}
                                    onClick={() => {
                                        setButtonNavigate(indx);
                                        setFlagModalSettingsMobile(false);
                                    }}
                                >
                                    {elem}
                                </div>
                            )}
                        </div>
                    </Modal>
                }
            </>
            <>
                <div
                    className={flagOpenStatisticTiming || flagOpenStatisticCourse ? "overlay open" : "overlay"}
                    onClick={() => {
                        setFlagOpenStatisticTiming(false);
                        setFlagOpenStatisticCourse(false);
                    }}
                />
            </>
        </div>
    );
};

export default InnerRequestMember;