import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import requests from "../../../../axios/requests";
import { Input, message, Modal, Spin } from "antd";
import variableLanguage from "../../../../utils/hooks/hookGetVariablesLanguage";
import { setUserTrajectory } from "../../../../../redux/actions/userActions";

import study_traektory from "../../../../../images/search_profession/study_traektory.svg";
import close_tag from "../../../../../images/search_profession/close_tag.svg";
import './ModalTrajectory.scss';

const ModalTrajectory = (props) => {
    const {
        flagModalTraektoryMobile, setFlagModalTraektoryMobile, apiRemoveTrajectory,
        setFlagModalGpt, getListApiCourses,addTrajectorySkill
    } = props;
    const {trajectorySkills} = useSelector(state => state.Reducer);
    const dispatch = useDispatch();

    const variables_8 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_8"});
    const variables_10 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_10"});
    const variables_28 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_28"});
    const variables_31 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_31"});

    const [valueInputTag, setValueInputTag] = useState("");
    const [existTagsList, setExistTagsList] = useState([]);
    const [timeoutSearch, setTimeoutSearch] = useState(null);
    const [timeoutFetchPagination, setTimeoutFetchPagination] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [flagOpenSelectTags, setFlagOpenSelectTags] = useState(false);
    const [loading, setLoading] = useState(false);

    let fetchSearchTags = (page_size) => {
        setLoading(true);
        requests.profs.get_speciality_tags(valueInputTag, 1, page_size || pageSize)
            .then((v) => setExistTagsList(v.data))
            .finally(() => setLoading(false))
    }
    let handleScroll = (event) => {
        let scrollTop = event.target.scrollTop;
        if (timeoutFetchPagination)
            clearTimeout(timeoutFetchPagination)
        if ((scrollTop >= (pageSize * 14)) && (existTagsList?.count > pageSize)) {
            setTimeoutFetchPagination(
                setTimeout(() => {
                    setPageSize(prev => prev + 10);
                    fetchSearchTags(pageSize + 10)
                }, 500)
            )
        }
    }

    useEffect(() => {
        if (valueInputTag.length > 1) {
            let lastSymbol = valueInputTag[valueInputTag.length - 1];
            if (lastSymbol !== " ") {
                setPageSize(10)
                setExistTagsList([]);
                if (timeoutSearch)
                    clearTimeout(timeoutSearch)

                setTimeoutSearch(setTimeout(() =>
                    fetchSearchTags(10), 500))
            }
        }
    }, [valueInputTag])
    return (
        <Modal
            visible={flagModalTraektoryMobile}
            footer={null}
            className={"modal_traektory-mobile"}
            closable={false}
            onCancel={() => setFlagModalTraektoryMobile(false)}
            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
            bodyStyle={{padding: 15, backgroundColor: "#27292F", borderRadius: 20}}
        >
            <div className="title">
                <img src={study_traektory}/>
                <span>{variables_8}</span>
            </div>

                <div className="select_block">
                    <Input
                        className={"input_classic"}
                        value={valueInputTag}
                        onChange={(e) => setValueInputTag(e.target.value)}
                        onFocus={() => setFlagOpenSelectTags(true)}
                        onBlur={() => {
                            setTimeout(() => {
                                setFlagOpenSelectTags(false);
                            }, 100);
                        }}
                        placeholder={variableLanguage({
                            keyPage: "searchProfession",
                            keyVariable: "variables_9"
                        })}
                    />
                    {existTagsList?.results?.length > 0 &&
                        <div
                            className={flagOpenSelectTags
                                ? "parent_tag open"
                                : "parent_tag"
                            }
                            onScroll={handleScroll}
                        >
                            {existTagsList.results.map((elem, indx) =>
                                <div
                                    className={"tag_"}
                                    key={indx}
                                    onClick={() => {
                                        setValueInputTag("");
                                        for (let i = 0; i < trajectorySkills.length; i++) {
                                            if (trajectorySkills[i].name === elem.name) {
                                                return message.warning(variables_31);
                                            }
                                        }
                                        addTrajectorySkill(elem);
                                        let newPrev = [...trajectorySkills];
                                        newPrev = [...newPrev, {name: elem.name}];
                                        return dispatch(setUserTrajectory(newPrev));
                                    }}
                                >
                                    {elem.name}
                                </div>
                            )}
                        </div>
                    }
                    {loading &&
                        <Spin className="spin_loading"/>
                    }
                </div>
                <div className="down_block-traektory">
                    <div className="block_tags">
                        {trajectorySkills.map((elem, indx) => {
                                return (
                                    <div
                                        key={indx}
                                        className={"tag"}>
                                        {elem.name}
                                        <img
                                            onClick={() => apiRemoveTrajectory(elem)}
                                            src={close_tag}
                                        />
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
            <div className="buttons">
                <div
                    onClick={() => setFlagModalGpt(true)}
                    className="btn_search-course"
                >
                    Создать план обучения
                </div>
                <div
                    onClick={getListApiCourses}
                    className="btn_search-course"
                >
                    {variables_10}
                </div>
            </div>
            <div
                className="close"
                onClick={() => setFlagModalTraektoryMobile(false)}
            >
                {variables_28}
            </div>
        </Modal>
    );
};

export default ModalTrajectory;