import React from 'react';
import { Progress } from "antd";
import top_grey_gorizontal from "../../../../images/search_profession/top_grey-gorizontal.svg";

const GorizontalProgressItem = (props) => {
    const {
        procentague, leftMiddle, index, indexSalary,
        more200 = false, isFavorite = false, color = false
    } = props;
    return (
        <div className={'gorizontal_progress'}>
            <div
                className="top_grey"
                style={{
                    position: 'absolute',
                    right: isFavorite ? 0 : -14,
                    zIndex: 1,
                    top: -8,
                }}
            >
                <img width={16} height={25} src={top_grey_gorizontal} alt=""/>
            </div>
            <div className="index">{index}</div>
            <div
                style={more200 ? {
                    position: 'absolute',
                    top: -1,
                    left: `${leftMiddle + 3}%`,
                    zIndex: 1,
                    display: "none"
                } : {
                    position: 'absolute',
                    top: -1,
                    left: `${leftMiddle + 3}%`,
                    zIndex: 1
                }}
                className="index_salary"
            >{indexSalary}</div>
            <Progress
                className={color === 'blue' ? 'progress_bar blue' : 'progress_bar'}
                percent={procentague}
                size="large"
                format={() => null}
                strokeColor={color === 'blue' ? '#2661D3' : '#28C98F'}
                trailColor={'#565F71'}
                strokeWidth={25}
                style={{
                    position: 'absolute',
                    left: isFavorite ? 0 : 13,
                    top: -8,
                    paddingLeft: isFavorite ? 15 : 0
                    // width: 660
                }}
            />
        </div>
    );
};

export default GorizontalProgressItem;