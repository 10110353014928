import React from 'react';
import fake_photos from "../../../../images/icons/fake_photos.png";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

import { ReactComponent as HomeActive } from "../../../../images/icons/home_active.svg";
import { ReactComponent as CodeIcon } from "../../../../images/icons/code_icon.svg";

const HomeWork = (props) => {
    const {
        activeStageHomework, stagesHomeWork, setHomeworkResult,
        setActiveTaskHomework, setActiveStageHomework, tasksHomeWork,
        getApiHomeWorkId, activeTaskHomework, parseDate, homeworkResult,
        history
    } = props;

    const variable_8 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_8"});
    const variable_22 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_22"});
    const variable_30 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_30"});
    const variable_32 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_32"});
    const variable_35 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_35"});
    const variable_37 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_37"});
    const variable_40 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_40"});
    const variable_41 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_41"});

    return (
        <div className={"home_work"}>
            {stagesHomeWork && stagesHomeWork.length > 0
                ? <>
                    <div className="stage_block">
                        <div className="title">{variable_35}</div>
                        {stagesHomeWork.map((elem, indx) =>
                            <div
                                className={activeStageHomework === elem.id ? "stage active" : "stage"}
                                onClick={() => {
                                    setHomeworkResult([]);
                                    setActiveStageHomework(elem.id);
                                    setActiveTaskHomework(0);
                                }}
                                key={elem.id}
                            >
                                <span> {variable_22} — {elem.sortOrder} {elem.name}</span>
                            </div>
                        )
                        }
                    </div>
                    <div className="matherial_block">
                        <div className="title">
                            {variable_8}
                        </div>
                        {tasksHomeWork && tasksHomeWork.length > 0 &&
                            tasksHomeWork.map((elem, indx) => {
                                    if (elem.stage.id === activeStageHomework)
                                        return (
                                            <div
                                                className={activeTaskHomework === elem.id
                                                    ? "matherial active"
                                                    : "matherial"
                                                }
                                                onClick={() => {
                                                    setActiveTaskHomework(elem.id);
                                                    getApiHomeWorkId("", false, elem.id);
                                                }}
                                                key={elem.id}
                                            >
                                                <div
                                                    className={elem.task_type === 'code' ? "img code" : "img"}>
                                                    {elem.task_type === 'code'
                                                        ? <CodeIcon className={"code"}/>
                                                        : <HomeActive/>
                                                    }
                                                </div>
                                                <span>
                                                    {elem.title}
                                                </span>
                                            </div>
                                        );
                                }
                            )
                        }
                        {activeStageHomework === 0 &&
                            <div style={{textAlign: 'center'}}>
                                {variable_37}
                            </div>
                        }
                    </div>
                    <div className="work_block">
                        <div className="title">{variable_40}</div>
                        {homeworkResult && homeworkResult.length > 0 && activeTaskHomework &&
                            homeworkResult.map((elem, indx) =>
                                <div className="row_study" key={indx}>
                                    <div className="top">
                                        <img
                                            src={elem.user.profile && elem.user.profile.avatar || fake_photos}/>
                                        <div className="border"/>
                                        <div
                                            className="name">
                                            {elem.user.profile && elem.user.profile.name}
                                        </div>
                                        <div className="border"/>
                                        <div
                                            className="name_matherial">
                                            {elem.task.title}
                                        </div>
                                        <div className="border"/>
                                        <div className="date">
                                            {parseDate(elem.time)}
                                        </div>
                                    </div>
                                    <div className="middle">
                                        {elem.task.stage.quest.name}
                                    </div>
                                    <div
                                        className="bottom"
                                        onClick={() => {
                                            if (elem.task.task_type === 'text')
                                                history(`/backoffice/check-homework/${elem.id}/${elem.task.id}/${elem.task.stage.id}/false`)
                                            else
                                                history(`/backoffice/check-codework/${elem.id}/${elem.task.id}/${elem.task.stage.id}/false`)
                                        }}
                                    >
                                        {variable_32}
                                    </div>
                                </div>
                            )
                        }
                        {activeStageHomework !== 0 && homeworkResult.length === 0 &&
                            <div style={{textAlign: 'center'}}>
                                {variable_41}
                            </div>
                        }
                    </div>
                </>
                : <div className={"no_content"}>
                    {variable_30}
                </div>
            }
        </div>
    );
};

export default HomeWork;