import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import requests from "../../axios/requests";
import {
    setActiveListProfession, setActiveProfession, setActiveVacancy,
    setDataBaseProfession, setDataProfession, setListPofessionOriginal
} from "../../../redux/actions/searchProfessionActions";
import { setUserTrajectory } from "../../../redux/actions/userActions";
import citiesEn from "../../data/citiesEn.json";
import citiesRu from "../../data/citiesRu.json";
import cn from 'classnames';
import { Steps } from "intro.js-react";
import { setFlagSteps } from "../../../redux/actions/actions";

import { Input, message, Modal, Pagination, Select, Spin } from "antd";
import SkillBlockProfession from "./ReuseComponents/SkillBlockProfession";
import Profession from "./ProfessionList/Profession";
import SkillBlockVacancy from "./ReuseComponents/SkillBlockVacancy";
import ModalFilter from "./Modals/ModalFilter";
import ModalFavorite from "./Modals/ModalFavorite";
import ModalCourse from "./Modals/ModalCourse";
import ModalProfessionInfo from "./Modals/ModalProfessionInfo";
import SkillAnimation from "./ReuseComponents/SkillAnimation";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import ProfessionList from "./ProfessionList/ProfessionList";
import TrajectorySection from "./TrajectorySection/TrajectorySection";
import VacancySection from "./VacancySection/VacancySection";
import ChatGptModal from "./ChatGptModal/ChatGptModal";
import SliderVacancy from './Mobile/SliderVacancy/SliderVacancy';
import ModalTrajectory from "./Mobile/ModalTrajectory/ModalTrajectory";
import ScrollUp from "../../components/reuseComponents/ScrollUp";

import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { ReactComponent as Logo } from "../../../images/icons/logo.svg";
import nav_bar_profession_top from "../../../images/search_profession/profession_list_label.svg";
import search_filter from "../../../images/search_profession/search_filter.svg";
import filter_mobile from "../../../images/search_profession/filter_mobile.svg";
import search_mobile from "../../../images/search_profession/search_icon.svg";
import favorite_mobile from "../../../images/search_profession/favorite_mobile.svg";
import settings_mobile from "../../../images/search_profession/settings_mobile.svg";
import settings_mobile_category from "../../../images/search_profession/settings_mobile-category.svg";
import close_modal_grey from "../../../images/search_profession/close_modal-course.svg";
import './SearchProfession.scss';

const SearchProfession = () => {
    // эти хуки лучше вызвать заранее и присвоить константе, а не в самой jsx-разметке, иначе конфликты в условиях.
    const variables_2 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_2"});
    const variables_3 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_3"});
    const variables_14 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_14"});
    const variables_24 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_24"});
    const variables_27 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_27"});
    const variables_29 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_29"});
    const variables_30 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_30"});
    const variables_31 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_31"});
    const variables_39 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_39"});

    const {
        activeListProfession, activeProfession, activeVacancy,
        dataProfession, dataBaseProfs, listProfessionOrigin
    } = useSelector(state => state.SearchProfession);
    const {userSkills, trajectorySkills, myRegion, language, user, flagSteps} = useSelector(state => state.Reducer);

    const [currentPage, setCurrentPage] = useState(1);
    const [displayListProfession, setDisplayListProfession] = useState([]);
    const [searchProfessionInput, setSearchProfessionInput] = useState("");
    const [favoriteProfession, setFavoriteProfession] = useState([]);
    const [favoriteVacancy, setFavoriteVacancy] = useState([]);
    const [myAnimationHidden, setMyAnimationHidden] = useState(false);
    const [myAnimationFlagMove, setMyAnimationFlagMove] = useState(false);
    const [currentSkillAddTraektory, setCurrentSkillAddTraektory] = useState(null);
    const [listCourse, setListCourse] = useState(null);
    const [coordsTagAnimation, setCoordsTagAnimation] = useState({});
    const [heightTraektory, setHeightTraektory] = useState(null);
    const [scrollY, setScrollY] = useState([]);
    const [scrollYVacancy, setScrollYVacancy] = useState(0);
    const [vacancycData, setVacancycData] = useState([]);
    const [sliderHeightVacancy, setSliderHeightVacancy] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [timeoutFetchNeyron, setTimeoutFetchNeyron] = useState(null);

    const [flagLoadingVacancy, setFlagLoadingVacancy] = useState(false);
    const [flagDropDownProfession, setFlagDropDownProfession] = useState(false);
    const [flagModalFilter, setFlagModalFilter] = useState(false);
    const [flagModalFavorite, setFlagModalFavorite] = useState(false);
    const [flagModalProfessionInfo, setFlagModalProfessionInfo] = useState(false);
    const [flagModalCourse, setFlagModalCourse] = useState(false);
    const [flagOpenSearchMobile, setFlagOpenSearchMobile] = useState(false);
    const [flagModalTraektoryMobile, setFlagModalTraektoryMobile] = useState(false);
    const [flagHiddenFotter, setFlagHiddenFooter] = useState(null);
    const [flagModalSettingsMobile, setFlagModalSettingsMobile] = useState(false);
    const [loadingProfession, setLoadingProfession] = useState(false);
    const [flagModalGpt, setFlagModalGpt] = useState(false);
    /*const [flagSteps, setFlagSteps] = useState(false);*/

    const dispatch = useDispatch();
    const innerBlockRef = useRef();
    const studyTraektoryRef = useRef();
    const refSteps = useRef();
    const refSliderContainer = useRef();
    const steps = [
        {
            element: ".professions",
            intro: `<span style="font-size: 20px; margin-bottom: 10px">  Выберите желаемую профессию.</span> <br/> <br/> Нажав на кнопку i получите подробное описание профессии, ` +
                "популярные ключевые навыки данной профессии, а также подходящие образовательные курсы.",
            /* intro: strStep,*/
            position: 'right',
        },
        {
            element: ".vacancies_block",
            intro: `<span style="font-size: 20px"> Выберите желаемую вакансию.</span> <br/> <br/>  Данные вакансии являются актуальными на площадках hh.ru, trudvsem.` +
                " Нажав на логотип вы можете перейти на первоисточник вакансии для более подробно ознакомления.",
            position: 'left',
        },
        {
            element: ".vacancy_section",
            intro: `<span style="font-size: 20px"> Добавьте навык.</span> <br/> <br/>  По выбранной вакансии система покажет недостающие ключевые навыки красным цветом.` +
                " Набирайте навыки в образовательную траекторию для дальнейшего его освоения." +
                `<br/> P.S. Если недостающие навыки не подсвечиваются выберите другую вакансию`,
            position: 'left',
        },
        {
            element: ".planned",
            intro: "Выберите навык и получите детальный план обучения.",
            position: 'bottom',
        },
        {
            element: ".courses",
            intro: "Создавайте курсы, добавляя навыки из учебного плана.",
            position: 'bottom',
        },
    ]

    let getCurrentHeight = () => {
        if (refSliderContainer?.current) {
            const {height} = refSliderContainer.current.getBoundingClientRect();
            if (height)
                setSliderHeightVacancy(height)
        }
    }
    let changeTrained = (value) => {
        requests.auth.edit_profile_new({trained: value})
            .catch(err => console.error(err))
    }
    let itemRender = (_, type, originalElement) => {
        if (type === "prev")
            return null;
        if (type === "next") {
            return;
        }
        return originalElement;
    };
    let handleCurrentProfession = (current) => {
        dispatch(setActiveListProfession(current));
        setFlagDropDownProfession(false);
    };
    let customPagination = (array) => {
        if (array) {
            const contentPerPage = 12;
            const lastIndex = currentPage * contentPerPage
            const firstIndex = lastIndex - contentPerPage;
            return array.slice(firstIndex, lastIndex);
        }
        return null;
    };
    let searchProfession = (value) => {
        if (!value) {
            setDisplayListProfession(customPagination(listProfessionOrigin));
            return
        }
        let newArray = listProfessionOrigin.filter(p => p.name.toUpperCase().includes(value.toUpperCase()));
        setDisplayListProfession(newArray);
        setCurrentPage(1);
    }
    let handleChangeFavoriteProfession = (elem) => {
        let newArrProf = [...favoriteProfession];
        for (let i = 0; i < favoriteProfession.length; i++) {
            if (favoriteProfession[i].profession.id === elem.id) {
                newArrProf = newArrProf.filter(v => v.profession.id !== elem.id);
                requests.profs.remove_profession_favorite(elem.id)
                    .catch((error) => console.error(error));
                return setFavoriteProfession(newArrProf);
            }
        }
        requests.profs.add_profession_favorite({profession: elem.id, tags_percent: Number(elem.procentague)})
            .then(() => getApiProfessionFavorite())
            .catch((error) => console.error(error));
    };
    let handleChangeFavoriteVacancy = (elem) => {
        let newArrVacancy = [...favoriteVacancy];
        let newArr = [...vacancycData];
        for (let i = 0; i < favoriteVacancy.length; i++) {
            if (favoriteVacancy[i].id === elem.id) {
                newArrVacancy = newArrVacancy.filter(v => v.id !== elem.id);
                requests.profs.remove_vacancy_favorite(elem.id)
                    .then(() => getApiVacancyFavorite())
                    .catch((error) => console.error(error));
                let updateElemIndx = newArr.findIndex(x => x.id === elem.id);
                if (updateElemIndx !== -1) {
                    newArr[updateElemIndx].favorite = false;
                    setVacancycData(newArr);
                }
                return setFavoriteVacancy(newArrVacancy);
            }
        }

        let salaryStr = () => {
            let str = `${(elem.salary?.from && !elem.salary?.to) ? "От " : ""}`;
            if (elem.salary?.from) str = str + elem.salary?.from;
            if (!elem.salary?.from && elem.salary?.to) str = str + "До ";
            if (elem.salary?.to) str = str + " - ";
            if (elem.salary?.to) str = str + elem.salary?.to;
            return str;
        };
        let obj = {
            id: elem.id,
            name: elem.name,
            type: elem.alternate_url.includes("//hh") ? "hh" : "trud_vsem",
            salary: salaryStr(),
            company: elem.employer.name,
            link: elem.alternate_url
        };
        requests.profs.add_vacancy_favorite(obj)
            .then(() => {
                getApiVacancyFavorite();
                let updateElemIndx = newArr.findIndex(x => x.id === elem.id);
                if (updateElemIndx !== -1) {
                    newArr[updateElemIndx].favorite = true;
                    setVacancycData(newArr);
                }
            })
            .catch((error) => console.error(error));
        newArrVacancy = [...favoriteVacancy, elem];
        setFavoriteVacancy(newArrVacancy);
    };
    let timeoutHiddenAnimation = () => {
        setMyAnimationHidden(false);
        setMyAnimationFlagMove(true);
        setTimeout(() => {
            setMyAnimationHidden(true);
            setMyAnimationFlagMove(false);
        }, 600);
    };
    let handleScroll = (event) => {
        if (window.innerWidth < 991) {
            setScrollY(event.currentTarget.scrollTop);
            if (event.currentTarget.scrollTop < scrollY)
                setFlagHiddenFooter(true);
            else
                setFlagHiddenFooter(false);
        }
        if (event.currentTarget.scrollTop < 90)
            setScrollYVacancy(0)
        else
            setScrollYVacancy(1)
    }
    let handleOpenSearchMobile = () => {
        setFlagOpenSearchMobile(true);
    };
    let getListApiVacancy = async (professionTitle, myRegion, disability) => {
        setFlagLoadingVacancy(true);
        setVacancycData([]);
        let hhArray = [];

        if (language === "en") {
            let findIndx = citiesEn.citiesEn.findIndex(x => x === myRegion);
            if (findIndx !== -1)
                myRegion = citiesRu.citiesRu[findIndx];
        }

        await requests.profs.get_hh_vacancy(activeProfession.professionTitle, myRegion)
            .then(res => hhArray = res.data?.vacancies)

        setVacancycData(hhArray);
        setFlagLoadingVacancy(false);
    };
    let getListApiCourses = async () => {
        setFlagModalCourse(true);
        requests.quests.get_quests({available: true})
            .then(res => setListCourse(res.data))
            .catch((error) => console.error(error));
    };
    let getApiProfessionFavorite = () => {
        requests.profs.get_profession_favorite()
            .then((res) => {
                setFavoriteProfession(res.data);
            })
            .catch((err) => console.error(err));
    };
    let getApiVacancyFavorite = () => {
        requests.profs.get_vacancy_favorite()
            .then((res) => setFavoriteVacancy(res.data))
            .catch((err) => console.error(err));
    };
    let addTrajectorySkill = (elem, needTimeout = false) => {
        for (let i = 0; i < trajectorySkills.length; i++) {
            if (trajectorySkills[i].name === elem.name) {
                return message.warning(variables_31);
            }
        }
        let newArr = [...trajectorySkills];
        newArr = [...newArr, {id: elem.id, name: elem.name}];
        if (needTimeout) {
            setCurrentSkillAddTraektory(elem.name);
            timeoutHiddenAnimation();
            setTimeout(() => {
                dispatch(setUserTrajectory(newArr))
            }, 500);
        } else
            dispatch(setUserTrajectory(newArr))

        requests.auth
            .patch_organization_info({planning_skills: newArr.map((elem => elem.id))})
            .then(() => message.success(variables_39))
            .catch((e) => console.error(e));
    };
    let removeTrajectorySkill = (skill) => {
        let newArr;
        for (let i = 0; i < trajectorySkills.length; i++) {
            if (trajectorySkills[i].id === skill.id) {
                newArr = trajectorySkills.filter(t => t.id !== skill.id);
                break;
            }
        }
        dispatch(setUserTrajectory(newArr));
        requests.auth
            .patch_organization_info({planning_skills: newArr.map((elem => elem.id))})
            .catch((e) => console.error(e));
    };
    let getNeyronProfs = async () => {
        console.log('call this')
        setLoadingProfession(true);
        let skillsArray = userSkills.map(skill => skill.name).flat() || [];
        let profsList = [];
        await requests.profs.profs_list()
            .then(res => {
                profsList = res.data.map((prof2) => {
                    return {...prof2, tags: [...(prof2.tags || [])]};
                });
                profsList = profsList.sort((a, b) => b.favorite - a.favorite);
            })
            .catch(err => console.error(err))
        const wait = (milliseconds) => {
            return new Promise(
                (res, rej) => setTimeout(
                    () => rej(new Error(`timed out after ${milliseconds} ms`)),
                    milliseconds
                ))
        }
        const abortController = new AbortController();
        const url = `${process.env.REACT_APP_API_URL}v2/web/student/profession/profession/${activeListProfession === 0 ? "all" : "it"}/`;

        const fetchData = fetch(
            url,
            {
                method: 'POST',
                headers: {
                    "Authorization": `Token ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                },
                body: JSON.stringify({tags: skillsArray}),
                signal: abortController.signal,
            }
        );
        const fetchOrTimeout = Promise.race([fetchData, wait(4000)]);
        fetchOrTimeout
            .then(async (response) => {
                let res = await response.json();
                if ("detail" in res) {
                    setLoadingProfession(false)
                    dispatch(setDataBaseProfession(profsList))
                } else {
                    setLoadingProfession(false)
                    dispatch(setDataProfession(res))
                    dispatch(setDataBaseProfession([]))
                }
            })
            .catch(error => {
                abortController.abort();
                setLoadingProfession(false)
                dispatch(setDataBaseProfession(profsList))
                console.error(error)
            });
        /* requests.profs
             .get_profs_neyron(activeListProfession === 0 ? "all" : "it", {tags: skillsArray})
             .then(res => {
                 let profs =
                     Object.entries(res.data)
                         .map((elem) => elem[1])
                         .sort((a, b) => b.percent - a.percent);
                 profs.forEach((_, i) => {
                     let matchingprofs = profsList.filter((obj) => profs[i].name === obj.name);
                     if (typeof matchingprofs !== "undefined" && matchingprofs.length > 0) {
                         profs[i].favorite = matchingprofs[0].favorite || false;
                         profs[i].key = matchingprofs[0].id;
                         profs[i].extra = (matchingprofs[0]?.tags || []).map((tag) => {
                                 const name = tag.name.toLowerCase();
                                 return {name, status: tag.status, id: tag.id || null};
                             }
                         );
                     }
                 });
                 setLoadingProfession(false)
                 dispatch(setDataProfession(profs))
                 dispatch(setDataBaseProfession([]))
             })
             .catch(err => {
                 console.error(err)
                 setLoadingProfession(false)
                 dispatch(setDataBaseProfession(profsList))
             })*/

    }

    useEffect(() => {
        getApiProfessionFavorite();
        getApiVacancyFavorite();
    }, []);
    useEffect(() => {
        if (activeListProfession === 0) {
            if (dataProfession.all.length > 0)
                setDisplayListProfession(customPagination(dataProfession.all))
        } else {
            if (dataProfession.it.length > 0)
                setDisplayListProfession(customPagination(dataProfession.it))
        }
        if (dataBaseProfs.length > 0)
            setDisplayListProfession(customPagination(dataBaseProfs))
    }, [currentPage, listProfessionOrigin, dataProfession, dataBaseProfs]);
    useEffect(() => {
        if (activeProfession !== null)
            getListApiVacancy(activeProfession.professionTitle, myRegion, false);
    }, [activeProfession]);
    useEffect(() => {
        searchProfession(searchProfessionInput)
    }, [searchProfessionInput])
    useEffect(() => {
        if (trajectorySkills && trajectorySkills.length > 0)
            dispatch(setUserTrajectory(trajectorySkills))
        let height = studyTraektoryRef?.current?.getBoundingClientRect().height;
        setHeightTraektory(height);
    }, [trajectorySkills]);
    useEffect(() => {
        if (activeListProfession === 1) {
            if (dataProfession.it.length === 0) {
                if (timeoutFetchNeyron)
                    clearTimeout(timeoutFetchNeyron)
                setTimeoutFetchNeyron(
                    setTimeout(() => getNeyronProfs(), 1000)
                )
            }
            else {
                setDisplayListProfession(customPagination(dataProfession.it))
                dispatch(setListPofessionOriginal(dataProfession.it))
            }
        }
        if (activeListProfession === 0) {
            if (dataProfession.all.length === 0) {
                if (timeoutFetchNeyron)
                    clearTimeout(timeoutFetchNeyron)
                setTimeoutFetchNeyron(
                    setTimeout(() => getNeyronProfs(), 1000)
                )
            } else {
                setDisplayListProfession(customPagination(dataProfession.all))
                dispatch(setListPofessionOriginal(dataProfession.all))
            }
        }
        setSearchProfessionInput("")
        setCurrentPage(1);
    }, [activeListProfession, userSkills])
    useEffect(() => {
        if (vacancycData.length > 0) {
            setTimeout(() => {
                getCurrentHeight();
            }, 100)
        }
    }, [vacancycData])
    useEffect(() => {
        if (typeof user?.trained !== 'undefined' && !user.trained) {
            dispatch(setFlagSteps(true))
        }
    }, [user]);

    /* if (!activeModules.includes("planning_tags"))
         history("/")*/
    return (
        <div className={"search_profession-parent"}>
            {window.innerWidth > 991 &&
                <Steps
                    options={{
                        // scrollToElement: false,
                        doneLabel: "Завершить",
                        nextLabel: "Далее",
                        prevLabel: "Вернуться",
                        showButtons: true,
                        showBullets: false,
                        overlayOpacity: 0.7,
                        disableInteraction: false,
                        exitOnOverlayClick: false
                    }}
                    enabled={flagSteps}
                    steps={steps}
                    initialStep={0}
                    ref={steps => (refSteps.current = steps)}
                    onBeforeChange={(index) => {
                        /* refSteps.current.updateStepElement(index);*/


                        if (index === 1 && !activeProfession) {
                            message.warning("Выберите профессию")
                            return false
                        }
                        if (index === 2 && !activeVacancy) {
                            message.warning("Выберите вакансию")
                            return false
                        }
                        setCurrentStep(index)

                    }}
                    onExit={() => {
                        changeTrained(true)
                        dispatch(setFlagSteps(false))
                    }}
                />
            }
            <ProfessionList
                displayListProfession={displayListProfession}
                favoriteProfession={favoriteProfession}
                listProfessionOrigin={listProfessionOrigin}
                loadingProfession={loadingProfession}
                currentPage={currentPage}
                handleChangeFavoriteProfession={handleChangeFavoriteProfession}
                setCurrentPage={setCurrentPage}
                searchProfessionInput={searchProfessionInput}
                flagDropDownProfession={flagDropDownProfession}
                flagModalProfessionInfo={flagModalProfessionInfo}
                setFlagModalProfessionInfo={setFlagModalProfessionInfo}
                setFlagDropDownProfession={setFlagDropDownProfession}
                setSearchProfessionInput={setSearchProfessionInput}
                changeIntro={() => dispatch(setFlagSteps(true))}
                flagSteps={flagSteps}
            />
            <div className="main">
                <TrajectorySection
                    studyTraektoryRef={studyTraektoryRef}
                    getListApiCourses={getListApiCourses}
                    addTrajectorySkill={addTrajectorySkill}
                    removeTrajectorySkill={removeTrajectorySkill}
                    openChatGpt={() => setFlagModalGpt(true)}
                    flagSteps={flagSteps}
                    setFlagSteps={(value) => dispatch(setFlagSteps(value))}
                    currentStep={currentStep}
                />
                <VacancySection
                    vacancycData={vacancycData}
                    flagModalFavorite={flagModalFavorite}
                    setFlagModalFilter={setFlagModalFilter}
                    setFlagModalFavorite={setFlagModalFavorite}
                    favoriteVacancy={favoriteVacancy}
                    favoriteProfession={favoriteProfession}
                    heightTraektory={heightTraektory}
                    handleChangeFavoriteProfession={handleChangeFavoriteProfession}
                    handleAddSkillTraektory={addTrajectorySkill}
                    myAnimationHidden={myAnimationHidden}
                    myAnimationFlagMove={myAnimationFlagMove}
                    setCoordsTagAnimation={setCoordsTagAnimation}
                    studyTraektoryRef={studyTraektoryRef}
                    currentSkillAddTraektory={currentSkillAddTraektory}
                    flagLoadingVacancy={flagLoadingVacancy}
                    getListApiVacancy={getListApiVacancy}
                    handleChangeFavoriteVacancy={handleChangeFavoriteVacancy}
                    setVacancycData={setVacancycData}
                    setFlagModalProfessionInfo={setFlagModalProfessionInfo}
                    flagSteps={flagSteps}
                    currentStep={currentStep}
                />
            </div>
            <div
                className="mobile_block"
                ref={innerBlockRef}
                onScroll={(event) => handleScroll(event)}
            >
                <div className={flagOpenSearchMobile ? "top_filter hidden" : "top_filter"}>
                    <div className="icon">
                        <Logo
                            width={38}
                            height={38}
                            onClick={() => window.open("https://skillometer.ru/", 'blank')}
                        />
                    </div>
                    <div className="filters_mobile">
                        <div
                            className="filter"
                            onClick={() => {
                                if (activeProfession) {
                                    setFlagModalFilter(!flagModalFilter);
                                }
                            }}
                        >
                            <img width={62} height={34} src={filter_mobile}/>
                        </div>
                        <div className="favorite"
                             onClick={() => setFlagModalFavorite(!flagModalFavorite)}
                        >
                            <img width={62} height={34} src={favorite_mobile}/>
                        </div>
                        {!activeProfession &&
                            <div className="search"
                                 onClick={handleOpenSearchMobile}
                            >
                                <img width={62} height={34} src={search_filter}/>
                            </div>
                        }
                    </div>
                    {!activeProfession &&
                        <div className={flagOpenSearchMobile ? "search_mobile active" : "search_mobile"}>
                            <img src={search_mobile} style={{top: 2, left: 2}}/>
                            <Input
                                className={"input_classic input_search-mobile"}
                                placeholder={""}
                                value={searchProfessionInput}
                                onChange={(e) => {
                                    setSearchProfessionInput(e.target.value)
                                }}
                            />
                            <span onClick={() => {
                                setFlagOpenSearchMobile(false);
                                setSearchProfessionInput("");
                            }}>Отменить</span>
                        </div>
                    }
                    <div
                        className="settings"
                        onClick={() => {
                            setFlagModalSettingsMobile(true);
                            setFlagOpenSearchMobile(false);
                            setFlagHiddenFooter(false);
                        }}
                    >
                        <img src={settings_mobile}/>
                    </div>
                </div>
                <div
                    className={cn("show_traektory", {
                        "sticky": scrollY > 60
                    })
                    }
                >
                    <div className="btn" onClick={() => {
                        if (flagHiddenFotter || flagHiddenFotter === null) {
                            if (typeof setFlagModalTraektoryMobile === "function")
                                setFlagModalTraektoryMobile(true);
                        }
                    }}>
                        {variables_27}
                    </div>
                </div>
                <div className="middle_catalog">
                    {!activeProfession &&
                        <>
                            <div className="title_catalog">
                                <img width={40} height={24} src={nav_bar_profession_top}/>
                                <span>{variables_29}</span>
                            </div>
                            <div className="list_catalog">
                                <ul>
                                    {(displayListProfession && displayListProfession.length > 0) &&
                                        <>
                                            {displayListProfession.length &&
                                                displayListProfession.map((prof, index) =>
                                                    <Profession
                                                        key={index}
                                                        procentague={prof.percent && prof.percent}
                                                        professionTitle={prof.name}
                                                        click={(obj) => {
                                                            dispatch(setActiveProfession(obj))
                                                            dispatch(setActiveVacancy(false))
                                                        }}
                                                        activeId={activeProfession?.id}
                                                        flagModalInfo={flagModalProfessionInfo}
                                                        setFlagModalProfessionInfo={() => setFlagModalProfessionInfo(!flagModalProfessionInfo)}
                                                        favoriteProfession={favoriteProfession}
                                                        changeFavoriteProfession={handleChangeFavoriteProfession}
                                                        prof={{...prof}}
                                                        isProfessionMobile={true}
                                                    />
                                                )
                                            }
                                        </>
                                    }
                                    {listProfessionOrigin && listProfessionOrigin.length === 0 && !searchProfessionInput
                                        ? <Spin style={{
                                            position: "absolute",
                                            left: "50%",
                                            transform: "translate(-50%, 0)"
                                        }}/>
                                        : <></>
                                    }
                                </ul>
                                {listProfessionOrigin && listProfessionOrigin.length > 12 &&
                                    <div className="pagination_all">
                                        <>
                                            <Pagination
                                                itemRender={itemRender}
                                                defaultCurrent={1}
                                                defaultPageSize={12}
                                                total={listProfessionOrigin.length}
                                                onChange={(e) => setCurrentPage(e)}
                                                showTitle={false}
                                            />
                                        </>
                                    </div>
                                }
                            </div>
                        </>
                    }
                    {activeProfession &&
                        <>
                            <div
                                className="prev_button"
                                onClick={() => {
                                    dispatch(setActiveProfession(null))
                                }}
                            >
                                <Arrow/>
                                <span
                                    style={{
                                        fontSize: 14,
                                        color: "white"
                                    }}
                                >
                                    {variables_24}
                                </span>
                            </div>
                            {vacancycData.length > 0 &&
                                <div className="slider_section" ref={refSliderContainer}>
                                    <SliderVacancy
                                        sliderHeightVacancy={sliderHeightVacancy}
                                        activeProfession={activeProfession}
                                        activeVacancy={activeVacancy}
                                        vacancycData={vacancycData}
                                        handleChangeFavoriteVacancy={handleChangeFavoriteVacancy}
                                    />
                                </div>
                            }
                            {flagLoadingVacancy &&
                                <Spin className="spin"/>
                            }
                            {!activeVacancy &&
                                <div className="block-profession">
                                    <div className="h1">
                                        <span>{activeProfession && activeProfession.professionTitle}</span>
                                    </div>
                                    <div className="h2"><span>{variables_14}</span></div>
                                    <SkillBlockProfession
                                        userSkills={userSkills || []}
                                        professionSkillLeft={activeProfession.extra}
                                        addSkillTraektory={(skill) => handleAddSkillTraektory(skill, false)}
                                        myAnimationHidden={myAnimationHidden}
                                        myAnimationFlagMove={myAnimationFlagMove}
                                        needAnimation={false}
                                    />
                                </div>
                            }
                            {activeVacancy?.skills?.length > 0 &&
                                <div className="vacancy_info">
                                    <div className="title_vacancy">
                                        {activeVacancy.name}
                                    </div>
                                    <SkillBlockVacancy
                                        userSkills={userSkills || []}
                                        needSkillsThisVacansy={activeVacancy?.skills || []}
                                        addSkillTraektory={(skill) => handleAddSkillTraektory(skill, false)}
                                    />
                                </div>
                            }
                            {activeVacancy !== null && Object.values(activeVacancy)?.length > 0 && !activeVacancy.skills?.length &&
                                <div className="no_data">Нет данных о вакансии</div>
                            }
                        </>
                    }
                </div>
                <>
                    {flagModalTraektoryMobile &&
                        <ModalTrajectory
                            flagModalTraektoryMobile={flagModalTraektoryMobile}
                            setFlagModalTraektoryMobile={setFlagModalTraektoryMobile}
                            apiRemoveTrajectory={removeTrajectorySkill}
                            setFlagModalGpt={setFlagModalGpt}
                            getListApiCourses={getListApiCourses}
                            addTrajectorySkill={addTrajectorySkill}
                        />
                    }
                </>
                <>
                    {flagModalSettingsMobile &&
                        <Modal
                            visible={flagModalSettingsMobile}
                            footer={null}
                            className={"modal_settings-mobile"}
                            closable={false}
                            onCancel={() => {
                                setFlagHiddenFooter(null);
                                setFlagModalSettingsMobile(false);
                            }}
                            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                            bodyStyle={{padding: 15, backgroundColor: "#343843", borderRadius: 16}}
                        >
                            <div className="title">
                                <div className="group_left">
                                    <img src={settings_mobile_category}/>
                                    <span>
                                        {variables_30}
                                    </span>
                                </div>
                                <div className="close" onClick={() => {
                                    setFlagModalSettingsMobile(false);
                                    setFlagHiddenFooter(null);
                                }}
                                >
                                    <img src={close_modal_grey} alt=""/>
                                </div>
                            </div>
                            <div className="list">
                                <div
                                    className={activeListProfession === 0 ? "active" : ""}
                                    onClick={() => {
                                        handleCurrentProfession(0);
                                        setFlagModalSettingsMobile(false);
                                    }}
                                >
                                    {variables_3}
                                </div>
                                <div
                                    className={activeListProfession === 1 ? "active" : ""}
                                    onClick={() => {
                                        handleCurrentProfession(1);
                                        setFlagModalSettingsMobile(false);
                                    }}
                                >
                                    {variables_2}
                                </div>
                            </div>
                        </Modal>
                    }
                </>
            </div>
            {scrollYVacancy > 0 &&
                <ScrollUp refProps={innerBlockRef}/>
            }
            <>
                {flagModalProfessionInfo &&
                    <ModalProfessionInfo
                        flagModalInfo={flagModalProfessionInfo}
                        popularSkillInfo={activeProfession.extra}
                        objActiveProfession={activeProfession}
                        addTrajectory={addTrajectorySkill}
                        closeModalInfo={() => {
                            setFlagModalProfessionInfo(!flagModalProfessionInfo);
                        }}
                    />
                }
            </>
            <>
                {flagModalFavorite &&
                    <ModalFavorite
                        favoriteProfession={favoriteProfession}
                        favoriteVacancy={favoriteVacancy}
                        flagModalFavorite={flagModalFavorite}
                        myRegion={myRegion}
                        closeModal={() => {
                            setFlagModalFavorite(false);
                        }}
                        handleAddSkillTraektory={(skill, needTimeout) => addTrajectorySkill(skill, needTimeout)}
                        getApiVacancyFavorite={getApiVacancyFavorite}
                        getApiProfessionFavorite={getApiProfessionFavorite}
                        setActiveProfessionProps={(elem) => {
                            let newObj = {
                                id: String(elem.profession.id),
                                professionTitle: elem.profession.name,
                                extra: elem.profession.tags
                            };
                            dispatch(setActiveProfession(newObj))
                        }}
                    />
                }
            </>
            <>
                {flagModalFilter &&
                    <ModalFilter
                        flagModalFilter={flagModalFilter}
                        closeModal={() => setFlagModalFilter(false)}
                        setAllJobsVacancy={(vacancy) => setVacancycData(vacancy)}
                    />
                }
            </>
            <>
                {flagModalCourse &&
                    <ModalCourse
                        flagModalCourse={flagModalCourse}
                        closeModalCourse={() => {
                            setFlagModalCourse(false);
                        }}
                        skillsUserTraektory={trajectorySkills}
                        listCourse={listCourse}
                        handleRemoveSkillBasket={removeTrajectorySkill}
                    />
                }
            </>
            <>
                {flagModalGpt &&
                    <ChatGptModal
                        openModal={flagModalGpt}
                        closeModalCourse={() => {
                            setFlagModalGpt(false);
                        }}
                        skillsUserTraektory={trajectorySkills}
                        handleCloseModal={() => setFlagModalGpt(false)}
                    />
                }
            </>
            <>
                <SkillAnimation
                    myAnimationHidden={myAnimationHidden}
                    myAnimationFlagMove={myAnimationFlagMove}
                    currentSkillAddTraektory={currentSkillAddTraektory}
                    coords={coordsTagAnimation}
                />
            </>
        </div>
    );
};

export default SearchProfession;