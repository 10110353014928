import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import requests from "../../../axios/requests";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import { motion } from "framer-motion/dist/framer-motion";
import { Transition } from "react-transition-group";
import { defaultFuncAndData } from "../../../utils/defaultData";
import { Spin } from "antd";

import Video from "./Video";
import Article from "./Article";
import Quiz from "./Quiz";
import Code from "./Code";
import Task from "./Task";
import Scorm from "./Scorm";

import video_icon from "../../../../images/icons/video_icon.svg";
import article_icon from "../../../../images/icons/article_icon.svg";
import test_icon from "../../../../images/icons/test_icon.svg";
import home_work_icon from "../../../../images/icons/home_work.svg";
import code_icon from "../../../../images/icons/code_icon.svg";
import lms_icon from "../../../../images/icons/lms_icon.svg";
import { ReactComponent as Arrow } from "../../../../images/arrow.svg";
import { ReactComponent as ViewCourse } from "../../../../images/icons/view_course.svg";

const ViewLessonParent = (props) => {
    const variable_1 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_2"});

    const {
        stage, lessonNumber, closeLesson, propsSetEdit,
        activeProps, transitionClass, showComments
    } = props;

    const {language} = useSelector(state => state.Reducer);
    const [active, setActive] = useState(activeProps);
    const [stageItems, setStageItems] = useState([]);
    const [activeNavigation, setActiveNavigation] = useState(0);
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        requests.stages.get_stages_matherial(stage.id)
            .then((response) => {
                setStageItems(response.data);
            })
            .catch(err => console.error(err));
        requests.auth.get_profile_new(stage.id)
            .then((response) => {
                setProfile(response.data);
            })
            .catch(err => console.error(err));

    }, []);
    useEffect(() => {
        setActive(activeProps);
    }, [activeProps]);
    return (
        <div className={`view_lesson ${transitionClass}`}>
            <div
                className="name_lesson"
                onClick={closeLesson}
            >
                <span>{lessonNumber}. {stage?.name}</span>
                <div className="eye">
                    <ViewCourse/>
                </div>
            </div>
            <div className="group_inner">
                {((stageItems && stageItems.length > 0) || (stage?.generation_in_progress)) &&
                    <div className={"stage_block"}>
                        {stageItems.map((elem, indx) => {
                                let getIconType = (elem) => {
                                    switch (elem.theory_type) {
                                        case "A":
                                            return article_icon;
                                        case "V":
                                            return video_icon;
                                        case "Q":
                                            return test_icon;
                                        case "LMS":
                                            return lms_icon;
                                        default:
                                            if (elem.task_type === "text")
                                                return home_work_icon;
                                            else
                                                return code_icon;
                                    }
                                };
                                if (window.innerWidth < 820) {
                                    return (
                                        <div style={{display: "flex"}}>
                                            <div
                                                className={active === indx ? "stage active" : "stage"}
                                                key={indx}
                                                style={active !== indx && active !== null
                                                    ? {opacity: 0, zIndex: -1, display: "none", transform: "scale(0)",}
                                                    : {}
                                                }
                                                onClick={() => {
                                                    if (active === indx) {
                                                        setActive(null);
                                                    } else {
                                                        setActive(indx);
                                                    }

                                                }}
                                            >
                                                <img src={getIconType(elem)}/>
                                                <div className="info">{elem.title}</div>
                                            </div>
                                            <div
                                                className={active === indx ? "arrow_block active" : "arrow_block"}
                                                style={active !== indx && active !== null
                                                    ? {opacity: 0, zIndex: -1, display: "none", transform: "scale(0)",}
                                                    : {}
                                                }
                                                onClick={() => {
                                                    if (active === indx) {
                                                        setActive(null);
                                                    } else {
                                                        setActive(indx);
                                                    }
                                                }}
                                            >
                                                <Arrow/>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <>
                                            <motion.div
                                                className={active === indx
                                                    ? "stage active"
                                                    : "stage"
                                                }
                                                onClick={() => setActive(indx)}
                                                variants={defaultFuncAndData.listAnimationDelay()}
                                                initial="hidden"
                                                animate="visible"
                                                custom={(indx) * 0.2}
                                                key={indx}
                                            >
                                                <img src={getIconType(elem)}/>
                                                <div className="info">{elem.title}</div>
                                            </motion.div>
                                        </>
                                    )
                                }
                            }
                        )}
                        {stage?.generation_in_progress &&
                            <div className="loading_matherial">
                                <div className="text">
                                    {language === "ru"
                                        ? <> Идёт загрузка материала.<br/>
                                            Можете обновить страницу...
                                        </>
                                        : <>
                                            Loading content.<br/>
                                            You can refresh the page...
                                        </>
                                    }
                                </div>
                                <Spin/>
                            </div>
                        }
                    </div>
                }
                {stageItems.length < 1 &&
                    <>
                        <div className="group">
                            <div className={"active"}>{variable_1}</div>
                            <div onClick={() => propsSetEdit()}>{variable_2}</div>
                        </div>
                    </>
                }
                <Transition
                    in={active !== null && stageItems && stageItems.length > 0}
                    timeout={400}
                    unmountOnExit
                    mountOnEnter
                >
                    {(state) => (
                        <div className={`content_parent ${state}`}>
                            <div className="title">
                                <span>{stageItems[active] && stageItems[active].title}</span>
                                <div className="group">
                                    <div
                                        className={activeNavigation === 0 ? "active" : ""}
                                        onClick={() => setActiveNavigation(0)}
                                    >
                                        {variable_1}
                                    </div>
                                    <div
                                        className={activeNavigation === 1 ? "active" : ""}
                                        onClick={() => {
                                            propsSetEdit(active);
                                        }}
                                    >
                                        {variable_2}
                                    </div>
                                </div>
                            </div>
                            {active !== null &&
                                <>
                                    {stageItems[active].theory_type === "V" &&
                                        <Video
                                            videoProps={stageItems[active]}
                                            profile={profile}
                                            show_comments={showComments}
                                        />
                                    }
                                    {stageItems[active].theory_type === "A" &&
                                        <Article
                                            {...stageItems[active]}
                                            profile={profile}
                                            show_comments={showComments}
                                        />
                                    }
                                    {stageItems[active].theory_type === "Q" &&
                                        <Quiz
                                            {...stageItems[active]}
                                            questions={stageItems[active].questions}
                                            profile={profile}
                                            show_comments={showComments}
                                        />
                                    }
                                    {stageItems[active].theory_type === "T" &&
                                        <>
                                            {stageItems[active].task_type === "code"
                                                ? <Code
                                                    taskProps={stageItems[active]}
                                                    profile={profile}
                                                    show_comments={showComments}

                                                />
                                                : <Task
                                                    taskProps={stageItems[active]}
                                                    profile={profile}
                                                    show_comments={showComments}
                                                />
                                            }
                                        </>
                                    }
                                    {stageItems[active].theory_type === "LMS" &&
                                        <Scorm
                                            {...stageItems[active]}
                                            profile={profile}
                                            show_comments={showComments}
                                        />
                                    }
                                </>
                            }
                        </div>
                    )}
                </Transition>
            </div>
        </div>
    );
};

export default ViewLessonParent;