import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate,  useParams } from "react-router-dom";
import moment from "moment";
import requests from "../../axios/requests";
import { defaultFuncAndData } from "../../utils/defaultData";

import { DemoRow } from "../GroupsList/GroupsList";
import PrevBtn from "../../components/reuseComponents/PrevBtn";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

import { ReactComponent as Accept } from "../../../images/icons/result_accept.svg";
import { ReactComponent as Deny } from "../../../images/icons/result_false.svg";
import fake_photos from "../../../images/icons/fake_photos.png";

const CheckTest = () => {
    const variable_1 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_11"});

    const params = useParams();
    const history = useNavigate();
    const {language} = useSelector(state => state.Reducer);
    const [quizData, setQuizData] = useState({});

    useEffect(() => {
        requests.quiz.get_quiz_result_id(params)
            .then((res) => {
                setQuizData(res.data);
            })
            .catch(err => console.error(err));
    }, []);
    return (
        <div className={"check_test"} >
            <div
                className="group_demo-block"
                style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 10}}
            >
                <PrevBtn click={() => history(-1)}/>
                <DemoRow/>
            </div>
            <div className="top_info">
                <div className="image_block">
                    <img src={quizData.user && quizData.user.profile.avatar || fake_photos}/>
                    <div className="fio">{quizData.user && quizData.user.profile.name || ""}</div>
                </div>
                <div className={"info_row"}>
                    <span>{variable_11} </span>
                    <span>{quizData.attempts ? variable_1 : variable_2}</span>
                </div>
                <div className={"info_row"}>
                    <span>{variable_3} </span>
                    <span>{quizData.stage && quizData.stage.quest.name || ""}</span>
                </div>
                <div className={"info_row"}>
                    <span>{variable_4}: </span>
                    <span> {quizData.stage && quizData.stage.name}</span>
                </div>
                <div className={"info_row"}>
                    <span>{variable_5} </span>
                    <span> {quizData.title && quizData.title || ""}</span>
                </div>
                {quizData.attempts &&
                    <div className={"info_row"}>
                        <span>{variable_6}  </span>
                        <span> {quizData.results && quizData.results.length > 0 ? quizData.results.length : 0} </span>
                        <span style={{color: "#757A88", marginLeft: 3}}> / </span>
                        <span style={{color: "#757A88", marginLeft: 3}}> {quizData.attempts} </span>
                    </div>
                }

            </div>
            <div className="list_history-quiz">
                {quizData?.results?.length > 0 &&
                    quizData.results.map((elem, indx) =>
                        <div className="history_quiz control_quiz" key={indx}>
                            <div className="top_block">
                                <div className="group_left">
                                    {elem.correct_answers_count !== elem.questions_count
                                        ? <Deny/>
                                        : <Accept/>
                                    }
                                    <div className={"group_block"}>
                                        <span>
                                            Попытка №{elem.attempt_number && elem.attempt_number}
                                        </span>
                                        <div style={{height: 22, margin: "10px 5px 0 5px"}}>
                                            <span style={{color: "#757A88"}}>
                                                ({elem.correct_answers_count || 0}
                                            </span>
                                            <span style={{color: "#757A88", marginLeft: 3}}>
                                                /
                                            </span>
                                            <span style={{color: "#757A88", marginLeft: 3}}>
                                                {elem.questions_count && elem.questions_count || ""})
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"date_current"}>
                                    <span style={{color: "#757A88"}}>
                                        {variable_7}
                                    </span>
                                    <span>
                                        {elem.created_at && moment(elem.created_at).format("DD.MM.YY / HH:mm:ss") || ""}
                                    </span>
                                </div>
                            </div>
                            <ul className="result_list">
                                {elem.questions && elem.questions.length > 0 &&
                                    elem.questions.map((question, ind) =>
                                        <li key={ind}>
                                            <div style={{display: "flex", marginBottom: 10}}>
                                                <div className="indx">{ind + 1}.</div>
                                                <span>{variable_8} {question.title}</span>
                                            </div>
                                            <div className="answer">
                                                 <span style={{color: "white"}}
                                                 >
                                                     {
                                                         language === "ru"
                                                             ? defaultFuncAndData.declension(Object.keys(question.answers).length, ["Ответ", "Ответы", "Ответы"])
                                                             : Object.keys(question.answers).length > 1
                                                                 ? "answers"
                                                                 : "answer"
                                                     }
                                                     {" "}
                                                     {variable_9} <br/>
                                                     {Object.keys(question.answers).map((elem, i) => {
                                                             return (
                                                                 <span
                                                                     key={i}
                                                                     style={question.answers[elem].correct ? {color: "#30E0A1"} : {color: "#FF3434"}}
                                                                 >
                                                                    {variable_10}{i + 1}.
                                                                    <span style={{color: "white"}}>
                                                                        {" "} {question.answers[elem].text}
                                                                    </span>
                                                                    <br/>
                                                                </span>
                                                             );
                                                         }
                                                     )}
                                                 </span>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default CheckTest;