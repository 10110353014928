import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import requests from "../../axios/requests";
import { Button, Form, Input, message, notification, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import ReCAPTCHA from "react-google-recaptcha";

const { Link: AntdLink } = Typography;

const ReuseInput = (props) => {
    let { value, onChange, title, required = false, onBlur = null, placeholder = "" } = props;

    return (
        <div className="reuse_input-parent ">
            {title &&
                <div className="title">{title} {required && <span style={{ color: "#FF3434" }}>*</span>}</div>
            }
            <Input
                className={"input_classic"}
                value={value}
                onChange={(e) => onChange(e)}
                placeholder={placeholder}
                onBlur={onBlur}
            />
        </div>
    );
};
const ReuseCheckbox = (props) => {
    let { value, title, change, indx } = props;

    return (
        <div className="checkbox_group">
            <span>{title}</span>
            <div className="form-group">
                <input
                    type="checkbox"
                    id={"business" + indx}
                    checked={value}
                    onChange={(e) => change(e.target.checked)}
                />
                <label
                    htmlFor={"business" + indx}
                />
            </div>
        </div>
    );
};
const Register = () => {
    const history = useNavigate();

    const variable_4 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_4" });
    const variable_5 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_5" });
    const variable_6 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_6" });
    const variable_18 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_18" });
    const variable_19 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_19" });
    const variable_20 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_20" });
    const variable_21 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_21" });
    const variable_22 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_22" });
    const variable_23 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_23" });
    const variable_24 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_24" });
    const variable_25 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_25" });
    const variable_26 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_26" });
    const variable_27 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_27" });
    const variable_28 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_28" });
    const variable_29 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_29" });
    const variable_30 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_30" });
    const variable_31 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_31" });
    const variable_32 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_32" });
    const variable_33 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_33" });
    const variable_34 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_34" });
    const variable_35 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_35" });
    const variable_36 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_36" });
    const variable_37 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_37" });
    const variable_38 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_38" });
    const variable_39 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_39" });
    const variable_40 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_40" });
    const variable_41 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_41" });
    const variable_42 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_42" });
    const variable_43 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_43" });
    const variable_44 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_44" });
    const variable_45 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_45" });
    const variable_46 = variableLanguage({ keyPage: "authorization", keyVariable: "variable_46" });

    const [activeNavigate, setActiveNavigate] = useState(0);
    const [organizationValues, setOrganizationValues] = useState({
        short_name: "",
        contacts: "",
        payment_partner: false
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [email, setEmail] = useState("");

    const [form] = Form.useForm();
    const refEmail = useRef();
    const refPassword = useRef();
    const refPasswordAgain = useRef();
    const recaptchaRef = useRef();

    const openNotificationWithIcon = type => {
        notification[type]({
            message: variable_20,
            duration: 15,
            description: variable_21
        });
    };
    const openNotificationUSER = type => {
        notification[type]({
            message: variable_22,
            duration: 5,
            description: variable_23
        });
    };
    const onFinish = async (values) => {
        const token = await recaptchaRef.current.executeAsync();
        if (token) {
            requests.auth
                .register({ username: values.username, password: values.password })
                .then((response) => {
                    recaptchaRef.current.reset();
                    
                    if (response.status === 203) {
                        openNotificationUSER("warning");
                    }
                    if (!response.data.error) {
                        localStorage.setItem("name", response.data.name);
                        localStorage.setItem("user_id", response.data.user_id);
                        localStorage.setItem("token", response.data.token);
                        openNotificationWithIcon("warning");
                        setActiveNavigate(1);
                        requests.auth.get_organization_info()
                            .then((v) => {
                                if (v.status === 200) {
                                    setOrganizationValues({
                                        short_name: v.data.short_name,
                                        contacts: v.data.contacts,
                                        payment_partner: v.data.payment_partner

                                    });
                                }
                            })
                            .catch(err => console.error(err));
                        /*history(`/backoffice/organization`);*/
                    } else if (response.data.error[0] === "Данный пользователь уже существует") {
                        setErrorMessage(variable_24);
                    }
                })
                .catch((e) => console.log(e));
        }
    };
    const onEmailChange = (e) => {
        form.setFieldsValue({ username: e.target.value.trim() });
    };
    let handleSendOrganication = () => {
        if (!organizationValues.short_name) return message.error(variable_25);
        if (!organizationValues.contacts) return message.error(variable_26);
        requests.auth
            .put_organization_info(organizationValues)
            .then((response) => {
                message.success(variable_27);
                history("/backoffice/my-course");
            })
            .catch((e) => console.log(e));
    };

    if (localStorage.getItem("user_id") && activeNavigate !== 1) {
        history("/backoffice");
    }
    return (
        <div className={"parent_register"}>
            {activeNavigate === 0 &&
                <div className={"register"}>
                    <h2>{variable_28}</h2>
                    {/* <div className={"title"}>{variable_29}</div>
                    <input
                        ref={refEmail}
                        onClick={() => {
                            refEmail.current.focus();
                            console.log('click')
                        }}
                        onFocus={() => {
                            refEmail.current.focus();
                            console.log('focus')
                        }}
                        style={{marginBottom: 15}}
                        className={"input_custom"}
                        onChange={(e) => setEmail(e.target.value)}
                    />*/}
                    <Form name="basic" layout="vertical" size="large" onFinish={onFinish} form={form}>
                        <div className={"title"}>{variable_29}</div>
                        <Form.Item
                            name="username"
                            hasFeedback
                            extra={errorMessage}
                            rules={[{ required: true, message: variable_4, type: "email" }]}
                            onClick={() => refEmail.current.focus()}
                        >
                            <Input ref={refEmail} className={"input_custom"} onChange={onEmailChange} />
                        </Form.Item>

                        <div className={"title"}>{variable_5}</div>
                        <Form.Item
                            name="password"
                            hasFeedback
                            rules={[
                                { required: true, message: variable_6 },
                                { min: 6, message: variable_30 }]}
                            onClick={() => refPassword.current.focus()}
                        >
                            <Input ref={refPassword} type={"password"} className={"input_custom"} />
                        </Form.Item>

                        <div className={"title"}>{variable_31}</div>
                        <Form.Item
                            hasFeedback
                            name="password2"
                            dependencies={["password"]}
                            rules={[
                                { required: true, message: variable_32 },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(variable_18));
                                    }
                                })
                            ]}
                            onClick={() => refPasswordAgain.current.focus()}
                        >
                            <Input ref={refPasswordAgain} type={"password"} className={"input_custom"} />
                        </Form.Item>

                        <Form.Item
                            name="remember"
                            valuePropName="checked"
                            rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error("Обязательно к подтверждению")) }]}
                        >
                            <div className="checkbox_group">
                                <div className="form-group">
                                    <input
                                        type="checkbox"
                                        id={"remember"}
                                    />
                                    <label
                                        htmlFor={"remember"}
                                    />
                                    <span style={{ color: "#A2AACA" }}>{variable_33} </span>
                                    <span>
                                        <AntdLink className={"a"} href="https://skillometer.ru/legal"
                                            target="_blank">{variable_34}</AntdLink>
                                    </span>
                                    <span> {variable_35} </span>
                                    <span><AntdLink className={"a"} href="https://skillometer.ru/offer"
                                        target="_blank">{variable_36}</AntdLink>
                                    </span>
                                </div>
                            </div>
                        </Form.Item>

                        <Form.Item>
                            <Button className={"btn_send"} htmlType="submit">{variable_37}</Button>
                            <br />
                            <br />
                            <span
                                style={{
                                    marginLeft: "10px",
                                    color: "#A2AACA"
                                }}
                            >
                                {variable_38} {" "}
                                <Link
                                    to={{
                                        pathname: `/backoffice/login/`
                                    }}
                                    className={"a"}
                                >
                                    {variable_39}
                                </Link>
                            </span>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={"6Lfr084mAAAAAMlGHEpV7ti8tqn70lulrj04v4Mt"}
                                className={"captcha"}
                                size="invisible"
                            />
                        </Form.Item>
                    </Form>
                </div>
            }
            {activeNavigate === 1 &&
                <div className="organization_block">
                    <div className="h2">{variable_40}</div>
                    <div className="form_block">
                        <ReuseInput
                            value={organizationValues.short_name}
                            title={variable_41}
                            onChange={(e) => setOrganizationValues((prev) => ({
                                ...prev,
                                ["short_name"]: e.target.value
                            }))}
                            placeholder={variable_42}
                            required={true}
                        />
                        <div className="info_short">{variable_43}</div>
                        <div className="title_about">{variable_44} <span
                            style={{ color: "#FF3434" }}>*</span>
                        </div>
                        <TextArea
                            className={"textarea_antd"}
                            bordered={false}
                            value={organizationValues.contacts}
                            onChange={(e) => {
                                setOrganizationValues((prev) => ({ ...prev, ["contacts"]: e.target.value }));
                            }}
                            placeholder={variable_42}
                        />
                        <div className="info_short">{variable_45}</div>
                        {/* <ReuseCheckbox
                            value={organizationValues.payment_partner}
                            title={variable_46}
                            change={(e) => setOrganizationValues((prev) => ({...prev, ["payment_partner"]: e}))}
                            indx={1}
                        />*/}
                        <div
                            onClick={handleSendOrganication}
                            className="btn_send button_classic"
                        >
                            {variable_19}
                        </div>

                    </div>
                </div>
            }
        </div>
    );
};
export default Register;
