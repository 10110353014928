import React, { useEffect, useState } from 'react';
import requests from "../../../axios/requests";
import { Input, message } from "antd";
import CommentCustom from "../../../components/reuseComponents/CommentCustom";
import send_comment from "../../../../images/icons/send_comment.png";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

const Scorm = (props) => {
    const {text, image_file, url_refs, lms_package, id, task_file_name, task_file} = props;

    const variable_5 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_6"});

    const [valueComment, setValueComment] = useState("");
    const [comments, setComments] = useState("");
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState("");

    let getApiComments = () => {
        requests.comments.get_comments({
            contentType: 46,
            objectId: id
        })
            .then((res) => {
                setComments(res.data);
            })
            .catch((error) => console.error(error));
    };
    let sendComment = () => {
        if (!valueComment) {
            return;
        }
        let obj = {
            phone: props.profile.phone,
            user: {
                profile: {
                    avatar: props.profile.avatar || ""
                },
            },
            content_type: 46,
            object_id: id,
            text: valueComment
        };
        requests.comments.create_comment(obj)
            .then((res) => {
                setComments((prev) => [...prev, res.data]);
            })
            .catch((error) => console.error(error));
        setValueComment("");
    };
    let handleSetLikeDislake = (id, value) => {
        let obj = {
            "content_type": 86,
            "object_id": id,
            "value": value
        };
        requests.comments.like_dislike(obj)
            .then((res) => {
                getApiComments();
            })
            .catch((error) => console.error(error))
            .finally();
    };
    let handleRemoveComment = (id) => {
        requests.comments.remove_comment(id)
            .then(() => {
                getApiComments();
                message.success(variable_7);
            })
            .catch(err => console.error(err));
    };

    useEffect(() => {
        getApiComments();
    }, [])
    return (
        <div className="scorm_content">
            {lms_package !== null &&
                <iframe
                    src={process.env.REACT_APP_API_URL + `/v2/web/student/stage/stage_theory/${props?.id}/lms/main`}
                    width={"100%"}
                />
            }
            {props?.show_comments &&
                <div style={{width: "100%", marginTop: 15}}>
                    {comments && comments.length > 0 &&
                        <div className="comment_title">
                            {variable_5}
                        </div>
                    }
                    {comments && comments.length > 0 &&
                        comments.map((com, indx) =>
                            <div key={indx}>
                                <CommentCustom
                                    {...com}
                                    setLike={(id) => handleSetLikeDislake(id, true)}
                                    setDislake={(id) => handleSetLikeDislake(id, false)}
                                    removeComment={handleRemoveComment}
                                />
                            </div>
                        )
                    }
                    <div className="comment_input">
                        <Input
                            placeholder={variable_6}
                            className={"input_classic"}
                            value={valueComment}
                            onChange={(e) => setValueComment(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) sendComment();
                            }}
                        />
                        <img src={send_comment} onClick={sendComment}/>
                    </div>
                </div>
            }
        </div>
    );
};

export default Scorm;