import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import requests from "../../axios/requests";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { Modal } from "antd";
import Trial from "../../components/reuseComponents/Trial";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

import close from "../../../images/icons/close_x.svg";
import module_1 from '../../../images/modules/module_1.svg';
import module_2 from '../../../images/modules/module_2.svg';
import module_3 from '../../../images/modules/module_3.svg';
import module_4 from '../../../images/modules/module_4.svg';
import module_5 from '../../../images/modules/module_5.svg';
import module_6 from '../../../images/modules/module_6.svg';
import module_7 from '../../../images/modules/module_7.svg';
import module_8 from '../../../images/modules/module_8.svg';

const Modules = () => {
    const variable_1 = variableLanguage({keyPage: "module", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "module", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "module", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "module", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "module", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "module", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "module", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "module", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "module", keyVariable: "variable_9"});

    const { trial_status} = useSelector(state => state.Reducer);
    const history = useNavigate();

    const [moduleList, setDoduleList] = useState([]);
    const [openModule, setOpenModule] = useState({});
    const [flagOpenModal, setFlagOpenModal] = useState(false);

    let switchModuleIndx = (indx) => {
        switch (indx) {
            case 0:
                return module_1;
            case 1:
                return module_2;
            case 2:
                return module_3;
            case 3:
                return module_4;
            case 4:
                return module_5;
            case 5:
                return module_6;
            case 6:
                return module_7;
            case 7:
                return module_8;
            default:
                return module_1;
        }
    };
    let switchModuleType = (type) => {
        switch (type) {
            case "base":
                return module_1;
            case "corp_quests":
                return module_2;
            case "code_task":
                return module_3;
            case "planning_tags":
                return module_4;
            default:
                return module_1
        }
    };
    let createLinkPayModule = (id) => {
        requests.organization.create_link_payment_module(id)
            .then(res => {
                if (res.data?.payment_url)
                    window.location.href = res.data.payment_url;
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        requests.organization.get_modules_list()
            .then((res) => setDoduleList(res.data))
            .catch(err => console.error(err))
    }, [])
    return (
        <div className="modules">
            <div className="inner_block">
                {(trial_status === 0 || trial_status === 1) && <Trial/>}
                <div className="title">{variable_8}</div>
                <ul className="module_list">
                    {moduleList.length > 0 && moduleList.map((elem, indx) =>
                        <li
                            key={indx}
                        >
                            <div className="module_image"><img src={switchModuleIndx(indx)}/></div>
                            <div className="module_name">{elem.name}</div>
                            <div className="module_info">{elem.short_description}
                            </div>
                            <div className="module_cost">
                                <div>{variable_2}</div>
                                <div>{elem.price || 0}{variable_7}</div>
                            </div>
                            <div
                                className="btn"
                                onClick={() => {
                                    setFlagOpenModal(true);
                                    setOpenModule(elem);
                                }}
                            >
                                {variable_1}
                            </div>
                        </li>
                    )}
                </ul>
            </div>
            <>
                <Modal
                    visible={flagOpenModal}
                    footer={null}
                    className={"modal_module"}
                    closable={false}
                    onCancel={() => {
                        setFlagOpenModal(false);
                        setOpenModule({});
                    }}
                    maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                >
                    <div
                        className="close_modal"
                        onClick={() => setFlagOpenModal(false)}
                    >
                        <img src={close}/>
                    </div>
                    <div className="module_icon">
                        <img src={switchModuleType(openModule?.type)}/>
                    </div>
                    <div className="h2">
                        {openModule?.name}
                    </div>
                    {openModule?.image &&
                        <div className="module_image">
                            <img src={openModule?.image}/>
                        </div>
                    }
                    <div className="h3">
                        {variable_9}
                    </div>
                    <pre className="detail">
                            {openModule?.full_description}
                        </pre>
                    {openModule?.current_subscriptions?.length > 0
                        ? <>
                            <div className="duration">
                                    <span>
                                        {variable_3}
                                    </span>
                                <span>
                                        {moment(openModule?.current_subscriptions[0]?.finished_at).format("DD.MM.YY")}
                                    </span>
                            </div>
                            <div className="groups_btn-provide">
                                <div
                                    className="btn_provide"
                                    onClick={() => history('/backoffice/current-course/module')}
                                >
                                    {variable_4}
                                </div>
                                <div
                                    className="btn_provide pay_subscription"
                                    onClick={() => createLinkPayModule(openModule?.id)}
                                >
                                    {variable_5}
                                </div>
                            </div>
                        </>
                        : <div
                            className="btn_provide"
                            onClick={() => createLinkPayModule(openModule?.id)}
                        >
                            {variable_6}
                        </div>
                    }
                </Modal>
            </>
        </div>
    );
};

export default Modules;