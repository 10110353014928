import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import requests from "../../axios/requests";
import { defaultFuncAndData } from "../../utils/defaultData";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

import { message, Progress } from "antd";
import ReuseDropDown from "../../components/reuseComponents/ReuseDropDown";
import InputWsButton from "../../components/reuseComponents/InputButton";
import { DemoRow } from "../GroupsList/GroupsList";
import PrevBtn from "../../components/reuseComponents/PrevBtn";
import NavigationMobile from "./NavigationMobile/NavigationMobile";
import ProgressStudents from "./Tabs/ProgressStudents";
import TestWork from "./Tabs/TestWork";
import HomeWork from "./Tabs/HomeWork";
import Journal from "./Tabs/Journal";
import Button from "../../components/Button/Button";

import { ReactComponent as Studys } from "../../../images/icons/studys_.svg";
import { ReactComponent as People } from "../../../images/icons/people_.svg";
import { ReactComponent as ProgressStudys } from "../../../images/icons/progress_studys.svg";
import { ReactComponent as HomeWorkIcon } from "../../../images/icons/home_work-mobile.svg";
import { ReactComponent as TestIcon } from "../../../images/icons/test_icon-mobile.svg";
import { ReactComponent as JournalIcon } from "../../../images/icons/journal_icon.svg";
import './InfoStudents.scss';

const InfoStudent = () => {
    const params = useParams();
    const history = useNavigate();
    const {pathname} = useLocation();
    const {language, currentQuest, currentGroup} = useSelector((state) => state.Reducer);

    const variable_6 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_9"});
    const variable_23 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_23"});
    const variable_24 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_24"});
    const variable_14 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_14"});
    const variable_15 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_17"});
    const variable_18 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_18"});
    const variable_20 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_20"});
    const variable_50 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_50"});

    const [activeStageQuiz, setActiveStageQuiz] = useState(0);
    const [stagesQuiz, setStagesQuiz] = useState([]);
    const [tasksQuiz, setTasksQuiz] = useState([]);
    const [quizJournal, setQuizJournal] = useState([]);
    const [activeTaskTest, setActiveTaskTest] = useState(0);
    const [quizResult, setQuizResult] = useState([]);
    const [stagesQuizkNames, setStagesQuizNames] = useState([]);
    const [activeDropQuiz, setActiveDropQuiz] = useState(0);
    const [originalStagesQuiz, setOriginalStagesQuiz] = useState([]);
    const [activeStageHomework, setActiveStageHomework] = useState(0);
    const [stagesHomeWork, setStagesHomeWork] = useState([]);
    const [tasksHomeWork, setTasksHomeWork] = useState([]);
    const [homeworkJournal, setHomeworkJournal] = useState([]);
    const [activeTaskHomework, setActiveTaskHomework] = useState(0);
    const [homeworkResult, setHomeworkResult] = useState([]);
    const [stagesHomeWorkNames, setStagesHomeWorkNames] = useState([]);
    const [activeDropHomework, setActiveDropHomework] = useState(0);
    const [originalStagesHomework, setOriginalStagesHomework] = useState([]);
    const [activeDropJournal, setActiveDropJournal] = useState(1);
    const [displayJournal, setDisplayJournal] = useState([]);
    const [activeNavigation, setActiveNavigation] = useState(0);
    const [searchStudyJournal, setSearchStudyJournal] = useState("");
    const [statisticData, setStatisticData] = useState("");
    const [allProgress, setAllProgress] = useState([]);
    const [ordering, setOrdering] = useState(0);
    const [searchStudyProgress, setSearchStudyProgress] = useState("");
    const [flags, setFlags] = useState({
        flagProgressDropDown: false,
        flagOpenSearchMobile: false,
        flagOpenSettings: false,

        flagOpenJournal: false,
        flagDropQuiz: false,
        flagDropHomework: false,
        flagTabooCopyQuiz: false,
        flagTabooCopyHomework: false,
        flagHiddenFotter: null,
    });
    const titlesArr = [variable_6, variable_9, variable_8, variable_7];

    let getApiProgressStudent = (group_id, ordering = "", words = "") => {
        requests.quests
            .get_progress_by_quest_new(params.id, ordering, words)
            .then((response) => {
                setAllProgress(response.data);
            })
            .catch((e) => {
                console.log(e.message);
            });
    };
    let parseDate = (num) => {
        return moment(num).format("DD.MM.YY / HH:mm");
    };
    let funcFilterStages = (activeDropIndx, originalStages, stagesNames, setStages, setActiveStage, setResult) => {
        setActiveStage(null);
        setResult([]);

        if (activeDropIndx === 0) {
            setStages(originalStages);
        } else {
            let currentDropName = stagesNames[activeDropIndx] || "";
            if (currentDropName) {
                let newArr = [...originalStages];
                newArr = newArr.filter(elem => elem.name === currentDropName);
                setStages(newArr);
            }
        }
    };
    let getApiTest = () => {
        requests.quiz.get_quiz_result("", "", "", currentGroup.id || params.id)
            .then((res) => {
                let arrTasks = [];
                let arrStages = [];
                let arrNames = [localStorage.getItem("language") === "en" ? "All classes" : "Все занятия"];

                for (let i = 0; i < res.data.length; i++) {
                    arrTasks = [...arrTasks, res.data[i].quiz];
                    arrStages = [...arrStages, res.data[i].quiz.stage];
                    arrNames = [...arrNames, res.data[i].quiz.stage.name];
                }

                // оставить только уникальные stage и task
                let tmpArray = [];

                function itemCheck(id) {
                    if (tmpArray.indexOf(id) === -1) {
                        tmpArray.push(id);
                        return true;
                    }
                    return false;
                }

                setStagesQuizNames(Array.from(new Set(arrNames)));
                setStagesQuiz(arrStages.filter((item) => item.id && itemCheck(item.id)).sort((a, b) => a.sortOrder - b.sortOrder));
                setTasksQuiz(arrTasks.filter((item) => item.id && itemCheck(item.id)).sort((a, b) => a.sortOrder - b.sortOrder));
            });
    };
    let getApiHomeWork = (group_id = params.id, words = "", status = false, task = "") => {
        requests.quests.homework_checked_list_new(group_id, words, status, task)
            .then((v) => {

                let arrStages = [];
                let arrTasks = [];
                let arrNames = [localStorage.getItem("language") === "en" ? "All classes" : "Все занятия"];

                for (let i = 0; i < v.data.length; i++) {
                    arrTasks = [...arrTasks, v.data[i].task];
                    arrStages = [...arrStages, v.data[i].task.stage];
                    arrNames = [...arrNames, v.data[i].task.stage.name];
                }

                // оставить только уникальные stage и task
                let tmpArray = [];

                function itemCheck(id) {
                    if (tmpArray.indexOf(id) === -1) {
                        tmpArray.push(id);
                        return true;
                    }
                    return false;
                }

                setStagesHomeWorkNames(Array.from(new Set(arrNames)));
                setStagesHomeWork(arrStages.filter((item) => item.id && itemCheck(item.id)).sort((a, b) => a.sortOrder - b.sortOrder));
                setTasksHomeWork(arrTasks.filter((item) => item.id && itemCheck(item.id)).sort((a, b) => a.sortOrder - b.sortOrder));
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };
    let getApiHomeWorkId = (words = "", status = null, task = "") => {
        requests.quests.homework_checked_list_new(currentGroup.id || params.id, words, status, task)
            .then((v) => {
                setHomeworkResult(v.data);
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };
    let getApiHomeWorkJournal = (words = "", status = null) => {
        requests.quests.homework_checked_list_new(currentGroup.id || params.id, words, status)
            .then((v) => {
                setHomeworkJournal(v.data);
                setDisplayJournal(v.data);
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };
    let getApiQuizJournal = () => {
        requests.quiz.get_quiz_result(false, false, false, currentGroup.id || params.id)
            .then((res) => {
                setQuizJournal(res.data);
            });
    };
    let searchConcat = async () => {
        if (activeDropJournal === 0) {
            let homeworkJournal = [];
            let testJournal = [];
            await requests.quests.homework_checked_list_new(currentGroup.id || params.id, searchStudyJournal)
                .then((res) => {
                    homeworkJournal = res.data;
                })
                .catch((error) => {
                    console.log(error, "error");
                });
            await requests.quiz.get_quiz_result(false, searchStudyJournal, false, currentGroup.id || params.id)
                .then((res) => {
                    testJournal = res.data;
                });
            setDisplayJournal([...testJournal, ...homeworkJournal]);
        }
        if (activeDropJournal === 1) {
            requests.quests.homework_checked_list_new(currentGroup.id || params.id, searchStudyJournal)
                .then((v) => {
                    setDisplayJournal(v.data);
                })
                .catch((error) => {
                    console.log(error, "error");
                });
        }
        if (activeDropJournal === 2) {
            requests.quiz.get_quiz_result(false, searchStudyJournal, false, currentGroup.id || params.id)
                .then((res) => {
                    setDisplayJournal(res.data);
                });
        }
    };
    let newPathname = (newTab) => {
        let newPathname = pathname.substring(0, pathname.length - 1) + newTab
        history(newPathname)
    }
    let downloadStudent = () => {
        requests.quests.studentsFile(params?.id)
            .then(({data}) => {
             /*   let newUrl = window.location.origin.includes('.ru')
                    ? 'https://skillometer.ru'
                    : 'http://skillometer.pro';*/
                window.open(data.url, "_blank")
            })
            .catch(err => {
                if(err?.response?.status === 400 ) {
                    message.error(err.response?.data?.error)
                }
            })
    }
    useEffect(() => {
        getApiProgressStudent();
        getApiTest();
        getApiHomeWork();
        getApiHomeWorkJournal();
        getApiQuizJournal();

        const currentTab = params?.currentTab || 0;
        setActiveNavigation(Number(currentTab));

        requests.quests.get_stats_progress(params.id)
            .then(res => setStatisticData(res.data))
            .catch(err => console.error(err));
    }, []);
    useEffect(() => {
        if (activeDropJournal === 0) {
            let concatArr = quizJournal.concat([], homeworkJournal);
            setDisplayJournal(concatArr);
        }
        if (activeDropJournal === 1) {
            setDisplayJournal(homeworkJournal);
        }
        if (activeDropJournal === 2) {
            setDisplayJournal(quizJournal);
        }
    }, [activeDropJournal]);
    useEffect(() => {
        if (stagesQuiz.length > 0 && !flags.flagTabooCopyQuiz) {
            let copyElem = JSON.parse(JSON.stringify(stagesQuiz));
            setOriginalStagesQuiz(copyElem);
            setFlags(prev => ({...prev, ["flagTabooCopyQuiz"]: true}));
        }
        if (stagesHomeWork.length > 0 && !flags.flagTabooCopyHomework) {
            let copyElem = JSON.parse(JSON.stringify(stagesHomeWork));
            setOriginalStagesHomework(copyElem);
            setFlags(prev => ({...prev, ["flagTabooCopyHomework"]: true}));
        }
    }, [stagesHomeWork, stagesQuiz]);
    useEffect(() => {
        if (activeNavigation === 1) {
            funcFilterStages(
                activeDropQuiz, originalStagesQuiz, stagesQuizkNames,
                setStagesQuiz, setActiveStageQuiz, setQuizResult
            );
        }
        if (activeNavigation === 2) {
            funcFilterStages(
                activeDropHomework, originalStagesHomework, stagesHomeWorkNames,
                setStagesHomeWork, setActiveStageHomework, setHomeworkResult
            );
        }
    }, [activeDropHomework, activeDropQuiz]);
    useEffect(() => {
        if (searchStudyJournal) {
            searchConcat();
        } else {
            if (activeDropJournal === 0) {
                let concatArr = quizJournal.concat([], homeworkJournal);
                setDisplayJournal(concatArr);
            }
            if (activeDropJournal === 1) {
                setDisplayJournal(homeworkJournal);
            }
            if (activeDropJournal === 2) {
                setDisplayJournal(quizJournal);
            }
        }
    }, [searchStudyJournal]);
    useEffect(() => {
        getApiProgressStudent(params.id, ordering, searchStudyProgress);
    }, [searchStudyProgress])
    return (
        <div className={"info_students"}>
            <NavigationMobile
                click={(num) => {
                    setActiveNavigation(num)
                    newPathname(num)
                }}
                flagOpenSearchMobile={flags.flagOpenSearchMobile}
                setFlagOpenSettings={(val) => setFlags((prev) => ({...prev, ["flagOpenSettings"]: val}))}
                settingsHide={true}
                activePage={activeNavigation}
            />
            <div>
                <div className="top_info">
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 10
                    }}>
                        <PrevBtn
                            click={() => history("/backoffice/groups-list")}
                        />
                        <DemoRow/>
                    </div>
                    <div className="group_title">
                      <span>
                          {titlesArr[activeNavigation]}
                          <span style={{color: "#474C5A"}}> /{currentQuest.name}</span>
                          <span style={{color: "#474C5A"}}>/{currentGroup.name}</span>
                  </span>
                    </div>
                    <div className="group_stat">
                        <div className="block">
                            <Studys/>
                            <div className="group">
                                <div
                                    className="title">
                                    {statisticData && statisticData.total}
                                    {
                                        language === "ru"
                                            ? defaultFuncAndData.declension(statisticData?.total, [" ученик", " ученика", " учеников"])
                                            : statisticData?.total > 1
                                                ? " students"
                                                : " student"
                                    }
                                </div>
                                <div className="info">{variable_14}</div>
                            </div>
                        </div>
                        <div className="block">
                            {window.innerWidth < 820
                                ? <div className={"progress_antd"}>
                                    <Progress
                                        type="dashboard"
                                        percent={statisticData && statisticData.close_to_finish_percent}
                                        gapDegree={1}
                                        strokeColor={defaultFuncAndData.calcColorPercentProgress(84)}
                                        width={85}
                                        height={85}
                                        format={(percent) => percent + "%"}
                                        trailColor={"#474C5A"}
                                        strokeWidth={12}
                                    />
                                </div>
                                : <Progress
                                    type="dashboard"
                                    percent={statisticData && statisticData.close_to_finish_percent}
                                    gapDegree={1}
                                    strokeColor={defaultFuncAndData.calcColorPercentProgress(84)}
                                    width={85}
                                    height={85}
                                    format={(percent) => percent + "%"}
                                    trailColor={"#474C5A"}
                                    strokeWidth={15}
                                />
                            }
                            <div className="group">
                                <div
                                    className="title">
                                    {statisticData && statisticData.close_to_finish}
                                    {
                                        language === "ru"
                                            ? defaultFuncAndData.declension(statisticData?.close_to_finish, [" ученик", " ученика", " учеников"])
                                            : statisticData?.close_to_finish > 1
                                                ? " students"
                                                : " student"
                                    }
                                </div>
                                <div className="info">{variable_15}</div>
                            </div>
                        </div>
                        <div className="block">
                            <People/>
                            <div className="group">
                                <div className="title">{statisticData && statisticData.passed_total}</div>
                                <div className="info">{variable_16}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search_block">
                    <div
                        className={activeNavigation === 0 ? "progress_studys active" : "progress_studys"}
                        onClick={() => {
                            setActiveNavigation(0);
                            newPathname(0);
                        }}
                    >
                        <div><ProgressStudys/></div>
                        <span>{variable_6}</span>
                    </div>

                    <div
                        onClick={() => {
                            setActiveNavigation(1);
                            newPathname(1)
                        }}
                        className={activeNavigation === 1 ? "result_test active" : "result_test"}
                    >
                        <div><TestIcon/></div>
                        <span>{variable_9} </span>
                    </div>
                    <div
                        onClick={() => {
                            setActiveNavigation(2);
                            newPathname(2);
                        }}
                        className={activeNavigation === 2 ? "home_ active" : "home_"}
                    >
                        <div><HomeWorkIcon/></div>
                        <span>{variable_8} </span>
                    </div>
                    <div
                        className={activeNavigation === 3 ? "journal_ active" : "journal_"}
                        onClick={() => {
                            setActiveNavigation(3);
                            newPathname(3);
                        }}
                    >
                        <div><JournalIcon/></div>
                        <span>{variable_7} </span>
                    </div>
                    {activeNavigation === 0
                        ? <>
                            <ReuseDropDown
                                flagOpen={flags.flagProgressDropDown}
                                setFlagOpen={(bool) => setFlags((prev) => ({...prev, ["flagProgressDropDown"]: bool}))}
                                arrList={[variable_17, variable_18, variable_20]}
                                activeArrList={ordering}
                                setActiveArrList={(indx) => {
                                    setOrdering(indx);
                                    getApiProgressStudent(params.id, indx, searchStudyProgress);
                                }}
                                onBlur={() => setFlags(prev => ({...prev, ['flagProgressDropDown']: false}))}
                            />
                            <div className="search">
                                <InputWsButton
                                    value={searchStudyProgress}
                                    setValue={(e) => setSearchStudyProgress(e)}
                                    /*keyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            getApiProgressStudent(params.id, ordering, searchStudyProgress);
                                        }
                                    }}*/
                                    search={() => {
                                    }}
                                />
                            </div>
                        </>
                        : <></>
                    }
                    {activeNavigation === 1 &&
                        <ReuseDropDown
                            flagOpen={flags.flagDropQuiz}
                            setFlagOpen={(bool) => {
                                setFlags((prev) => ({...prev, ["flagDropQuiz"]: bool}));
                            }}
                            arrList={stagesQuizkNames}
                            activeArrList={activeDropQuiz}
                            setActiveArrList={(indx) => setActiveDropQuiz(indx)}
                            onBlur={() => setFlags(prev => ({...prev, ['flagDropQuiz']: false}))}
                        />
                    }
                    {activeNavigation === 2 &&
                        <ReuseDropDown
                            flagOpen={flags.flagDropHomework}
                            setFlagOpen={(bool) => {
                                setFlags((prev) => ({...prev, ["flagDropHomework"]: bool}));
                            }}
                            arrList={stagesHomeWorkNames}
                            activeArrList={activeDropHomework}
                            setActiveArrList={(indx) => setActiveDropHomework(indx)}
                            onBlur={() => setFlags(prev => ({...prev, ['flagDropHomework']: false}))}
                        />
                    }
                    {activeNavigation === 3 &&
                        <>
                            <ReuseDropDown
                                flagOpen={flags.flagOpenJournal}
                                setFlagOpen={(bool) => {
                                    setFlags((prev) => ({...prev, ["flagOpenJournal"]: bool}));
                                }}
                                arrList={[variable_23, variable_8, variable_24]}
                                activeArrList={activeDropJournal}
                                setActiveArrList={(indx) => setActiveDropJournal(indx)}
                                onBlur={() => setFlags(prev => ({...prev, ['flagOpenJournal']: false}))}
                            />
                            <div className="search">
                                <InputWsButton
                                    value={searchStudyJournal}
                                    setValue={(e) => setSearchStudyJournal(e)}
                                    search={{}}
                                />
                            </div>
                        </>
                    }
                    {activeNavigation === 0 &&
                        <Button
                            text={variable_50}
                            click={() => downloadStudent()}
                            maxWidth={"245px"}
                            margin={"0 0 0 15px"}
                            padding={"5px 13px 7px"}
                        />
                    }
                </div>
                {activeNavigation === 0 &&
                    <ProgressStudents
                        allProgress={allProgress}
                        history={history}
                    />
                }
                {activeNavigation === 1 &&
                    <TestWork
                        stagesQuiz={stagesQuiz}
                        tasksQuiz={tasksQuiz}
                        currentQuest={currentQuest}
                        quizResult={quizResult}
                        activeTaskTest={activeTaskTest}
                        activeStageQuiz={activeStageQuiz}
                        setQuizResult={setQuizResult}
                        parseDate={parseDate}
                        setActiveTaskTest={setActiveTaskTest}
                        setActiveStageQuiz={setActiveStageQuiz}
                        history={history}
                    />
                }
                {activeNavigation === 2 &&
                    <HomeWork
                        stagesHomeWork={stagesHomeWork}
                        setHomeworkResult={setHomeworkResult}
                        setActiveStageHomework={setActiveStageHomework}
                        setActiveTaskHomework={setActiveTaskHomework}
                        activeStageHomework={activeStageHomework}
                        tasksHomeWork={tasksHomeWork}
                        activeTaskHomework={activeTaskHomework}
                        getApiHomeWorkId={getApiHomeWorkId}
                        homeworkResult={homeworkResult}
                        parseDate={parseDate}
                        history={history}
                    />
                }
                {activeNavigation === 3 &&
                    <Journal
                        displayJournal={displayJournal}
                        parseDate={parseDate}
                        currentQuest={currentQuest}
                        activeDropJournal={activeDropJournal}
                        history={history}
                    />
                }
            </div>
        </div>
    );
};

export default InfoStudent;