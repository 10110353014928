import React from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { message } from "antd";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import requests from "../../../axios/requests";
import FetchSkills from "../../../components/FetchSkills/FetchSkills";

import { ReactComponent as TrajectoryIcon } from "../../../../images/search_profession/study_traektory.svg";
import close_tag from "../../../../images/search_profession/close_tag.svg";
import './TrajectorySection.scss';

const TrajectorySection = (props) => {
    const history = useNavigate();
    const {studyTraektoryRef, addTrajectorySkill, removeTrajectorySkill,} = props;
    const {trajectorySkills} = useSelector(state => state.Reducer);

    const variables_8 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_8"});
    const variables_10 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_10"});
    const variables_36 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_36"});
    const variables_37 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_37"});

    return (
        <div
            className={cn("trajectory_section", {
                z_index_0: props.flagSteps && props.flagSteps !== 3
            })}
            ref={studyTraektoryRef}
        >
            <div className="down_block-traektory">
                <div className="group">
                    <div className="svg">
                        <TrajectoryIcon/>
                    </div>
                    <span>{variables_8}</span>
                    <FetchSkills addTrajectorySkill={addTrajectorySkill}/>
                </div>
                {trajectorySkills?.length > 0 &&
                    <div className="block_tags">
                        {trajectorySkills.map((elem, indx) =>
                            <div
                                key={indx}
                                className={elem.has_generated_quest ? "tag without_img" : "tag"}
                            >
                                <span>{elem.name}</span>
                                {!elem.has_generated_quest &&
                                    <img
                                        onClick={() => removeTrajectorySkill(elem)}
                                        src={close_tag}
                                    />
                                }
                            </div>
                        )}
                    </div>
                }
                <div className="buttons">
                    <div
                        className="btn_search-course button_classic planned"
                        onClick={() => {
                            if (trajectorySkills.length > 0) {
                                /*props.openChatGpt()*/

                                if (props.flagSteps) {
                                    props.setFlagSteps(false)
                                    requests.auth.edit_profile_new({trained: true})
                                        .catch(err => console.error(err))
                                }
                                history('/backoffice/generate-course')
                            } else
                                message.warning(variables_37)
                        }}
                    >
                        {variables_36}
                    </div>
                    <div
                        className="btn_search-course button_classic courses"
                        onClick={() => {
                            if (trajectorySkills.length > 0)
                                history("/backoffice/current-course/")
                            else
                                message.warning(variables_37)
                        }}
                    >
                        {variables_10}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrajectorySection;