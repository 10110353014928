import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import requests from "../../../axios/requests";

import { Modal, notification, Progress } from "antd";
import { defaultFuncAndData } from "../../../utils/defaultData";
import { CourseItem } from "../../MyCourse/MyCourse";
import DangerSkill from "../ReuseComponents/DangerSkill";
import GorizontalProgressItem from "../Chart/GorizontalProgressItem";
import ParentBlockProgressGorizontal from "../Chart/ParentBlockProgressGorizontal";
import variableLanguage from '../../../utils/hooks/hookGetVariablesLanguage';

import { ReactComponent as Arrow } from "../../../../images/arrow.svg";
import { ReactComponent as Star } from "../../../../images/search_profession/nav_bar-star.svg";import favorite_icon_big from "../../../../images/search_profession/favorite_icon-big.svg";
import disability from "../../../../images/search_profession/disability.svg";
import hh_icon from "../../../../images/search_profession/hh_icon.svg";
import trud_vsem_icon from "../../../../images/search_profession/trud_vsem-icon.svg";
import close_modal from "../../../../images/search_profession/close_modal-course.svg";
import './ModalFavorite.scss';

const ModalFavorite = (props) => {
    let {
        flagModalFavorite, favoriteProfession, favoriteVacancy, myRegion,
        closeModal, getApiVacancyFavorite, getApiProfessionFavorite, setActiveProfessionProps
    } = props;
    const history = useNavigate();

    const [activeCategory, setActiveCategory] = useState(0);
    const [activeProfession, setActiveProfession] = useState(null);
    const [activeVacancy, setActiveVacancy] = useState(null);
    const [arrVacancy, setArrVacancy] = useState(null);
    const [courses, setCourses] = useState([]);
    const [minSalary, setMinSalary] = useState(100000);
    const [averageSalary, setAverageSalary] = useState(0);
    const [maxSalary, setMaxSalary] = useState(0);
    const [currentSalaryVacancy, setCurrentSalaryVacancy] = useState(null);
    const [flagDropDownCategory, setFlagDropDownCategory] = useState(false);
    const [render, setRender] = useState(1);

    const variable_1 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_1'})
    const variable_2 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_2'})
    const variable_3 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_3'})
    const variable_4 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_4'})
    const variable_5 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_5'})
    const variable_6 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_6'})
    const variable_7 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_7'})
    const variable_8 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_8'})
    const variable_9 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_9'})
    const variable_10 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_10'})
    const variable_11 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_11'})
    const variable_12 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_12'})
    const variable_13 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_13'})
    const variable_14 = variableLanguage({keyPage: 'favorites', keyVariable: 'variable_14'})

    let handleCloseModal = () => {
        closeModal();
        setMaxSalary(0);
        setAverageSalary(0);
        setMinSalary(100000);
    };
    let addTrajectorySkill = (name) => {
        let obj = {name: name}
        requests.quests.add_trajectory_skill(obj)
            .then((res) => {
                let newActiveProfession = activeProfession;
                newActiveProfession.profession.tags = newActiveProfession.profession.tags.filter(x => x.name !== name);
                setActiveProfession(newActiveProfession);
                setRender((prev) => prev + 1)
            })
            .catch((e) => {
                console.log(e, "error 1 add_trajectory ");
            });
    }
    let getApiCatalog = () => {
        let arr = [];
        for (let i = 0; i < activeProfession.profession.tags.length; i++) {
            arr = [...arr, activeProfession.profession.tags[i].id]
        }
        requests.quests.get_quest_quest(arr || null, 1, 12, 0)
            .then(res => {
                setCourses(res.data.results)
            })
            .catch((error) => console.log("ошибка get_quest_quest", error))
    };

    useEffect(async () => {
        if (activeProfession?.profession?.tags?.length > 0) {
            getApiCatalog();
        }
        if (activeProfession !== null) {
            let disability = false;
            setArrVacancy([]);
            requests.profs.get_headHunter_jobs(activeProfession.professionTitle, myRegion, disability)
                .then((v) => {
                    setArrVacancy(prev => {
                        let newPrev = [...prev];
                        let data = v.data?.vacancies.slice(0, 5);
                        for (let i = 0; i < data.length; i++) {
                            newPrev.push(data[i]);
                        }
                        newPrev.sort(() => Math.random() - 0.5);
                        return newPrev;
                    });

                }).catch((error) => {
               /* if (error.response.data.access === false) {
                    notification["error"]({
                        message: "Закончились попытки!",
                        duration: 2
                    });
                }*/
            });
            requests.profs.get_trudvsem_vacancy(activeProfession.professionTitle, myRegion, disability)
                .then((v) => {
                    let newTrudVacancy = v.data?.vacancies.slice(0, 5).map((elem) => {
                        return {
                            alternate_url: elem?.vac_url,
                            employer: {
                                name: elem?.company.name
                            },
                            id: elem?.id,
                            name: elem["job-name"],
                            salary: {
                                from: elem?.salary_min || 0,
                                to: elem?.salary_max || 0,
                                currency: elem.currency.includes("руб.") ? "RUR" : elem.currency
                            },
                            skills: []
                        };
                    });
                    setArrVacancy(prev => {
                        let newPrev = [...prev];
                        for (let i = 0; i < newTrudVacancy.length; i++) {
                            newPrev.push(newTrudVacancy[i]);
                        }
                        newPrev.sort(() => Math.random() - 0.5);
                        return newPrev;
                    });
                }).catch((error) => {
                console.error(error);
            });
        }

    }, [activeProfession]);
    useEffect(() => {
        if (arrVacancy && arrVacancy.length > 0) {
            setMinSalary(defaultFuncAndData.calcSalarys(arrVacancy).minSalary);
            setMaxSalary(defaultFuncAndData.calcSalarys(arrVacancy).maxSalary);
            setAverageSalary(defaultFuncAndData.calcSalarys(arrVacancy).averageSalary);
        }
    }, [arrVacancy]);
    useEffect(() => {
        if (activeVacancy && activeVacancy.salary) {
            let newSalary = '';
            newSalary = activeVacancy.salary.split(' ');
            if (newSalary.length === 4) {
                newSalary = newSalary[3]
            }
            if (newSalary.length === 3) {
                newSalary = newSalary[2]
            }
            if (newSalary.length === 2) {
                newSalary = newSalary[1]
            }
            setCurrentSalaryVacancy(Number(newSalary))
        }
    }, [activeVacancy]);
    return (
        <Modal
            visible={flagModalFavorite}
            footer={null}
            className={"modal_favorite"}
            closable={false}
            onCancel={handleCloseModal}
            bodyStyle={{padding: 40, backgroundColor: "#F0E9FF", borderRadius: 40}}
        >
            <div className="title">
                <span>{variable_1}</span>
                <div className="close_icon" onClick={closeModal}><img src={close_modal} alt=""/></div>
            </div>
            <div className="category">
                <div>{variable_2}</div>
                <div
                    onClick={() => {
                        setActiveCategory(0);
                        setActiveVacancy(null);
                    }}
                    className={activeCategory === 0 ? "active" : ""}
                >{variable_3}
                </div>
                <div
                    onClick={() => {
                        setActiveCategory(1);
                        setActiveProfession(null);
                    }}
                    className={activeCategory === 1 ? "active" : ""}
                >{variable_4}
                </div>
            </div>
            <div
                className={flagDropDownCategory ? "category_mobile active" : "category_mobile"}
                onClick={() => setFlagDropDownCategory(!flagDropDownCategory)}
            >
                <span>{variable_2}</span>
                <span style={{color: "#B3DAFF", marginLeft: 5}}>{activeCategory === 0 ? variable_3 : variable_4}</span>
                <Arrow/>
            </div>
            <div className={flagDropDownCategory ? "drop_down-category-mobile active" : "drop_down-category-mobile"}>
                <div onClick={() => {
                    if (flagDropDownCategory) {
                        setActiveCategory(0);
                        setFlagDropDownCategory(false);
                    }
                }}>{variable_3}
                </div>
                <div onClick={() => {
                    if (flagDropDownCategory) {
                        setActiveCategory(1);
                        setFlagDropDownCategory(false);
                    }
                }}>{variable_4}
                </div>
            </div>
            <div className="list_category">
                <ul>
                    {activeCategory === 0 ?
                        favoriteProfession.map((elem, indx) =>
                            <li className={activeProfession && activeProfession.profession.id === elem.profession.id ? "profession active" : "profession"}
                                key={indx}
                                onClick={() => setActiveProfession(elem)}>
                                <div
                                    className="favorite_icon"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        requests.profs.remove_profession_favorite(elem.profession.id)
                                            .then((res) => {
                                                getApiProfessionFavorite();
                                                setActiveProfession(null);
                                                console.log(res.data, "res data remove_vacancy_favorite");
                                            })
                                            .catch((error) => console.log(error, "error remove_profession_favorite"));
                                    }}
                                >
                                    <img src={favorite_icon_big}/>
                                </div>
                                <div className="border"/>
                                <span style={{alignSelf: "center", fontSize: 14}}>{elem.profession.name}</span>
                                <div className="right_block-favorite">
                                    <div className="progress">
                                        <Progress
                                            type="dashboard"
                                            percent={elem.tags_percent || ''}
                                            gapDegree={1}
                                            strokeColor={defaultFuncAndData.calcColorPercentProgressProfession(elem.tags_percent || '')}
                                            width={40}
                                            height={40}
                                            format={(percent) => percent}
                                            trailColor={activeProfession && activeProfession.id === elem.id ? "#A2AACA" : "#474C5A"}
                                            strokeWidth={20}
                                        />
                                    </div>
                                    <div
                                        className="btn_redirect"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setActiveProfessionProps(elem);
                                            closeModal();
                                        }}
                                    >{variable_5}
                                    </div>
                                </div>
                            </li>
                        )
                        :
                        favoriteVacancy.map((elem, indx) =>
                            <li
                                className={activeVacancy && activeVacancy.id === elem.id ? "vacancy active" : "vacancy"}
                                key={indx}
                                onClick={() => setActiveVacancy(elem)}
                            >
                                <div
                                    className="favorite_icon"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        requests.profs.remove_vacancy_favorite(elem.id)
                                            .then((res) => {
                                                getApiVacancyFavorite();
                                                console.log(res.data, "res data remove_vacancy_favorite");
                                            })
                                            .catch((error) => console.log(error, "error remove_vacancy_favorite"));
                                    }}

                                ><img src={favorite_icon_big}/></div>
                                <div className="border"/>
                                <span
                                    style={{
                                        alignSelf: "center", minWidth: "20%", whiteSpace: "nowrap",
                                        overflow: "hidden", textOverflow: "ellipsis"
                                    }}
                                >
                                        {elem.name}
                                     </span>
                                <div className="border"/>
                                <div
                                    className="salary"
                                    style={{alignSelf: "center"}}
                                >
                                    {elem.salary}
                                </div>
                                <div className="border"/>
                                <div className="company">{elem.name}</div>
                                <div className="img_block" style={!elem.disability ? {paddingLeft: 20} : {}}>
                                    {elem.disability && <img src={disability} width={18} height={18} alt=""/>}
                                    <img
                                        src={elem.link && elem.link.includes("hh.ru") ? hh_icon : trud_vsem_icon}
                                        width={22} height={elem.link && elem.link.includes("hh.ru") ? 11 : 20}
                                        alt=""
                                    />
                                </div>
                                <div className="btn_redirect"
                                     onClick={() => window.open(elem.link, "blank")}>{variable_12}
                                </div>
                            </li>
                        )
                    }
                </ul>
            </div>
            <div className="list_category-mobile">
                <ul>
                    {activeCategory === 0 ?
                        favoriteProfession.map((elem, indx) => {
                                return <li
                                    className={activeProfession && activeProfession.profession.id === elem.profession.id ? "profession_mobile active" : "profession_mobile"}
                                    key={indx}
                                    onClick={() => setActiveProfession(elem)}
                                >
                                    <div className="top">
                                        <div
                                            className="favorite_icon"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                requests.profs.remove_profession_favorite(elem.profession.id)
                                                    .then((res) => {
                                                        getApiProfessionFavorite();
                                                        setActiveProfession(null);
                                                        console.log(res.data, "res data remove_vacancy_favorite");
                                                    })
                                                    .catch((error) => console.log(error, "error remove_profession_favorite"));
                                            }}
                                        >
                                            <Star
                                                className={defaultFuncAndData.calcActiveFavoriteIcon(elem, favoriteProfession) ? "active" : ""}
                                            />
                                        </div>
                                        <div className="border"/>
                                        <span>{elem.profession.name}</span>
                                    </div>
                                    <div className="middle">
                                        <div><span>{variable_10}</span> <span>{elem.tags_percent || 0}%</span></div>
                                        <div className="progress">
                                            <Progress
                                                percent={elem.tags_percent || 0}
                                                strokeColor={defaultFuncAndData.calcColorPercentProgressProfession(elem.tags_percent || 0)}
                                                showInfo={false}
                                                trailColor={"#9B99A7"}
                                            />
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div
                                            className="btn_redirect"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setActiveProfessionProps(elem);
                                                closeModal();
                                            }}
                                        >{variable_5}
                                        </div>
                                    </div>
                                </li>;
                            }
                        )
                        :
                        favoriteVacancy.map((elem, indx) =>
                            <li
                                className={activeVacancy && activeVacancy.id === elem.id ? "vacancy_mobile active" : "vacancy_mobile"}
                                key={indx}
                                onClick={() => setActiveVacancy(elem)}
                            >
                                <div
                                    // className={activeVacancy && activeVacancy.name === elem.name && activeVacancy.skills.length > 0 ? "active" : ""}>
                                >
                                    <div className="top">
                                        <Star
                                            className={defaultFuncAndData.calcActiveFavoriteIcon(elem, favoriteVacancy) ? "active" : ""}
                                            width={14} height={14}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                requests.profs.remove_vacancy_favorite(elem.id)
                                                    .then((res) => {
                                                        getApiVacancyFavorite();
                                                        console.log(res.data, "res data remove_vacancy_favorite");
                                                    })
                                                    .catch((error) => console.log(error, "error remove_vacancy_favorite"));
                                            }}
                                        />
                                        <div className="border"/>
                                        <span>{elem.name}</span>
                                        <div className="btn_redirect">
                                            <img
                                                style={{cursor: "pointer"}}
                                                onClick={() => window.open(elem.link ? elem.link : '/', "blank")}
                                                width={23}
                                                height={elem.link && elem.link.includes("hh.ru") ? 10 : 18}
                                                src={elem.link && elem.link.includes("hh.ru") ? hh_icon : trud_vsem_icon}
                                            />
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <div className="left">
                                            <div className="salary">
                                                <span style={{whiteSpace: "nowrap"}}>{elem.salary}</span>
                                            </div>
                                            {activeVacancy && activeVacancy.name === elem.name
                                                ? <div
                                                    className="btn_more-info"
                                                    onClick={() => {
                                                        setActiveVacancy(null);
                                                    }}
                                                >
                                                    {variable_13}
                                                </div>
                                                : <div
                                                    className="btn_more-info"
                                                    onClick={() => {
                                                        setActiveVacancy(elem);
                                                    }}
                                                >
                                                    {variable_13}
                                                </div>
                                            }
                                        </div>
                                        <div className="border"/>
                                        <div className="right">
                                            <div className="employer">{elem.name}</div>
                                            <div className="btn_redirect"
                                                 onClick={() => window.open(elem.link ? elem.link : '/', "blank")}>
                                                {variable_12}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                </ul>
            </div>
            {activeProfession &&
                <>
                    <div className="active_vacancy-title">{activeProfession.profession.name}</div>
                    <div className="description">{activeProfession?.profession?.description || ''}</div>
                    <div className="active_vacancy-popular" style={{fontSize: 20}}>{variable_6}</div>
                    <div className="tags_block">
                        <DangerSkill
                            data={activeProfession && activeProfession.profession.tags}
                            isFavorite={true}
                            addSkillTraektory={(skill) => addTrajectorySkill(skill.name)}
                        />
                    </div>
                   {/* {(courses && courses.length > 0) &&
                        <div className="recomendation_block">
                            <div className="title">
                                <div className="group_">
                                    <div className="h3">{variable_7}</div>
                                    <div className="text">{variable_8}</div>
                                </div>
                                <div
                                    className="btn_show"
                                    onClick={() => history('/course-list')}
                                >{variable_9}
                                </div>
                            </div>
                            <ul>
                                {courses.slice(0, 4).map((course, indx) => {
                                    return (
                                        <li key={indx} className={"reuse_course-item"}>
                                            <CourseItem
                                                course={course}
                                                dateCourse={true}
                                                cost={true}
                                                redirectUrl={course.id}
                                                isCourseList={true}
                                            />
                                        </li>
                                    );
                                })
                                }
                            </ul>
                        </div>
                    }*/}

                    {/*  <div className="salary_title">{variable_11}</div>
                    <ParentBlockProgressGorizontal long_line={true} isFavorite={true} activeCategory={activeCategory}>
                        <GorizontalProgressItem
                            leftMiddle={defaultFuncAndData.calcProcentague(maxSalary)}
                            procentague={defaultFuncAndData.calcProcentague(maxSalary)}
                            indexSalary={maxSalary.toString()}
                            isFavorite={true}
                            index={3}
                            more200={maxSalary > 230000}
                        />
                        <GorizontalProgressItem
                            leftMiddle={defaultFuncAndData.calcProcentague(averageSalary)}
                            procentague={defaultFuncAndData.calcProcentague(averageSalary)}
                            indexSalary={averageSalary.toString()}
                            isFavorite={true}
                            index={2}
                            more200={averageSalary > 230000}
                        />
                        <GorizontalProgressItem
                            leftMiddle={defaultFuncAndData.calcProcentague(minSalary)}
                            procentague={defaultFuncAndData.calcProcentague(minSalary)}
                            indexSalary={minSalary.toString()}
                            isFavorite={true}
                            index={1}
                            more200={minSalary > 230000}
                        />
                    </ParentBlockProgressGorizontal>
                    <div className="bottom_info">
                        <div>1. <span> Минимальная заработная плата среди</span> выбранной профессии на рынке</div>
                        <div>2. <span> Средняя заработная плата среди</span> выбранной профессии на рынке</div>
                        <div>3. <span> Максимальная зарабатная плата среди</span> выбранной профессии на рынке</div>
                    </div>*/}
                </>
            }
            {activeVacancy &&
                <>
                    <div className="active_vacancy-title">{activeVacancy.name}</div>
                    {activeVacancy.skill &&
                        <>
                            <div className="active_vacancy-popular">{variable_6}</div>
                            <div className="tags_block">
                                <DangerSkill
                                    data={activeVacancy.skills}
                                    addSkillTraektory={(skill) => console.log(skill, "skill block modal 2")}
                                />
                            </div>
                        </>
                    }
                    <div className="salary_title">{variable_11}</div>
                    <ParentBlockProgressGorizontal long_line={true} isFavorite={true}>
                        <GorizontalProgressItem
                            // leftMiddle={defaultFuncAndData.calcProcentague(currentSalaryVacancy)}
                            // procentague={defaultFuncAndData.calcProcentague(currentSalaryVacancy)}
                            // indexSalary={currentSalaryVacancy ? currentSalaryVacancy.toString() : "null"}
                            leftMiddle={defaultFuncAndData.calcProcentague(currentSalaryVacancy)}
                            procentague={defaultFuncAndData.calcProcentague(currentSalaryVacancy)}
                            indexSalary={currentSalaryVacancy ? currentSalaryVacancy : ''}
                            color={"blue"}
                            isFavorite={true}
                            index={6}
                            more200={currentSalaryVacancy && currentSalaryVacancy > 230000}

                        />
                        <GorizontalProgressItem
                            leftMiddle={defaultFuncAndData.calcProcentague(85000)}
                            procentague={defaultFuncAndData.calcProcentague(85000)}
                            indexSalary={"85 000"}
                            isFavorite={true}
                            index={5}
                            more200={false}
                        />
                        <GorizontalProgressItem
                            leftMiddle={defaultFuncAndData.calcProcentague(10000)}
                            procentague={defaultFuncAndData.calcProcentague(10000)}
                            indexSalary={"10 000"}
                            isFavorite={true}
                            index={4}
                            more200={false}
                        />
                        <GorizontalProgressItem
                            leftMiddle={defaultFuncAndData.calcProcentague(61000)}
                            procentague={defaultFuncAndData.calcProcentague(61000)}
                            indexSalary={"61 000"}
                            isFavorite={true}
                            index={3}
                            more200={false}
                        />
                        <GorizontalProgressItem
                            leftMiddle={defaultFuncAndData.calcProcentague(48000)}
                            procentague={defaultFuncAndData.calcProcentague(48000)}
                            indexSalary={"48 000"}
                            isFavorite={true}
                            index={2}
                            more200={false}
                        />
                        <GorizontalProgressItem
                            leftMiddle={defaultFuncAndData.calcProcentague(88000)}
                            procentague={defaultFuncAndData.calcProcentague(88000)}
                            indexSalary={"88 000"}
                            isFavorite={true}
                            index={1}
                            more200={false}
                        />
                    </ParentBlockProgressGorizontal>
                    {/* <div className="bottom_info">
                        <div>1. <span> Минимальная заработная плата среди</span> всех вакансий на рынке</div>
                        <div>2. <span> Минимальная заработная плата среди</span> выбранных вакансий</div>
                        <div>3. <span> Средняя заработная плата среди</span> всех вакансий на рынке</div>
                        <div>4. <span> Средняя заработная плата среди</span> выбранных вакансий</div>
                        <div>5. <span> Максимальная зарабатная плата среди</span> всех вакансий на рынке</div>
                        <div>6. <span> Зарабатная плата</span> выбранной вакансии</div>
                    </div>*/}
                </>
            }
            <span style={{position: 'absolute', opacity: 0}}>{render}</span>
        </Modal>
    );
};

export default ModalFavorite;