const obj = {
    en: {
        profile: {
            variable_1: "Profile photo",
            variable_2: "File format: jpg/jpeg, png",
            variable_3: "Resolution: 220x220 - 900x900 px",
            variable_4: "File size: up to 10 mb",
            variable_5: "Replace photo",
            variable_6: "Full name",
            variable_7: "Your phone number",
            variable_8: "Russia",
            variable_9: "Belarus",
            variable_10: "Kazakhstan",
            variable_11: "Azerbaijan",
            variable_12: "Uzbekistan",
            variable_13: "Georgia",
            variable_14: "Kyrgyzstan",
            variable_15: "Sending email notifications",
            variable_16: "Change",
            variable_17: "Organization",
            variable_18: "Name of organization",
            variable_19: "Not entered",
            variable_20: "Your organization's name will appear in the course directory",
            variable_21: "About me, Contact information",
            variable_22: "More about your organization, contact details, links",
            variable_23: "Receive payments through the Skillometer system",
            variable_24: "Save",
            variable_25: "Save photo",
            variable_26: "Organization data",
            variable_27: "Fill in the name of the organization",
            variable_28: "Fill out contact information",
            variable_29: "Changes saved",
            variable_30: "Saved",
            variable_31: "Please enter a valid e-mail",
            variable_32: "Please enter your full name",
            variable_33: "Enter your phone number",
            variable_34: "Profile edited successfully",
            variable_35: "Save payment details",
            variable_36: "Full name of Company (Example, Limited Liability Company \"My Company\")",
            variable_37: "Abbreviated name of Company (Example, LLC \"My Company\")",
            variable_38: "Organization email",
            variable_39: "Store name in SMS and 3DS checkout page in foreign language",
            variable_40: "TIN",
            variable_41: "Taxpayer Identification Number",
            variable_42: "KPP",
            variable_43: "Tax Registration Reason Code",
            variable_44: "PSRN",
            variable_45: "Primary State Registration Number",
            variable_46: "Acc.",
            variable_47: "Organization's bank account.",
            variable_48: "Correspondent account",
            variable_49: "Correspondent account of the organization in the bank.",
            variable_50: "Name of the bank",
            variable_51: "Enter the name of the bank where the current account is located.",
            variable_52: "RCBIC",
            variable_53: "Bank identification code.",
            variable_54: "Information about the leader",
            variable_55: "Name",
            variable_56: "Surname",
            variable_57: "Middle name",
            variable_58: "Date of Birth",
            variable_59: "Phone",
            variable_60: "Country of nationality",
            variable_61: "Legal address",
            variable_62: "Zip code",
            variable_63: "Country",
            variable_64: "City",
            variable_65: "Street, house, building, apartment, office",
            variable_66: "Actual address",
            variable_67: "January",
            variable_68: "February",
            variable_69: "March",
            variable_70: "April",
            variable_71: "May",
            variable_72: "June",
            variable_73: "July",
            variable_74: "August",
            variable_75: "September",
            variable_76: "October",
            variable_77: "November",
            variable_78: "December",
            variable_79: "Saved",
            variable_80: "Error saved",
            variable_81: "Fill in all the fields",
            variable_82: "PSRN 13 characters",
            variable_83: "Acc. 20 characters",
            variable_84: "RCBIC 9 characters",
            variable_85: "Correspondent account 20 characters",
            variable_86: "Zip code 6 characters",
            variable_87: "Full name of Sole Proprietor (Example, Sole Proprietor Ivanov Ivan Ivanovich)",
            variable_88: "Abbreviated name of Sole Proprietor (Example, SP Ivanov I. I.)",
            variable_89: "Fill in the details of the organization to confirm account",
            variable_90: "SP",
            variable_91: "PSRNSP",
            variable_92: "Fill in all fields of the actual address.",
            variable_93: "Shop name in SMS 14 characters.",
            variable_94: "Account not verified",
            variable_95: "Account verified",
            variable_96: "PSRNSP 15 characters",
        },
        searchProfession: {
            variables_1: 'Professions',
            variables_2: 'IT professions',
            variables_3: 'All profession',
            variables_4: 'Filters',
            variables_5: 'Favorites',
            variables_6: 'Search for a profession',
            variables_7: 'Find',
            variables_8: 'Syllabus',
            variables_9: 'Add your desired skill',
            variables_10: 'Create course',
            variables_11: 'Choose the right profession for you',
            variables_12: 'Vacancy',
            variables_13: 'Description of the profession',
            variables_14: 'Popular Skills',
            variables_15: 'Key skills, by created courses',
            variables_16: 'Skills that may suit you to create a course',
            variables_17: 'Missing skills are missing',
            variables_18: 'Vacancy description',
            variables_19: 'Required skills for employment',
            variables_20: {
                part_1: 'Your courses fully cover the requirements of the employer',
                part_2: 'Skills that may suit you to create a course',
            },
            variables_21: 'Salary opportunities in the profession ',
            variables_22: 'Go to vacancy',
            variables_23: 'No data on required skills',
            variables_24: 'Back to the catalog of professions',
            variables_25: 'Detailed',
            variables_26: 'Go to website',
            variables_27: 'Show Skillset',
            variables_28: 'Close',
            variables_29: 'Professions',
            variables_30: 'Category',
            variables_31: 'This skill is already in the Skillset',
            variables_32: 'Skill added',
            variables_33: 'Specify city/region',
            variables_34: "Key skills, by created courses",
            variables_35: "Подобрать курсы",
            variables_36: "Generate course",
            variables_37: "Add at least one skill",
            variables_38: "instruction",
            variables_39: "Skill added",
            variables_40: "Professions",
            variables_41: "Generate course",
            variables_42: "My courses",
        },
        modalProfessionInfo: {
            variables_1: 'Popular Skills',
            variables_2: 'In-demand courses',
            variables_3: 'If you pass these courses, then your chance to get into a better paid company increases.',
            variables_4: 'Show all',

        },
        modalCourse: {
            variable_1: 'Show all',
            variable_2: 'Price',
            variable_3: 'Date',
            variable_4: 'In-demand courses',
            variable_5: 'If you pass these courses, then your chance to get into more paid company increases',
            variable_6: 'Sort by',
            variable_7: 'Skillset'
        },
        favorites: {
            variable_1: 'Favorites',
            variable_2: 'Category:',
            variable_3: 'Profession',
            variable_4: 'Vacancy',
            variable_5: 'Go to vacancies',
            variable_6: 'Popular Skills',
            variable_7: 'In-demand courses',
            variable_8: 'If you pass these courses, then your chance to get into a better paid company increases.',
            variable_9: 'Show all',
            variable_10: 'Your match',
            variable_11: 'Current salary opportunities',
            variable_12: 'Go to',
            variable_13: 'Detailed ',
            variable_14: 'Hide Skills'
        },
        filters: {
            variable_1: 'Filters',
            variable_2: 'Keywords',
            variable_3: 'Search only',
            variable_4: 'in company name',
            variable_5: 'in job description',
            variable_6: 'List of industries',
            variable_7: 'Specify industry',
            variable_8: 'Select industries',
            variable_9: 'Region',
            variable_10: 'Append',
            variable_11: 'Income level',
            variable_12: 'from',
            variable_13: 'With salary indication',
            variable_14: 'Show',
            variable_15: 'Required experience',
            variable_16: 'No experience',
            variable_17: 'From 1 year to 3 years',
            variable_18: '3 to 6 years old',
            variable_19: 'Over 6 years',
            variable_20: 'Schedule',
            variable_21: 'Full-time',
            variable_22: 'Shift work',
            variable_23: 'Flexible schedule',
            variable_24: 'Distant work',
            variable_25: 'Shift method',
            variable_26: 'Sort By',
            variable_27: 'Date',
            variable_28: 'Descending income',
            variable_29: 'Ascending income',
            variable_30: 'Compliance',
            variable_31: 'By distance',
            variable_32: 'Output',
            variable_33: 'During all this time',
            variable_34: 'Per month',
            variable_35: 'During the week',
            variable_36: 'Over the last three days',
            variable_37: 'Per day',
            variable_38: 'Find vacancies'
        },
        navbar: {
            variable_1: "Do you really want to leave?",
            variable_2: "Yes",
            variable_3: "No",
            variable_4: "Profile",
            variable_5: "Courses list",
            variable_6: "Notification",
            variable_7: "Log out",
            variable_8: "Courses",
            variable_9: "Incoming requests",
            variable_10: "Academic performance",
            variable_11: "Payment history",
            variable_12: "Module",
            variable_13: "Academic plan",
            variable_14: "Change language",
            variable_15: "Course generator",
        },
        notification: {
            variable_1: "Notifications",
            variable_2: "New",
            variable_3: "All",
            variable_4: "Question",
            variable_5: "Notification",
            variable_6: "New",
            variable_7: "Viewed",
            variable_8: "Error receiving notifications.",
            variable_9: "",
            variable_10: "",
            variable_11: "",
            variable_12: "",
            variable_13: "",
            variable_14: "",
            variable_15: "",
            variable_16: "",
            variable_17: "",
            variable_18: "",
            variable_19: "",
            variable_20: "",
            variable_21: "",
        },
        myCourse: {
            variable_1: "Cancel",
            variable_2: "Courses",
            variable_3: "Incoming requests",
            variable_4: "Academic performance",
            variable_5: "Add tutorial",
            variable_6: "Search",
            variable_7: "All categories",
            variable_8: "Category",
            variable_9: "Search",
            variable_10: "Your trial period has ended",
            variable_11: "Your trial period has ended, to access your account, fill in the details of the organization.",
            variable_12: "Confirm Organization",
            variable_13: "Trial period:",
            variable_14: "d.",
            variable_15: "h.",
            variable_16: "m.",
            variable_17: "s.",
            variable_18: "If you have a question - write:",
            variable_19: "",
            variable_20: "",
            variable_21: "",
        },
        courseCard: {
            variable_1: "video",
            variable_2: {
                part_1: "article",
                part_2: "articles",
            },
            variable_3: {
                part_1: "homework",
                part_2: "homeworks",
            },
            variable_4: {
                part_1: "test",
                part_2: "tests"
            },
            variable_5: "Key skills",
            variable_6: "Cost:",
            variable_7: "Free",
            variable_8: "from",
            variable_9: "to",
            variable_10: "Link successfully copied!",
            variable_11: "Engineering & IT",
            variable_12: "Science & Medicine",
            variable_13: "Languages & Copywriting",
            variable_14: "Management & Marketing",
            variable_15: "Finance & Law",
            variable_16: "Design & Creative",
            variable_17: "Sport",
            variable_18: "Shown in catalog",
            variable_19: "Not shown in catalog",
            variable_20: "Corporate",
            variable_21: "Link",
            variable_22: "Edit Course",
        },
        innerRequestMember: {
            variable_1: "During all this time",
            variable_2: "During the week",
            variable_3: "Per month",
            variable_4: "In a year",
            variable_5: "Reject",
            variable_6: "Exclude",
            variable_7: "Group",
            variable_8: "To accept",
            variable_9: "Postpone",
            variable_10: "Yes",
            variable_11: "No",
            variable_12: "All courses",
            variable_13: "Cancel",
            variable_14: "Incoming requests",
            variable_15: "Courses",
            variable_16: "Academic performance",
            variable_17: "Finding a student",
            variable_18: "Views",
            variable_19: "Requests",
            variable_20: "per.",
            variable_21: "Complete the walkthrough",
            variable_22: "Reviews",
            variable_23: "All members",
            variable_24: "Expelled",
            variable_25: "Hide the entire table",
            variable_26: "Show entire table",
            variable_27: "No requests",
            variable_28: "No dropouts",
            variable_29: "List",
            variable_30: "No members",
            variable_31: "Search course",
            variable_32: "Specify the reason for the exception",
            variable_33: "Reason",
            variable_34: "Message",
        },
        groupsList: {
            variable_1: "Received",
            variable_2: "Denied",
            variable_3: "All classes",
            variable_4: "Groups",
            variable_5: "Back",
            variable_6: "Student Progress",
            variable_7: "Journal",
            variable_8: "Hometasks",
            variable_9: "Test results",
            variable_10: "Courses",
            variable_11: "Incoming requests",
            variable_12: "Academic performance",
            variable_13: "Open group",
            variable_14: "Pass the course",
            variable_15: "Nearing completion of course",
            variable_16: "Number of people who completed the course",
            variable_17: "No sorting",
            variable_18: "By academic performance",
            variable_19: "No students",
            variable_20: "By last name",
            variable_21: "Key student skills",
            variable_22: "Lesson",
            variable_23: "All",
            variable_24: "Test",
            variable_25: "Training mode",
            variable_26: "Test Mode",
            variable_27: "Attempts:",
            variable_28: "No Tests Completed",
            variable_29: "No completed tests and homework",
            variable_30: "No completed homework",
            variable_31: "No Tests Completed",
            variable_32: "Under consideration",
            variable_33: "List",
            variable_34: "Under consideration",
            variable_35: "Lessons",
            variable_36: "Tests",
            variable_37: "Select lessons",
            variable_38: "Test results",
            variable_39: "Choose a test",
            variable_40: "Work to be checked",
            variable_41: "Select homework",
            variable_42: "Active groups",
            variable_43: "Groups in the archive",
            variable_44: "active",
            variable_45: "archival",
            variable_46: "Move group to archive?",
            variable_47: "Move to archive",
            variable_48: "Return to active groups?",
            variable_49: "Return from archive",
            variable_50: "Upload list of students"
        },
        checkHomeWork: {
            variable_1: "Task Accepted",
            variable_2: "The task has been reworked",
            variable_3: "Course name:",
            variable_4: "Lesson:",
            variable_5: "Task:",
            variable_6: "Description",
            variable_7: "Links to sources",
            variable_8: "Answer:",
            variable_9: "Accepted",
            variable_10: "Under consideration",
            variable_11: "Sent for revision",
            variable_12: "Date:",
            variable_13: "Student response:",
            variable_14: "Attached file:",
            variable_15: "To accept",
            variable_16: "Submit for revision",
            variable_17: "Specify your remarks, or results of check. Not necessary",
            variable_18: "Accept a job",
            variable_20: "Your response:",
            variable_21: "Comments",
            variable_22: "Write a comment",
            variable_23: "Task",
            variable_24: "Demo video",
            variable_25: "Image",
        },
        checkTestWork: {
            variable_1: "Checking",
            variable_2: "Training",
            variable_3: "Course name:",
            variable_4: "Lesson",
            variable_5: "Test name:",
            variable_6: "Attempts:",
            variable_7: "Date and time of passage:",
            variable_8: "Question:",
            variable_9: "student:",
            variable_10: "Answer №",
            variable_11: "Test type:",
            variable_12: "",
        },
        currentCourse_step_1: {
            variable_1: "Course editing",
            variable_2: "Create a course",
            variable_3: "Are you sure you want to delete the course?",
            variable_4: "Yes",
            variable_5: "No",
            variable_6: "Course deleted successfully",
            variable_7: "Course published",
            variable_8: "Course saved",
            variable_9: "Course editing error",
            variable_10: "Error",
            variable_11: "Course cards",
            variable_12: "Direction of activity",
            variable_13: "Course name",
            variable_14: "Short course description",
            variable_15: "Describe what the person can learn",
            variable_16: "Demo video",
            variable_17: "Link to youtube",
            variable_18: "Your video",
            variable_19: "Youtube link",
            variable_20: "Cover",
            variable_21: "Start and end date",
            variable_22: "Duration in academic hours",
            variable_23: "Landing",
            variable_24: "Skills after completing the course",
            variable_25: "The general course is paid",
            variable_26: "The general course is free",
            variable_27: "Corporate Course",
            variable_28: "Course cost",
            variable_29: "Groups",
            variable_30: "Description",
            variable_31: "Group",
            variable_32: "Enter a group name",
            variable_33: "Add a group",
            variable_34: "Enter a new group name",
            variable_35: "Your phone number",
            variable_36: "Russia",
            variable_37: "Belarus",
            variable_38: "Kazakhstan",
            variable_39: "",
            variable_40: "Azerbaijan",
            variable_41: "Uzbekistan",
            variable_42: "Georgia",
            variable_43: "Kyrgyzstan",
            variable_44: "Enter course name",
            variable_45: "Enter the number of academic hours",
            variable_46: "Add skills while taking the course",
            variable_47: "Enter the cost of the course",
            variable_48: "Please enter a valid e-mail",
            variable_49: "Update the course start and end date for publication.",
            variable_50: "Engineering & IT",
            variable_51: "Science & Medicine",
            variable_52: "Languages & Copywriting",
            variable_53: "Management & Marketing",
            variable_54: "Finance & Law",
            variable_55: "Design & Creative",
            variable_56: "Sport",
            variable_57: "Edit Image",
            variable_58: "File uploaded successfully!",
            variable_59: "File upload error.",
            variable_60: "Save",
            variable_61: "Cancel",
            variable_62: "Select a file to start downloading or drag it to this window",
            variable_63: "Communication methods",
            variable_64: "Upload file size limit up to 500MB.",
            variable_65: 'Video title character limit exceeded (255)',
            variable_66: "Choose which area your course will be in. When the student chooses this direction, he will see your card.",
            variable_67: "The title should be short and descriptive, for example: “Python for beginners”, “Basics of business correspondence”, etc.",
            variable_68: "A very important item to complete. This text will be carefully read by visitors to our platform and should be short (3-5 sentences) while describing all the main objectives of the course.",
            variable_69: "The image will be on the cover of the card, choose a high quality and “selling” image on your topic. You can upload a demo video, it will be displayed in the card preview.",
            variable_70: "It is on these dates that your course will appear in the general catalog.",
            variable_71: "Start typing a skill, and then select the one you want from the suggestions. This will help interested students find your course in their educational path.",
            variable_72: "Organize your students into different groups so you can more easily track their progress, archive at the end of the course, and group new student streams.",
            variable_73: "Left",
            variable_74: "Right",
            variable_75: "Missing",
            variable_76: "Banner",
            variable_77: "Course content",
            variable_78: "Basic information",
            variable_79: "Sign up",
            variable_80: "An image of your course on the landing page.",
            variable_81: "Upload file size limit up to 350MB.",
            variable_82: "Course description generation",
            variable_83: "Share",
        },
        currentCourse_step_2: {
            variable_1: "Speakers",
            variable_2: "Speaker",
            variable_3: "Delete speaker",
            variable_4: "Yes",
            variable_5: "No",
            variable_6: "Upload a photo",
            variable_7: "Name",
            variable_8: "Surname",
            variable_9: "Position held",
            variable_10: "Number of characters: 150",
            variable_11: "Number of characters: 1000",
            variable_12: "Description",
            variable_13: "Enter the speaker's name",
            variable_14: "Enter speaker's last name",
            variable_15: "Specify the position of the speaker",
            variable_16: "Save speaker",
            variable_17: "Add speaker",
            variable_18: "Who will suit",
            variable_19: "The target audience",
            variable_20: "Describe who suits",
            variable_21: "Add target audience",
            variable_22: "Add a description",
            variable_23: "Save Block",
            variable_24: "Add block",
            variable_25: "What will a person learn",
            variable_26: "Skill",
            variable_27: "Add a Skill",
            variable_28: "Describe what the person can learn",
            variable_29: "Save the skill",
            variable_30: "Add Skill",
            variable_31: "Back",
            variable_32: "Save course",
            variable_33: "Preview",
            variable_34: "Not entered",
            variable_35: "Name all speakers of the course and write a couple of sentences about them.",
            variable_36: "Here you can write 3-5 descriptions of 1-2 sentences about who your course may suit.",
            variable_37: "Describe the key skills that students will acquire.",
            variable_38: "",
            variable_39: "",
            variable_40: "",
        },
        currentCourse_step_3: {
            variable_1: "Course landing page",
            variable_2: "Course card",
            variable_3: "Back",
            variable_4: "Publish Course",
            variable_5: "Key skills",
            variable_6: "from",
            variable_7: "to",
            variable_8: "Price:",
            variable_9: "Go to course",
            variable_10: "Engineering & IT",
            variable_11: "Science & Medicine",
            variable_12: "Languages & Copywriting",
            variable_13: "Management & Marketing",
            variable_14: "Finance & Law",
            variable_15: "Design & Creative",
            variable_16: "Sport"
        },
        landing: {
            variable_1: 'Courses catalog',
            variable_2: 'Speakers',
            variable_3: 'About the course',
            variable_4: 'Who will suit',
            variable_5: 'What will you get',
            variable_6: 'Log-in',
            variable_7: 'What is the format of the training',
            variable_8: 'Video',
            variable_9: 'Learn theory and practice tasks close to real.',
            variable_10: 'Test',
            variable_11: 'Solve problems after each module and get feedback from the curator expert within 1-3 business days.',
            variable_12: 'Article',
            variable_13: 'Study text material online mode with anyone convenient for you devices.',
            variable_14: 'Homework',
            variable_15: 'Study text material online mode with anyone convenient for you devices.',
            variable_16: 'With speaker',
            variable_17: 'Take part in training experienced speakers.',
            variable_18: 'Who will suit',
            variable_19: 'What will you learn',
            variable_20: 'Participant feedback',
            variable_21: 'Do you have any questions?',
            variable_22: 'Ask your question and the speakers of the course will be happy to answer your questions!',
            variable_23: 'Name',
            variable_24: 'Phone',
            variable_25: 'Your message',
            variable_26: 'By clicking on the button, I agree to the processing of personal data and with rules use of the Platform',
            variable_27: 'Send',
            variable_28: 'Sign up for a course',
            variable_29: 'Question sent.',
            variable_30: 'You have already submitted a question.',
            variable_31: 'Enter your name',
            variable_32: 'Enter phone',
            variable_33: 'Enter your message',
            variable_34: 'You have already applied',
            variable_35: 'Application sent',
            variable_36: 'An error has occurred',
            variable_37: 'Question sent',
            variable_38: 'Page not found!',
            variable_39: 'Pay for course',
            variable_40: 'Go to course',
            variable_41: 'Back to catalog',
            variable_42: 'You are already a member of the course',
            variable_43: "Programming",
            variable_44: "Complete tasks online at any time and from any device convenient for you.",
            variable_45: "Learn and put your skills into practice right away. Code directly on our platform.",
            variable_46: "Your resume after completing the course",
            variable_47: "Course content",
            variable_48: "Course materials",
            variable_50: "Share",


        },
        generateCourse: {
            variable_1: "Course Generator",
            variable_2: "Choose one of your skills to generate a course that's right for you",
            variable_3: "Increase",
            variable_4: "Downsize",
            variable_5: "Generation settings",
            variable_6: "Generate Learning Plan",
            variable_7: "Offer is limited, hurry up!",
            variable_8: "Manage to invite a friend and get 2 generations for yourself and 2 generations for a friend for free",
            variable_9: "Invite",
            variable_10: "Difficulty",
            variable_11: "For newbies",
            variable_12: "Course time",
            variable_13: "Create course",
            variable_14: "Buy generation",
            variable_15: "Failed to generate course attempts",
            variable_16: "Plan of the education",
            variable_17: "Please wait, it will take some time to process your request...",
            variable_18: "Wait for plan generation to complete",
            variable_19: "Add skills ",
            variable_20: "Count lessons",
            variable_21: "Choice",
            variable_22: "Go to course",
            variable_23: "Top up",
            variable_24: "Number of plans",
            variable_25: "Number of courses",
            variable_26: "Copy link",
            variable_27: "Copy",
            variable_28: "Send a friend an invite",
            variable_29: "Favorite",
            variable_30: "List of training plans saved in favorites",
            variable_31: "Invite friends to receive 2 additional course generations. Your friends will also receive 2 course generations",
            variable_32: "Curl",
            variable_33: "Skillset",
            variable_34: "Select skill",
            variable_35: "Uncurl",
            variable_36: "Log-in",
            variable_37: "Share the course in messengers",
            variable_38: "Plan of the education",
        },
        courseManage: {
            variable_1: "Link successfully copied!",
            variable_2: "Cancel",
            variable_3: "Back",
            variable_4: "Courses",
            variable_5: "Academic performance",
            variable_6: "Add a lesson",
            variable_7: "Share",
            variable_8: "Class reorder not saved",
            variable_9: "Lesson",
            variable_10: "Are you sure you want to delete the lesson?",
            variable_11: "Yes",
            variable_12: "No",
            variable_13: "Category",
            variable_14: "list",
            variable_15: "Lesson deleted",
            variable_16: "Lesson added",
            variable_17: "All lesson",
            variable_18: "Incoming requests",
            variable_19: "Editing content",
            variable_20: "Course editing",
        },
        courseManageEditLesson: {
            variable_1: "Video edited!",
            variable_2: "Article edited!",
            variable_3: "Enter a title",
            variable_4: "Added video material",
            variable_5: "Material added",
            variable_6: "Job added successfully",
            variable_7: "Material removed",
            variable_8: "Class name changed",
            variable_9: "Video",
            variable_10: "Article",
            variable_11: "Task",
            variable_12: "Test",
            variable_13: "Are you sure you want to delete content?",
            variable_14: "Yes",
            variable_15: "No",
            variable_16: "Save the title of the activity",
            variable_17: "You can move sections",
            variable_18: "Add material",
            variable_19: "Material editing",
            variable_20: "View",
            variable_21: "Editing",
            variable_22: "Adding material",
            variable_23: "Type of educational material",
            variable_24: "Material title",
            variable_25: "Enter a title",
            variable_26: "Not entered",
            variable_27: "Demo video",
            variable_28: "Link to youtube",
            variable_29: "Your video",
            variable_30: "Provide a link",
            variable_31: "Description",
            variable_32: "Link",
            variable_33: "Save",
            variable_34: "cancel",
            variable_35: "Image",
            variable_36: "Task description",
            variable_37: "Links and description of sources",
            variable_38: "Enter test title",
            variable_39: "Add a question",
            variable_40: "Enter a question name",
            variable_41: "Response text not provided",
            variable_42: "Please indicate at least one correct answer",
            variable_43: "Quiz edited successfully",
            variable_44: "Test title",
            variable_45: "Questions and Answers",
            variable_46: "Question",
            variable_47: "Question Title",
            variable_48: "Option",
            variable_49: "Completion recommendations",
            variable_50: "No recommendations based on test results",
            variable_51: "Recommendations based on test results",
            variable_52: "For those who passed the quiz with the result:",
            variable_53: "Save test",
            variable_54: "The task has been successfully edited",
            variable_55: "Enter the name of the task",
            variable_56: "Task name",
            variable_57: "Enter the content of the task",
            variable_58: "Task сontent",
            variable_59: "Edit image",
            variable_60: "Edit file",
            variable_61: "Training",
            variable_62: "Control",
            variable_63: "Questions and Answers",
            variable_64: "Type",
            variable_65: "Number of attempts",
            variable_66: "Test title",
            variable_67: "Question",
            variable_68: "Question Title",
            variable_69: "Quiz added",
            variable_70: "Attach task file",
            variable_71: "Add",
            variable_72: "File",
            variable_73: "Homework for self-check.",
            variable_74: "Control homework.",
            variable_75: "Type of home building",
            variable_76: "Attach file",
            variable_77: "Programming",
            variable_78: "Material description",
            variable_79: "Enter material description",
            variable_80: "Task",
            variable_81: "Task description",
            variable_82: "Task",
            variable_83: "Text task",
            variable_84: "Programming",
            variable_85: "Enter title",
            variable_86: "Enter a task",
            variable_87: "Choose a programming language",
            variable_88: "Self-test homework does not block a student's access to other activities. After sending the task to the teacher for verification, the student can move on to the next material.",
            variable_89: "Control homework blocks the student's access to the following classes until the teacher accepts the current assignment.",
            variable_90: "To access the creation of a programming lesson, you need to connect the \"Assignments with programming\" module.",
            variable_91: "SCORM"
        },
        courseManageViewLesson: {
            variable_1: "View",
            variable_2: "Editing",
            variable_3: "Description",
            variable_4: "Links and description of sources",
            variable_5: "Comments",
            variable_6: "Write a comment",
            variable_7: "Comment has been deleted",
            variable_8: "Article",
            variable_9: "Links and sources",
            variable_10: "There are no questions! Delete material, or add new questions!",
            variable_11: "Unable to display file",
            variable_12: "Task description",
            variable_13: "Attached task",
            variable_14: "File",
            variable_15: "(for self-test)",
            variable_16: "(control)",
            variable_17: "Attached file",
            variable_18: "Demo video",
            variable_19: "Task",
            variable_20: "(control)",
            variable_21: "Programming language",
            variable_22: "Comment has been deleted."
        },
        authorization: {
            variable_1: "Incorrect login or password",
            variable_2: "Login",
            variable_3: "Mail",
            variable_4: "Enter your email",
            variable_5: "Password",
            variable_6: "Enter password",
            variable_7: "To remember me",
            variable_8: "Sign In",
            variable_9: "Create an account",
            variable_10: "Forgot your password?",
            variable_11: "A password recovery code has been sent to your email",
            variable_12: "Password changed successfully",
            variable_13: "Forgot your password? Enter the email address you used to register your account",
            variable_14: "Back",
            variable_15: "Request",
            variable_16: "Enter a new password",
            variable_17: "Confirm the password",
            variable_18: "Passwords do not match!",
            variable_19: "Save",
            variable_20: "Form of organization",
            variable_21: "Fill out the organization form, otherwise you will not be able to create courses!",
            variable_22: "Registration error",
            variable_23: "This account is already registered",
            variable_24: "This user already exists",
            variable_25: "Fill in the name of the organization",
            variable_26: "Fill out contact information",
            variable_27: "Saved",
            variable_28: "Registration",
            variable_29: "Email",
            variable_30: "Minimum 6 characters required",
            variable_31: "Confirm password",
            variable_32: "Confirm the password",
            variable_33: "I agree with ",
            variable_34: "privacy policy ",
            variable_35: "and ",
            variable_36: "contract of offer",
            variable_37: "Register",
            variable_38: "Already have an account?",
            variable_39: "Sign In",
            variable_40: "Organization",
            variable_41: "Name of company",
            variable_42: "Not entered",
            variable_43: "The name of your organization will be displayed in the course catalog",
            variable_44: "About me, Contact information",
            variable_45: "More about your organization, contact details, links",
            variable_46: "Receive payments through the Skillometer system",
            variable_47: "",
        },
        historyPay: {
            variable_1: "During all this time",
            variable_2: "For today",
            variable_3: "For yesterday",
            variable_4: "During the week",
            variable_5: "Per month",
            variable_6: "In a year",
            variable_7: "Search course",
            variable_8: "By creation date",
            variable_9: "By course id",
            variable_10: "By course name",
            variable_11: "By username",
            variable_12: "By transaction amount",
            variable_13: "By commission amount",
            variable_14: "No data",
            variable_15: "Purchases",
            variable_16: "No data",
            variable_17: "User name",
            variable_18: "Date and time of operation",
            variable_19: "Course name",
            variable_20: "Transaction amount (rub.)",
            variable_21: "MP commission (rub.)",
        },
        module: {
            variable_1: "Open module",
            variable_2: "Subscription:",
            variable_3: "Subscription is active until:",
            variable_4: "Go to course creation",
            variable_5: "Renew your subscription",
            variable_6: "Connect",
            variable_7: "₽ / Month",
            variable_8: "Modules",
            variable_9: "What does the module provide",

        },
        modalInfo: {
            variable_1: "Thank you for choosing the Skillometer Education Platform. We offer a modular subscription system for online schools. As soon as the first student enrolls in your course with uploaded materials, your subscription to the ",
            variable_2: "Go to modules",
            variable_3: "Pay for the module",
        },
        chatGptModal: {
            variable_1: "Create a learning plan for course generation",
            variable_2: "The system will help generate a step-by-step training plan and form text content for mastering the skill",
            variable_3: "What will we learn?",
            variable_4: "Choose a skill to create a content learning plan",
            variable_5: "Refresh",
            variable_6: "You can generate a learning plan for one skill up to 5 times and choose the best option for course generation.",
            variable_7: "Updated:",
            variable_8: "Please wait, it will take some time to process your request...",
            variable_9: "Save instruction",
            variable_10: "Add a course and go to learning",
            variable_11: "Pay",
            variable_12: "Failed to generate course attempts",
            variable_13: "Pay for course generation (left:",
            variable_14: "Count courses:",
            variable_15: "Total amount:",
            variable_16: "Choose the best option:",
            variable_17: "Go to course",
            variable_18: "Wait for plan generation to complete",
            variable_19: "Pay for course generation",
            variable_20: "Available ",
            variable_21: " generations course",
            variable_22: "Total price:",
            variable_23: "Price with discount",
            variable_24: "Minimum quantity for purchase: 5 generations",
        }

    },
    ru: {
        profile: {
            variable_1: "Фото профиля",
            variable_2: "Формат файла: jpg/jpeg, png",
            variable_3: "Разрешение: 220x220 - 900x900 px",
            variable_4: "Размер файла: до 10 мб",
            variable_5: "Заменить фото",
            variable_6: "ФИО",
            variable_7: "Ваш номер телефона",
            variable_8: "Россия",
            variable_9: "Беларусь",
            variable_10: "Казахстан",
            variable_11: "Азербайджан",
            variable_12: "Узбекистан",
            variable_13: "Грузия",
            variable_14: "Кыргызстан",
            variable_15: "Рассылка уведомлений на почту",
            variable_16: "Изменить",
            variable_17: "Организация",
            variable_18: "Наименование организации",
            variable_19: "Не введено",
            variable_20: "Название вашей организации будет отображаться в каталоге курсов",
            variable_21: "О себе, Контактная информация",
            variable_22: "Подробнее о вашей организации, контактные данные, ссылки",
            variable_23: "Получать платежи через систему Skillometer",
            variable_24: "Сохранить",
            variable_25: "Сохранить фото",
            variable_26: "Данные организации",
            variable_27: "Заполните наименование организации",
            variable_28: "Заполните контактную информацию",
            variable_29: "Изменения сохранены",
            variable_30: "Сохранено",
            variable_31: "Введите корректный e-mail",
            variable_32: "Укажите ФИО",
            variable_33: "Укажите номер телефона",
            variable_34: "Профиль успешно отредактирован",
            variable_35: "Сохранить данные для платежей",
            variable_36: "Полное наименование организации(например, Общество с ограниченной ответственностью \"Рога и копыта\")",
            variable_37: "Сокращенное наименование организации(например, ООО \"Рога и копыта\")",
            variable_38: "E-mail организации",
            variable_39: "Название магазина в СМС и на странице проверки 3DS на иностранном языке",
            variable_40: "ИНН",
            variable_41: "Укажите идентификационный номер налогоплательщика",
            variable_42: "КПП",
            variable_43: "Код причины постановки на учёт",
            variable_44: "ОГРН",
            variable_45: "Государственный регистрационный номер записи, вносимой в Единый  государственный реестр юридических  лиц/Государственный регистрационный номер записи,вносимой в Единый государственный реестр индивидуальных предпринимателей.",
            variable_46: "Расчетный счет",
            variable_47: "Расчетный счет организации в банке.",
            variable_48: "Корреспондентский счет",
            variable_49: "Корреспондентский счет организации в банке.",
            variable_50: "Наименование банка",
            variable_51: "Укажите название банка,в котором находится расчетный счет.",
            variable_52: "БИК",
            variable_53: "Банковский идентификационный код.",
            variable_54: "Сведения о руководителе",
            variable_55: "Имя",
            variable_56: "Фамилия",
            variable_57: "Отчество",
            variable_58: "Дата рождения",
            variable_59: "Телефон",
            variable_60: "Страна гражданства",
            variable_61: "Юридический адрес",
            variable_62: "Почтовый индекс",
            variable_63: "Страна",
            variable_64: "Населенный пункт",
            variable_65: "Улица, дом, корпус, квартира, офис",
            variable_66: "Фактический адрес",
            variable_67: "Январь",
            variable_68: "Февраль",
            variable_69: "Март",
            variable_70: "Апрель",
            variable_71: "Май",
            variable_72: "Июнь",
            variable_73: "Июль",
            variable_74: "Август",
            variable_75: "Сентябрь",
            variable_76: "Октябрь",
            variable_77: "Ноябрь",
            variable_78: "Декабрь",
            variable_79: "Сохранено",
            variable_80: "Ошибка сохранения",
            variable_81: "Заполните все поля.",
            variable_82: "ОГРН 13 символов",
            variable_83: "Расчетный счет 20 символов",
            variable_84: "Бик 9 символов",
            variable_85: "Корреспондентский счет 20 символов",
            variable_86: "Почтовый индекс 6 символов",
            variable_87: "Полное наименование (например, Индивидуальный предприниматель Иванов Иван Иванович)",
            variable_88: "Сокращенное наименование (например, ИП Иванов И.И.)",
            variable_89: "Заполните данные организации, чтобы подтвердить аккаунт",
            variable_90: "ИП",
            variable_91: "ОГРНИП",
            variable_92: "Заполните все поля фактического адреса.",
            variable_93: "Название магазина в СМС 14 символов. ",
            variable_94: "Аккаунт не подтвержден",
            variable_95: "Аккаунт подтвержден",
            variable_96: "ОГРНИП 15 символов",
        },
        searchProfession: {
            variables_1: 'Профессии',
            variables_2: 'IT профессии',
            variables_3: 'Все профессии',
            variables_4: 'Фильтры',
            variables_5: 'Избранное',
            variables_6: 'Поиск профессии',
            variables_7: 'Найти',
            variables_8: 'Учебный план',
            variables_9: 'Добавьте желаемый навык',
            variables_10: 'Создать курс',
            variables_11: 'Выберите для себя подходящую профессию',
            variables_12: 'Вакансии',
            variables_13: 'Описание профессии',
            variables_14: 'Популярные навыки',
            variables_15: 'Ключевые навыки, по созданным курсам',
            variables_16: 'Навыки которые могут вам подойти для создания курса',
            variables_17: 'Недостающие навыки отсутствуют для этой профессии',
            variables_18: 'Описание вакансии',
            variables_19: 'Требуемые навыки для трудоустройства',
            variables_20: {
                part_1: 'Ваши курсы полностью покрывают требования работодателя',
                part_2: 'Навыки которые могут вам подойти для создания курса'
            },
            variables_21: 'Зарплатные возможности в профессии ',
            variables_22: 'Перейти к вакансии',
            variables_23: 'Нет данных о требуемых навыках',
            variables_24: 'Назад к каталогу профессий',
            variables_25: 'Подробнее',
            variables_26: 'Перейти',
            variables_27: 'Показать Скиллсет',
            variables_28: 'Закрыть',
            variables_29: 'Профессии',
            variables_30: 'Категория',
            variables_31: 'Этот навык уже есть в Скиллсете',
            variables_32: 'Навык добавлен',
            variables_33: 'Укажите город/регион',
            variables_34: "Ключевые навыки, по созданным курсам",
            variables_35: "Подобрать курсы",
            variables_36: "Генерировать курс",
            variables_37: "Добавьте хотя бы один навык",
            variables_38: "инструкция",
            variables_39: "Навык добавлен",
            variables_40: "Обзор профессий",
            variables_41: "Генерация курса",
            variables_42: "Мои курсы",
        },
        modalProfessionInfo: {
            variables_1: 'Популярные навыки',
            variables_2: 'Курсы по востребованным скилам',
            variables_3: 'Если пройдете данные курсы, то ваш шанc попасть в более оплачиваемую компанию возрастает.',
            variables_4: 'Показать все',
        },
        modalCourse: {
            variable_1: 'Показать все',
            variable_2: 'Цене',
            variable_3: 'Дате',
            variable_4: 'Курсы по востребованным скилам',
            variable_5: 'Если пройдете данные курсы, то ваш шанc попасть в более оплачиваемую компанию возрастает',
            variable_6: 'Сортировать по',
            variable_7: 'Скиллсет'
        },
        favorites: {
            variable_1: 'Избранное',
            variable_2: 'Категория:',
            variable_3: 'Профессия',
            variable_4: 'Вакансия',
            variable_5: 'Перейти к вакансиям',
            variable_6: 'Популярные навыки',
            variable_7: 'Курсы по востребованным скилам',
            variable_8: 'Если пройдете данные курсы, то ваш шан попасть в более оплачиваемую компанию возрастает.',
            variable_9: 'Показать все',
            variable_10: 'Ваше соответсвие',
            variable_11: 'Текущие зарплатные возможности',
            variable_12: 'Перейти',
            variable_13: 'Подробнее',
            variable_14: 'Скрыть навыки'
        },
        filters: {
            variable_1: 'Фильтры',
            variable_2: 'Ключевые слова',
            variable_3: 'Искать только',
            variable_4: 'в названии компании',
            variable_5: 'в описании вакансии',
            variable_6: 'Список отраслей',
            variable_7: 'Указать отрасль',
            variable_8: 'Выбор отраслей',
            variable_9: 'Регион',
            variable_10: 'Добавить',
            variable_11: 'Уровень дохода',
            variable_12: 'от',
            variable_13: 'С указанием зп',
            variable_14: 'Показывать',
            variable_15: 'Требуемый опыт',
            variable_16: 'Нет опыта',
            variable_17: 'От 1 года до 3 лет',
            variable_18: 'От 3 до 6 лет',
            variable_19: 'Более 6 лет',
            variable_20: 'График работы',
            variable_21: 'Полный день',
            variable_22: 'Сменный график',
            variable_23: 'Гибкий график',
            variable_24: 'Удаленная работа',
            variable_25: 'Вахтовый метод',
            variable_26: 'Сортировка',
            variable_27: 'По дате',
            variable_28: 'По убыванию дохода',
            variable_29: 'По возрастанию дохода',
            variable_30: 'По соответствию',
            variable_31: 'По удалённости',
            variable_32: 'Выводить',
            variable_33: 'За всё время',
            variable_34: 'За месяц',
            variable_35: 'За неделю',
            variable_36: 'За последние три дня',
            variable_37: 'За сутки',
            variable_38: 'Подобрать вакансии'
        },
        navbar: {
            variable_1: "Вы действительно хотите выйти?",
            variable_2: "Да",
            variable_3: "Нет",
            variable_4: "Профиль",
            variable_5: "Список курсов",
            variable_6: "Уведомления",
            variable_7: "Выйти",
            variable_8: "Курсы",
            variable_9: "Входящие заявки",
            variable_10: "Успеваемость",
            variable_11: "История платежей",
            variable_12: "Модули",
            variable_13: "Учебный план",
            variable_14: "Сменить язык",
            variable_15: "Генератор курсов",
        },
        notification: {
            variable_1: "Уведомления",
            variable_2: "Новые",
            variable_3: "Все",
            variable_4: "Вопрос",
            variable_5: "Уведомление",
            variable_6: "Новое",
            variable_7: "Просмотрено",
            variable_8: "Ошибка получения уведомлений.",
            variable_9: "",
            variable_10: "",
            variable_11: "",
            variable_12: "",
            variable_13: "",
            variable_14: "",
            variable_15: "",
            variable_16: "",
            variable_17: "",
            variable_18: "",
            variable_19: "",
            variable_20: "",
            variable_21: "",
        },
        myCourse: {
            variable_1: "Отменить",
            variable_2: "Курсы",
            variable_3: "Входящие заявки",
            variable_4: "Успеваемость",
            variable_5: "Добавить обучающий курс",
            variable_6: "Поиск",
            variable_7: "Все категории",
            variable_8: "Категория",
            variable_9: "Найти",
            variable_10: "Ваш пробный период закончился",
            variable_11: "Ваш пробный период закончился, для доступа к вашему аккаунту заполните данные организации.",
            variable_12: "Подтвердить организацию",
            variable_13: "Пробный период:",
            variable_14: "д.",
            variable_15: "ч.",
            variable_16: "м.",
            variable_17: "c.",
            variable_18: "Если возник вопрос- пишите:",
            variable_19: "",
            variable_20: "",
            variable_21: "",
        },
        courseCard: {
            variable_1: "видео",
            variable_2: {
                part_1: "статья",
                part_2: "статьи",
                part_3: "статей"
            },
            variable_3: "д/з",
            variable_4: {
                part_1: "тест",
                part_2: "теста",
                part_3: "тестов"
            },
            variable_5: "Ключевые навыки",
            variable_6: "Стоимость:",
            variable_7: "Бесплатно",
            variable_8: "c",
            variable_9: "по",
            variable_10: "Ссылка успешно скопирована!",
            variable_11: "Инженерные & IT",
            variable_12: "Наука & Медицина",
            variable_13: "Языки & Копирайтинг",
            variable_14: "Менеджмент & Маркетинг",
            variable_15: "Финансы & Юриспруденция",
            variable_16: "Дизайн & Креатив",
            variable_17: "Спорт",
            variable_18: "Отображено в каталоге",
            variable_19: "Не отображено в каталоге",
            variable_20: "Корпоративный",
            variable_21: "Ссылка",
            variable_22: "Редактировать курс",
        },
        innerRequestMember: {
            variable_1: "За всё время",
            variable_2: "За неделю",
            variable_3: "За месяц",
            variable_4: "За год",
            variable_5: "Отклонить",
            variable_6: "Исключить",
            variable_7: "Группа",
            variable_8: "Принять",
            variable_9: "Перенести",
            variable_10: "Да",
            variable_11: "Нет",
            variable_12: "Все курсы",
            variable_13: "Отменить",
            variable_14: "Входящие заявки",
            variable_15: "Курсы",
            variable_16: "Успеваемость",
            variable_17: "Поиск ученика",
            variable_18: "Просмотры",
            variable_19: "Заявки",
            variable_20: "чел.",
            variable_21: "Завершают прохождение",
            variable_22: "Отзывы",
            variable_23: "Все участники",
            variable_24: "Отчисленные",
            variable_25: "Скрыть всю таблицу",
            variable_26: "Показать всю таблицу",
            variable_27: "Нет заявок",
            variable_28: "Нет отчисленных",
            variable_29: "Список",
            variable_30: "Нет участников",
            variable_31: "Поиск курса",
            variable_32: "Укажите причину исключения",
            variable_33: "Причина",
            variable_34: "Сообщение",
        },
        groupsList: {
            variable_1: "Принято",
            variable_2: "Отказано",
            variable_3: "Все занятия",
            variable_4: "Группы",
            variable_5: "Назад",
            variable_6: "Прогресс учеников",
            variable_7: "Журнал",
            variable_8: "Домашние задания",
            variable_9: "Результаты тестов",
            variable_10: "Курсы",
            variable_11: "Входящие заявки",
            variable_12: "Успеваемость",
            variable_13: "Открыть группу",
            variable_14: "Проходят курс",
            variable_15: "Близятся к завершению курса",
            variable_16: "Количество людей прошедших курс",
            variable_17: "Без сортировки",
            variable_18: "По успеваемости",
            variable_19: "Нет учеников",
            variable_20: "По фамилии",
            variable_21: "Ключевые навыки ученика",
            variable_22: "Занятие",
            variable_23: "Все",
            variable_24: "Тест",
            variable_25: "Тренировочный режим",
            variable_26: "Проверочный режим",
            variable_27: "Попыток:",
            variable_28: "Нет выполненных тестов",
            variable_29: "Нет выполненных тестов и домашних заданий",
            variable_30: "Нет выполненных домашних заданий",
            variable_31: "Нет выполненных тестов",
            variable_32: "На рассмотрении",
            variable_33: "Список",
            variable_34: "На рассмотрении",
            variable_35: "Занятия",
            variable_36: "Тесты",
            variable_37: "Выберите занятия",
            variable_38: "Результаты тестов",
            variable_39: "Выберите тест",
            variable_40: "Работы на проверку",
            variable_41: "Выберите домашние задания",
            variable_42: "Активные группы",
            variable_43: "Группы в архиве",
            variable_44: "активные",
            variable_45: "архивные",
            variable_46: "Перенести группу в архив?",
            variable_47: "Перенести в архив",
            variable_48: "Вернуть в активные группы?",
            variable_49: "Вернуть из архива",
            variable_50: "Выгрузить список учеников",

        },
        checkHomeWork: {
            variable_1: "Задание принято",
            variable_2: "Задание ушло на доработку",
            variable_3: "Название курса:",
            variable_4: "Занятие:",
            variable_5: "Задание:",
            variable_6: "Описание",
            variable_7: "Ссылки на источники",
            variable_8: "Ответ:",
            variable_9: "Принято",
            variable_10: "На рассмотрении",
            variable_11: "Отправлено на доработку",
            variable_12: "Время:",
            variable_13: "Ответ ученика:",
            variable_14: "Прикреплённый файл:",
            variable_15: "Принять",
            variable_16: "Отправить на доработку",
            variable_17: "Укажите ваши замечания, или результаты проверки. Не обязательно",
            variable_18: "Принять работу",
            variable_20: "Ваш ответ:",
            variable_21: "Комментарии",
            variable_22: "Оставьте комментарий",
            variable_23: "Задача",
            variable_24: "Видео-материал",
            variable_25: "Изображение",
        },
        checkTestWork: {
            variable_1: "Проверочный",
            variable_2: "Тренировочный",
            variable_3: "Название курса:",
            variable_4: "Занятие",
            variable_5: "Название теста:",
            variable_6: "Попыток:",
            variable_7: "Дата и время прохождения:",
            variable_8: "Вопрос:",
            variable_9: "ученика:",
            variable_10: "Ответ №",
            variable_11: "Тип теста:",
            variable_12: "",
        },
        currentCourse_step_1: {
            variable_1: "Редактирование курса",
            variable_2: "Создание курса",
            variable_3: "Вы действительно хотите удалить курс?",
            variable_4: "Да",
            variable_5: "Нет",
            variable_6: "Курс успешно удален",
            variable_7: "Курс опубликован",
            variable_8: "Курс сохранен",
            variable_9: "Ошибка редактирования курса",
            variable_10: "Ошибка",
            variable_11: "Карточка курса",
            variable_12: "Направление деятельности",
            variable_13: "Название курса",
            variable_14: "Краткое описание курса",
            variable_15: "Опишите чему человек сможет научиться",
            variable_16: "Демо-видео",
            variable_17: "Ссылка на youtube",
            variable_18: "Ваше видео",
            variable_19: "Youtube ссылка",
            variable_20: "Обложка",
            variable_21: "Дата начала и окончания",
            variable_22: "Продолжительность в академ часах",
            variable_23: "Лендинг",
            variable_24: "Навыки при прохождении курса ",
            variable_25: "Общий курс платный",
            variable_26: "Общий курс бесплатный",
            variable_27: "Корпоративный курс",
            variable_28: "Стоимость курса",
            variable_29: "Группы",
            variable_30: "Описание",
            variable_31: "Группа",
            variable_32: "Введите название группы",
            variable_33: "Добавить группу",
            variable_34: "Укажите новое название группы",
            variable_35: "Ваш номер телефона",
            variable_36: "Россия",
            variable_37: "Беларусь",
            variable_38: "Казахстан",
            variable_39: "",
            variable_40: "Азербайджан",
            variable_41: "Узбекистан",
            variable_42: "Грузия",
            variable_43: "Кыргызстан",
            variable_44: "Введите название курса",
            variable_45: "Введите количество академ часов",
            variable_46: "Добавьте навыки при прохождении курса",
            variable_47: "Укажите стоимость курса",
            variable_48: "Введите корректный e-mail",
            variable_49: "Обновите дату начала и окончания курса для публикации.",
            variable_50: "Инженерные & IT",
            variable_51: "Наука & Медицина",
            variable_52: "Языки & Копирайтинг",
            variable_53: "Менеджмент & Маркетинг",
            variable_54: "Финансы & Юриспруденция",
            variable_55: "Дизайн & Креатив",
            variable_56: "Спорт",
            variable_57: "Редактировать изображение",
            variable_58: "Файл успешно загружен!",
            variable_59: "Ошибка загрузки файла.",
            variable_60: "Сохранить",
            variable_61: "Отмена",
            variable_62: "Чтобы начать загрузку, выберите файл или перетащите его в это окно",
            variable_63: "Способы связи",
            variable_64: "Ограничение размера загружаемого файла до 500мб.",
            variable_65: 'Превышено количество символов у названия видео (255)',
            variable_66: "Выберите в какой области будет ваш курс. Когда ученик будет выбирать это направление он увидит вашу карточку.",
            variable_67: "Название должно быть коротким и информативным, например: “Python для начинающих”, “Основы деловой переписки” и т.д.",
            variable_68: "Очень важный для заполнения пункт. Этот текст будут внимательно читать посетители нашей платформы и он должен быть небольшим (3-5 предложений), при этом описывать все основные цели курса.",
            variable_69: "Изображение будет на обложке карточки, выбирайте высокое качество и ”продающее” изображение по вашей теме. Можно загрузить демо-видео, оно будет отображаться в превью карточки.",
            variable_70: "Именно в эти даты ваш курс будет отображаться в общем каталоге.",
            variable_71: "Начните вводить навык, а затем выберите нужный из предложенных. Это поможет заинтересованным студентам найти ваш курс по их образовательной траектории.",
            variable_72: "Вносите ваших студентов в разные группы, таким образом вам будет удобнее отслеживать их успеваемость, архивировать по окончании курса, а также группировать новые потоки студентов.",
            variable_73: "Слева",
            variable_74: "Справа",
            variable_75: "Отсутствует",
            variable_76: "Баннер",
            variable_77: "Содержание курса",
            variable_78: "Основная информация",
            variable_79: "Записаться",
            variable_80: "Изображение вашего курса в лендинге.",
            variable_81: "Ограничение размера загружаемого файла до 350мб.",
            variable_82: "Генерация описания курса",
            variable_83: "Поделиться",
        },
        currentCourse_step_2: {
            variable_1: "Спикеры",
            variable_2: "Спикер",
            variable_3: "Удалить спикера",
            variable_4: "Да",
            variable_5: "Нет",
            variable_6: "Загрузить фото",
            variable_7: "Имя",
            variable_8: "Фамилия",
            variable_9: "Занимаемая должность",
            variable_10: "Количество символов: 150",
            variable_11: "Количество символов: 1000",
            variable_12: "Описание",
            variable_13: "Укажите имя спикера",
            variable_14: "Укажите фамилию спикера",
            variable_15: "Укажите должность спикера",
            variable_16: "Сохранить спикера",
            variable_17: "Добавить спикера",
            variable_18: "Кому подойдет",
            variable_19: "Целевая аудитория",
            variable_20: "Опишите кому подойдет",
            variable_21: "Добавьте целевую аудиторию",
            variable_22: "Добавьте описание",
            variable_23: "Сохранить блок",
            variable_24: "Добавить блок",
            variable_25: "Чему научится человек",
            variable_26: "Навык",
            variable_27: "Добавьте навык",
            variable_28: "Опишите чему человек сможет научиться",
            variable_29: "Сохранить навык",
            variable_30: "Добавить навык",
            variable_31: "Назад",
            variable_32: "Сохранить курс",
            variable_33: "Предпросмотр",
            variable_34: "Не введено",
            variable_35: "Укажите всех спикеров курса и напишите пару предложений о них.",
            variable_36: "Тут можете написать 3-5 описаний по 1-2 предложения о том, кому может подойти ваш курс.",
            variable_37: "Опишите ключевые навыки, которые получат ученики.",
            variable_38: "",
            variable_39: "",
            variable_40: "",

        },
        currentCourse_step_3: {
            variable_1: "Лэндинг курса",
            variable_2: "Карточка курса",
            variable_3: "Назад",
            variable_4: "Опубликовать курс",
            variable_5: "Ключевые навыки",
            variable_6: "с",
            variable_7: "по",
            variable_8: "Стоимость:",
            variable_9: "Перейти к курсу",
            variable_10: "Инженерные & IT",
            variable_11: "Наука & Медицина",
            variable_12: "Языки & Копирайтинг",
            variable_13: "Менеджмент & Маркетинг",
            variable_14: "Финансы & Юриспруденция",
            variable_15: "Дизайн & Креатив",
            variable_16: "Спорт"
        },
        landing: {
            variable_1: 'Каталог курсов',
            variable_2: 'Спикеры',
            variable_3: 'О курсе',
            variable_4: 'Кому подойдёт',
            variable_5: 'Что получите',
            variable_6: 'Войти',
            variable_7: 'В каком формате проходит обучение',
            variable_8: 'Видео',
            variable_9: 'Изучаете теорию и практикуетесь на задачах, приближенных к реальным.',
            variable_10: 'Тест',
            variable_11: 'Решаете задачи после каждого модуля и получаете обратную связь от курирующего эксперта в течение 1–3 рабочих дней.',
            variable_12: 'Статья',
            variable_13: 'Изучайте текстовый материал в онлайн режиме с любого,  удобного для вас устройства.',
            variable_14: 'Домашнее задание',
            variable_15: 'Изучайте текстовый материал в онлайн режиме с любого,  удобного для вас устройства.',
            variable_16: 'Со спикером',
            variable_17: 'Проходите обучение с участием опытных спикеров.',
            variable_18: 'Кому подойдет',
            variable_19: 'Чему вы научитесь',
            variable_20: 'Отзывы участников',
            variable_21: 'Остались вопросы?',
            variable_22: 'Задайте свой вопрос и спикеры курса с удовольствием ответят на возникшие у вас вопросы!',
            variable_23: 'Имя',
            variable_24: 'Телефон',
            variable_25: 'Ваше сообщение',
            variable_26: 'Нажимая на кнопку, я соглашаюсь на обработку персональных данных и с правилами пользования Платформой',
            variable_27: 'Отправить',
            variable_28: 'Записаться на курс',
            variable_29: 'Question sent.',
            variable_30: 'Вы уже отправили вопрос.',
            variable_31: 'Введите имя',
            variable_32: 'Введите телефон',
            variable_33: 'Введите сообщение',
            variable_34: 'Вы уже подали заявку',
            variable_35: 'Заявка отправлена',
            variable_36: 'Произошла ошибка',
            variable_37: 'Вопрос отправлен',
            variable_38: 'Упс, а страница не найдена!',
            variable_39: 'Оплатить курс',
            variable_40: 'Перейти к курсу',
            variable_41: 'Назад в каталог',
            variable_42: 'Вы уже являетесь участником курса',
            variable_43: "Программирование",
            variable_44: "Выполняйте задания в онлайн режиме в любое время и с любого удобного вам устройства.",
            variable_45: "Учитесь и сразу применяйте ваши навыки на практике. Пишите код прямо на нашей платформе.",
            variable_46: "Ваше резюме после прохождения курса",
            variable_47: "Содержание курса",
            variable_48: "Материалы курса",
            variable_49: "Материалы курса",
            variable_50: "Поделиться",
        },
        generateCourse: {
            variable_1: "Генератор курсов",
            variable_2: "Выберите один из своих навыков, чтобы сгенерировать подходящий для вас курс",
            variable_3: "Увеличить",
            variable_4: "Уменьшить",
            variable_5: "Настройки генерации",
            variable_6: "Сгенерировать план обучения",
            variable_7: "Предложение ограничено, поторопись!",
            variable_8: "Успей пригласить друга и получи 2 генерации для себя и 2 генерации для друга бесплатно",
            variable_9: "Пригласить",
            variable_10: "Сложность",
            variable_11: "Для новичков",
            variable_12: "Время проходения",
            variable_13: "Создать курс",
            variable_14: "Приобрести генерацию",
            variable_15: "Закончились попытки генерации курса",
            variable_16: "План обучения по",
            variable_17: "Подождите, обработка запроса займёт некоторое время...",
            variable_18: "Дождитесь завершения генерации плана",
            variable_19: "Добавить навыки",
            variable_20: "Количество занятий",
            variable_21: "Вариант",
            variable_22: "Перейти к курсу",
            variable_23: "Пополнить",
            variable_24: "Количество планов",
            variable_25: "Количество курсов",
            variable_26: "Скопируй ссылку",
            variable_27: "Скопировать",
            variable_28: "Отправь другу приглашение",
            variable_29: "Избранное",
            variable_30: "Список планов по обучению сохранённый в избранном",
            variable_31: "Приглашай друзей, чтобы получить 2 дополнительных генераций курса. Твои друзья тоже получат 2 генерации курса",
            variable_32: "Свернуть",
            variable_33: "Скиллсет",
            variable_34: "Выберите навык",
            variable_35: "Развернуть",
            variable_36: "Войти",
            variable_37: "Поделись курсом в мессенджерах",
            variable_38: "План обучения",
        },
        courseManage: {
            variable_1: "Ссылка успешно скопирована!",
            variable_2: "Отменить",
            variable_3: "Назад",
            variable_4: "Курсы",
            variable_5: "Успеваемость",
            variable_6: "Добавить занятие",
            variable_7: "Поделиться",
            variable_8: "Изменение порядка занятий не сохранено",
            variable_9: "Занятие",
            variable_10: "Вы действительно хотите удалить занятие?",
            variable_11: "Да",
            variable_12: "Нет",
            variable_13: "Категория",
            variable_14: "список",
            variable_15: "Занятие удалено",
            variable_16: "Занятие добавлено",
            variable_17: "Все занятия",
            variable_18: "Входящие заявки",
            variable_19: "Редактирование материалов",
            variable_20: "Редактирование курса",
        },
        courseManageEditLesson: {
            variable_1: "Видео отредактировано!",
            variable_2: "Статья отредактирована!",
            variable_3: "Укажите заголовок",
            variable_4: "Добавлен видео-материал",
            variable_5: "Добавлен материал",
            variable_6: "Задание успешно добавлено",
            variable_7: "Материал удалён",
            variable_8: "Название занятия изменено",
            variable_9: "Видео",
            variable_10: "Статья",
            variable_11: "Задание",
            variable_12: "Тест",
            variable_13: "Вы действительно хотите удалить материал?",
            variable_14: "Да",
            variable_15: "Нет",
            variable_16: "Сохранить заголовок занятия",
            variable_17: "Можно перемещать разделы",
            variable_18: "Добавить материал",
            variable_19: "Редактирование материала",
            variable_20: "Просмотр",
            variable_21: "Редактирование",
            variable_22: "Добавление материала",
            variable_23: "Тип учебного материала",
            variable_24: "Заголовок материала",
            variable_25: "Укажите название",
            variable_26: "Не введено",
            variable_27: "Видео-материал",
            variable_28: "Ссылка на youtube",
            variable_29: "Ваше видео",
            variable_30: "Укажите ссылку",
            variable_31: "Описание",
            variable_32: "Ссылка",
            variable_33: "Сохранить",
            variable_34: "отмена",
            variable_35: "Изображение",
            variable_36: "Описание задания",
            variable_37: "Ссылки и описание источников",
            variable_38: "Введите заголовок теста",
            variable_39: "Добавьте вопрос",
            variable_40: "Введите название вопроса",
            variable_41: "Не указан текст ответа",
            variable_42: "Укажите хотя-бы один правильный ответ",
            variable_43: "Квиз отредактирован успешно",
            variable_44: "Заголовок теста",
            variable_45: "Вопросы и варианты ответов",
            variable_46: "Вопрос",
            variable_47: "Заголовок вопроса",
            variable_48: "Вариант",
            variable_49: "Рекомендации по завершению",
            variable_50: "Без рекомендаций по результатам теста",
            variable_51: "Рекомендации по результатам теста",
            variable_52: "Для тех кто прошел квиз с результатом:",
            variable_53: "Сохранить тест",
            variable_54: "Задание успешно отредактировано",
            variable_55: "Введите название задания",
            variable_56: "Название задания",
            variable_57: "Введите содержимое задания",
            variable_58: "Содержимое задания",
            variable_59: "Редактировать изображение",
            variable_60: "Редактировать файл",
            variable_61: "Тренировочный",
            variable_62: "Контрольный",
            variable_63: "Вопросы и варианты ответов",
            variable_64: "Тип",
            variable_65: "Кол-во попыток",
            variable_66: "Заголовок теста",
            variable_67: "Вопрос",
            variable_68: "Заголовок вопроса",
            variable_69: "Добавлен квиз",
            variable_70: "Прикрепить файл задания",
            variable_71: "Добавить",
            variable_72: "Файл",
            variable_73: "Домашнее задание для самопроверки.",
            variable_74: "Контрольное домашнее задание.",
            variable_75: "Тип домашнего задания",
            variable_76: "Прикрепить файл",
            variable_77: "Прораммирование",
            variable_78: "Описание материала",
            variable_79: "Введите описание материала",
            variable_80: "Задача",
            variable_81: "Описание задачи",
            variable_82: "Задание",
            variable_83: "Текстовое задание",
            variable_84: "Программирование",
            variable_85: "Введите заголовок",
            variable_86: "Введите задание",
            variable_87: "Выберите язык программирования",
            variable_88: "Домашнее задание для самопроверки не блокирует доступ у ученика к другим занятиям. Отправив на проверку учителю задание, ученик может перейти к следующему материалу.",
            variable_89: "Контрольное домашнее задание блокирует доступ ученика к следующим занятиям, пока учитель не примет текущее задание.",
            variable_90: "Чтобы получить доступ к созданию урока программирования, нужно подключить модуль \"Задания с программированием\".",
            variable_91: "SCORM"

        },
        courseManageViewLesson: {
            variable_1: "Просмотр",
            variable_2: "Редактирование",
            variable_3: "Описание",
            variable_4: "Ссылки и описание источников",
            variable_5: "Комментарии",
            variable_6: "Оставьте комментарий",
            variable_7: "Комментарий удалён.",
            variable_8: "Статья",
            variable_9: "Ссылки и источники",
            variable_10: "Отсутствуют вопросы! Удалите материал, или добавьте новые вопросы!",
            variable_11: "Не удалось отобразить файл",
            variable_12: "Описание задания",
            variable_13: "Прикрепленное задание",
            variable_14: "Файл",
            variable_15: "(для самопроверки)",
            variable_16: "(контрольное)",
            variable_17: "Прикреплённый файл",
            variable_18: "Видео-материал",
            variable_19: "Задача",
            variable_20: "(контрольная)",
            variable_21: "Язык программирования",
            variable_22: "Комментарий удалён."
        },
        authorization: {
            variable_1: "Неправильный логин, или пароль",
            variable_2: "Логин",
            variable_3: "Почта",
            variable_4: "Введите почту",
            variable_5: "Пароль",
            variable_6: "Введите пароль",
            variable_7: "Запомнить меня",
            variable_8: "Войти",
            variable_9: "Создать аккаунт",
            variable_10: "Забыли пароль?",
            variable_11: "На ваш Email отправлен код восстановления пароля",
            variable_12: "Пароль успешно изменён",
            variable_13: "Забыли пароль? Укажите email с которого вы регистрировали аккаунт",
            variable_14: "Назад",
            variable_15: "Отправить",
            variable_16: "Введите новый пароль",
            variable_17: "Подтвердите пароль",
            variable_18: "Пароли не совпадают!",
            variable_19: "Сохранить",
            variable_20: "Форма организации",
            variable_21: "Заполните форму организации, в ином случае, вы не сможете создавать курсы!",
            variable_22: "Ошибка регистрации",
            variable_23: "Данный аккаунт уже зарегистрирован",
            variable_24: "Данный пользователь уже существует",
            variable_25: "Заполните наименование организации",
            variable_26: "Заполните контакную информацию",
            variable_27: "Сохранено",
            variable_28: "Регистрация",
            variable_29: "Электронная почта",
            variable_30: "Требуется минимум 6 символов",
            variable_31: "Подтверждение пароля",
            variable_32: "Подтвердите пароль",
            variable_33: "Я соглашаюсь с ",
            variable_34: "политикой конфиденциальности ",
            variable_35: "и ",
            variable_36: "договором оферты",
            variable_37: "Зарегистрироваться",
            variable_38: "Уже есть аккаунт?",
            variable_39: "Войдите",
            variable_40: "Организация",
            variable_41: "Наименование организации",
            variable_42: "Не введено",
            variable_43: "Название вашей организации будет отображаться в каталоге курсов",
            variable_44: "О себе, Контактная информация",
            variable_45: "Подробнее о вашей организации, контактные данные, ссылки",
            variable_46: "Получать платежи через систему Skillometer",
            variable_47: "",
        },
        historyPay: {
            variable_1: "За всё время",
            variable_2: "За сегодня",
            variable_3: "За вчера",
            variable_4: "За неделю",
            variable_5: "За месяц",
            variable_6: "За год",
            variable_7: "Поиск курса",
            variable_8: "По дате создания",
            variable_9: "По id курса",
            variable_10: "По наименованию курса",
            variable_11: "По имени пользователя",
            variable_12: "По сумме операции",
            variable_13: "По размеру комиссии",
            variable_14: "Нет данных",
            variable_15: "Покупки",
            variable_16: "Нет данных",
            variable_17: "Фио пользователя",
            variable_18: "Дата и время операции",
            variable_19: "Наименование курса",
            variable_20: "Сумма операции (руб.)",
            variable_21: "Комиссия МП (руб.)",
        },
        module: {
            variable_1: "Открыть модуль",
            variable_2: "Подписка:",
            variable_3: "Подписка активна до:",
            variable_4: "Перейти к созданию курса",
            variable_5: "Продлить подписку",
            variable_6: "Подключить",
            variable_7: "₽ / Месяц",
            variable_8: "Модули",
            variable_9: "Что даёт модуль",
        },
        modalInfo: {
            variable_1: "Благодарим за выбор образовательной платформы Skillometer. Мы предлагаем модульную систему подписки для онлайн-школ. Как только первый ученик записывается на ваш курс с загруженными материалами, начинается ваша подписка на модуль ",
            variable_2: "Перейти к модулям",
            variable_3: "Оплатить модуль",
        },
        chatGptModal: {
            variable_1: "Создаем план обучения для генерации курса",
            variable_2: "Система поможет сгенерировать пошаговый план обучения и сформировать текстовый контент для освоения навыка",
            variable_3: "Что будем учить?",
            variable_4: "Выберите навык чтобы создать план обучения с контентом",
            variable_5: "Обновить",
            variable_6: "Вы можете сгенерировать план обучения по одному навыку не более 5 раз и выбрать лучший вариант для генерации курса.",
            variable_7: "Обновлено:",
            variable_8: "Подождите, обработка запроса займёт некоторое время...",
            variable_9: "Сохранить инструкцию",
            variable_10: "Добавить курс и перейти к обучению",
            variable_11: "Оплатить",
            variable_12: "Закончились попытки генерации курса",
            variable_13: "Оплатить генерацию курсов (осталось:",
            variable_14: "Количество курсов:",
            variable_15: "Итоговая сумма:",
            variable_16: "Выберите лучший вариант:",
            variable_17: "Перейти к курсу",
            variable_18: "Дождитесь завершения генерации плана",
            variable_19: "Оплатить генерацию курсов",
            variable_20: "Доступно ",
            variable_21: " генераций курса",
            variable_22: "Сумма:",
            variable_23: "Цена со скидкой",
            variable_24: "Минимальное количество для покупки: 5 генераций",

        }
    }
};
export default obj;