import React, { useEffect, useState } from "react";
import styled from "styled-components";
import requests from "../axios/requests";

import { message, Upload } from "antd";
import variableLanguage from "../utils/hooks/hookGetVariablesLanguage";
import { ReactComponent as Drag } from "../../images/icons/drag_drop.svg";

const HideSpinner = styled.div`
  .ant-upload-text-icon {
    visibility: hidden;
  }
`;
export const StorageUploader = (props) => {
    let {
        yandexKey, count, fileTypes, setFileName, firstRenderImage, setFileListProps = false,
        fileWillBeUpload = false, firstRenderFile = false, removeFile = false
    } = props;

    const variable_58 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_58"});
    const variable_59 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_59"});
    const variable_62 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_62"});
    const variable_64 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_64"});
    const variable_65 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_65"});

    const [fileList, setFileList] = useState(firstRenderImage || []);
    let customRequest = (file) => {
        if (file?.size / 1000 > 500000) {
            return message.warning(variable_64)
        }
        if (file?.name !== undefined && file.name.length > 244)
            return message.error(variable_65)
        setFileName && setFileName(file.name);

        requests.storage.storage_upload((yandexKey + (file.name ? "/" + file.name : "")).replace(/\s+/g, "_"), file, (progress) => {
            var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            setFileList([{
                uid: file.name,
                name: file.name,
                percent: percentCompleted,
                status: (percentCompleted === 100) ? "done" : "uploading",
            }]);
            if (typeof setFileListProps === 'function') {
                setFileListProps([{
                    uid: file.name,
                    name: file.name,
                    percent: percentCompleted,
                    status: (percentCompleted === 100) ? "done" : "uploading",
                }]);
            }
        }, fileTypes)
            .then(() => {
                message.success(variable_58);
                if (fileWillBeUpload) {
                    fileWillBeUpload(file?.name)
                }
            })
            .catch(() => {
                message.error(variable_59);
                setFileList([{
                    uid: file.name,
                    name: file.name,
                    percent: 0,
                    status: "error"
                }]);
            });
    };

    useEffect(() => {
        if (firstRenderImage && firstRenderImage.length > 0) {
            if (fileList.length === 0) {
                setFileList(firstRenderImage)
            } else {
                if (firstRenderImage[0]?.url !== fileList[0]?.url && firstRenderImage[0]?.name !== fileList[0]?.name)
                    setFileList(firstRenderImage)
            }
        }
        if (firstRenderFile)
            if (fileList.length === 0 && firstRenderFile.length > 0 && firstRenderFile[0].name)
                setFileList(firstRenderFile)
    }, [firstRenderImage, firstRenderFile])
    return (
        <div className={"upload_antd"}>
            <HideSpinner>
                <Upload.Dragger
                    listType="picture"
                    fileList={fileList}
                    accept={fileTypes}
                    maxCount={count}
                    defaultFileList={fileList}
                    beforeUpload={(file) => customRequest(file)}
                    onRemove={() => {
                        setFileList([])
                        if (removeFile) {
                            removeFile()
                        }
                    }}
                    progress={{
                        strokeColor: {
                            "0%": "#108ee9",
                            "100%": "#87d068"
                        },
                        strokeWidth: 3
                    }}
                >
                    <Drag/>
                    <div className={"text"}>{variable_62}</div>
                </Upload.Dragger>
            </HideSpinner>
        </div>
    );
};
