import React, { useEffect, useState } from 'react';
import ReactPlayer from "react-player";
import { defaultFuncAndData } from "../../../utils/defaultData";
import requests from "../../../axios/requests";

import { Image, Input, message, Upload } from "antd";
import CommentCustom from "../../../components/reuseComponents/CommentCustom";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

import send_comment from "../../../../images/icons/send_comment.png";

const Code = (props) => {
    let {text, image_file, url_refs, video_file, video_file_name, youtube, block_next, language, id} = props.taskProps;

    const variable_5 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_7"});
    const variable_9 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_9"});
    const variable_11 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_11"});
    const variable_15 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_15"});
    const variable_18 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_18"});
    const variable_19 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_19"});
    const variable_20 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_20"});
    const variable_21 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_21"});

    const [valueComment, setValueComment] = useState("");
    const [comments, setComments] = useState("");
    const [videoFile, setVideoFile] = useState("");
    const [videoFileName, setVideoFileName] = useState("");
    const languages = ["Python", "Java", "C++", "C#", "C", "Golang", "JavaScript"];

    let uploadProps = {
        onChange({file}) {
            if (file.status !== 'uploading') {
            }
        },
        listType: "card",
        fileList: [
            {
                uid: "-1",
                name: videoFileName,
                status: "done",
                response: props.variable_26,
                url: videoFile,

            }
        ],
        defaultFileList: [
            {
                uid: '1',
                name: videoFileName,
                status: 'done',
                response: variable_11, // custom error message to show
                url: videoFile,
                // thumbUrl: task_file,
            },
        ],
    };
    let isVideo = (fileName) => {
        const videoFormats = [
            ".WEBM", ".MPG", ".MP2", ".MPEG", ".MPE", ".MPV",
            ".OGG", ".MP4", ".M4P", ".M4V", ".AVI", ".WMV",
            ".MOV", ".QT", ".FLV", ".SWF", "AVCHD"
        ]
        return (videoFormats.some((format) => fileName?.toLowerCase().includes(format.toLowerCase())))
    }
    let getApiComments = () => {
        requests.comments.get_comments({
            contentType: 46,
            objectId: id
        })
            .then((res) => {
                setComments(res.data)
            })
            .catch((error) => console.log(error, "error get_comments"));
    };
    let sendComment = () => {
        if (!valueComment) {
            return;
        }
        let obj = {
            phone: props.profile.phone,
            user: {
                profile: {
                    avatar: props.profile.avatar || ''
                },
            },
            content_type: 46,
            object_id: id,
            text: valueComment
        }
        requests.comments.create_comment(obj)
            .then((res) => {
                setComments((prev) => [...prev, res.data])
            })
            .catch((error) => console.log(error, "error add_comment"));
        setValueComment("");
    };
    let handleSetLikeDislake = (id, value) => {
        let obj = {
            "content_type": 86,
            "object_id": id,
            "value": value
        }
        requests.comments.like_dislike(obj)
            .then((res) => {
                getApiComments();
            })
            .catch((error) => console.log(error, "error set_like"))
            .finally();
    };
    let handleRemoveComment = (id) => {
        requests.comments.remove_comment(id)
            .then(() => {
                getApiComments();
                message.success(variable_7)
            })
            .catch(err => console.error(err))
    }
    let displayCurrentLanguage = () => {
        switch (language) {
            case 'python':
                return 0;
            case 'java':
                return 1;
            case 'c':
                return 2;
            case 'c++':
                return 3;
            case 'c#':
                return 4;
            case 'golang':
                return 5;
            case 'js':
                return 6;
        }
    }

    useEffect(() => {
        getApiComments()
    }, [id])
    useEffect(() => {
        if (video_file_name) {
            setVideoFileName(video_file_name);
        }
        if (video_file) {
            setVideoFile(video_file);
        }
    }, [video_file_name, video_file]);
    return (
        <>
            <div className="code_block">
                {image_file && <Image src={image_file} style={{borderRadius: 20}}/>}
                <div className={'h4'} style={{marginTop: 5}}>
                    {variable_19}
                    <span style={{fontSize: 18, color: '#A2AACA', marginLeft: 10, verticalAlign: 'text-bottom'}}>
                    {block_next ? variable_20 : variable_15}
                </span>
                </div>
                <pre className="description">{defaultFuncAndData.linkChanger(text)}</pre>
                <div className={'h4'} style={{marginTop: 5}}>{variable_21}</div>
                <div>
                    <div className="text">{languages[displayCurrentLanguage()]}</div>
                </div>
                {youtube || video_file
                    ? <>
                        <div className={'h4'} style={{fontSize: 22, marginTop: 5, marginBottom: 10}}>{variable_18}</div>
                        {youtube
                            ? <div className='player-wrapper'>
                                <ReactPlayer
                                    controls
                                    className='react-player'
                                    url={youtube}
                                    width='100%'
                                    height='100%'
                                />
                            </div>
                            : <>
                                {isVideo(video_file_name) &&
                                    <div className='player-wrapper'>
                                        <ReactPlayer
                                            controls
                                            className='react-player'
                                            url={video_file}
                                            width='100%'
                                            height='100%'
                                        />
                                    </div>
                                }
                            </>
                        }
                        {video_file_name &&
                            <div className={"video_file-name"}>{video_file_name}</div>
                        }
                        {/*{!youtube &&
                            <Upload {...uploadProps} disabled></Upload>
                        }*/}
                    </>
                    : <></>
                }
                {url_refs &&
                    <>
                        <div className="url_title">{variable_9}</div>
                        <div className="url_ref">{defaultFuncAndData.linkChanger(url_refs)}</div>
                    </>
                }
            </div>
            {props?.show_comments &&
                <div style={{width: '100%', marginTop: 15}}>
                    {comments && comments.length > 0 &&
                        <div className="comment_title">
                            {variable_5}
                        </div>
                    }
                    {comments && comments.length > 0 &&
                        comments.map((com, indx) =>
                            <div key={indx}>
                                <CommentCustom
                                    {...com}
                                    setLike={(id) => handleSetLikeDislake(id, true)}
                                    setDislake={(id) => handleSetLikeDislake(id, false)}
                                    removeComment={handleRemoveComment}
                                />
                            </div>
                        )
                    }
                    <div className="comment_input">
                        <Input
                            placeholder={variable_6}
                            className={"input_classic"}
                            value={valueComment}
                            onChange={(e) => setValueComment(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) sendComment();
                            }}
                        />
                        <img src={send_comment} onClick={sendComment}/>
                    </div>
                </div>
            }
        </>

    );
};

export default Code;