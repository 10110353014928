import React from 'react';

import { ReactComponent as HomeWorkIcon } from "../../../../images/icons/home_work-mobile.svg";
import { ReactComponent as ProgressStudys } from "../../../../images/icons/progress_studys.svg";
import { ReactComponent as TestIcon } from "../../../../images/icons/test_icon-mobile.svg";
import { ReactComponent as JournalIcon } from "../../../../images/icons/journal_icon.svg";
import { ReactComponent as SettingsMobile } from "../../../../images/icons/settings_mobile.svg";
import { ReactComponent as Logo } from "../../../../images/icons/logo.svg";

const NavigationMobile = (props) => {
    let {
        click, flagOpenSearchMobile, setFlagOpenSettings,
        activePage, settingsHide = false
    } = props;
    return (
        <div className={flagOpenSearchMobile ? "top_filter-mobile hidden" : "top_filter-mobile"}>
            <div className="icon">
                <Logo
                    width={38}
                    height={38}
                    onClick={() => window.open("https://skillometer.ru/", 'blank')}
                />
            </div>
            <div className={"group_navigation-mobile"}>
                <div
                    className={activePage === 0 ? "active" : ""}
                    onClick={() => click(0)}
                >
                    <ProgressStudys/>
                </div>
                <div
                    className={activePage === 1 ? "active" : ""}
                    onClick={() => click(1)}
                >
                    <TestIcon/>
                </div>
                <div
                    className={activePage === 2 ? "active" : ""}
                    onClick={() => click(2)}
                >
                    <HomeWorkIcon/>
                </div>
                <div
                    className={activePage === 3 ? "active" : ""}
                    onClick={() => click(3)}
                >
                    <JournalIcon/>
                </div>
            </div>
            <SettingsMobile
                className="settings"
                onClick={() => setFlagOpenSettings(true)}
                style={settingsHide ? {opacity: 0} : {}}
            />
        </div>
    );
};

export default NavigationMobile;