import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setMyRegion } from "../../../../redux/actions/userActions";
import { setActiveProfession, setActiveVacancy } from "../../../../redux/actions/searchProfessionActions";
import cn from "classnames";

import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import { Select, Spin } from "antd";
import SkillBlockProfession from "../ReuseComponents/SkillBlockProfession";
import SkillBlockVacancy from "../ReuseComponents/SkillBlockVacancy";
import ParentBlockProgressVertical from "../Chart/ParentBlockProgressVertical";
import cities from "../../../data/hhcities.json";
import { defaultFuncAndData } from "../../../utils/defaultData";

import { ReactComponent as InfoIconB } from "../../../../images/search_profession/info_icon_b.svg";
import { ReactComponent as StarIconB } from "../../../../images/search_profession/star_icon_b.svg";
import { ReactComponent as Star } from "../../../../images/search_profession/nav_bar-star.svg";
import vacancies from "../../../../images/search_profession/vacancies_filter.svg";
import filter from "../../../../images/search_profession/filter_icon.svg";
import favorite from "../../../../images/search_profession/favorite_icon.svg";
import hh_icon from "../../../../images/search_profession/hh_icon.svg";
import trud_vsem_icon from "../../../../images/search_profession/trud_vsem-icon.svg";
import language_icon from "../../../../images/search_profession/language_icon.svg";

const VacancySection = (props) => {
    const {
        setFlagModalFilter, setFlagModalFavorite, flagModalFavorite, handleChangeFavoriteProfession,
        favoriteProfession, favoriteVacancy, heightTraektory, setFlagModalProfessionInfo, handleAddSkillTraektory,
        myAnimationHidden, myAnimationFlagMove, setCoordsTagAnimation, currentSkillAddTraektory, vacancycData,
        flagLoadingVacancy, handleChangeFavoriteVacancy, flagSteps, currentStep
    } = props;
    const dispatch = useDispatch();
    const variables_4 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_4" });
    const variables_5 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_5" });
    const variables_11 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_11" });
    const variables_12 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_12" });
    const variables_13 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_13" });
    const variables_18 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_18" });
    const variables_21 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_21" });
    const variables_22 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_22" });
    const variables_33 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_33" });

    const { activeProfession, activeVacancy } = useSelector(state => state.SearchProfession);
    const { userSkills } = useSelector(state => state.Reducer);
    const [allCities, setAllCities] = useState([]);
    const [scrollYVacancy, setScrollYVacancy] = useState(0);
    const [flagOpenCity, setFlagOpenCity] = useState(false);

    const vanaciesRef = useRef();
    const listVacancyRef = useRef();

    let getCities = () => {
        let result = [];
        for (let i = 0; i < cities.length; i++) {
            for (let j = 0; j < cities[i].areas.length; j++) {
                result = [...result, cities[i].areas[j].name];
                for (let c = 0; c < cities[i].areas[j].areas.length; c++) {
                    result = [...result, cities[i].areas[j].areas[c].name];
                }
            }
        }
        setAllCities(result);
    };
    let changeRegion = (value) => {
        localStorage.setItem("region", value);
        dispatch(setMyRegion(value))
    };
    let handleScroll = (event) => {
        if (event.currentTarget.scrollTop < 110) {
            setScrollYVacancy(0)
            if (vanaciesRef?.current && scrollYVacancy) {
                vanaciesRef.current.scroll({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        } else
            setScrollYVacancy(1)
    }
    let minHeightList = () => {
        // функция высчитывает высоту для списка вакансий (ul), что-бы всегда был скролл.
        let height = window.innerHeight - heightTraektory - 40;
        return height;
    };
    let calcActiveFavorite = () => {
        if (favoriteProfession && favoriteProfession.length > 0 && activeProfession !== null) {
            for (let i = 0; i < favoriteProfession.length; i++) {
                if (activeProfession.id === favoriteProfession[i].profession.id)
                    return true;
            }
        }
        return false;
    };

    useEffect(() => {
        return () => {
            dispatch(setActiveVacancy(null))
            dispatch(setActiveProfession(null))
        }
    }, [])
    return (
        <div>
            <div
                className={scrollYVacancy > 0
                    ? "vacancies_title-row hide"
                    : "vacancies_title-row"
                }
            >
                <div
                    className={scrollYVacancy > 0 ? "vacancies_title active" : "vacancies_title"}>
                    {(activeProfession !== null || activeVacancy)
                        ? <div className={"vacancies_title-text"}>
                            <img src={vacancies} />
                            <span>{variables_12}</span>
                        </div>
                        : <></>
                    }
                </div>
                <div className={scrollYVacancy > 0 ? "filters hide" : "filters"}>
                    <div
                        onClick={() => {
                            if (activeProfession)
                                setFlagModalFilter(true);
                        }}
                        className={activeProfession ? "filter" : "filter disabled"}
                    >
                        <div className="svg"><img src={filter} /></div>
                        <span>{variables_4}</span>
                    </div>
                    <div
                        className="favorites"
                        onClick={() => setFlagModalFavorite(!flagModalFavorite)}
                    >
                        <div className="svg"><img src={favorite} /></div>
                        <span>
                            {variables_5}
                            ({favoriteVacancy.length + favoriteProfession.length})
                        </span>
                    </div>
                    <div className="select_language">
                        <div className="img"><img src={language_icon} /></div>
                        <Select
                            className={flagOpenCity ? "regionSelect active" : "regionSelect"}
                            size="medium"
                            showSearch
                            showArrow={false}
                            placeholder={variables_33}
                            onChange={changeRegion}
                            defaultValue={localStorage.getItem("region")}
                            onDropdownVisibleChange={(e) => {
                                setFlagOpenCity(e);
                                if (e && allCities.length === 0)
                                    getCities();
                            }}
                            dropdownAlign={{
                                offset: [0, 0]
                            }}
                        >
                            <>
                                {allCities.length > 0 &&
                                    allCities.map((city, indx) =>
                                        <Select.Option
                                            key={indx}
                                            value={city}
                                        >
                                            {city}
                                        </Select.Option>
                                    )
                                }
                            </>
                            {/*    {language === "ru" &&
                                <>
                                    {allCities.length > 0 &&
                                        allCities.map((city, indx) =>
                                            <Select.Option
                                                key={indx}
                                                value={city}
                                            >
                                                {city}
                                            </Select.Option>
                                        )
                                    }
                                </>
                            }*/}
                            {/*  {language === "en" &&
                                <>
                                    {citiesEn.citiesEn.length > 0 &&
                                        citiesEn.citiesEn.map((city, indx) =>
                                            <Select.Option
                                                key={indx}
                                                value={city}
                                            >
                                                {city}
                                            </Select.Option>
                                        )
                                    }
                                </>
                            }*/}
                        </Select>
                    </div>
                </div>
            </div>
            <div
                className={cn(vacancycData && vacancycData.length === 7 && scrollYVacancy > 0
                    ? "middle_block active margin_list"
                    : scrollYVacancy > 0 ? "middle_block active" : "middle_block", {
                    z_index_0: flagSteps && (currentStep !== 1 && currentStep !== 2)
                })
                }
                style={scrollYVacancy > 0
                    ? { maxHeight: window.innerHeight - 60 - heightTraektory }
                    : { maxHeight: window.innerHeight - 95 - heightTraektory }
                }
            >
                <div
                    onScroll={handleScroll}
                    style={scrollYVacancy > 0
                        ? { maxHeight: window.innerHeight - 60 - heightTraektory }
                        : { maxHeight: window.innerHeight - 95 - heightTraektory }
                    }
                    className={cn("vacancies_block", {
                        "active": scrollYVacancy > 1,
                        "opacity": flagSteps && (currentStep !== 1)
                    })
                    }
                    ref={vanaciesRef}
                >
                    <ul
                        style={scrollYVacancy > 0
                            ? { minHeight: minHeightList() || "inherit" }
                            : {}
                        }
                        ref={listVacancyRef}
                    >
                        {vacancycData && vacancycData.length > 0 &&
                            vacancycData.map((elem, indx) =>
                                <li
                                    className={activeVacancy && activeVacancy.id === elem.id ? "active" : ""}
                                    key={`${indx}-`}
                                    onClick={() => {
                                        /*dispatch(setActiveVacancy(null))*/
                                        setTimeout(() => {
                                            dispatch(setActiveVacancy(elem))
                                        }, 300)
                                    }}
                                    id={elem.id}
                                >
                                    <div className="title_vacancy">
                                        <Star
                                            width={14}
                                            height={14}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleChangeFavoriteVacancy(elem)
                                            }}
                                            className={defaultFuncAndData.calcActiveFavoriteIcon(elem, favoriteVacancy) ? "active" : ""}
                                        />
                                        <div className="border" />
                                        <span>{elem.name}</span>
                                        <img
                                            onClick={() => window.open(elem.alternate_url, "blank")}
                                            width={23}
                                            height={elem.alternate_url.includes("hh.ru") ? 10 : 18}
                                            src={elem.alternate_url.includes("hh.ru") ? hh_icon : trud_vsem_icon}
                                        />
                                    </div>
                                    <div className="compensation">
                                        <span
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}
                                        >
                                            {elem.salary && elem.salary.from && !elem.salary.to ? "От " : ""}
                                            {elem.salary && !elem.salary.from && elem.salary.to ? "До " : ""}
                                            {elem.salary && elem.salary.from ? elem.salary.from : ""}
                                            {elem.salary && elem.salary.to ? " - " : ""}
                                            {elem.salary && elem.salary.to ? elem.salary.to : ""}
                                            {elem.salary && (elem.salary.to || elem.salary.from)
                                                ? <>
                                                    {elem.salary && elem.salary.currency === "RUR" ? " руб." : ""}
                                                    {elem.salary && elem.salary.currency === "USD" ? " $" : ""}
                                                </>
                                                : <></>
                                            }
                                        </span>
                                        <div className="border" />
                                        <span
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}
                                        >
                                            {elem.employer && elem.employer.name}
                                        </span>
                                    </div>
                                </li>
                            )}
                    </ul>
                    {flagLoadingVacancy
                        ? <Spin style={{
                            position: "absolute",
                            left: "50%",
                            top: 20,
                            transform: "translate(-50%, 0)"
                        }} />
                        : <></>
                    }
                </div>
                <div
                    style={scrollYVacancy > 0
                        ? { maxHeight: window.innerHeight - 40 - heightTraektory }
                        : { maxHeight: window.innerHeight - 95 - heightTraektory }
                    }
                    className={cn("vacancies_info-block", {
                        "active": scrollYVacancy > 0,
                        "hide_scroll": !activeVacancy && !activeProfession
                    })
                    }
                >
                    <div
                        className={cn("profession_section", {
                            "active": activeProfession && !activeVacancy,
                            "opacity": flagSteps && (currentStep !== 2)
                        })
                        }
                    >
                        <div className={"description"}>{variables_13}</div>
                        <div className="h1">
                            <span>{activeProfession && activeProfession.professionTitle}</span>
                        </div>
                        <div className="group_icons">
                            <div onClick={() => setFlagModalProfessionInfo(true)}>
                                <InfoIconB />
                            </div>
                            <div
                                className={calcActiveFavorite() ? "active" : ""}
                                onClick={() => handleChangeFavoriteProfession(activeProfession)}>
                                <StarIconB />
                            </div>
                        </div>
                        {/*{activeProfession &&
                                <div className="h2"><span>{variables_14}</span></div>
                            }*/}
                        <SkillBlockProfession
                            userSkills={userSkills || []}
                            professionSkillLeft={activeProfession?.extra || []}
                            addSkillTraektory={(skill) => handleAddSkillTraektory(skill, true)}
                            myAnimationHidden={myAnimationHidden}
                            myAnimationFlagMove={myAnimationFlagMove}
                            currentSkillAddTraektory={currentSkillAddTraektory}
                            needAnimation={true}
                            setCoordsTagAnimation={(e) => setCoordsTagAnimation(e)}
                            activeProfession={activeProfession}
                        />
                    </div>
                    <div  className={cn("vacancy_section", {
                            "active": activeVacancy,
                            "opacity": flagSteps,
                            "current_intro": flagSteps && (currentStep === 2)
                        })
                        }
                        >
                        <div className="description">{variables_18}</div>
                        <div className="h1">{activeVacancy?.name}</div>
                        <div className="group_icons">
                            <div
                                onClick={() => window.open(activeVacancy?.alternate_url, "blank")}
                            >
                                <img
                                    src={activeVacancy?.alternate_url?.includes("hh.ru") ? hh_icon : trud_vsem_icon} />
                            </div>
                            <div
                                className={defaultFuncAndData.calcActiveFavoriteIcon(activeVacancy, favoriteVacancy) ? "active" : ""}
                                onClick={() => handleChangeFavoriteVacancy(activeVacancy)}
                            >
                                <StarIconB />
                            </div>
                        </div>
                        {activeVacancy && activeVacancy?.skills?.length > 0 ?
                            <SkillBlockVacancy
                                userSkills={userSkills || []}
                                needSkillsThisVacansy={activeVacancy && activeVacancy.skills || []}
                                addSkillTraektory={(skill) => handleAddSkillTraektory(skill, true)}
                                setCoordsTagAnimation={(e) => setCoordsTagAnimation(e)}
                                needAnimation={true}
                            />
                            : <></>
                        }
                        <p>{variables_21} {activeProfession && activeProfession.professionTitle}</p>
                        <ParentBlockProgressVertical
                            allJobs={vacancycData}
                            setActiveVacancy={(elem) => dispatch(setActiveVacancy(elem))}
                            activeVacancy={activeVacancy}
                        />
                        <div
                            className="btn_go"
                            onClick={() => window.open(activeVacancy?.alternate_url, "blank")}
                        >
                            {variables_22}
                        </div>
                    </div>
                </div>
            </div>
            {activeProfession === null && !activeVacancy &&
                <div className="no_data">{variables_11}</div>
            }
        </div>
    );
};

export default VacancySection;