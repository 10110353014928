import React, { useEffect, useRef, useState } from 'react'
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

import ReactCrop, { centerCrop, makeAspectCrop, } from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'

function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

function ImageCrop({customRequest, imageSrcProps}) {
    const variable_25 = variableLanguage({keyPage: 'profile', keyVariable: 'variable_25'});

    const [imgSrc, setImgSrc] = useState('')
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(1)

    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)

    let onImageLoad = (e) => {
        if (aspect) {
            const {width, height} = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }
    let custom = (file) => {
        function dataURItoBlob(dataURI) {
            var byteString = atob(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], {type: mimeString});
        }
        const blob = dataURItoBlob(file.toDataURL('image/png'));
        const resultFile = new File([blob], "image");
        customRequest(resultFile);
    }
    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )
    useEffect(() => {
        setImgSrc(imageSrcProps)
    }, [imageSrcProps])
    return (
        <div className="edit_image">
            {imgSrc && (
                <>
                    <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => {
                            setCrop(percentCrop)
                        }}
                        onComplete={(c) => {
                            setCompletedCrop(c);
                        }}
                        aspect={aspect}
                    >
                        <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{transform: `scale(${scale}) rotate(${rotate}deg)`}}
                            onLoad={onImageLoad}
                        />
                    </ReactCrop>
                    <div className="group_btn">
                        <div
                            className="btn"
                             onClick={() => custom(previewCanvasRef.current)}
                        >
                            {variable_25}
                        </div>
                    </div>
                </>
            )}
            <div className={'canvas'}>
                {completedCrop && (
                    <canvas
                        ref={previewCanvasRef}
                        style={{}}
                    />
                )}
            </div>

        </div>
    )
}

export default ImageCrop