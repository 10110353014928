import React, { useEffect, useRef, useState } from "react";
import requests from "../../../axios/requests";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import InputCustom from "../../../components/reuseComponents/InputCustom";
import TextArea from "antd/es/input/TextArea";
import ImageCrop from "../ImageCorp/ImageCrop";
import { message, Modal, Popconfirm, Tooltip } from "antd";

import add_step from "../../../../images/icons/add_step.png";
import description_row from "../../../../images/icons/description_row.png";
import description_row2 from "../../../../images/icons/description_row2.png";
import edit_icon from "../../../../images/icons/edit_icon.png";
import remove_icon from "../../../../images/icons/remove_icon.png";
import fake_photos from "../../../../images/icons/fake_photos.png";
import close_modal from "../../../../images/icons/close_modal.svg";
import { ReactComponent as Arrow } from "../../../../images/arrow.svg";
import { ReactComponent as QuestionIcon } from "../../../../images/icons/question.svg";

const Step2 = (props) => {
    let {
        tagsToProps, setOriginalSpeakerProps, setOriginalSuitsProps,
        setOriginalLearnProps, setNextStep, speakers, suits, setArrForRemoveLearn, learn,
        setStep, setArrRemoveSpeaker, setArrRemoveSuits, setSaveCourseProps
    } = props;

    const variable_1 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_14"});
    const variable_15 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_17"});
    const variable_18 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_18"});
    const variable_19 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_19"});
    const variable_20 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_20"});
    const variable_21 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_21"});
    const variable_22 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_22"});
    const variable_23 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_23"});
    const variable_24 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_24"});
    const variable_25 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_25"});
    const variable_26 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_26"});
    const variable_27 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_27"});
    const variable_28 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_28"});
    const variable_29 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_29"});
    const variable_30 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_30"});
    const variable_31 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_31"});
    const variable_32 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_32"});
    const variable_33 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_33"});
    const variable_35 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_35"});
    const variable_36 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_36"});
    const variable_37 = variableLanguage({keyPage: "currentCourse_step_2", keyVariable: "variable_37"});

    const [currentEditSpeaker, setCurrentEditSpeaker] = useState(null);
    const [currentEditSuits, setCurrentEditSuits] = useState(null);
    const [currentEditLearn, setCurrentEditLearn] = useState(null);
    const [flagOpenDropDownTags, setFlagOpenDropDownTags] = useState(false);
    const [flagModalEditImage, setFlagModalEditImage] = useState(false);
    const [flagsShow, setFlagsShow] = useState({
        showTemplateSpeakers: false,
        showEditSpeakers: false,
        showTemplateWhoSuits: false,
        showEditWhoSuits: false,
        showTemplateWhatLearn: false,
        showEditWhatLearn: false,
    });

    const [imageSrcProps, setImageSrcProps] = useState("");
    const [render, setRender] = useState(1);
    const inputPhotoRef = useRef();
    const refSpeaker = useRef();

    const [templateSpeaker, setTemplateSpeaker] = useState({
        first_name: "",
        last_name: "",
        position: "",
        description: "",
        avatar: ""
    });
    const [originalSpeaker, setOriginalSpeaker] = useState([]);
    const [copySpeaker, setCopySpeaker] = useState({
        first_name: "",
        last_name: "",
        position: "",
        description: "",
        avatar: ""
    });

    const [templateSuits, setTemplateSuits] = useState({
        title: "",
        description: ""
    });
    const [originalSuits, setOriginalSuits] = useState([]);
    const [copySuits, setCopySuits] = useState({
        title: "",
        description: ""
    });

    const [templateLearn, setTemplateLearn] = useState({
        speciality_tag: {
            name: ""
        },
        description: ""
    });
    const [originalLearn, setOriginalLearn] = useState([]);
    const [copyLearn, setCopyLearn] = useState({
        speciality_tag: {
            name: ""
        },
        description: ""
    });

    let scrollIntoView = (ref) => {
        if (typeof ref?.current !== "undefined")
            ref.current.scrollIntoView({block: "center", behavior: "smooth"});
    };
    let onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                customRequest(event.target.files[0]);
                setImageSrcProps(reader.result?.toString() || "");
                setFlagModalEditImage(true);
            };
            reader.readAsDataURL(event.target.files[0]);

        }
    };
    let customRequest = (file, closeModal) => {
        let fileTypes = "image/*";
        requests.storage.get_yandex_quest_key()
            .then((v) => {
                requests.storage.storage_upload(v.data.key, file, (progress) => {
                    var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
                }, fileTypes)
                    .then((res) => {
                        setRender((prev) => prev + 1);
                        if (closeModal) {
                            setFlagModalEditImage(false);
                            setImageSrcProps("");
                        }
                        if (currentEditSpeaker !== null) {
                            return setCopySpeaker((prev) => ({
                                ...prev,
                                ["avatar"]: "https://storage.yandexcloud.net/skill-storage/" + v.data.key
                            }));
                        }
                        setTemplateSpeaker((prev) => ({
                            ...prev,
                            ["avatar"]: "https://storage.yandexcloud.net/skill-storage/" + v.data.key
                        }));
                        inputPhotoRef.current.value = "";
                    })
                    .catch((e) => {
                        console.log(e, "Ошибка загрузки файла.");
                    });
            });
    };
    let getCurrentTags = () => {
        let result = [];
        for (let i = 0; i < tagsToProps.length; i++) {
            let flag = false;
            for (let j = 0; j < originalLearn.length; j++) {
                if (originalLearn[j].speciality_tag.name === tagsToProps[i].name) {
                    flag = true;
                }
            }
            if (!flag) result = [...result, tagsToProps[i]];
        }
        return result;
    };
    let saveTemplateCurrentSpeaker = () => {
        // если шаблон для спикера заполнен - добавить его в originalSpeaker
        if (templateSpeaker.first_name && templateSpeaker.last_name && templateSpeaker.position) {
            setOriginalSpeaker(prev => [...prev, templateSpeaker]);
            setCurrentEditSpeaker(null);
            setTemplateSpeaker({
                first_name: "",
                last_name: "",
                position: "",
                description: ""
            });
            setTimeout(() => {
                setFlagsShow(prev => ({...prev, ["showTemplateSpeakers"]: false}));
                setFlagsShow(prev => ({...prev, ["showEditSpeakers"]: false}));
            }, 100);
        }
    };
    let saveTemplateCurrentWhoSuits = () => {
        // если шаблон для "кому подойдёт" заполнен - добавить его в OriginalSuits
        if (templateSuits.title && templateSuits.description) {
            setOriginalSuits(prev => [...prev, templateSuits]);
            setCurrentEditSuits(null);
            setTemplateSuits({
                title: "",
                description: ""
            });
            setTimeout(() => {
                setFlagsShow(prev => ({...prev, ["showTemplateWhoSuits"]: false}));
                setFlagsShow(prev => ({...prev, ["showEditWhoSuits"]: false}));
            }, 100);
        }
    };
    let saveTemplateCurrentLearn = () => {
        // если шаблон для "чему научится" заполнен - добавить его в OriginalLearn
        if (templateLearn?.speciality_tag?.name && templateLearn.description) {
            setOriginalLearn(prev => [...prev, templateLearn]);
            setCurrentEditLearn(null);
            setTemplateLearn({
                speciality_tag: {
                    name: ""
                },
                description: ""
            });
            setTimeout(() => {
                setFlagsShow(prev => ({...prev, ["showTemplateWhatLearn"]: false}));
                setFlagsShow(prev => ({...prev, ["showEditWhatLearn"]: false}));
            }, 100);
        }
    };

    useEffect(() => {
        setOriginalSpeakerProps(originalSpeaker);
    }, [originalSpeaker]);
    useEffect(() => {
        if (originalSuits && originalSuits.length > 0) {
            setOriginalSuitsProps(originalSuits);
        }
    }, [originalSuits]);
    useEffect(() => {
        if (originalLearn && originalLearn.length > 0) {
            setOriginalLearnProps(originalLearn);
        }
    }, [originalLearn]);
    useEffect(() => {
        if (speakers && speakers.length > 0)
            setOriginalSpeaker(speakers);
    }, [speakers]);
    useEffect(() => {
        if (suits && suits.length > 0)
            setOriginalSuits(suits);
    }, [suits]);
    useEffect(() => {
        if (learn && learn.length > 0)
            setOriginalLearn(learn);
    }, [learn]);
    return (
        <div className={"step_2"}>
            <div className="h2">
                {variable_1}
                <Tooltip
                    title={variable_35}
                    align={"top"}
                >
                    <QuestionIcon/>
                </Tooltip>
            </div>
            <div className="speaker_parent-block" ref={refSpeaker}>
                {originalSpeaker && originalSpeaker.length > 0 &&
                    <div className="right_col">
                        {originalSpeaker.map((elem, indx) =>
                            <>
                                <div className="description_row" key={indx}>
                                    <div className="title">{variable_2} {indx + 1}</div>
                                    <div
                                        className={
                                            currentEditSpeaker !== null && currentEditSpeaker === indx
                                                ? "description active"
                                                : "description"
                                        }
                                    >
                                        <img src={description_row}/>
                                        <div className="text_1">{elem.first_name} {elem.last_name}</div>
                                        <img src={description_row2}/>
                                        <div className="text_2">{elem.position}</div>
                                        <div className="group_icon">
                                            <img
                                                src={edit_icon}
                                                onClick={() => {
                                                    setCurrentEditSpeaker(indx);
                                                    let copy = JSON.parse(JSON.stringify(originalSpeaker[indx]));
                                                    setCopySpeaker(copy);
                                                    setImageSrcProps("");
                                                    setFlagsShow(prev => ({...prev, ["showEditSpeakers"]: true}));
                                                }}
                                            />
                                            <Popconfirm
                                                placement="bottom"
                                                title={`${variable_3} ${indx + 1}?`}
                                                okText={variable_4}
                                                cancelText={variable_5}
                                                onConfirm={() => {
                                                    let newArr = [...originalSpeaker];
                                                    newArr.splice(indx, 1);
                                                    setOriginalSpeaker(newArr);
                                                    setArrRemoveSpeaker(elem);
                                                }}>
                                                <img src={remove_icon}/>
                                            </Popconfirm>
                                        </div>
                                    </div>
                                </div>
                            </>)
                        }
                    </div>
                }
                {currentEditSpeaker === null &&
                    <div className={flagsShow.showTemplateSpeakers ? "left_col show" : "left_col"}>
                        <div className="photo_row">
                            <div className="photo">
                                <img
                                    key={templateSpeaker.avatar}
                                    src={templateSpeaker.avatar || fake_photos}
                                    style={{borderRadius: 20}}
                                />
                                <label className="btn_photo button_classic" htmlFor="photo2">{variable_6}</label>
                                <input
                                    style={{display: "none"}}
                                    ref={inputPhotoRef}
                                    id="photo2"
                                    type="file"
                                    accept="image/png, image/jpeg, image/svg"
                                    className={"input_photo"}
                                    onChange={(e) => onImageChange(e)}
                                />
                            </div>
                            <div className="fio">
                                <input style={{opacity: 0, position: "absolute", zIndex: -2}}/>
                                <InputCustom
                                    value={templateSpeaker.first_name}
                                    setValue={(e) => setTemplateSpeaker((prev) => ({...prev, ["first_name"]: e}))}
                                    title={variable_7}
                                    required={true}
                                />
                                <InputCustom
                                    value={templateSpeaker.last_name}
                                    setValue={(e) => setTemplateSpeaker((prev) => ({...prev, ["last_name"]: e}))}
                                    title={variable_8}
                                    required={true}
                                />
                                <InputCustom
                                    value={templateSpeaker.position}
                                    setValue={(e) => setTemplateSpeaker((prev) => ({...prev, ["position"]: e}))}
                                    title={variable_9}
                                    maxLen={150}
                                    placeholder={variable_10}
                                    required={true}
                                />
                            </div>
                        </div>
                        <>
                            <div style={{margin: "0 0 5px 15px", color: "#A2AACA"}}>{variable_12}</div>
                            <TextArea
                                placeholder={variable_11}
                                maxLength={1000}
                                className={"textarea_antd"}
                                value={templateSpeaker.description}
                                onChange={(e) => setTemplateSpeaker((prev) => ({
                                    ...prev,
                                    ["description"]: e.target.value
                                }))}
                                rows={4}
                            />
                        </>
                        <div
                            className=" btn_save"
                            onClick={() => {
                                if (!templateSpeaker.first_name) return message.error(variable_13);
                                if (!templateSpeaker.last_name) return message.error(variable_14);
                                if (!templateSpeaker.position) return message.error(variable_15);
                                setOriginalSpeaker((prev) => [...prev, templateSpeaker]);
                                setTemplateSpeaker({
                                    first_name: "",
                                    last_name: "",
                                    position: "",
                                    description: "",
                                    avatar: ""
                                });
                                setImageSrcProps("");
                                setFlagsShow(prev => ({...prev, ["showTemplateSpeakers"]: false}));
                            }}
                        >
                            <span>{variable_16}</span>
                        </div>
                    </div>
                }
                <div className={flagsShow.showEditSpeakers ? "left_col edit show" : "left_col edit"}>
                    <div className="photo_row">
                        <div className="photo">
                            <img
                                key={copySpeaker.avatar}
                                src={copySpeaker.avatar || fake_photos}
                                style={{borderRadius: 20}}
                            />
                            <label className="btn_photo button_classic" htmlFor="photo2">{variable_6}</label>
                            <input
                                style={{display: "none"}}
                                id="photo2"
                                type="file"
                                accept="image/png, image/jpeg, image/svg"
                                className={"input_photo"}
                                onChange={(e) => onImageChange(e)}
                            />
                        </div>
                        <div className="fio">
                            <input style={{opacity: 0, position: "absolute", zIndex: -2}}/>
                            <InputCustom
                                value={copySpeaker.first_name}
                                setValue={(e) => setCopySpeaker((prev) => ({...prev, ["first_name"]: e}))}
                                title={variable_7}
                                required={true}
                            />
                            <InputCustom
                                value={copySpeaker.last_name}
                                setValue={(e) => setCopySpeaker((prev) => ({...prev, ["last_name"]: e}))}
                                title={variable_8}
                                required={true}
                            />
                            <InputCustom
                                value={copySpeaker.position}
                                setValue={(e) => setCopySpeaker((prev) => ({...prev, ["position"]: e}))}
                                title={variable_9}
                                maxLen={150}
                                placeholder={variable_10}
                                required={true}
                            />
                        </div>
                    </div>
                    <>
                        <div style={{margin: "0 0 5px 15px", color: "#A2AACA"}}>{variable_12}</div>
                        <TextArea
                            placeholder={variable_11}
                            className={"textarea_antd"}
                            value={copySpeaker.description}
                            onChange={(e) => setCopySpeaker((prev) => ({...prev, ["description"]: e.target.value}))}
                            rows={4}
                            maxLength={1000}
                        />
                    </>
                    <>
                        <div
                            className="btn_save"
                            onClick={() => {
                                if (!copySpeaker.first_name) return message.error(variable_13);
                                if (!copySpeaker.last_name) return message.error(variable_14);
                                if (!copySpeaker.position) return message.error(variable_15);
                                let newArr = [...originalSpeaker];
                                newArr[currentEditSpeaker] = copySpeaker;
                                setOriginalSpeaker(newArr);
                                setCurrentEditSpeaker(null);
                                setImageSrcProps("");
                                setFlagsShow(prev => ({...prev, ["showEditSpeakers"]: false}));
                            }}
                        >
                            <span>{variable_16}</span>
                        </div>
                    </>
                </div>

                {!flagsShow.showTemplateSpeakers && !flagsShow.showEditSpeakers
                    ? <div
                        className="btn_add show_speaker"
                        onClick={() => setFlagsShow(prev => ({...prev, ["showTemplateSpeakers"]: true}))}
                    >
                        <img src={add_step}/>
                        <span>{variable_17}</span>
                    </div>
                    : <></>
                }
            </div>
            <div className="h2">
                {variable_18}
                <Tooltip
                    title={variable_36}
                    align={"top"}
                >
                    <QuestionIcon/>
                </Tooltip>
            </div>
            <div className="who_suit-parent">
                {originalSuits && originalSuits.length > 0 &&
                    <div className="who_suit-block">
                        {
                            originalSuits.map((elem, indx) =>
                                <div className="description_row" key={indx}>
                                    <div className="title">{variable_19} {indx + 1}</div>
                                    <div className="description">
                                        <img src={description_row}/>
                                        <div className="text_1">{elem.title}</div>
                                        <img src={description_row2}/>
                                        <div className="text_2">{elem.description}</div>
                                        {currentEditSuits === null &&
                                            <div className="group_icon">
                                                <img
                                                    src={edit_icon}
                                                    onClick={() => {
                                                        let copy = JSON.parse(JSON.stringify(originalSuits[indx]));
                                                        setCopySuits(copy);
                                                        setCurrentEditSuits(indx);
                                                        setTemplateSuits({
                                                            title: "",
                                                            description: ""
                                                        });
                                                        setFlagsShow(prev => ({...prev, ["showEditWhoSuits"]: true}));
                                                    }}
                                                />
                                                <img
                                                    onClick={() => {
                                                        let newArr = [...originalSuits];
                                                        newArr.splice(indx, 1);
                                                        setOriginalSuits(newArr);
                                                        setArrRemoveSuits(elem);
                                                    }}
                                                    src={remove_icon}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                }
                <div
                    className={flagsShow.showTemplateWhoSuits ? "who_suit-block template show" : "who_suit-block template"}>
                    {currentEditSuits === null &&
                        <>
                            <InputCustom
                                value={templateSuits.title}
                                setValue={(e) => setTemplateSuits((prev) => ({...prev, ["title"]: e}))}
                                title={variable_19}
                            />
                            <span style={{margin: "0 0 5px 15px", color: "#A2AACA"}}>Описание </span>
                            <TextArea
                                placeholder={variable_20}
                                className={"textarea_antd"}
                                value={templateSuits.description}
                                onChange={(e) => setTemplateSuits((prev) => ({
                                    ...prev,
                                    ["description"]: e.target.value
                                }))}
                            />
                            <div
                                className="btn_save"
                                onClick={() => {
                                    if (!templateSuits.title) return message.error(variable_21);
                                    if (!templateSuits.description) return message.error(variable_22);
                                    setOriginalSuits((prev) => [...prev, templateSuits]);
                                    setTemplateSuits({
                                        title: "",
                                        description: ""
                                    });
                                    setFlagsShow(prev => ({...prev, ["showTemplateWhoSuits"]: false}));
                                }}
                            >
                                <span>{variable_23}</span>
                            </div>
                        </>
                    }
                </div>
                <div
                    className={flagsShow.showEditWhoSuits ? "who_suit-block template show" : "who_suit-block template"}>
                    <>
                        <InputCustom
                            value={copySuits.title}
                            setValue={(e) => setCopySuits((prev) => ({...prev, ["title"]: e}))}
                            title={variable_19}
                            onBlur={() => {
                                let newArr = [...originalSuits];
                                newArr[currentEditSuits] = copySuits;
                                setOriginalSuits(newArr);
                                setCurrentEditSuits(null);
                                setCopySuits({
                                    title: "",
                                    description: ""
                                });
                                setFlagsShow(prev => ({...prev, ["showEditWhoSuits"]: false}));
                            }}
                        />
                        <span style={{margin: "0 0 5px 15px", color: "#A2AACA"}}>{variable_12}</span>
                        <TextArea
                            placeholder={variable_20}
                            className={"textarea_antd"}
                            value={copySuits.description}
                            onChange={(e) => setCopySuits((prev) => ({...prev, ["description"]: e.target.value}))}
                            onBlur={() => {
                                if (!copySuits.title) return message.error(variable_21);
                                if (!copySuits.description) return message.error(variable_22);
                                let newArr = [...originalSuits];
                                newArr[currentEditSuits] = copySuits;
                                setOriginalSuits(newArr);
                                setCurrentEditSuits(null);
                                setCopySuits({
                                    title: "",
                                    description: ""
                                });
                                setFlagsShow(prev => ({...prev, ["showEditWhoSuits"]: false}));
                            }}
                        />

                    </>
                </div>
                {!flagsShow.showEditWhoSuits && !flagsShow.showTemplateWhoSuits
                    ? <div
                        className="btn_add show_speaker"
                        onClick={() => setFlagsShow(prev => ({...prev, ["showTemplateWhoSuits"]: true}))}
                    >
                        <img src={add_step}/>
                        <span>{variable_24}</span>
                    </div>
                    : <></>
                }
            </div>
            <div className="h2">
                {variable_25}
                <Tooltip
                    title={variable_37}
                    align={"top"}
                >
                    <QuestionIcon/>
                </Tooltip>
            </div>
            {originalLearn && originalLearn.length > 0 &&
                <div className="what_learn-block">
                    {originalLearn.map((elem, indx) => {
                            return (
                                <div className="description_row" key={indx}>
                                    <div className="title" style={{}}>{variable_26} {indx + 1}</div>
                                    <div className="description">
                                        <img src={description_row}/>
                                        <div className="text_1">{elem.speciality_tag.name}</div>
                                        <img src={description_row2}/>
                                        <div className="text_2">{elem.description}</div>
                                        {currentEditLearn === null &&
                                            <div className="group_icon">
                                                <img
                                                    src={edit_icon}
                                                    onClick={() => {
                                                        let copy = JSON.parse(JSON.stringify(originalLearn[indx]));
                                                        setCopyLearn(copy);
                                                        setCurrentEditLearn(indx);
                                                        setTemplateLearn({
                                                            speciality_tag: {
                                                                name: ""
                                                            },
                                                            description: ""
                                                        });
                                                        setFlagsShow(prev => ({...prev, ["showEditWhatLearn"]: true}));
                                                    }}
                                                />
                                                <img
                                                    onClick={() => {
                                                        let newArr = [...originalLearn];
                                                        newArr.splice(indx, 1);
                                                        setOriginalLearn(newArr);
                                                        setArrForRemoveLearn(elem);
                                                    }}
                                                    src={remove_icon}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            );
                        }
                    )
                    }
                </div>
            }
            <div
                className={flagsShow.showTemplateWhatLearn
                    ? "what_learn-block template show"
                    : "what_learn-block template"
            }
            >
                <>
                    {getCurrentTags() && getCurrentTags().length > 0 &&
                        <>
                            <div style={{margin: "0px 0 5px 15px ", color: "#A2AACA"}}>{variable_26}</div>
                            <div
                                onClick={() => setFlagOpenDropDownTags(!flagOpenDropDownTags)}
                                className={flagOpenDropDownTags ? "drop_down-parent open" : "drop_down-parent"}
                                tabIndex={0}
                                onBlur={() => {
                                    setTimeout(() => {
                                        setFlagOpenDropDownTags(false)
                                    }, 100)
                                }}
                            >
                                <span> {templateLearn.speciality_tag.name}</span>
                                <Arrow/>
                                {!templateLearn.speciality_tag.name &&
                                    <div className="placeholder">{variable_27}</div>
                                }

                            </div>
                            <div className={flagOpenDropDownTags ? "drop_down open" : "drop_down"}>
                                {getCurrentTags().map((elem, indx) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setTemplateLearn((prev) => ({
                                                        ...prev,
                                                        ["speciality_tag"]: getCurrentTags()[indx]
                                                    }));
                                                    setTimeout(() => {
                                                        setFlagOpenDropDownTags(false);
                                                    }, 100)
                                                }}
                                                className={"item"}
                                                key={indx}
                                            >
                                                {elem.name}
                                            </div>
                                        );
                                    }
                                )}
                            </div>

                            <div style={{margin: "0px 0 5px 15px", color: "#A2AACA"}}>{variable_12}</div>
                            <TextArea
                                placeholder={variable_28}
                                className={"textarea_antd"}
                                value={templateLearn.description}
                                onChange={(e) => setTemplateLearn((prev) => ({
                                    ...prev,
                                    ["description"]: e.target.value
                                }))}
                            />
                            <div
                                className="btn_save"
                                onClick={() => {
                                    if (!templateLearn.description) return message.error(variable_22);
                                    setOriginalLearn((prev) => [...prev, templateLearn]);
                                    setTemplateLearn({
                                        speciality_tag: "",
                                        description: ""
                                    });
                                    setTimeout(() => {
                                        setFlagsShow(prev => ({...prev, ["showTemplateWhatLearn"]: false}));
                                    }, 100)
                                }}
                            >
                                <span>{variable_29}</span>
                            </div>
                        </>
                    }
                </>
            </div>
            <div
                className={flagsShow.showEditWhatLearn ? "what_learn-block template show" : "what_learn-block template"}
            >
                <div style={{margin: "0 0 5px 15px ", color: "#A2AACA"}}>{variable_12}</div>
                <div
                    onClick={() => {
                        setFlagOpenDropDownTags(!flagOpenDropDownTags);
                    }}
                    className={flagOpenDropDownTags ? "drop_down-parent open" : "drop_down-parent"}
                >
                    <span> {copyLearn.speciality_tag.name}</span>
                    <Arrow/>
                </div>
                <div className={flagOpenDropDownTags ? "drop_down open" : "drop_down"}>
                    {getCurrentTags().map((elem, indx) =>
                        <div
                            onClick={() => {
                                setFlagOpenDropDownTags(false);
                                let newArr = [...originalLearn];
                                copyLearn["speciality_tag"] = elem;
                                newArr[currentEditLearn] = copyLearn;
                                setOriginalLearn(newArr);

                                setCopyLearn({
                                    speciality_tag: {
                                        name: ""
                                    },
                                    description: ""
                                });
                                setTimeout(() => {
                                    setCurrentEditLearn(null);
                                    setFlagsShow(prev => ({...prev, ["showEditWhatLearn"]: false}));
                                }, 100)
                            }}
                            className={"item"}
                            key={indx}
                        >{elem.name}</div>
                    )}
                </div>
                <div style={{margin: "5px 0 5px 15px", color: "#A2AACA"}}>{variable_12}</div>
                <TextArea
                    placeholder={variable_28}
                    className={"textarea_antd"}
                    value={copyLearn.description}
                    onChange={(e) => setCopyLearn((prev) => ({...prev, ["description"]: e.target.value}))}
                    onBlur={() => {
                        if (!copyLearn.description) return message.error(variable_22);
                        let newArr = [...originalLearn];
                        newArr[currentEditLearn] = copyLearn;
                        setOriginalLearn(newArr);
                        setCurrentEditLearn(null);
                        setCopyLearn({
                            speciality_tag: {
                                name: ""
                            },
                            description: ""
                        });
                        setFlagsShow(prev => ({...prev, ["showEditWhatLearn"]: false}));
                    }}
                />
            </div>
            {!flagsShow.showEditWhatLearn && !flagsShow.showTemplateWhatLearn && getCurrentTags() && getCurrentTags().length > 0
                ? <div
                    className="btn_add show_speaker"
                    onClick={() => setFlagsShow(prev => ({...prev, ["showTemplateWhatLearn"]: true}))}
                    style={{marginBottom: 15}}
                >
                    <img src={add_step}/>
                    <span>{variable_30}</span>
                </div>
                : <></>
            }
            <div className={"group_bottom-btn"} style={{display: "flex", maxWidth: 580, width: "100%"}}>
                <div
                    onClick={() => {
                        saveTemplateCurrentWhoSuits();
                        saveTemplateCurrentSpeaker();
                        saveTemplateCurrentLearn();
                        setTimeout(() => {
                            setStep();
                        }, 100);
                    }}
                    className="btn_prev"
                >
                    {variable_31}
                </div>
                <div
                    onClick={() => {
                        if (templateSpeaker.first_name && templateSpeaker.last_name && templateSpeaker.position) {
                            return setTimeout(() => {
                                setSaveCourseProps(templateSpeaker, "speaker");
                                saveTemplateCurrentSpeaker();
                            }, 100);
                        }
                        if (templateSuits.title && templateSuits.description) {
                            return setTimeout(() => {
                                setSaveCourseProps(templateSuits, "suits");
                                saveTemplateCurrentWhoSuits();
                            }, 100);
                        }
                        if (templateLearn?.speciality_tag?.name && templateLearn.description) {
                            return setTimeout(() => {
                                setSaveCourseProps(templateLearn, "learn");
                                saveTemplateCurrentLearn();
                            }, 100);
                        }
                        setSaveCourseProps();
                    }}
                    className="btn_save-course button_classic"
                >
                    {variable_32}
                </div>
                <div
                    className="btn_preview button_classic"
                    onClick={() => {
                        saveTemplateCurrentSpeaker();
                        saveTemplateCurrentWhoSuits();
                        saveTemplateCurrentLearn();
                        setTimeout(() => {
                            setNextStep();
                        }, 100);

                    }}
                >{variable_33}
                </div>
            </div>
            <Modal
                visible={flagModalEditImage}
                footer={null}
                className={"modal_edit-image"}
                closable={true}
                closeIcon={<div><img src={close_modal} alt=""/></div>}
                onCancel={() => setFlagModalEditImage(false)}
                maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                bodyStyle={{padding: 50, backgroundColor: "#343843", borderRadius: 35}}
            >
                <ImageCrop
                    customRequest={(value) => customRequest(value, true)}
                    imageSrcProps={imageSrcProps}
                />
            </Modal>
            <div className="render" style={{position: "fixed", opacity: 0, zIndex: -1}}>{render}</div>
        </div>
    );
};

export default Step2;
