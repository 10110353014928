import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import requests from "../../../axios/requests";
import moment from "moment";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { defaultFuncAndData } from "../../../utils/defaultData";
import { setUserTrajectory } from "../../../../redux/actions/userActions";

import InputCustom from "../../../components/reuseComponents/InputCustom";
import TextArea from "antd/es/input/TextArea";
import { StorageUploader } from "../../../data/StorageUploader";
import { DatePicker, Input, message, Modal, Spin, Tooltip, Upload, } from "antd";
import ImageCropBanner from "../ImageCorp/ImageCropBanner";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import CourseCard from "../../../components/CourseCard/CourseCard";

import { ReactComponent as Arrow } from "../../../../images/arrow.svg";
import { ReactComponent as QuestionIcon } from "../../../../images/icons/question.svg";
import close_tag from "../../../../images/icons/close_tag.png";
import it_icon from "../../../../images/icons/it_icon.png";
import medical_icon from "../../../../images/icons/medical_icon.png";
import languages_icon from "../../../../images/icons/languages_icon.png";
import menegement_icon from "../../../../images/icons/menegement_icon.png";
import finance_icon from "../../../../images/icons/finance_icon.png";
import design_icon from "../../../../images/icons/design_icon.png";
import sport_icon from "../../../../images/icons/sport_icon.png";
import description_row from "../../../../images/icons/description_row.png";
import edit_icon from "../../../../images/icons/edit_icon.png";
import remove_icon from "../../../../images/icons/remove_icon.png";
import add_step from "../../../../images/icons/add_step.png";
import rus from "../../../../images/country/russian_icon.png";
import belarus from "../../../../images/country/belarus_icon.png";
import kazahstan from "../../../../images/country/kazahstan_icon.png";
import azer from "../../../../images/country/az.png";
import uz from "../../../../images/country/uz.png";
import gr from "../../../../images/country/gr.png";
import kr from "../../../../images/country/kr.png";
import close_modal from "../../../../images/icons/close_modal.svg";
import upload from '../../../../images/icons/upload.svg';
import academic_plan from '../../../../images/icons/academic_plan_step1.svg';
import add_skill_step1 from '../../../../images/icons/add_skill_step1.svg';
import default_course from "../../../../images/icons/default_course.png";
import Button from "../../../components/reuseComponents/Button/Button";
import CustomCheckbox from "../../../components/reuseComponents/CustomCheckbox";
import cn from "classnames";
import Tag from "../../../components/Tag/Tag";

const Step1 = (props) => {
    let {
        setTagsToProps, setNameProps, setAboutProps, setDemoVideoName,
        setLinkYoutubeProps, setCourseImage, setEmailFeedback, setPhoneFeedback,
        setNextStep, setActiveSpecialId, setDateBegin, setDateEnd, setCorporate,
        setCostCourseProps, setAcademHourseProps, setGroupProps, setArrForRemoveGroup,
        setLinkDemoFileProps, yandexKeyVideoPreview, setCourseImageName, setDisabledComments,
        setBanner, setBannerPosition, setContentDescription, fetchCardImageKey, setAccessAfterBegin
    } = props;
    let {
        academicHours, dateBegin, dateEnd, demo_video, demo_video_name, demo_youtube,
        description, name, participateprice, participatetype, phone, speciality,
        speciality_tag, isCorporate, groups, email, y_image_name, y_image, banner,
        banner_name, banner_position, content_description, access_after_begin, show_comments
    } = props.courseData;
    const {RangePicker} = DatePicker;
    const location = useLocation();
    const dispatch = useDispatch();
    const {language} = useSelector(state => state.Reducer);

    const variable_11 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_14"});
    const variable_15 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_17"});
    const variable_18 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_18"});
    const variable_19 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_19"});
    const variable_20 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_20"});
    const variable_21 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_21"});
    const variable_22 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_22"});
    const variable_23 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_23"});
    const variable_24 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_24"});
    const variable_25 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_25"});
    const variable_26 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_26"});
    const variable_27 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_27"});
    const variable_28 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_28"});
    const variable_29 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_29"});
    const variable_30 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_30"});
    const variable_31 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_31"});
    const variable_32 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_32"});
    const variable_33 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_33"});
    const variable_34 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_34"});
    const variable_35 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_35"});
    const variable_36 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_36"});
    const variable_37 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_37"});
    const variable_38 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_38"});
    const variable_40 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_40"});
    const variable_41 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_41"});
    const variable_42 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_42"});
    const variable_43 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_43"});
    const variable_44 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_44"});
    const variable_45 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_45"});
    const variable_46 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_46"});
    const variable_47 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_47"});
    const variable_48 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_48"});
    const variable_49 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_49"});
    const variable_50 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_50"});
    const variable_51 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_51"});
    const variable_52 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_52"});
    const variable_53 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_53"});
    const variable_54 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_54"});
    const variable_55 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_55"});
    const variable_56 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_56"});
    const variable_62 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_62"});
    const variable_63 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_63"});
    const variable_66 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_66"});
    const variable_67 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_67"});
    const variable_68 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_68"});
    const variable_69 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_69"});
    const variable_70 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_70"});
    const variable_71 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_71"});
    const variable_72 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_72"});
    const variable_73 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_73"});
    const variable_74 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_74"});
    const variable_75 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_75"});
    const variable_76 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_76"});
    const variable_77 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_77"});
    const variable_78 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_78"});
    const variable_79 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_79"});
    const variable_80 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_80"});
    const variable_83 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_83"});

    const {activeModules} = useSelector(state => state.Reducer);
    const {trajectorySkills} = useSelector(state => state.Reducer);

    const [activeBasicRow, setActiveBasicRow] = useState(0);
    const [activeSlidePreview, setActiveSlidePreview] = useState(0);
    const [activeMaskPhone, setActiveMaskPhone] = useState(0);
    const [valueInputTag, setValueInputTag] = useState("");
    const [fileList, setFileList] = useState("");
    const [activeDemoRow, setActiveDemoRow] = useState(0);
    const [existTagsList, setExistTagsList] = useState([]);
    const [currentEditGroup, setCurrentEditGroup] = useState(null);
    const [dateBeginValue, setDateBeginValue] = useState("");
    const [dateEndValue, setDateEndValue] = useState("");
    const [imageSrcProps, setImageSrcProps] = useState("");
    const [imageSrcPropsCard, setImageSrcPropsCard] = useState("");
    const [nameImageCropFile, setNameImageCropFile] = useState("");
    const [nameImageCropFileCard, setNameImageCropFileCard] = useState("");
    const [fileListCrop, setFileListCrop] = useState([]);
    const [fileListCropCard, setFileListCropCard] = useState([]);
    const [valueGpt, setValueGpt] = useState("");
    const [resultGpt, setResultGpt] = useState("");
    const [extraSkills, setExtraSkills] = useState([]);

    const [templateGroup, setTemplateGroup] = useState({
        name: ""
    });
    const [originalGroup, setOriginalGroup] = useState(groups || []);
    const [copyGroup, setCopyGroup] = useState({
        name: ""
    });

    const [flagOpenSelectTags, setFlagOpenSelectTags] = useState(false);
    const [flagsDropdownPhone, setFlagsDropdownPhone] = useState(false);
    const [flagDropDownActivity, setFlagDropDownActivity] = useState(false);
    const [flagDayDropDown, setFlagDayDropDown] = useState(false);
    const [flagMonthDropDown, setFlagMonthDropDown] = useState(false);
    const [flagDayDropDownEnd, setFlagDayDropDownEnd] = useState(false);
    const [flagMonthDropDownEnd, setFlagMonthDropDownEnd] = useState(false);
    const [flagDropDownTimerDay, setFlagDropDownTimerDay] = useState(false);
    const [flagDropDownTimerHours, setFlagDropDownTimerHours] = useState(false);
    const [flagModalDate, setFlagModalDate] = useState(false);
    const [flagModalEditImage, setFlagModalEditImage] = useState(false);
    const [flagModalEditImageCard, setFlagModalEditImageCard] = useState(false);
    const [keyImage, setKeyImage] = useState(0);
    const [loadingGpt, setLoadingGpt] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [timeoutSearch, setTimeoutSearch] = useState(null);
    const [timeoutFetchPagination, setTimeoutFetchPagination] = useState(null);
    const [loading, setLoading] = useState(false);

    const arrMaskPhone = [
        "+7 (999) 999-99-99",
        "+375 (99) 999-99-99",
        "+7 (999) 999-99-99",
        "+\\9\\9\\4 (99) 999-99-99",
        "+\\9\\9\\8 (99) 999-99-99",
        "+\\9\\9\\5 (999) 999-99-99",
        "+\\9\\9\\6 (999) 99-99-99",
    ];
    const arrIconsCountry = [
        <div className="icon_country"><img src={rus}/></div>,
        <div className="icon_country"><img src={belarus}/></div>,
        <div className="icon_country"><img src={kazahstan}/></div>,
        <div className="icon_country"><img src={azer}/></div>,
        <div className="icon_country"><img src={uz}/></div>,
        <div className="icon_country"><img src={gr}/></div>,
        <div className="icon_country"><img src={kr}/></div>,
    ];
    const arrActivity = [
        <div>
            <img src={sport_icon}/>
            <span>{variable_56}</span>
        </div>,
        <div>
            <img src={medical_icon}/>
            <span>{variable_51}</span>
        </div>,
        <div>
            <img src={menegement_icon}/>
            <span>{variable_53}</span>
        </div>,
        <div>
            <img src={it_icon}/>
            <span>{variable_50}</span>
        </div>,
        <div>
            <img src={languages_icon}/>
            <span>{variable_52}</span>
        </div>,
        <div>
            <img src={design_icon}/>
            <span>{variable_55}</span>
        </div>,
        <div>
            <img src={finance_icon}/>
            <span>{variable_54}</span>
        </div>
    ];
    const basicInfoBlockRef = useRef();
    const inputPhotoRef = useRef();

    let activeColorList = () => {
        switch (speciality) {
            case 4:
                return "#8146FF";
            case 2:
                return "#3077FF";
            case 5:
                return "#30E0A1";
            case 3:
                return "#FFD23B";
            case 7:
                return "#FF47ED";
            case 6:
                return "#FF3434";
            case 1:
                return "#FF5937";
            default:
                return "grey";
        }
    };
    let fetchSearchTags = (page_size) => {
        setLoading(true);
        requests.profs.get_speciality_tags(valueInputTag, 1, page_size || pageSize)
            .then((v) => setExistTagsList(v.data))
            .finally(() => setLoading(false))
    };
    let beforeMaskedStateChange = ({nextState}) => {
        let {value} = nextState;
        let newValue = value.split("");
        if (value.endsWith("/")) {
            value = value.slice(0, -1);
        }
        if (newValue[4] === "3" && newValue[5] === "7" && newValue[6] === "5") {
            newValue[4] = "_";
            newValue[5] = "_";
            newValue[6] = "_";
            newValue = newValue.join("");
            nextState.value = newValue;
            return {
                ...nextState,
                newValue
            };
        }
        return {
            ...nextState,
            value
        };
    };
    let customRequest = (file, closeModal) => {
        if (file?.size / 1000 > 100000) {
            return message.warning("file size")
        }
        if (file?.name !== undefined && file.name.length > 244)
            return message.error("length name > 244 symbols")

        const formData = new FormData();
        formData.append("image_file", file);
        formData.append("image_name", nameImageCropFile.split(".")[0].replace(/\s+/g, "_") + "/" + Date.now());
        formData.append("width", 1245);
        formData.append("height", 650);

        requests.quests.upload_image_course(formData, (progress) => {
            let percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            setFileListCrop([{
                uid: percentCompleted === 100 ? "-1" : "0",
                name: nameImageCropFile.replace(/\s+/g, "_"),
                percent: percentCompleted,
                status: (percentCompleted === 100) ? "done" : "uploading",
            }]);
        })
            .then((res) => {
                if (res.data?.url) {
                    setBanner(res.data.url, nameImageCropFile.replace(/\s+/g, "_"));
                }
            })
            .catch(err => console.error(err))
        setFlagModalEditImage(false);
    };
    let customRequestCard = (file, closeModal) => {
        if (file?.size / 1000 > 100000) {
            return message.warning("file size")
        }
        if (file?.name !== undefined && file.name.length > 244)
            return message.error("length name > 244 symbols")
        const formData = new FormData();
        formData.append("image_file", file);
        formData.append("image_name", nameImageCropFileCard.split(".")[0].replace(/\s+/g, "_") + "/" + Date.now());
        formData.append("width", 312);
        formData.append("height", 175);

        requests.quests.upload_image_course(formData, (progress) => {
            let percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            setFileListCropCard([{
                uid: percentCompleted === 100 ? "-1" : "0",
                name: nameImageCropFile.replace(/\s+/g, "_"),
                percent: percentCompleted,
                status: (percentCompleted === 100) ? "done" : "uploading",
            }]);
        })
            .then((res) => {
                if (res.data?.url) {
                    setCourseImageName(file.name);
                    setCourseImage(res.data.url);
                    fetchCardImageKey();
                }
            })
            .catch(err => console.error(err))
        setFlagModalEditImageCard(false);
    };
    let onImageChange = (event) => {
        if (event) {
            setBanner(null, null)
            setFileListCrop([]);
            setNameImageCropFile("")
            let reader = new FileReader();
            reader.onload = (e) => {
                setNameImageCropFile(event?.name.replace(/\s+/g, "_"))
                setImageSrcProps(reader.result?.toString() || "");
                setFlagModalEditImage(true)
            };
            reader.readAsDataURL(event);
        }
    };
    let onImageChangeCard = (event) => {
        if (event) {
            setCourseImage("")
            setCourseImageName("")
            setFileListCropCard([]);
            setNameImageCropFileCard("")
            let reader = new FileReader();
            reader.onload = (e) => {
                setNameImageCropFileCard(event?.name.replace(/\s+/g, "_"))
                setImageSrcPropsCard(reader.result?.toString() || "");
                setFlagModalEditImageCard(true)
            };
            reader.readAsDataURL(event);
        }
    };
    let fetchGptApi = (text = "") => {
        setLoadingGpt(true);
        requests.quests.fetchGptApi({name: text || valueGpt})
            .then(res => {
                /*setResultGpt(res.data.description)*/
                setLoadingGpt(false)
                setAboutProps(res.data.description)
            })
            .catch(err => {
                console.error(err)
                setLoadingGpt(false)
            })
    }
    let addSkillOfTrajectory = (elem) => {
        setExtraSkills(prev => [...prev, elem.id]);
        let newTrajectoryArr = [...trajectorySkills];
        newTrajectoryArr = newTrajectoryArr.filter(skill => skill.id !== elem.id);
        dispatch(setUserTrajectory(newTrajectoryArr))
        addSkillTag(elem);
    }
    let addSkillTag = (elem) => {
        let flag = true;
        for (let i = 0; i < speciality_tag.length; i++) {
            if (typeof speciality_tag[i].name === "undefined") {
                if (speciality_tag[i].speciality_tag.name === elem.name) {
                    flag = false;
                    break;
                }
            } else {
                if (speciality_tag[i].name === elem.name) {
                    flag = false;
                    break;
                }
            }
        }

        if (flag) {
            setTimeout(() => {
                setTagsToProps({name: elem.name, id: elem.id});
            }, 200);
        }
    }
    let removeSkillTag = (elem) => {
        if (extraSkills.includes(elem.id)) {
            let newTrajectoryArr = [...trajectorySkills];
            let newExtraSkills = [...extraSkills];
            newTrajectoryArr = [...newTrajectoryArr, elem];
            newExtraSkills = newExtraSkills.filter(id => id !== elem.id);
            dispatch(setUserTrajectory(newTrajectoryArr))
            setExtraSkills(newExtraSkills);
        }
    }
    let handleScroll = (event) => {
        let scrollTop = event.target.scrollTop;
        if (timeoutFetchPagination)
            clearTimeout(timeoutFetchPagination)
        if ((scrollTop >= (pageSize * 14)) && (existTagsList?.count > pageSize)) {
            setTimeoutFetchPagination(
                setTimeout(() => {
                    setPageSize(prev => prev + 10);
                    fetchSearchTags(pageSize + 10)
                }, 500)
            )
        }
    }

    useEffect(() => {
        if (location?.pathname?.includes("module") && basicInfoBlockRef.current) {
            setActiveBasicRow(2);
        }
    }, [])
    useEffect(() => {
        if (groups && groups.length > 0) {
            setOriginalGroup(groups);
        }
    }, [groups]);
    useEffect(() => {
        if (activeBasicRow === 2) {
            setCorporate(true);
            setCostCourseProps(null);
        }
        if (activeBasicRow === 1) {
            setCostCourseProps(null);
        }
    }, [activeBasicRow]);
    useEffect(() => {
        if (dateBegin && dateEnd) {
            let beginSplit = dateBegin.split("-");
            let endSplit = dateEnd.split("-");
            let strBegin = `${beginSplit[2]}.${beginSplit[1]}.${beginSplit[0]}`;
            let strEnd = `${endSplit[2]}.${endSplit[1]}.${endSplit[0]}`;
            setDateBeginValue(strBegin);
            setDateEndValue(strEnd);
        }
    }, [dateBegin, dateEnd]);
    useEffect(() => {
        if (isCorporate) return setActiveBasicRow(2);
    }, [isCorporate]);
    useEffect(() => {
        if (location?.pathname?.includes("module"))
            return setActiveBasicRow(2);
        if (isCorporate)
            return setActiveBasicRow(2);
        if (participateprice)
            return setActiveBasicRow(0);
        if (!participatetype)
            return setActiveBasicRow(1);
        setActiveBasicRow(1);
    }, [participatetype]);
    useEffect(() => {
        if (demo_youtube) return setActiveDemoRow(0);
        if (demo_video) return setActiveDemoRow(1);
    }, [demo_video, demo_youtube]);
    useEffect(() => {
        if (banner && banner_name)
            setFileListCrop([{name: banner_name, status: "done", url: banner, percent: 100}])
    }, [banner, banner_name])
    useEffect(() => {
        if (y_image && y_image_name)
            setFileListCropCard([{name: y_image_name, status: "done", url: y_image, percent: 100}])
    }, [y_image, y_image_name])
    useEffect(() => {
        if (valueInputTag.length > 1) {
            let lastSymbol = valueInputTag[valueInputTag.length - 1];
            if (lastSymbol !== " ") {
                setPageSize(10)
                setExistTagsList([]);
                if (timeoutSearch)
                    clearTimeout(timeoutSearch)

                setTimeoutSearch(setTimeout(() =>
                    fetchSearchTags(10), 500))
            }
        }
    }, [valueInputTag])
    return (
        <div className={"step_1"}>
            <div className="h2">{variable_11}</div>
            <div className="cards_course-parent">
                <div className="cards_course">
                    <div className={"title"}>
                        <span>{variable_12}</span>
                        <Tooltip title={variable_66} align={"top"}>
                            <QuestionIcon/>
                        </Tooltip>
                    </div>
                    <div
                        style={{backgroundColor: `${activeColorList()}`, color: "white"}}
                        onClick={() => setFlagDropDownActivity(!flagDropDownActivity)}
                        className={flagDropDownActivity ? "drop_down open" : "drop_down "}
                        tabIndex={0}
                        onBlur={() => setFlagDropDownActivity(false)}
                    >
                        {speciality !== null
                            ? <>
                                {arrActivity[speciality - 1]}
                            </>
                            : "Без категории"
                        }
                        <div className={flagDropDownActivity ? "arrow tranform" : "arrow"}><Arrow/></div>
                        <div
                            style={{backgroundColor: `${activeColorList()}`}}
                            className={flagDropDownActivity ? "drop_down-list active" : "drop_down-list"}>
                            <ul>
                                {arrActivity.map((elem, index) => {
                                        if (index !== speciality - 1)
                                            return <li
                                                key={index}
                                                onClick={() => {
                                                    setActiveSpecialId(index + 1);
                                                    setFlagDropDownActivity(false)
                                                }}
                                            >
                                                {elem}
                                            </li>;
                                    }
                                )}
                            </ul>
                        </div>
                    </div>
                    <InputCustom
                        value={name}
                        setValue={(e) => {
                            setNameProps(e);
                        }}
                        title={variable_13}
                        required={true}
                        info={variable_67}
                    />
                    <div className="title">
                        <span>{variable_21}</span>
                        <Tooltip title={variable_70} align={"top"}>
                            <QuestionIcon/>
                        </Tooltip>
                        <CustomCheckbox
                            value={access_after_begin}
                            setValue={(e) => setAccessAfterBegin(e)}
                            label={"Курс будет доступен только с даты начала"}
                            id={"course_begin"}
                        />
                    </div>
                    <div className="date_range">
                        {dateBeginValue && dateEndValue &&
                            <RangePicker
                                className={"date_range-antd"}
                                dropdownClassName={"createDateRangePicker"}
                                format={"DD.MM.YYYY"}
                                defaultValue={[moment(dateBeginValue, "DD.MM.YYYY"), moment(dateEndValue, "DD.MM.YYYY")]}
                                onChange={(e, c) => {
                                    setDateBegin(c[0]);
                                    setDateEnd(c[1]);
                                }}
                                allowClear={false}
                            />
                        }
                        {!dateBeginValue && !dateEndValue &&
                            <RangePicker
                                className={"date_range-antd"}
                                dropdownClassName={"createDateRangePicker"}
                                format={"DD.MM.YYYY"}
                                onChange={(e, c) => {
                                    setDateBegin(c[0]);
                                    setDateEnd(c[1]);
                                }}
                                allowClear={false}
                            />
                        }
                    </div>
                    <InputCustom
                        value={academicHours}
                        setValue={(e) => setAcademHourseProps(e)}
                        title={variable_22}
                        required={true}
                        type={"number"}
                    />
                    <div className="title">
                        <span>{variable_20}</span>
                        <Tooltip title={variable_69} align={"top"}>
                            <QuestionIcon/>
                        </Tooltip>
                    </div>
                    <div className={"draggable"}>
                        <label
                            className="label_crop"
                            htmlFor="photo3"
                            onDragEnter={(e) => {
                                e.preventDefault();
                                e.stopPropagation()
                            }}
                            onDragLeave={(e) => {
                                e.preventDefault();
                                e.stopPropagation()
                            }}
                            onDragOver={(e) => {
                                e.preventDefault();
                                e.stopPropagation()
                            }}
                            onDrop={e => {
                                e.preventDefault();
                                e.stopPropagation()
                                if (e.dataTransfer?.files[0]?.type.includes("image"))
                                    onImageChangeCard(e.dataTransfer.files[0])
                            }}
                        >
                            <img src={upload}/>
                            <span>{variable_62}</span>
                        </label>
                        <input
                            ref={inputPhotoRef}
                            style={{display: "none"}}
                            id="photo3"
                            type="file"
                            accept="image/*"
                            key={keyImage + "_"}
                            className={"input_photo"}
                            onChange={(e) => {
                                e.target.input = "";
                                setKeyImage(prev => prev + 1)
                                onImageChangeCard(e.target.files[0])
                            }}
                            draggable={true}
                        />
                        <Upload
                            listType="picture"
                            fileList={fileListCropCard}
                            onRemove={() => {
                                setCourseImage("");
                                setCourseImageName("");
                                setFileListCropCard([]);
                            }}
                        />
                    </div>
                    <div className="demo_row">
                        <span style={{marginLeft: 15}}>{variable_16}</span>
                        <div className="group_demo" style={{paddingRight: 0}}>
                            <div
                                className={activeDemoRow === 0 ? "active" : ""}
                                onClick={() => setActiveDemoRow(0)}
                            >{variable_17}
                            </div>
                            <div
                                className={activeDemoRow === 1 ? "active" : ""}
                                onClick={() => setActiveDemoRow(1)}
                            >
                                {variable_18}
                            </div>
                        </div>
                    </div>
                    {activeDemoRow === 0 &&
                        <InputCustom
                            value={demo_youtube}
                            setValue={(e) => setLinkYoutubeProps(e)}
                            title={variable_17}
                            placeholder={variable_19}
                        />
                    }
                    {activeDemoRow === 1 &&
                        <div className={"draggable"}>
                            <StorageUploader
                                yandexKey={yandexKeyVideoPreview}
                                count={1}
                                fileTypes={"video/*"}
                                setFileListProps={(file) => {
                                    setDemoVideoName(file[0]?.name)
                                    setFileList(file);
                                    let url = "https://storage.yandexcloud.net/skill-storage/"
                                        + yandexKeyVideoPreview
                                        + ("/" + file[0]?.name).replace(/\s+/g, "_");
                                    setLinkDemoFileProps(url)
                                }}
                                firstRenderImage={demo_video_name ? [{name: demo_video_name, status: "done"}] : []}
                                removeFile={() => {
                                    setLinkDemoFileProps('');
                                    setDemoVideoName('');
                                }}
                            />
                        </div>
                    }
                </div>
                <div className={`parent_course-card`}>
                    <div className="reuse_course-item">
                        <CourseCard
                            course={props.courseData}
                            callInCreate={true}
                            dateCourse={true}
                            cost={true}
                            rating={true}
                            popular={false}
                            needLink={false}
                        />
                    </div>
                    <div className="btn_slide">
                        <div
                            className={activeSlidePreview === 0 ? "active" : ""}
                            onClick={() => setActiveSlidePreview(0)}
                        />
                        <div
                            className={activeSlidePreview === 1 ? "active" : ""}
                            onClick={() => setActiveSlidePreview(1)}
                        />
                    </div>
                </div>
                {/*<div className="chat_gpt">
                    <div className="header">
                        <span>{variable_82}</span>
                    </div>
                    <div className="result">
                        {loadingGpt &&
                            <Spin style={{position: "absolute", left: "50%", transform: "translateX(-50%)"}}/>
                        }
                        <pre>{resultGpt}</pre>
                    </div>
                    <div className="footer">
                        <input
                            value={valueGpt}
                            onChange={(e) => setValueGpt(e.target.value)}
                            className="input_classic chat_gpt-input"
                            placeholder={"Составьте описание"}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13 && !loadingGpt) {
                                    fetchGptApi()
                                }
                            }}
                        />
                        <div
                            className={
                                cn("btn", {
                                    "disabled": !name || loadingGpt,
                                })
                            }
                            onClick={() => {
                                if (!name)
                                    return
                                if (!loadingGpt) {
                                    setResultGpt("");
                                    if (!valueGpt) {
                                        if (language === "ru")
                                            fetchGptApi(`Сгенерируй продающий текст для курса \"${name}\", максимальное количество слов 50 `);
                                        else
                                            fetchGptApi(`Generate a sales text for the course \"${name}\", max 50 words `)
                                    } else fetchGptApi()
                                }
                            }}
                        >Сгенерировать описание курса
                        </div>
                    </div>
                </div>*/}
            </div>
            <div className="h2">{variable_23}</div>
            <div className="landing_parent">
                <div
                    className="landing_block"
                    ref={basicInfoBlockRef}
                >
                    <div className="title_info">
                        <span>{variable_76}</span>
                        <Tooltip title={variable_80} align={"top"}>
                            <QuestionIcon/>
                        </Tooltip>
                    </div>
                    <div className="demo_row">
                        <div
                            className={banner_position === "left" ? "active" : ""}
                            onClick={() => setBannerPosition("left")}
                        >{variable_73}
                        </div>
                        <div
                            className={banner_position === "right" ? "active" : ""}
                            onClick={() => setBannerPosition("right")}
                        >
                            {variable_74}
                        </div>
                        <div
                            className={banner_position === null ? "active" : ""}
                            onClick={() => setBannerPosition(null)}
                        >
                            {variable_75}
                        </div>
                    </div>
                    <div className="crop_image">
                        <label
                            className="label_crop"
                            htmlFor="photo4"
                            onDragEnter={(e) => {
                                e.preventDefault();
                                e.stopPropagation()
                            }}
                            onDragLeave={(e) => {
                                e.preventDefault();
                                e.stopPropagation()
                            }}
                            onDragOver={(e) => {
                                e.preventDefault();
                                e.stopPropagation()
                            }}
                            onDrop={e => {
                                e.preventDefault();
                                e.stopPropagation()
                                if (e.dataTransfer?.files[0]?.type.includes("image"))
                                    onImageChange(e.dataTransfer.files[0])
                            }}
                        >
                            <img src={upload}/>
                            <span>{variable_62}</span>
                        </label>
                        <input
                            ref={inputPhotoRef}
                            style={{display: "none"}}
                            id="photo4"
                            type="file"
                            accept="image/*"
                            key={keyImage}
                            className={"input_photo"}
                            onChange={(e) => {
                                e.target.input = "";
                                setKeyImage(prev => prev + 1)
                                onImageChange(e.target.files[0])
                            }}
                            draggable={true}
                        />
                        <Upload
                            listType="picture"
                            fileList={fileListCrop}
                            onRemove={() => {
                                setBanner(null, null)
                                setBannerPosition(null)
                                setFileListCrop([]);
                            }}
                        />
                    </div>
                    <div className="draggable">
                        {/*  <StorageUploader
                            yandexKey={yandexKeyBannerImage}
                            count={1}
                            fileTypes={"image/*"}
                            setFileListProps={(file) => {
                                if (file.length > 0) {
                                    if (file[0].percent === 100) {
                                        setTimeout(() => {
                                            setBanner("https://storage.yandexcloud.net/skill-storage/" + yandexKeyBannerImage, file[0].name);
                                            fetchBannerImageKey(); // обновить ключ
                                        }, 500)
                                    }
                                }
                            }}
                            firstRenderImage={banner_name ? [{name: banner_name, status: "done", url: banner}] : []}
                            removeFile={() => {
                                setBanner(null, null)
                                setBannerPosition(null)
                            }}
                        />*/}
                    </div>
                    <div className="title_info">
                        <span>{variable_14}</span>
                        <Button
                            text={"Сгенерировать описание курса"}
                            click={() => {
                                if (!name)
                                    return
                                if (!loadingGpt) {
                                    setResultGpt("");
                                    if (!valueGpt) {
                                        if (language === "ru")
                                            fetchGptApi(`Сгенерируй продающий текст для курса \"${name}\", максимальное количество слов 50 `);
                                        else
                                            fetchGptApi(`Generate a sales text for the course \"${name}\", max 50 words `)
                                    } else fetchGptApi()
                                }
                            }}
                            maxWidth={"fit-content"}
                            disabled={!name || loadingGpt}
                            margin={"0 0 0 auto"}
                            padding={"6px 13px 8px"}
                            boxShadow={"none"}
                        />
                    </div>
                    <div className="textarea_parent">
                        <TextArea
                            placeholder={variable_15}
                            className="textarea_antd description"
                            value={description}
                            onChange={(e) => {
                                setAboutProps(e.target.value);
                            }}
                            disabled={loadingGpt}
                        />
                        <div className="resize">
                            <Arrow/>
                            <Arrow/>
                        </div>
                        {loadingGpt &&
                            <Spin
                                style={{
                                    position: "absolute",
                                    left: "50%",
                                    top: "20px",
                                    transform: "translateX(-50%)"
                                }}
                            />
                        }
                    </div>
                    <div className="title_info">
                        <span>{variable_77}</span>
                        <Tooltip title={variable_68} align={"top"}>
                            <QuestionIcon/>
                        </Tooltip>
                        <CustomCheckbox
                            value={show_comments}
                            setValue={(e) => setDisabledComments(e)}
                            label={"Показать комментарии"}
                            id={"course_disabled_comments"}
                        />
                    </div>
                    <div className="textarea_parent">
                        <TextArea
                            placeholder={variable_77}
                            className="textarea_antd contain"
                            value={content_description}
                            onChange={(e) => setContentDescription(e.target.value)}
                        />
                        <div className="resize">
                            <Arrow/>
                            <Arrow/>
                        </div>
                    </div>
                </div>
                <div className={`parent_banner-preview`}>
                    <div
                        className={!banner
                            ? (speciality === 3 || speciality === 5)
                                ? "banner_preview default dark"
                                : "banner_preview default"
                            : "banner_preview"
                        }
                        key={keyImage}
                        style={banner
                            ? {backgroundImage: `url(${banner})`}
                            : {backgroundImage: `url(${defaultFuncAndData.defaultImageCourse(speciality - 1) || default_course})`}
                        }
                    >
                        <div className="label"> Превью баннера в лендинге</div>
                        {(banner_position === "left" || banner_position === "right")
                            ? <div className={banner_position === "right" ? "banner right" : "banner"}>
                                <div className="title">
                                    <Tooltip
                                        title={name || ""}
                                        placement="right"
                                        overlayInnerStyle={{maxHeight: 400, overflow: "auto"}}
                                    >
                                        {defaultFuncAndData.spliceStr(name, 35)}
                                    </Tooltip>
                                </div>
                                <div className="info">
                                    <Tooltip
                                        title={description || ""}
                                        placement="right"
                                        overlayInnerStyle={{maxHeight: 400, overflow: "auto"}}
                                    >
                                        {defaultFuncAndData.spliceStr(description, 80)}
                                    </Tooltip>
                                </div>
                                <div className="btn_bottom">
                                    <div className="record">
                                        {variable_79}
                                    </div>
                                </div>
                            </div>
                            : <></>
                        }
                    </div>
                    <div className="btn_share">
                        {variable_83}
                    </div>
                </div>
            </div>
            <div className="h2">{variable_29}</div>
            <div className="group_block">
                {originalGroup && originalGroup.length > 0 &&
                    originalGroup.map((elem, indx) =>
                        <div className="description_row" key={indx}>
                            <div className="title">{variable_30}</div>
                            <div className="description">
                                <img src={description_row}/>
                                <div className="text_1">{elem.name}</div>
                                {currentEditGroup === null &&
                                    <div className="group_icon">
                                        <img
                                            src={edit_icon}
                                            onClick={() => {
                                                let copy = JSON.parse(JSON.stringify(originalGroup[indx]));
                                                setCopyGroup(copy);
                                                setCurrentEditGroup(indx);
                                                setTemplateGroup({name: ""});
                                            }}
                                        />
                                        <img
                                            onClick={() => {
                                                let newArr = [...originalGroup];
                                                newArr.splice(indx, 1);
                                                setOriginalGroup(newArr);
                                                if (elem.id)
                                                    setArrForRemoveGroup(elem.id);
                                            }}
                                            src={remove_icon}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }
                {currentEditGroup === null &&
                    <>
                        <InputCustom
                            value={templateGroup.name}
                            setValue={(e) => setTemplateGroup((prev) => ({...prev, ["name"]: e}))}
                            title={variable_31}
                            placeholder={variable_32}
                            info={variable_72}
                        />
                        <div
                            className="btn_add"
                            onClick={() => {
                                if (!templateGroup.name)
                                    return message.error(variable_32);
                                setOriginalGroup((prev) => [...prev, templateGroup]);
                                setTemplateGroup({name: ""});
                            }}
                        >
                            <img src={add_step}/>
                            <span>{variable_33}</span>
                        </div>
                    </>
                }
                {currentEditGroup !== null &&
                    <InputCustom
                        value={copyGroup.name}
                        setValue={(e) => setCopyGroup((prev) => ({...prev, ["name"]: e}))}
                        title={variable_31}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                return message.error(variable_34);
                            }
                            let newArr = [...originalGroup];
                            newArr[currentEditGroup] = copyGroup;
                            setOriginalGroup(newArr);
                            setCurrentEditGroup(null);
                            setCopyGroup({name: ""});
                        }}
                        info={variable_72}
                    />
                }
            </div>
            <div className="h2">{variable_78}</div>
            <div className="basic_info-parent">
                <div className="basic_info-block">
                    <div className="demo_row">
                        <div
                            className={activeBasicRow === 0 ? "active" : ""}
                            onClick={() => setActiveBasicRow(0)}
                        >
                            {variable_25}
                        </div>
                        <div
                            className={activeBasicRow === 1 ? "active" : ""}
                            onClick={() => setActiveBasicRow(1)}
                        >
                            {variable_26}
                        </div>
                        {activeModules.includes("corp_quests")
                            ?
                            <div
                                className={activeBasicRow === 2 ? "active" : ""}
                                onClick={() => setActiveBasicRow(2)}
                            >
                                {variable_27}
                            </div>
                            : <></>
                        }
                    </div>
                    {activeBasicRow === 0 &&
                        <>
                            <div className="title_info">
                                <span>{variable_28}</span>
                                <span style={{marginLeft: 4, color: "red"}}> *</span>
                            </div>
                            <Input
                                className={"input_classic"}
                                value={participateprice}
                                onChange={(e) => setCostCourseProps(e.target.value)}
                                type={"number"}
                                onWheelCapture={e => {
                                    e.target.blur()
                                }}
                            />
                            <div className="icon_currency"><span>₽</span></div>
                        </>
                    }
                    <div className="title_info">
                        <span>{variable_24}</span>
                        <span style={{marginLeft: 4, color: "red"}}> *</span>
                        <Tooltip title={variable_71} align={"top"}>
                            <QuestionIcon/>
                        </Tooltip>
                    </div>
                    <div className="select_block">
                        <Input
                            className={"input_classic"}
                            value={valueInputTag}
                            onChange={(e) => setValueInputTag(e.target.value)}
                            onFocus={() => setFlagOpenSelectTags(true)}
                            onBlur={() => {
                                setTimeout(() => setFlagOpenSelectTags(false), 150)
                            }}
                        />
                        {existTagsList?.results?.length > 0 &&
                            <div
                                className={flagOpenSelectTags
                                    ? "parent_tag open"
                                    : "parent_tag"
                                }
                                onScroll={handleScroll}
                            >
                                {existTagsList.results.map((elem, indx) =>
                                    <Tag
                                        elem={elem}
                                        key={elem?.id || indx}
                                        cursorPointer={true}
                                        hideRemoveIcon={true}
                                        countCourses={undefined}
                                        countPlanes={undefined}
                                        exist_quest={false}
                                        click={() => {
                                            setTimeout(() => {
                                                addSkillTag(elem)
                                            }, 150)
                                        }}
                                    />
                                    /*<div
                                        className={"tag"}
                                        key={indx}
                                        onClick={() => {
                                            setTimeout(() => {
                                                addSkillTag(elem)
                                            }, 150)
                                        }}
                                    >
                                        {elem.name}
                                    </div>*/
                                )}
                            </div>
                        }
                        {loading &&
                            <Spin className="spin_loading"/>
                        }
                    </div>
                    {speciality_tag && speciality_tag.length > 0 &&
                        <div className="skill_block">
                            {speciality_tag.map((elem, indx) =>
                                <div
                                    className={"skill"}
                                    key={indx}
                                >
                                    <span>
                                        {elem.name || elem.speciality_tag.name}
                                    </span>
                                    <img
                                        src={close_tag}
                                        onClick={() => {
                                            removeSkillTag(elem)
                                            setTagsToProps(elem, true);
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    }
                </div>
                <>
                    {trajectorySkills.length > 0 &&
                        <div className="academic_plan">
                            <div className="title">
                                <img src={academic_plan}/>
                                <span>Учебный план</span>
                            </div>
                            <div className="list_skills">
                                {trajectorySkills.map((elem) =>
                                    <div
                                        className="skill"
                                        onClick={() => addSkillOfTrajectory(elem)}
                                        key={elem.id}
                                    >
                                        <span>{elem.name}</span>
                                        <img src={add_skill_step1} alt=""/>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </>
            </div>
            <div className="h2">{variable_63}</div>
            <div className="contact_block">
                <InputCustom
                    value={email}
                    setValue={(e) => {
                        setEmailFeedback(e);
                    }}
                    title={"E-mail"}
                    placeholder={"E-mail"}
                />
                <div className="phone_parent">
                    <div className="title">{variable_35}</div>
                    <InputMask
                        className={flagsDropdownPhone ? "phoneNumber active" : "phoneNumber"}
                        style={{width: "100%", zIndex: 3}}
                        value={phone}
                        alwaysShowMask={true}
                        onChange={(e) => {
                            setPhoneFeedback(e.target.value);
                        }}
                        mask={arrMaskPhone[activeMaskPhone]}
                        beforeMaskedStateChange={beforeMaskedStateChange}
                    />
                    {arrIconsCountry[activeMaskPhone]}
                    <div className={flagsDropdownPhone ? "drop_down-phone active" : "drop_down-phone"}>
                        <ul>
                            <li onClick={() => {
                                setActiveMaskPhone(0, "activeMaskPhone");
                                setFlagsDropdownPhone(false);
                            }}>
                                <img width={21} height={15} src={rus}/>
                                +7 (___) ___-__-__
                                {variable_36}
                            </li>
                            <li onClick={() => {
                                setActiveMaskPhone(1, "activeMaskPhone");
                                setFlagsDropdownPhone(false);
                            }}>
                                <img width={21} height={15} src={belarus}/>
                                +375 (___) ___-__-__
                                {variable_37}
                            </li>
                            <li onClick={() => {
                                setActiveMaskPhone(2, "activeMaskPhone");
                                setFlagsDropdownPhone(false);
                            }}>
                                <img width={21} height={15} src={kazahstan}/>
                                +7 (___) ___-__-__
                                {variable_38}
                            </li>
                            <li onClick={() => {
                                setActiveMaskPhone(3, "activeMaskPhone");
                                setFlagsDropdownPhone(false);
                            }}>
                                <img width={21} height={15} src={azer}/>
                                +994 (___) __-__-__
                                {variable_40}
                            </li>
                            <li onClick={() => {
                                setActiveMaskPhone(4, "activeMaskPhone");
                                setFlagsDropdownPhone(false);
                            }}>
                                <img width={21} height={15} src={uz}/>
                                +998 (__) ___-__-__
                                {variable_41}
                            </li>
                            <li onClick={() => {
                                setActiveMaskPhone(5, "activeMaskPhone");
                                setFlagsDropdownPhone(false);
                            }}>
                                <img width={21} height={15} src={gr}/>
                                +995 (___) __-__-__
                                {variable_42}
                            </li>
                            <li onClick={() => {
                                setActiveMaskPhone(6, "activeMaskPhone");
                                setFlagsDropdownPhone(false);
                            }}>
                                <img width={21} height={15} src={kr}/>
                                +996 (___) __-__-__
                                {variable_43}
                            </li>
                        </ul>
                    </div>
                    <div
                        className={flagsDropdownPhone ? "arrow_phone active" : "arrow_phone"}
                        onClick={() => setFlagsDropdownPhone(!flagsDropdownPhone)}>
                        <Arrow className={flagsDropdownPhone ? "active" : ""}/>
                    </div>
                </div>
            </div>
            <div
                className="btn_next button_classic"
                style={fileList.length > 0 && fileList[0].percent !== 100 ? {
                    cursor: "auto",
                    backgroundColor: "grey"
                } : {}}
                onClick={() => {
                    if (fileList.length > 0 && fileList[0].percent !== 100) return;
                    if (fileListCrop.length > 0 && fileListCrop[0].percent !== 100) return;
                    if (fileListCropCard.length > 0 && fileListCropCard[0].percent !== 100) return;
                    if (!name) return message.error(variable_44);
                    if (!academicHours) return message.error(variable_45);
                    if (speciality_tag && speciality_tag.length === 0) return message.error(variable_46);
                    if (activeBasicRow === 0 && (!participateprice || participateprice === "0")) return message.error(variable_47);
                    if (dateEndValue) {
                        let a = moment(dateEndValue, "DD.MM.YYYY").valueOf();
                        let b = moment().valueOf();
                        if (a.valueOf() && (a.valueOf() < b.valueOf() || a.valueOf() === b.valueOf()))
                            return setFlagModalDate(true);
                    }
                    if (email) {
                        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                        if (reg.test(email) == false) {
                            return message.error(variable_48);
                        }
                    }
                    if (activeBasicRow === 1) {
                        setCostCourseProps(0);
                        setCorporate(false);
                    }
                    if (activeBasicRow === 0) setCorporate(false);
                    setGroupProps(originalGroup);
                    setNextStep();
                }}>
                Далее
            </div>
            <>
                {flagModalDate &&
                    <Modal
                        visible={flagModalDate}
                        footer={[
                            <div
                                className={"btn_ok"}
                                onClick={() => setFlagModalDate(false)}
                                key={"ok"}
                            >Ок</div>
                        ]}
                        className={"modal_date"}
                        width={"auto"}
                        closable={false}
                        centered={true}
                        onCancel={() => {
                            setFlagModalDate(false);
                        }}
                        maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.50)"}}
                        bodyStyle={{padding: 25, backgroundColor: "#343843", borderRadius: 16}}
                    >
                        <span style={{color: "white"}}>{variable_49}</span>
                    </Modal>
                }
            </>
            <>
                {flagModalEditImage &&
                    <Modal
                        visible={flagModalEditImage}
                        footer={null}
                        className={"modal_edit-image profile_img"}
                        closable={false}
                        closeIcon={<div><img src={close_modal} alt=""/></div>}
                        onCancel={() => {
                        }}
                        maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                        bodyStyle={{padding: "50px 50px 40px 50px", backgroundColor: "#343843", borderRadius: 35}}
                    >
                        <ImageCropBanner
                            customRequest={(value) => customRequest(value, true)}
                            imageSrcProps={imageSrcProps}
                            nameImageCropFile={nameImageCropFile}
                        />
                    </Modal>
                }
            </>
            <>
                {flagModalEditImageCard &&
                    <Modal
                        visible={flagModalEditImageCard}
                        footer={null}
                        className={"modal_edit-image profile_img"}
                        closable={false}
                        closeIcon={<div><img src={close_modal} alt=""/></div>}
                        onCancel={() => {
                        }}
                        maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                        bodyStyle={{padding: "50px 50px 40px 50px", backgroundColor: "#343843", borderRadius: 35}}
                    >
                        <ImageCropBanner
                            customRequest={(value) => customRequestCard(value, true)}
                            imageSrcProps={imageSrcPropsCard}
                            nameImageCropFile={nameImageCropFileCard}
                        />
                    </Modal>
                }
            </>
            <div
                onClick={() => {
                    setFlagMonthDropDown(false);
                    setFlagMonthDropDownEnd(false);
                    setFlagDayDropDownEnd(false);
                    setFlagDayDropDown(false);
                    setFlagDropDownTimerDay(false);
                    setFlagDropDownTimerHours(false);
                }}
                className={
                    (flagMonthDropDownEnd || flagDayDropDownEnd
                        || flagMonthDropDown || flagDayDropDown || flagDropDownTimerDay || flagDropDownTimerHours) ? "overlay open" : "overlay"}
            />

        </div>
    );
};

export default Step1;
