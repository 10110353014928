import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import requests from "../../axios/requests";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import { setCurrentGroupR, setCurrentQuestR } from "../../../redux/actions/actions";

import { Pagination, Popconfirm } from "antd";
import InputWsButton from "../../components/reuseComponents/InputButton";
import ReuseDropDown from "../../components/reuseComponents/ReuseDropDown";
import Trial from "../../components/reuseComponents/Trial";
import ModalInfo from "../CourseManage/ModalInfo/ModalInfo";

import { ReactComponent as Logo } from "../../../images/icons/logo.svg";
import { ReactComponent as Group_icon4 } from "../../../images/speciality_id-group/group_icon4.svg";
import { ReactComponent as Group_icon3 } from "../../../images/speciality_id-group/group_icon3.svg";
import { ReactComponent as Group_icon2 } from "../../../images/speciality_id-group/group_icon2.svg";
import { ReactComponent as Group_icon1 } from "../../../images/speciality_id-group/group_icon1.svg";
import { ReactComponent as Group_icon5 } from "../../../images/speciality_id-group/group_icon5.svg";
import { ReactComponent as Group_icon6 } from "../../../images/speciality_id-group/group_icon6.svg";
import { ReactComponent as Group_icon7 } from "../../../images/speciality_id-group/group_icon7.svg";
import fake_photos from "../../../images/icons/fake_photos.png";

export const DemoRow = ({currentActive = "groups-list"}) => {
    const history = useNavigate();

    const variable_10 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_12"});

    return (
        <div className="group_demo">
            <div
                className={currentActive === "my-course" ? "active" : ""}
                onClick={() => history("/backoffice/my-course")}
            >
                {variable_10}
            </div>
            <div
                className={currentActive === "inner-request" ? "active" : ""}
                onClick={() => history("/backoffice/request-members")}
            >
                {variable_11}
            </div>
            <div
                className={currentActive === "groups-list" ? "active" : ""}
                onClick={() => history("/backoffice/groups-list")}
            >
                {variable_12}
            </div>
        </div>
    );
};
const GroupsList = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const {language, trial_status} = useSelector((state) => state.Reducer);

    const variable_4 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_4"});
    const variable_13 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_13"});
    const variable_42 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_42"});
    const variable_43 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_43"});
    const variable_44 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_44"});
    const variable_45 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_45"});
    const variable_46 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_46"});
    const variable_47 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_47"});
    const variable_48 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_48"});
    const variable_49 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_49"});

    const [activeCourseDropDown, setActiveCourseDropDown] = useState(0);
    const [activeArchiveGroup, setActiveArchiveGroup] = useState(0);
    const [searchGroups, setSearchGroups] = useState("");
    const [allGroups, setAllGroups] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState("");
    const [courseListDropdown, setCourseListDropdown] = useState([]);
    const [flags, setFlags] = useState({
        flagDropCourseList: false,
        flafDropArchiveGroup: false
    });
    const [openModalInfo, setOpenModalInfo] = useState(false);
    const [baseAccess, setBaseAccess] = useState(false);
    const innerBlockRef = useRef();

    let payModule = () => {
        requests.organization.create_link_payment_module(4)
            .then(res => {
                if (res.data?.payment_url)
                    window.location.href = res.data.payment_url;
            })
            .catch(err => console.error(err))

    }
    let getApiBaseAccess = () => {
        requests.organization.has_base_acccess()
            .then(res => setBaseAccess(res.data))
    };
    let itemRender = (_, type, originalElement) => {
        if (type === "prev")
            return null;
        if (type === "next") {
            return;
        }
        return originalElement;
    };
    let handleSendArchive = (elem) => {
        let obj = {
            "name": elem.name,
            "archived": true
        }
        requests.quests.change_quest_group(elem.id, obj)
            .then(() => {
                let newArr = [...allGroups];
                newArr = newArr.filter(x => x.id !== elem.id);
                setAllGroups(newArr);
            })
    };
    let handleOutArchive = (elem) => {
        let obj = {
            "name": elem.name,
            "archived": false
        }
        requests.quests.change_quest_group(elem.id, obj)
            .then(() => {
                let newArr = [...allGroups];
                newArr = newArr.filter(x => x.id !== elem.id);
                setAllGroups(newArr);
            })
    };
    let getApiGroupActive = (quest_id = 0, word = "", currentPage = null, count = null, archive = false) => {
        requests.quests.get_quest_groups(quest_id, word, currentPage, count, archive)
            .then((res) => {
                setAllGroups(res.data.results);
                setTotal(res.data.count);
            })
            .catch((err) => console.log(err, "error get_quest_groups"));
    };
    let callGroupActive = () => {
        let archive = false;

        if (activeArchiveGroup === 1)
            archive = true;
        if (activeCourseDropDown === 0) {
            getApiGroupActive(0, searchGroups, currentPage, 12, archive);
        } else getApiGroupActive(courseListDropdown[activeCourseDropDown].id, searchGroups, false, false, archive);
    };

    useEffect(() => {
        getApiBaseAccess();

        requests.quests
            .get_org_quests()
            .then((data) => {
                if (data.status === 200) {
                    if (data.data === undefined || data.data === null) {
                        data.data = [];
                    }
                    let nameLocalization = language === "en" ? "All courses" : "Все курсы";
                    let arrStatisticCourse = [{id: null, name: nameLocalization}];
                    for (let i = 0; i < data.data.length; i++)
                        arrStatisticCourse = [...arrStatisticCourse, {name: data.data[i].name, id: data.data[i].id}];
                    setCourseListDropdown(arrStatisticCourse);
                }
            });
    }, []);
    useEffect(() => {
        setCurrentPage(1);
        setSearchGroups('');
        callGroupActive();
    }, [activeCourseDropDown]);
    useEffect(() => {
        callGroupActive();
    }, [currentPage]);
    useEffect(() => {
        setCurrentPage(1);
        setSearchGroups('');
        callGroupActive();
    }, [activeArchiveGroup])
    useEffect(() => {
        if (typeof baseAccess?.status !== "undefined" && !baseAccess?.status)
            setOpenModalInfo(true)
    }, [baseAccess])
    return (
        <div className={"groups_list-parent"}>
            <div ref={innerBlockRef} className="inner_block">
                {(trial_status === 0 || trial_status === 1) && <Trial/>}
                <div
                    className={flags.flagOpenSearchMobile
                        ? "top_filter-mobile hidden"
                        : "top_filter-mobile"
                    }
                >
                    <div className="icon">
                        <Logo
                            width={38}
                            height={38}
                            onClick={() => window.open("https://skillometer.ru/", 'blank')}
                        />
                    </div>
                </div>
                <div className="top">
                    <div>
                        <span>{variable_4}</span>
                        <span className="location" style={{margin: '0 2px 0 6px'}}>/</span>
                        <span className="location">{activeArchiveGroup === 0 ? variable_44 : variable_45}</span>
                    </div>
                    <DemoRow currentActive={"groups-list"}/>
                </div>
                {baseAccess?.status &&
                    <div className={"group_page"}>
                        <div className="top_filter">
                            <ReuseDropDown
                                flagOpen={flags.flagDropCourseList}
                                setFlagOpen={(bool) => setFlags(prev => ({...prev, ["flagDropCourseList"]: bool}))}
                                arrList={courseListDropdown}
                                activeArrList={activeCourseDropDown}
                                setActiveArrList={(indx) => setActiveCourseDropDown(indx)}
                                onBlur={() => setFlags((prev) => ({...prev, ['flagDropCourseList']: false}))}
                            />
                            <ReuseDropDown
                                flagOpen={flags.flafDropArchiveGroup}
                                setFlagOpen={(bool) => setFlags(prev => ({...prev, ["flafDropArchiveGroup"]: bool}))}
                                arrList={[variable_42, variable_43]}
                                activeArrList={activeArchiveGroup}
                                setActiveArrList={(indx) => setActiveArchiveGroup(indx)}
                                onBlur={() => setFlags((prev) => ({...prev, ['flafDropArchiveGroup']: false}))}
                            />
                            <div className="search">
                                <InputWsButton
                                    value={searchGroups}
                                    setValue={(e) => setSearchGroups(e)}
                                    keyDown={(e) => {
                                        if (e.keyCode === 13)
                                            callGroupActive();
                                    }}
                                    search={() => callGroupActive()}
                                />
                            </div>
                        </div>
                        <div className="group_page-list">
                            {allGroups.length > 0 &&
                                allGroups.map((elem, indx) => {
                                        let calcSpecialId = (speciality_id) => {
                                            switch (speciality_id) {
                                                case 4:
                                                    return <Group_icon4/>;
                                                case 1:
                                                    return <Group_icon1/>;
                                                case 2:
                                                    return <Group_icon2/>;
                                                case 3:
                                                    return <Group_icon3/>;
                                                case 7:
                                                    return <Group_icon7/>;
                                                case 5:
                                                    return <Group_icon5/>;
                                                case 6:
                                                    return <Group_icon6/>;
                                                default:
                                                    return <Group_icon4 className={"grey_icon"}/>;
                                            }
                                        };
                                        return (
                                            <div className={activeArchiveGroup === 0 ? "item" : "item light"} key={indx}>
                                                <div className="photo_users">
                                                    {calcSpecialId(elem.quest.speciality)}
                                                    <div className="group_photo">
                                                        {elem.quest_members && elem.quest_members.length > 0 &&
                                                            elem.quest_members.map((member, indx) => {
                                                                    if (indx === 0) return <div key={indx}>
                                                                        <img
                                                                            src={member.avatar || fake_photos}
                                                                            style={{zIndex: 5}}
                                                                        />
                                                                    </div>;
                                                                    if (indx === 1) return <div key={indx}>
                                                                        <img src={member.avatar || fake_photos} style={{
                                                                            zIndex: 4,
                                                                            marginLeft: -8
                                                                        }}/>
                                                                    </div>;
                                                                    if (indx === 2) return <div key={indx}>
                                                                        <img src={member.avatar || fake_photos} style={{
                                                                            zIndex: 3,
                                                                            marginLeft: -8
                                                                        }}/>
                                                                    </div>;
                                                                    if (indx === 3) return <div key={indx}>
                                                                        <img src={member.avatar || fake_photos} style={{
                                                                            zIndex: 2,
                                                                            marginLeft: -8
                                                                        }}/>
                                                                    </div>;
                                                                    if (indx === 4) return <div key={indx}>
                                                                        <img src={member.avatar || fake_photos} style={{
                                                                            zIndex: 1,
                                                                            marginLeft: -8
                                                                        }}/>
                                                                    </div>;
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="title_group">{elem.name}</div>
                                                <div
                                                    className="info">{elem.quest.name}</div>
                                                <div style={{display: 'flex', marginTop: 'auto'}}>
                                                    <div
                                                        onClick={() => {
                                                            dispatch(setCurrentQuestR(elem.quest));
                                                            dispatch(setCurrentGroupR(elem));
                                                            history(`/backoffice/student-progress/${elem.id}/0`);
                                                        }}
                                                        className="btn_open"
                                                    >
                                                        {variable_13}
                                                    </div>
                                                    {activeArchiveGroup === 0
                                                        ? <Popconfirm
                                                            title={variable_46}
                                                            onConfirm={() => handleSendArchive(elem)}
                                                        >
                                                            <div className="archive">{variable_47}</div>
                                                        </Popconfirm>
                                                        : <Popconfirm
                                                            title={variable_48}
                                                            onConfirm={() => handleOutArchive(elem)}
                                                        >
                                                            <div className="archive out">{variable_49}</div>
                                                        </Popconfirm>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                        {total && total > 12
                            ? <div className="pagination_all">
                                <Pagination
                                    itemRender={itemRender}
                                    defaultCurrent={1}
                                    defaultPageSize={12}
                                    total={total}
                                    onChange={(e) => setCurrentPage(e)}
                                    showTitle={false}
                                />
                            </div>
                            : <></>
                        }
                    </div>
                }
            </div>
            <ModalInfo
                openModalInfo={openModalInfo}
                setOpenModalInfo={() => {
                    setOpenModalInfo(false)
                    history("/backoffice/modules")
                }}
                rediectToPay={payModule}
            />
        </div>
    );
};

export default GroupsList;