let initialState = {
    activeListProfession: 1,
    activeProfession: null,
    activeVacancy: null,
    dataProfession: {
        it: [],
        all: []
    },
    dataBaseProfs: [],
    listProfessionOrigin: []
};
const SearchProfession = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ACTIVE_LIST_PROFESSION":
            return {
                ...state,
                activeListProfession: action.payload
            };
        case "SET_ACTIVE_PROFESSION":
            return {
                ...state,
                activeProfession: action.payload
            };
        case "SET_ACTIVE_VACANCY":
            return {
                ...state,
                activeVacancy: action.payload
            };
        case "SET_DATA_BASE_PROFESSION":
            return {
                ...state,
                dataBaseProfs: action.payload,
                listProfessionOrigin: action.payload?.length > 0 
                ? action.payload 
                : state.listProfessionOrigin
            };
        case "SET_DATA_PROFESSION":
            let newObj = {};
            if (state.activeListProfession === 0)
                newObj = {
                    it: state.dataProfession.it,
                    all: action.payload
                }
            else newObj = {
                it: action.payload,
                all: state.dataProfession.all,
            }
            return {
                ...state,
                dataProfession: newObj,
                listProfessionOrigin: action.payload
            };
        case "SET_LIST_PROFESSION_ORIGIN":
            return {
                ...state,
                listProfessionOrigin: action.payload
            };

        default:
            return state;
    }
}
export default SearchProfession;