import React from 'react';
import fake_photos from "../../../../images/icons/fake_photos.png";
import { Progress } from "antd";
import { defaultFuncAndData } from "../../../utils/defaultData";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

const ProgressStudents = (props) => {
    const {allProgress,  history} = props;
    const variable_19 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_19"});

    return (
        <div className="study_block">
            {allProgress && allProgress.length > 0
                ? <>
                    {allProgress.map((elem, indx) =>
                        <div
                            className={"member"}
                            key={indx}
                        >
                            <div
                                className={"study"}
                                onClick={() => history(`/backoffice/student-card/${elem.id}`)}
                            >
                                <img
                                    src={elem.user.profile && elem.user.profile.avatar || fake_photos}
                                    style={{borderRadius: 14}}
                                />
                                <div className="fio">
                                    {elem.user.profile && elem.user.profile.name}
                                </div>
                                <Progress
                                    type="dashboard"
                                    percent={elem.progress}
                                    gapDegree={1}
                                    strokeColor={defaultFuncAndData.calcColorPercentProgress(elem.progress)}
                                    width={42}
                                    height={42}
                                    format={(percent) => percent}
                                    trailColor={"#474C5A"}
                                    strokeWidth={20}
                                />
                            </div>
                        </div>
                    )
                    }
                </>
                : <div className={"no_content"}> {variable_19}</div>
            }
        </div>
    );
};

export default ProgressStudents;