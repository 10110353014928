import React from "react";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import "./PrevButton.scss";

const PrevButton = ({click}) => {

    const variable_10 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_5"});
    return (
        <div className="prev_button" onClick={click}>
            <Arrow/>
            <div> {variable_10}</div>
        </div>
    );
};

export default PrevButton;