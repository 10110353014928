import React, { useEffect, useState } from 'react';
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import requests from "../../../../axios/requests";
import { useParams } from "react-router-dom";
import { setConfig } from "../../../../../redux/actions/actions";

import Slider from "./Slider";
import Button from "../../../../components/reuseComponents/Button/Button";
import variableLanguage from "../../../../utils/hooks/hookGetVariablesLanguage";
import close_modal from "../../../../../images/icons/close_modal-course.svg";

const ModalBuy = ({openModalBuy, setOpenModalBuy}) => {
    const variable_11 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_11'});
    const variable_14 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_14'});
    const variable_15 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_15'});
    const variable_19 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_19'});

    const dispatch = useDispatch();
    const params = useParams();
    if (params?.token)
        localStorage.setItem("token", params.token)

    const {range, config} = useSelector(state => state.SliderReducer);
    const [result, setResult] = useState(null);
    const [price, setPrice] = useState(null);

    let buyTries = () => {
        requests.auth.buy_education_plan(range)
            .then(res => {
                setTimeout(() => window.location.href = res.data.payment_url)
            })
            .catch(err => console.error(err))
    }
    let calcResultPrice = () => {
        let discount = 0;
        for (let i = 5; i < range; i++)
            discount += 0.01;

        setResult((1 - discount.toFixed(2)) * (Number(price) * range))
    }
    useEffect(() => {
        requests.auth.get_config()
            .then(res => {
                dispatch(setConfig(res.data))
                setPrice(res.data.PLAN_TEACHER_RATE)
            })
            .catch(err => console.error(err))
    }, [])
    useEffect(() => {
        calcResultPrice()
    }, [range, price, config])
    return (
        <Modal
            open={params?.token ? true : openModalBuy}
            footer={null}
            className={"buy_education"}
            closable={false}
            onCancel={() => setOpenModalBuy(false)}
            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
            bodyStyle={{padding: "30px", borderRadius: 40}}
            destroyOnClose={true}
        >
            <div className="title">
                {variable_19}
                <div
                    className="close"
                    onClick={() => setOpenModalBuy(false)}
                >
                    <img src={close_modal} alt="close_modal"/>
                </div>
            </div>
            <div className="text">
                {variable_14}
                <span>{range}</span>
            </div>
            <Slider/>
            <div className="text">
                {variable_15}
                <span>{result?.toFixed(0)} ₽</span>
            </div>
            <Button
                text={variable_11}
                click={() => buyTries()}
                margin={"15px 0 0 0"}
                maxWidth={"100%"}
                zIndex={1}
            />
        </Modal>
    );
};
export default ModalBuy;