export const changeLanguage = (payload) => ({type: 'CHANGE_LANGUAGE', payload})
export const setEndTrialDate = (payload) => ({type: 'SET_TRIAL_DATE', payload});
export const setStatusTrial = (payload) => ({type: 'SET_STATUS_TRIAL', payload});
export const changeFocusOrganization = (payload) => ({type: 'CHANGE_FOCUS_ORGANIZATION', payload});
export const setCurrentQuestR = (payload) => ({type: 'SET_CURRENT_QUEST', payload});
export const setCurrentGroupR = (payload) => ({type: 'SET_CURRENT_GROUP', payload});
export const setActiveModules = (payload) => ({type: 'SET_ACTIVE_MODULES', payload});
export const setListModules = (payload) => ({type: 'SET_LIST_MODULES', payload});
export const setConfig = (payload) => ({type: 'SET_CONFIG', payload})
export const setRange = (payload) => ({type: 'SET_RANGE', payload})
export const setScrollDirection = (payload) => ({type: 'SCROLL_DIRECTION', payload})
export const setCollapse = (payload) => ({type: 'SET_COLLAPSE', payload})
export const setFlagSteps = (payload) => ({type: 'SET_FLAG_STEPS', payload})
export const setScrollUp = (payload) => ({type: 'SET_SCROLL_UP', payload})
export const setShowBuyModal = (payload) => ({type: 'SET_SHOW_GENERATE_BUY', payload})

//экшены для скиллсета
export const setSelectedTag = (payload) => ({type: 'SET_SELECTED_TAG', payload})
export const setLoadingGpt = (payload) => ({type: 'SET_LOADING_GPT', payload})
export const setLoadingIndx = (payload) => ({type: 'SET_LOADING_INDX', payload})
