import React from 'react';
import cn from "classnames";

import close_tag from "../../../images/icons/close_tag.svg";
import active_checkbox from "../../../images/active_checkbox.svg";
import add_inactive from "../../../images/add_icon-inactive.svg";
import "./Tag.scss";

const Tag = (props) => {
    const {
        elem, removeTag, border, colorActive, click,
        cursorPointer, className, hideRemoveIcon, exist_quest,
        countCourses, countPlanes
    } = props;
    return (
        <div
            className={cn(`tag ${className || ""}`, {
                without_img: className && (elem.has_generated_quest || hideRemoveIcon),
                color_active: colorActive,
                cursor_pointer: cursorPointer,
                margin_right: (countCourses !== undefined && countPlanes !== undefined),
                border
            })
            }
            onClick={click}
        >
            <span>{elem.name}</span>
            {(!elem.has_generated_quest && !hideRemoveIcon) &&
                <img
                    onClick={() => removeTag(elem)}
                    src={close_tag}
                />
            }
            {exist_quest &&
                <img src={active_checkbox} alt="check"/>
            }
            {!className &&
                <img src={add_inactive} alt="check"/>
            }
            {(countCourses !== undefined && countPlanes !== undefined) &&
                <div className="label">
                    <div className="figure"/>
                    <span className="count">
                        {countPlanes}
                    </span>
                    <div className="figure"/>
                    <span className="count">
                        {countCourses}
                    </span>
                </div>
            }
        </div>
    );
};

export default Tag;