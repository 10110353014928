import React from "react";
import InputMask from "react-input-mask";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

import rus from "../../../images/country/russian_icon.png";
import belarus from "../../../images/country/belarus_icon.png";
import kazahstan from "../../../images/country/kazahstan_icon.png";
import azer from "../../../images/country/az.png";
import uz from "../../../images/country/uz.png";
import gr from "../../../images/country/gr.png";
import kr from "../../../images/country/kr.png";
import { ReactComponent as ArrowLanguage } from "../../../images/arrow.svg";


const PhoneMask = (props) => {
    let {title, flags, data,activeMaskPhone, setChangeValue, handleChangeFlags, handleChangeFormProfile} = props;

    let beforeMaskedStateChange = ({nextState}) => {
        let {value} = nextState;
        let newValue = value.split("");

        if (value.endsWith("/")) {
            value = value.slice(0, -1);
        }

        if (newValue[4] === "3" && newValue[5] === "7" && newValue[6] === "5") {
            newValue[4] = "_";
            newValue[5] = "_";
            newValue[6] = "_";
            newValue = newValue.join("");
            nextState.value = newValue;
            return {
                ...nextState,
                newValue
            };
        }
        return {
            ...nextState,
            value
        };
    };

    const variable_8 = variableLanguage({keyPage: "profile", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "profile", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "profile", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "profile", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "profile", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "profile", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "profile", keyVariable: "variable_14"});

    const arrMaskPhone = [
        "+7 (999) 999-99-99",
        "+375 (99) 999-99-99",
        "+7 (999) 999-99-99",
        "+\\9\\9\\4 (99) 999-99-99",
        "+\\9\\9\\8 (99) 999-99-99",
        "+\\9\\9\\5 (999) 999-99-99",
        "+\\9\\9\\6 (999) 99-99-99",
    ];
    const arrIconsCountry = [
        <div className="icon_country"><img src={rus}/></div>,
        <div className="icon_country"><img src={belarus}/></div>,
        <div className="icon_country"><img src={kazahstan}/></div>,
        <div className="icon_country"><img src={azer}/></div>,
        <div className="icon_country"><img src={uz}/></div>,
        <div className="icon_country"><img src={gr}/></div>,
        <div className="icon_country"><img src={kr}/></div>,
    ];
    return (
        <div className="phone_parent">
            {title &&
                <div className="title">{title}</div>
            }
            <InputMask
                className={flags.dropdownPhone ? "phoneNumber active" : "phoneNumber"}
                style={{width: "100%", zIndex: 3}}
                value={data.phone}
                alwaysShowMask={true}
                onChange={(e) => setChangeValue(e.target.value, "phone")}
                mask={arrMaskPhone[activeMaskPhone]}
                beforeMaskedStateChange={beforeMaskedStateChange}
            />
            {arrIconsCountry[activeMaskPhone]}
            <div className={flags.dropdownPhone ? "drop_down-phone active" : "drop_down-phone"}>
                <ul>
                    <li onClick={() => {
                        handleChangeFormProfile(0, "activeMaskPhone");
                        handleChangeFormProfile("", "phone");
                        handleChangeFlags(false, "dropdownPhone");
                    }}>
                        <img width={21} height={15} src={rus}/>
                        +7 (___) ___-__-__ {variable_8}
                    </li>
                    <li onClick={() => {
                        handleChangeFormProfile(1, "activeMaskPhone");
                        handleChangeFormProfile("", "phone");
                        handleChangeFlags(false, "dropdownPhone");
                    }}>
                        <img width={21} height={15} src={belarus}/>
                        +375 (___) ___-__-__
                        {variable_9}
                    </li>
                    <li onClick={() => {
                        handleChangeFormProfile(2, "activeMaskPhone");
                        handleChangeFormProfile("", "phone");
                        handleChangeFlags(false, "dropdownPhone");
                    }}>
                        <img width={21} height={15} src={kazahstan}/>
                        +7 (___) ___-__-__
                        {variable_10}
                    </li>
                    <li onClick={() => {
                        handleChangeFormProfile(3, "activeMaskPhone");
                        handleChangeFormProfile("", "phone");
                        handleChangeFlags(false, "dropdownPhone");
                    }}>
                        <img width={21} height={15} src={azer}/>
                        +994 (___) __-__-__
                        {variable_11}
                    </li>
                    <li onClick={() => {
                        handleChangeFormProfile(4, "activeMaskPhone");
                        handleChangeFormProfile("", "phone");
                        handleChangeFlags(false, "dropdownPhone");
                    }}>
                        <img width={21} height={15} src={uz}/>
                        +998 (__) ___-__-__
                        {variable_12}
                    </li>
                    <li onClick={() => {
                        handleChangeFormProfile(5, "activeMaskPhone");
                        handleChangeFormProfile("", "phone");
                        handleChangeFlags(false, "dropdownPhone");
                    }}>
                        <img width={21} height={15} src={gr}/>
                        +995 (___) __-__-__
                        {variable_13}
                    </li>
                    <li onClick={() => {
                        handleChangeFormProfile(6, "activeMaskPhone");
                        handleChangeFormProfile("", "phone");
                        handleChangeFlags(false, "dropdownPhone");
                    }}>
                        <img width={21} height={15} src={kr}/>
                        +996 (___) __-__-__
                        {variable_14}
                    </li>
                </ul>
            </div>
            <div
                className={flags.dropdownPhone ? "arrow_phone active" : "arrow_phone"}
                onClick={() => handleChangeFlags(!flags.dropdownPhone, "dropdownPhone")}>
                <ArrowLanguage className={flags.dropdownPhone ? "active" : ""}/>
            </div>
        </div>
    );
};

export default PhoneMask;