import React, { useEffect, useState } from "react";
import { useNavigate,  useParams } from "react-router-dom";
import requests from "../../axios/requests";
import moment from "moment";

import { DemoRow } from "../GroupsList/GroupsList";
import PrevBtn from "../../components/reuseComponents/PrevBtn";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

import fake_photos from "../../../images/icons/fake_photos.png";
import { ReactComponent as Accept } from "../../../images/icons/result_accept.svg";
import { ReactComponent as Deny } from "../../../images/icons/result_false.svg";

const CheckTestScorm = () => {
    const variable_3 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_5"});
    const variable_7 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_7"});
    const variable_11 = variableLanguage({keyPage: "checkTestWork", keyVariable: "variable_11"});

    const params = useParams();
    const history = useNavigate();
    const [quizData, setQuizData] = useState({});

    useEffect(() => {
        requests.quiz.get_quiz_result_id(params)
            .then((res) => {
                setQuizData(res.data);
            })
            .catch(err => console.error(err));
    }, []);
    return (
        <div className={"check_test"}>
            <div
                className="group_demo-block"
                style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 10}}
            >
                <PrevBtn click={() => history(-1)}/>
                <DemoRow/>
            </div>
            <div className="top_info">
                <div className="image_block">
                    <img src={quizData.user && quizData.user.profile.avatar || fake_photos}/>
                    <div className="fio">{quizData.user && quizData.user.profile.name || ""}</div>
                </div>
                <div className={"info_row"}>
                    <span>{variable_11} </span>
                    <span>SCORM</span>
                </div>
                <div className={"info_row"}>
                    <span>{variable_3} </span>
                    <span>{quizData.stage && quizData.stage.quest.name || ""}</span>
                </div>
                <div className={"info_row"}>
                    <span>{variable_4}: </span>
                    <span> {quizData.stage && quizData.stage.name}</span>
                </div>
                <div className={"info_row"}>
                    <span>{variable_5} </span>
                    <span> {quizData.title && quizData.title || ""}</span>
                </div>
            </div>
            <div className="list_history-quiz">
                {quizData?.results?.length > 0 &&
                    quizData.results.map((elem, indx) =>
                        <div className="history_quiz control_quiz" key={indx}>
                            <div className="top_block">
                                <div className="group_left">
                                    {elem.lms_status
                                        ? <Accept/>
                                        : <Deny/>
                                    }
                                    <div className={"group_block"}>
                                        <span>
                                        Тест
                                            {elem.lms_status
                                                ? " "
                                                : " не "
                                            }
                                            пройден
                                        </span>
                                        <div style={{height: 22, margin: "10px 5px 0 5px"}}>
                                            <span style={{color: "#757A88"}}>
                                                ({elem.correct_answers_count || 0}
                                            </span>
                                            <span style={{color: "#757A88", marginLeft: 3}}>
                                                /
                                            </span>
                                            <span style={{color: "#757A88", marginLeft: 3}}>
                                                {elem.questions_count && elem.questions_count || ""})
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"date_current"}>
                                    <span style={{color: "#757A88"}}>
                                        {variable_7}
                                    </span>
                                    <span>
                                        {elem.created_at && moment(elem.created_at).format("DD.MM.YY / HH:mm:ss") || ""}
                                    </span>
                                </div>
                            </div>
                            <ul className="result_list">
                                {Object.keys(elem?.questions)?.length > 0 &&
                                    Object.keys(elem.questions).map((question, ind) =>
                                        <li key={ind}>
                                            <div
                                                className="indx"
                                                style={elem.questions[question] === "correct"
                                                    ? {color: "#30E0A1"}
                                                    : {color: "#FF3434"}
                                                }
                                            >
                                                {ind + 1}.
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default CheckTestScorm;