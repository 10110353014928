import React from 'react';
import { Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setRange } from "../../../../../redux/actions/actions";

const SliderAntd = () => {
    const {range} = useSelector(state => state.SliderReducer);
    const dispatch = useDispatch();
    return (
        <Slider
            value={range}
            onChange={(value) => dispatch(setRange(value))}
            min={5}
            max={50}
            className={"slider_antd"}
        />
    );
};

export default SliderAntd;