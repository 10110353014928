import { combineReducers, compose, applyMiddleware, legacy_createStore as createStore } from 'redux';
import Reducer from './reducers/reducer';
import SearchProfession from "./reducers/searchProfessionReducer";
import SliderReducer from "./reducers/sliderReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    Reducer,
    SearchProfession,
    SliderReducer
});
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware())
);

export default store;