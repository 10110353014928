import React, { useEffect, useRef, useState } from 'react';
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion/dist/framer-motion";
import { useLocation } from "react-router-dom";
import { setScrollDirection, setScrollUp } from "../../../redux/actions/actions";

import Sidebar from "../SideBar/Sidebar";
import MobileSidebar from "../SideBar/MobileSidebar/MobileSidebar";
import './Layout.scss';

const Layout = ({children}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const refLastScrollY = useRef();
    const {resizeWindow, scrollDirection, scrollUp} = useSelector(state => state.Reducer);
    const [timeoutReducer, setTimeoutReducer] = useState(null);

    let onScroll = (event) => {
        const scrollY = event.currentTarget.scrollTop;
        const direction = scrollY > refLastScrollY?.current ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - refLastScrollY?.current > 1 || scrollY - refLastScrollY?.current < -1)) {
            if (timeoutReducer)
                clearTimeout(timeoutReducer)
            setTimeoutReducer(
                setTimeout(() => dispatch(setScrollDirection(direction)), 10)
            )
        }
        refLastScrollY.current = scrollY > 0 ? scrollY : 0;
    }

    useEffect(() => {
        if (scrollUp) {
            document.getElementById('element_for_scroll_up').scrollTop = 0;
            dispatch(setScrollUp(false))
        }
    }, [scrollUp])
    return (
        <motion.div
            className={cn("layout", {
                padding: (resizeWindow && location.pathname === "/" && resizeWindow < 991) ||
                    !localStorage.getItem("token"),
            })}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            {localStorage.getItem("token") &&
                <>
                    <Sidebar/>
                    <MobileSidebar
                        flagHiddenFotter={scrollDirection && scrollDirection === "up"}
                    />
                </>
            }
            <div
                className="content"
                onScroll={onScroll}
                id={'element_for_scroll_up'}
            >
                {children}
            </div>
        </motion.div>
    );
};

export default Layout;