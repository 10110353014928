import React, { useState } from "react";
import ReactPlayer from "react-player";
import { defaultFuncAndData } from "../../../utils/defaultData";

import it_icon from "../../../../images/icons/it_icon.png";
import medical_icon from "../../../../images/icons/medical_icon.png";
import languages_icon from "../../../../images/icons/languages_icon.png";
import menegement_icon from "../../../../images/icons/menegement_icon.png";
import finance_icon from "../../../../images/icons/finance_icon.png";
import design_icon from "../../../../images/icons/design_icon.png";
import sport_icon from "../../../../images/icons/sport_icon.png";

import { ReactComponent as Start } from "../../../../images/icons/start_.svg";
import { ReactComponent as End } from "../../../../images/icons/end_.svg";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import LandingNew from "../../LandingNew/LandingNew";
import CourseCard from "../../../components/CourseCard/CourseCard";

const Step3 = (props) => {
    let {
        originalSpeakerProps, originalSuitsProps, originalLearnProps,
        setStep, setPublicCourse, courseData
    } = props;

    const variable_1 = variableLanguage({keyPage: "currentCourse_step_3", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "currentCourse_step_3", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "currentCourse_step_3", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "currentCourse_step_3", keyVariable: "variable_4"});
    const variable_10 = variableLanguage({keyPage: "currentCourse_step_3", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "currentCourse_step_3", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "currentCourse_step_3", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "currentCourse_step_3", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "currentCourse_step_3", keyVariable: "variable_14"});
    const variable_15 = variableLanguage({keyPage: "currentCourse_step_3", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "currentCourse_step_3", keyVariable: "variable_16"});

    const [activeNavigation, setActiveNavigation] = useState(0);
    return (
        <div className={"step_3"}>
            <div className="demo_row">
                <div className="group_demo" style={{paddingRight: 0}}>
                    <div
                        className={activeNavigation === 0 ? "active" : ""}
                        onClick={() => setActiveNavigation(0)}
                    >
                        {variable_1}
                    </div>
                    <div
                        className={activeNavigation === 1 ? "active" : ""}
                        onClick={() => setActiveNavigation(1)}
                    >
                        {variable_2}
                    </div>
                </div>
            </div>
            <div className="btn_row">
                <div
                    className="btn_prev"
                    onClick={() => setStep()}
                >
                    {variable_3}
                </div>
                <div className="btn_send" onClick={() => setPublicCourse(true)}>
                    {variable_4}
                </div>
            </div>
            {activeNavigation === 0 &&
                <>
                    <LandingNew
                        inCourseLanding={true}
                        data={courseData}
                        originalSpeakerProps={originalSpeakerProps}
                        originalSuitsProps={originalSuitsProps}
                        originalLearnProps={originalLearnProps}
                    />
                </>
            }
            {activeNavigation === 1 &&
                <>
                    <div className={"parent_course"}>
                        <div className={"reuse_course-item"}>
                            <CourseCard
                                course={props.courseData}
                                callInCreate={true}
                                dateCourse={true}
                                cost={true}
                                rating={true}
                                popular={false}
                                needLink={false}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default Step3;