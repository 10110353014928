import React, { useState } from "react";
import requests from "../../../../axios/requests";
import variableLanguage from "../../../../utils/hooks/hookGetVariablesLanguage";

import { Button, Input, Checkbox, message, InputNumber, } from "antd";
import ReuseDropDown from "../../../../components/reuseComponents/ReuseDropDown";

import remove_icon from "../../../../../images/icons/remove_icon.png";
import add_step from "../../../../../images/icons/add_step.png";
import { ReactComponent as AddQuestion } from "../../../../../images/icons/add_question.svg";
import { ReactComponent as RemoveQuestion } from "../../../../../images/icons/remove_question.svg";

const Choice = (props) => {
    let {
        el, setCheckbox, placeholder, removeAnswer,
        index, setText, setAddAnswer, viewRemoveAnswerIcon, viewAddAnswerIcon = false,
    } = props;

    return (
        <div className={"choice_parent"}>
            <Checkbox
                className={"checkbox_antd"}
                style={{alignSelf: "center"}}
                checked={el.correct}
                onClick={(e) => {
                    setCheckbox(e.target.checked);
                }}>
                <div style={{margin: " 0 5px", color: "white"}}>{index}</div>
            </Checkbox>
            <div className="group">
                <Input
                    value={el.text}
                    onChange={(e) => setText(e.target.value)}
                    className={"input_classic"}
                    style={{paddingRight: 30}}
                    size="large"
                    placeholder={placeholder}
                />
                {viewRemoveAnswerIcon &&
                    <img
                        onClick={() => removeAnswer(el.id)}
                        src={remove_icon}
                    />
                }
            </div>
            {viewAddAnswerIcon &&
                <div
                    onClick={() => setAddAnswer()}
                    className="add_answer"
                >
                    <img src={add_step}/>
                </div>
            }

        </div>
    );
};
const CreateQuiz = (props) => {
    let {stage_id, setView} = props;

    const variable_34 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_34"});
    const variable_38 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_38"});
    const variable_39 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_39"});
    const variable_40 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_40"});
    const variable_41 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_41"});
    const variable_42 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_42"});
    const variable_48 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_48"});
    const variable_49 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_49"});
    const variable_50 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_50"});
    const variable_51 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_51"});
    const variable_52 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_52"});
    const variable_53 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_53"});
    const variable_61 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_61"});
    const variable_62 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_62"});
    const variable_63 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_63"});
    const variable_64 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_64"});
    const variable_65 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_65"});
    const variable_66 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_66"});
    const variable_67 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_67"});
    const variable_68 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_68"});
    const variable_69 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_69"});

    const [indexActiveQuestion, setIndexActiveQuestion] = useState(0);
    const [activeTypeQuiz, setActiveTypeQuiz] = useState(0);
    const [activeRecomendation, setActiveRecomendation] = useState(0);
    const [countAttemps, setCountAttemps] = useState(1);
    const [quizTitle, setQuizTitle] = useState("");
    const [objRecomendation, setObjRecomendation] = useState({
        "low": "",
        "middle": "",
        "high": ""
    });

    const [flagOpenDropDown, setFlagOpenDropDown] = useState(false);
    const [flagOpenRecomendation, setFlagOpenRecomendation] = useState(false);
    const [questions, setQuestions] = useState([
        {
            answers: [
                {correct: false, text: ""},
                {correct: false, text: ""},
            ],
            title: ""
        }
    ]);

    let apiCreateQuiz = () => {
        if (!quizTitle) return message.error(variable_38);
        if (questions.length === 0) return message.error(variable_39);

        for (let i = 0; i < questions.length; i++) {
            if (!questions[i].title) {
                setIndexActiveQuestion(i);
                return message.error(variable_40);
            }
            let countCorrect = 0;
            for (let j = 0; j < questions[i].answers.length; j++) {
                if (questions[i].answers[j].correct) {
                    countCorrect++;
                }
                if (!questions[i].answers[j].text) {
                    setIndexActiveQuestion(i);
                    return message.error(variable_41);
                }
            }
            if (countCorrect === 0) {
                setIndexActiveQuestion(i);
                return message.error(variable_42);
            }
        }
        let objCreate = {
            stage: stage_id,
            theory_type: "Q",
            title: quizTitle,
        };
        if (activeTypeQuiz === 1) {
            objCreate.attempts = countAttemps;
            objCreate.block_next = true;
        }
        requests.stages
            .create_stages_metherial([objCreate])
            .then((response) => {
                if (response.status === 201) {
                    message.success(variable_69);
                    let idQuiz = response.data[0].id;
                    if (activeRecomendation === 1) {
                        let objRec = {
                            "quiz": idQuiz,
                            "low": objRecomendation.low,
                            "middle": objRecomendation.middle,
                            "high": objRecomendation.high
                        };
                        requests.quiz.create_recomendations([objRec])
                            .then((res) => {
                            })
                            .catch(err => console.error(err));
                    }
                    let sendArrQuestions = [];
                    let sendArrAnswers = [];
                    for (let i = 0; i < questions.length; i++) {
                        let obj = {
                            "quiz": idQuiz,
                            "title": questions[i].title,
                        };
                        sendArrQuestions = [...sendArrQuestions, obj];
                    }
                    requests.quiz.create_question(sendArrQuestions)
                        .then((res) => {
                            let idQuestion = res.data;
                            for (let i = 0; i < idQuestion.length; i++) {
                                for (let j = 0; j < questions[i].answers.length; j++) {
                                    let objAnsw = {
                                        "question": idQuestion[i].id,
                                        "text": questions[i].answers[j].text,
                                        "correct": questions[i].answers[j].correct,
                                    };
                                    sendArrAnswers = [...sendArrAnswers, objAnsw];
                                }
                            }
                            requests.quiz.create_answers(sendArrAnswers)
                                .catch((err) => console.error(err))
                                .finally(() => {
                                    setView();
                                });
                        });
                }
            })
            .catch((e) => console.log(e));
    };
    return (
        <>
            <div className={"create_quiz"}>
                <div className="label">{variable_64}</div>
                <ReuseDropDown
                    flagOpen={flagOpenDropDown}
                    setFlagOpen={(bool) => setFlagOpenDropDown(bool)}
                    arrList={[variable_61, variable_62]}
                    activeArrList={activeTypeQuiz}
                    setActiveArrList={(indx) => setActiveTypeQuiz(indx)}
                />
                <div className="label">{variable_63}</div>
                <div className="row_navigation-questions">
                    {Array(questions.length).fill(0).map((_, indx) =>
                        <div
                            className={indexActiveQuestion === indx ? "number active" : "number"}
                            key={indx}
                            onClick={() => {
                                setIndexActiveQuestion(indx);
                            }}
                        >
                            <span>{indx + 1}</span>
                        </div>
                    )}
                    <div
                        onClick={() => {
                            let newArr = [...questions];
                            newArr.push(
                                {
                                    answers: [{correct: false, text: ""}, {correct: false, text: ""}],
                                    title: ""
                                }
                            );
                            setQuestions(newArr);
                            setIndexActiveQuestion(prev => {
                                if (questions.length > 0) {
                                    return questions.length;
                                } else return 0;
                            });
                        }}
                        className="create_question"
                    >
                        <AddQuestion/>
                    </div>
                    {questions.length > 0 &&
                        <div
                            onClick={() => {
                                let newArr = [...questions];
                                newArr.splice(indexActiveQuestion, 1);
                                setIndexActiveQuestion(prev => {
                                    if (prev !== 0) {
                                        return prev - 1;
                                    } else return 0;
                                });
                                setQuestions(newArr);
                            }}
                            className="remove_question"
                        >
                            <RemoveQuestion/>
                        </div>
                    }

                    {activeTypeQuiz === 1 &&
                        <div className={"count_resp"}>
                            <span>{variable_65}</span>
                            <InputNumber
                                size="small"
                                min={1}
                                max={10}
                                value={countAttemps}
                                defaultValue={1}
                                onChange={(e) => setCountAttemps(e)}
                            />
                        </div>
                    }
                </div>
                <div className="label">{variable_66}</div>
                <Input
                    className={"input_classic"}
                    value={quizTitle}
                    onChange={(e) => setQuizTitle(e.target.value)}
                    placeholder={variable_66}
                    maxLength={99}
                />
                {questions && questions.length > 0 &&
                    <>
                        <div className="question_title" style={{ marginTop: 10, position: "relative"}}>
                            <div className="label">{variable_67}</div>
                            <Input
                                value={questions[indexActiveQuestion].title}
                                onChange={(e) => {
                                    let newArr = [...questions];
                                    questions[indexActiveQuestion].title = e.target.value;
                                    setQuestions(newArr);
                                }}
                                className={"input_classic"}
                                size="large"
                                placeholder={variable_68}
                                style={{marginBottom: "10px"}}
                            />
                        </div>

                        {questions[indexActiveQuestion].answers.map((el, ind) => {
                            let funcViewAddAnswerIcon = () => {
                                if (questions[indexActiveQuestion].answers.length - 1 == ind)
                                    return true;
                            };
                            return (
                                <Choice
                                    el={el}
                                    index={ind + 1}
                                    setCheckbox={(e) => {
                                        let newArr = [...questions];
                                        newArr[indexActiveQuestion].answers[ind].correct = e;
                                        setQuestions(newArr);
                                    }}
                                    setText={(e) => {
                                        let newArr = [...questions];
                                        newArr[indexActiveQuestion].answers[ind].text = e;
                                        setQuestions(newArr);
                                    }}
                                    placeholder={`${variable_48} ` + (ind + 1)}
                                    removeAnswer={() => {
                                        let newArr = [...questions];
                                        newArr[indexActiveQuestion].answers.splice(ind, 1);
                                        setQuestions(newArr);
                                    }}
                                    viewAddAnswerIcon={funcViewAddAnswerIcon()}
                                    setAddAnswer={() => {
                                        let newArr = [...questions];
                                        if (questions[indexActiveQuestion].answers.length < 10) {
                                            newArr[indexActiveQuestion].answers.push({correct: false, text: ""});
                                            setQuestions(newArr);
                                        }
                                    }}
                                    viewRemoveAnswerIcon={questions[indexActiveQuestion].answers.length > 2 ? true : false}
                                />
                            );
                        })}
                    </>
                }
                <div className="recomenditon">
                    <div className="group_drop-down">
                        <div className="label" style={{zIndex: 4}}>
                            {variable_49}
                        </div>
                        <ReuseDropDown
                            flagOpen={flagOpenRecomendation}
                            setFlagOpen={(bool) => setFlagOpenRecomendation(bool)}
                            arrList={[variable_50, variable_51]}
                            activeArrList={activeRecomendation}
                            setActiveArrList={(indx) => setActiveRecomendation(indx)}
                        />
                    </div>

                    {activeRecomendation === 1
                        && <>
                            <Input.TextArea
                                value={objRecomendation.low}
                                onChange={(e) => setObjRecomendation((prev) => ({...prev, ["low"]: e.target.value}))}
                                placeholder={`${variable_52} 0 - 33%`}
                                className={"textarea_antd"}
                                style={{marginTop: 0}}
                            />
                            <Input.TextArea
                                value={objRecomendation.middle}
                                onChange={(e) => setObjRecomendation((prev) => ({...prev, ["middle"]: e.target.value}))}
                                className={"textarea_antd"}
                                placeholder={`${variable_52} 34 - 66%`}
                            />
                            <Input.TextArea
                                value={objRecomendation.high}
                                onChange={(e) => setObjRecomendation((prev) => ({...prev, ["high"]: e.target.value}))}
                                className={"textarea_antd"}
                                placeholder={`${variable_52} 67 - 100%`}
                            />
                        </>}
                </div>
                <div className="group_buttons">
                    <div
                        className={ "btn_save"}
                        onClick={apiCreateQuiz}
                    >
                        {variable_53}
                    </div>
                    <div
                        className="btn_cansel btn_save"
                        onClick={() => setView()}
                        style={{ backgroundColor: "grey"}}
                    >
                        {variable_34}
                    </div>
                </div>
            </div>
        </>
    );
};
export default React.memo(CreateQuiz);

