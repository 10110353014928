import React, { useEffect, useState } from "react";
import requests from "../../../axios/requests";
import ReactPlayer from "react-player";
import { Input, message, Upload } from "antd";
import CommentCustom from "../../../components/reuseComponents/CommentCustom";

import send_comment from "../../../../images/icons/send_comment.png";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import { defaultFuncAndData } from "../../../utils/defaultData";

const Video = (props) => {
    const {
        youtube, text, url_refs, video_file, video_file_name,
        task_file, task_file_name, id
    } = props.videoProps;

    const variable_3 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_6"});
    const variable_17 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_17"});
    const variable_22 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_22"});

    const [valueComment, setValueComment] = useState("");
    const [comments, setComments] = useState("");
    const [taskFile, setTaskFile] = useState("");
    const [taskFileName, setTaskFileName] = useState("");

    let isVideo = (fileName) => {
        const videoFormats = [
            ".WEBM", ".MPG", ".MP2", ".MPEG", ".MPE", ".MPV",
            ".OGG", ".MP4", ".M4P", ".M4V", ".AVI", ".WMV",
            ".MOV", ".QT", ".FLV", ".SWF", "AVCHD"
        ]
        return (videoFormats.some((format) => fileName?.toLowerCase().includes(format.toLowerCase())))
    }
    let getApiComments = () => {
        requests.comments.get_comments({
            contentType: 46,
            objectId: id
        })
            .then((res) => {
                setComments(res.data)
            })
            .catch((error) => console.log(error, "error get_comments"));
    };
    let sendComment = () => {
        if (!valueComment) {
            return;
        }
        let obj = {
            phone: props.profile.phone,
            user: {
                profile: {
                    avatar: props.profile.avatar || ''
                },
            },
            content_type: 46,
            object_id: id,
            text: valueComment
        }
        requests.comments.create_comment(obj)
            .then((res) => {
                setComments((prev) => [...prev, res.data])
            })
            .catch((error) => console.log(error, "error add_comment"));
        setValueComment("");
    };
    let handleSetLikeDislake = (id, value) => {
        let obj = {
            "content_type": 86,
            "object_id": id,
            "value": value
        }
        requests.comments.like_dislike(obj)
            .then((res) => {
                getApiComments();
            })
            .catch((error) => console.log(error, "error set_like"))
            .finally();
    };
    let handleRemoveComment = (id) => {
        requests.comments.remove_comment(id)
            .then(() => {
                getApiComments();
                message.success(variable_22)
            })
            .catch(err => console.error(err))
    }
    let uploadProps = {
        onChange({file, fileList}) {
            if (file.status !== 'uploading') {
            }
        },
        listType: "card",
        fileList: [
            {
                uid: "-1",
                name: taskFileName,
                status: "done",
                response: props.variable_26,
                url: taskFile,

            }
        ],
        defaultFileList: [
            {
                uid: '1',
                name: taskFileName,
                status: 'done',
                url: taskFile,
            },
        ],
    };

    useEffect(() => {
        getApiComments()
    }, [id])
    useEffect(() => {
        if (task_file_name) {
            setTaskFileName(task_file_name);
        }
        if (task_file) {
            setTaskFile(task_file);
        }
    }, [task_file_name, task_file]);
    return (
        <>
            <div className={'video_block'}>
                {(video_file || youtube) &&
                    <div className={"video_content"}>
                        <div className="player-wrapper">
                            <ReactPlayer
                                className="react-player"
                                controls={true}
                                url={youtube ? youtube : video_file}
                                width="100%"
                                height="100%"
                                ref={player => player}
                            />
                        </div>
                    </div>
                }
                {video_file_name &&
                    <div className={"video_file-name"}>{video_file_name}</div>
                }
                {task_file &&
                    <>
                        <div className={'h4'} style={{fontSize: 22, marginTop: 5}}>{variable_17}</div>
                        <Upload {...uploadProps} disabled></Upload>
                    </>
                }
                {text &&
                    <>
                        <div
                            className="description_title"
                            style={{marginTop: 10}}
                        >
                            {variable_3}
                        </div>
                        <pre
                            className="description"
                            style={{marginBottom: 10}}
                        >
                            {defaultFuncAndData.linkChanger(text)}
                        </pre>
                    </>
                }
                {url_refs &&
                    <>
                        <div className="url_title">
                            {variable_4}
                        </div>
                        <div className="url_ref">
                            {defaultFuncAndData.linkChanger(url_refs)}
                        </div>
                    </>
                }
            </div>
            {props?.show_comments &&
                <div style={{width: '100%', marginTop: 15}}>
                    {comments && comments.length > 0 &&
                        <div className="comment_title">
                            {variable_5}
                        </div>
                    }
                    {comments && comments.length > 0 &&
                        comments.map((com, indx) =>
                            <div key={indx}>
                                <CommentCustom
                                    {...com}
                                    setLike={(id) => handleSetLikeDislake(id, true)}
                                    setDislake={(id) => handleSetLikeDislake(id, false)}
                                    removeComment={handleRemoveComment}
                                />
                            </div>
                        )
                    }
                    <div className="comment_input">
                        <Input
                            placeholder={variable_6}
                            className={"input_classic"}
                            value={valueComment}
                            onChange={(e) => setValueComment(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) sendComment();
                            }}
                        />
                        <img src={send_comment} onClick={sendComment}/>
                    </div>
                </div>
            }
        </>
    );
};

export default Video;
