import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import requests from "../../../axios/requests";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import { Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import { setActiveModules, setEndTrialDate, setStatusTrial } from "../../../../redux/actions/actions";
import cn from 'classnames';

import fake_photos from "../../../../images/icons/fake_photos.png";
import { ReactComponent as NavBarNotification } from "../../../../images/icons/navbar_notification.svg";
import { ReactComponent as NavBarCase } from "../../../../images/icons/nav_bar-course.svg";
import { ReactComponent as SidebarModule } from "../../../../images/icons/sidebar_module.svg";
import { ReactComponent as AcademicPlan } from "../../../../images/search_profession/academic_plan.svg";
import { ReactComponent as Logout } from "../../../../images/icons/logout.svg";
import { ReactComponent as HistoryPay } from "../../../../images/icons/history_pay.svg";
import { ReactComponent as Logo } from "../../../../images/icons/logo.svg";
import './MobileSidebar.scss';

const MobileSidebar = ({flagHiddenFotter}) => {
    const history = useNavigate();
    const {pathname} = useLocation();
    const [countNotification, setCountNotification] = useState(null);
    const dispatch = useDispatch();

    const variable_1 = variableLanguage({keyPage: "navbar", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "navbar", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "navbar", keyVariable: "variable_3"});

    const variable_8 = variableLanguage({keyPage: "navbar", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "navbar", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "navbar", keyVariable: "variable_10"});

    let getCountNotificationInterval = () => {
        setCountNotification(localStorage.getItem("count_notification"));
    };

    let handleLogOut = async () => {
        await requests.auth.logout()
            .catch(err => console.error(err))
        dispatch(setActiveModules([]))
        dispatch(setEndTrialDate(""))
        dispatch(setStatusTrial(null))
        window.localStorage.removeItem("user_id");
        window.localStorage.removeItem("name");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("photo");
        history(`/backoffice/login`);
    };
    useEffect(() => {
        getCountNotificationInterval();
        setInterval(() => {
            getCountNotificationInterval();
        }, 1000);
    }, []);
    return (
        <div
            className={cn("sidebar_mobile", {
                active: flagHiddenFotter || flagHiddenFotter === null
            })}
        >
            <div
                className={(flagHiddenFotter || flagHiddenFotter === null)
                    ? "demo_row-mobile open"
                    : "demo_row-mobile"
                }
            >
                <div className="group_mobile">
                    <div
                        className={pathname === "/backoffice/my-course" ? "active" : ""}
                        onClick={() => history("/backoffice/my-course")}
                    >
                        {variable_8}
                    </div>
                    <div
                        className={pathname === "/backoffice/request-members" ? "active" : ""}
                        onClick={() => history("/backoffice/request-members")}
                    >
                        {variable_9}
                    </div>
                    <div
                        className={pathname === "/backoffice/groups-list" ? "active" : ""}
                        onClick={() => history("/backoffice/groups-list")}
                    >
                        {variable_10}
                    </div>
                </div>
            </div>

            <div className="bar_mobile">
                <Logo
                    width={38}
                    height={38}
                    onClick={() => window.open("https://skillometer.ru/", 'blank')}
                />
                <img
                    width={38}
                    height={38}
                    src={localStorage.getItem("photo") || fake_photos}
                    onClick={() => {
                        history("/backoffice/profile");
                    }}
                />
                <div
                    className={window.location.href.includes("notification") ? "notification_svg active" : "notification_svg"}
                    onClick={() => {
                        history("/notification");
                    }}
                >
                    <NavBarNotification
                        width={34}
                        height={34}
                    />
                    {countNotification && countNotification > 0
                        ? <div className="count_notification">
                            <span>{countNotification > 99 ? "99+" : countNotification}</span></div>
                        : <></>
                    }
                </div>
                <NavBarCase
                    width={38}
                    height={38}
                    className={
                        !window.location.href.includes("/notification") &&
                        !window.location.href.includes("/history-pay") &&
                        !window.location.href.includes("/modules") &&
                        !window.location.href.includes("/profile") &&
                        !window.location.href.includes("/backoffice/academic-plan")
                            ? "active"
                            : ""
                    }
                    onClick={() => {
                        history("/backoffice/my-course");
                    }}
                />
                <HistoryPay
                    width={38}
                    height={38}
                    className={window.location.href.includes("/history-pay") ? "active history_pay-svg" : "history_pay-svg"}
                    onClick={() => {
                        history('/backoffice/history-pay')
                    }}
                />
                <div
                    className={window.location.href.includes("/backoffice/academic-plan")
                        ? "active academic-plan-svg"
                        : "academic-plan-svg"
                    }
                    onClick={() => history("/backoffice/academic-plan")}
                >
                    <AcademicPlan/>
                </div>
                {/* }*/}

                <SidebarModule
                    onClick={() => {
                        history("/backoffice/modules");
                    }}
                    width={38}
                    height={38}
                    className={window.location.href.includes("/modules") ? "active" : ""}
                />


                <Popconfirm
                    title={variable_1}
                    onConfirm={() => handleLogOut()}
                    okText={variable_2}
                    cancelText={variable_3}
                >
                    <Logout
                        width={38}
                        height={38}
                    />
                </Popconfirm>

            </div>
        </div>
    );
};

export default MobileSidebar;