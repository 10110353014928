import React, { useEffect, useState } from 'react';
import requests from "../../axios/requests";
import fake_photos from "../../../images/icons/fake_photos.png";
import { useParams } from "react-router-dom";

const ViewItemJournal = () => {
    const params = useParams();
    const id = params.id;
    const [homeworkHistory, setHomeworkHistory] = useState([]);
    const [theoriesCurrent, setTheoriesCurrent] = useState([]);

    const dateParser = (date) => {
        const options = {
            // era: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
            timezone: "UTC",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        };
        const actualDate = new Date(date);
        return `${actualDate.toLocaleDateString("ru", options)} `;
    };

    useEffect(() => {
        requests.quests.homework_history(id)
            .then(({data}) => {
                if (data && data.length > 0) {
                    setHomeworkHistory(data)
                    /*  setTheoriesCurrent(data.filter(x => x.id === checkHomeWorkId.taskId))*/
                }
            });

    }, [])
    useEffect(() => {
        /*if (homeworkHistory && homeworkHistory.length > 0) {
            requests.stages.get_stage_theories_info(homeworkHistory[0].task.id)
                .then(({data}) => {
                    if (data && data.length > 0) {
                        console.log(data, 'dadta this ')
                        /!* setTheoriesCurrent(data.filter(x => x.id === checkHomeWorkId.taskId))*!/
                    }
                });
        }*/
    }, [homeworkHistory])
    return (
        <div className={'check_work view_item-journal'}>
            {homeworkHistory && homeworkHistory.length > 0 &&
                <>
                    <div className="top_info">
                        <div className="image_block">
                            <img src={homeworkHistory[0].user.avatar || fake_photos}/>
                            <div
                                className="fio">{homeworkHistory[0].user.name} {homeworkHistory[0].user.last_name}</div>
                        </div>
                        <div className={'info_row'}>
                            <span>Название курса: </span>
                            <span>{homeworkHistory[0].task.quest}</span>
                        </div>
                        <div className={'info_row'}>
                            <span>Занятие 1: </span>
                            <span> {homeworkHistory[0].task.stage.name}</span>
                        </div>
                        <div className={'info_row'}>
                            <span>Задание: </span>
                            <span>  {homeworkHistory[0].task.title}</span>
                        </div>
                    </div>
                    <div className="h3">{homeworkHistory[0].task.stage.name}</div>

                    {homeworkHistory[0].task.task_file
                        ? <img src={homeworkHistory[0].task.task_file} className={'img_preview'}/>
                        : <><span>{homeworkHistory[0].task.task_file_name}</span></>
                    }


                    <div className="h4">Описание</div>
                    <div className="question">{homeworkHistory[0].task.title}</div>
                    {theoriesCurrent && theoriesCurrent.length > 0 && theoriesCurrent[0].urls &&
                        <>
                            <div className="h4">Ссылки на источники</div>
                            <div style={{color: '#A2AACA', fontSize: 20}}>{theoriesCurrent[0].urls}</div>
                        </>
                    }
                    {homeworkHistory.map((record, i) =>
                        <>
                            <div
                                className={(homeworkHistory.length - homeworkHistory.length) === i ? "box_teatcher-answer border_green" : "box_teatcher-answer"}>
                                <div className="top_row">
                                    <div className={'number_answer'}>
                                        {record.passed
                                            ? <>
                                                <span>Ответ: {homeworkHistory.length - i}</span>
                                                <span style={{color: '#30E0A1'}}> (Принято)</span>
                                            </>
                                            : <>
                                                <span>Ответ: {homeworkHistory.length - i}</span>
                                                <span style={{color: '#FF3434'}}> (Отправлено на доработку)</span>
                                            </>
                                        }

                                    </div>
                                    <div className='time'>Время: {dateParser(record.time)}</div>
                                </div>
                                <div className="bottom_row">
                                    <div className="text_row"><span>Ответ ученика:  </span> <span> {record.text}</span>
                                    </div>
                                    <div className="text_row"><span>Прикреплённый файл:  </span>
                                        <a href={record.file}>{record.task_file_name}</a>
                                    </div>
                                </div>
                            </div>

                            <div className="box_teatcher-answer-bottom">
                                <div className="bottom_row">
                                    <div className="text_row"><span>Ваш ответ:  </span>
                                        <span> {record.teachers_answer}</span></div>
                                    <div className="text_row"><span>Прикреплённый файл:  </span>
                                        <span>{record.teachers_file_name}</span>
                                    </div>
                                </div>
                            </div>

                        </>
                    )}
                </>
            }
        </div>
    );
};

export default ViewItemJournal;