import React from 'react';
import ChartJs from "../ChartJs";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

import { ReactComponent as StatiscticUp } from "../../../../images/icons/statistic_up.svg";
import { ReactComponent as StatiscticDown } from "../../../../images/icons/statistic_down.svg";

export const ReuseStatisticBlock = (props) => {
    let {title, indxSort, count, procentague, data, current, diff = 0, up = false} = props;

    const variable_1 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_4"});

    const list = [variable_1, variable_2, variable_3, variable_4];
    return (
        <div className="item">
            <div className="title">{title}</div>
            <div className="chart">
                <ChartJs data={data} current={current} diff={diff}/>
            </div>
            <div className="info">
                <div className="left">
                    {up
                        ? <StatiscticUp/>
                        : <StatiscticDown/>
                    }
                    <span style={up ? {color: "#30E0A1"} : {}}>{procentague}%</span>
                </div>
                <div className="right">
                    {count}
                </div>
            </div>
            <span>{list[indxSort]}</span>
        </div>
    );
};

export default ReuseStatisticBlock;