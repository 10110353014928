import React from 'react';
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { defaultFuncAndData } from "../../utils/defaultData";
import requests from "../../axios/requests";

import { message, Rate, Tooltip } from "antd";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

import { ReactComponent as CopyLink } from "../../../images/icons/copy_link.svg";
import { ReactComponent as ViewCourse } from "../../../images/icons/view_course.svg";
import { ReactComponent as Popular } from "../../../images/icons/popular_course.svg";
import { ReactComponent as Start } from "../../../images/icons/start_.svg";
import { ReactComponent as End } from "../../../images/icons/end_.svg";

import sport_icon from "../../../images/icons/sport_icon.png";
import medical_icon from "../../../images/icons/medical_icon.png";
import menegement_icon from "../../../images/icons/menegement_icon.png";
import it_icon from "../../../images/icons/it_icon.png";
import languages_icon from "../../../images/icons/languages_icon.png";
import design_icon from "../../../images/icons/design_icon.png";
import finance_icon from "../../../images/icons/finance_icon.png";
import ReactPlayer from "react-player";
import default_course from "../../../images/icons/default_course.png";
import video_icon from "../../../images/icons/video_icon.svg";
import article_icon from "../../../images/icons/article_icon.svg";
import test_icon from "../../../images/icons/test_icon.svg";
import home_work_icon from "../../../images/icons/home_work.svg";
import code_icon from "../../../images/icons/code_icon.svg";

export const CourseCard = (props) => {
    const {
        course, setCourse, setOpenModalInfo, baseAccess, callInCreate = false, rating = false,
        dateCourse = false, cost = false, popular = false, needLink = true,
    } = props;
    const history = useNavigate();
    const {language, activeModules} = useSelector(state => state.Reducer);

    const variable_5 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_14"});
    const variable_15 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_17"});
    const variable_18 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_18"});
    const variable_19 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_19"});
    const variable_20 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_20"});
    const variable_21 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_21"});
    const variable_22 = variableLanguage({keyPage: "courseCard", keyVariable: "variable_22"});

    const category = [
        <div className="category" style={{backgroundColor: "#FF5937"}}>
            <img width={16} height={16} src={sport_icon}/>
            <span>{variable_17}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#3077FF"}}>
            <img width={19} height={19} src={medical_icon}/>
            <span>{variable_12}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#FFD23B"}}>
            <img width={18} height={18} src={menegement_icon}/>
            <span style={{color: "#343843"}}>{variable_14}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#8146FF"}}>
            <img width={17} height={15} src={it_icon}/>
            <span>{variable_11}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#30E0A1"}}>
            <img width={26} height={12} src={languages_icon}/>
            <span style={{color: "#343843"}}>{variable_13}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#FF3434"}}>
            <img width={16} height={16} src={design_icon}/>
            <span>{variable_16}</span>
        </div>,
        <div className="category" style={{backgroundColor: "#FF47ED"}}>
            <img width={14} height={14} src={finance_icon}/>
            <span>{variable_15}</span>
        </div>
    ];

    let course_defaultImage = (id) => {
        switch (id) {
            case 1:
                return defaultFuncAndData.defaultImageCourse(0);
            case 2:
                return defaultFuncAndData.defaultImageCourse(1);
            case 3:
                return defaultFuncAndData.defaultImageCourse(2);
            case 4:
                return defaultFuncAndData.defaultImageCourse(3);
            case 5:
                return defaultFuncAndData.defaultImageCourse(4);
            case 6:
                return defaultFuncAndData.defaultImageCourse(5);
            case 7:
                return defaultFuncAndData.defaultImageCourse(6);
        }
    };
    return (
        <>
            {(course.demo_video || course.demo_youtube) ?
                <ReactPlayer
                    className="react-player"
                    style={{marginBottom: 15, width: "100%", borderRadius: 30}}
                    controls={true}
                    url={course.demo_youtube ? course.demo_youtube : course.demo_video}
                    width="100%"
                    height="175px"

                />
                : <img
                    src={course.y_image || course_defaultImage(course.speciality) || default_course}
                    style={{marginBottom: 15, width: "100%", aspectRation: 1.77 }}
                    className={"preview_image"}
                />
            }
            {rating && course.rating &&
                <div className="rate">
                    <Rate
                        disabled
                        allowHalf
                        defaultValue={course.rating}
                    />
                </div>
            }
            {popular &&
                <div className="popular">
                    <div className={"svg"}><Popular/></div>
                    <span>0</span>
                </div>
            }
            {category[course.speciality - 1]}
            <Tooltip
                overlayInnerStyle={{maxHeight: 400, overflow: "auto"}}
                placement="right"
                title={course.name || ""}
            >
                <div className="info">{defaultFuncAndData.spliceStr(course.name, 50)}</div>
            </Tooltip>
            {course?.count_video || course?.count_article || course?.count_quiz || course?.count_task || course?.count_code_task
                ? <div className="stage">
                    <ul>
                        {course?.count_video > 0 &&
                            <>
                                {language === "ru" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={`Курс содержит ${course.count_video} видео.`}
                                        >
                                            <span>{course.count_video}</span>
                                            <img src={video_icon} width={25}/>
                                        </Tooltip>
                                    </li>
                                }
                                {language === "en" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={course.count_video === 1
                                                ? `The course contains ${course.count_video} video.`
                                                : `The course contains ${course.count_video} videos.`
                                            }
                                        >
                                            <span>{course.count_video}</span>
                                            <img src={video_icon} width={25}/>
                                        </Tooltip>
                                    </li>
                                }
                            </>
                        }
                        {course?.count_article > 0 &&
                            <li>
                                {language === "ru" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={`Курс содержит ${course.count_article} ${defaultFuncAndData.declension(course.count_article, [" статью", " статьи", " статей"])}.`}
                                        >
                                            <span>{course.count_article}</span>
                                            <img src={article_icon} width={25}/>
                                        </Tooltip>
                                    </li>
                                }
                                {language === "en" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={course.count_article === 1
                                                ? `The course contains ${course.count_article} article.`
                                                : `The course contains ${course.count_article} articles.`
                                            }
                                        >
                                            <span>{course.count_article}</span>
                                            <img src={article_icon} width={25}/>
                                        </Tooltip>
                                    </li>
                                }
                            </li>
                        }
                        {course?.count_quiz > 0 &&
                            <>
                                {language === "ru" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={`Курс содержит ${course.count_quiz} ${defaultFuncAndData.declension(course.count_quiz, [" тест", " теста", " тестов"])}.`}
                                        >
                                            <span>{course.count_quiz}</span>
                                            <img src={test_icon} width={25}/>
                                        </Tooltip>
                                    </li>
                                }
                                {language === "en" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={course.count_quiz === 1
                                                ? `The course contains ${course.count_quiz} test.`
                                                : `The course contains ${course.count_quiz} tests.`
                                            }
                                        >
                                            <span>{course.count_quiz}</span>
                                            <img src={test_icon} width={25}/>
                                        </Tooltip>
                                    </li>
                                }
                            </>
                        }
                        {course?.count_text_task > 0 &&
                            <>
                                {language === "ru" &&
                                    <>
                                        <li>
                                            <Tooltip
                                                align={'top'}
                                                title={`Курс содержит ${course.count_text_task} ${defaultFuncAndData.declension(course.count_text_task, [" домашнее задание", " домашних задания", " домаших заданий"])}.`}
                                            >
                                                <span>{course.count_text_task}</span>
                                                <img src={home_work_icon} width={25}/>
                                            </Tooltip>
                                        </li>
                                    </>
                                }
                                {language === "en" &&
                                    <li>
                                        <Tooltip
                                            align={'top'}
                                            title={course.count_task === 1
                                                ? `The course contains ${course.count_task} homework.`
                                                : `The course contains ${course.count_task} homeworks.`
                                            }
                                        >
                                            <span>{course.count_task}</span>
                                            <img src={home_work_icon} width={25}/>
                                        </Tooltip>
                                    </li>
                                }
                            </>
                        }
                        {course?.count_code_task > 0 &&
                            <>
                                <li>
                                    <Tooltip
                                        align={'top'}
                                        title={`Курс содержит ${course.count_code_task} ${defaultFuncAndData.declension(course.count_code_task, [" задание", " задания", " заданий"])} по программированию.`}
                                    >
                                        <span>{course.count_code_task}</span>
                                        <img src={code_icon} width={25}/>
                                    </Tooltip>
                                </li>
                            </>
                        }
                    </ul>
                </div>
                : <></>
            }
            {course && course.speciality_tag.length > 0
                ? <>
                    <div className="title_skill">{variable_5}</div>
                    <div className="key_skills">
                        {course.speciality_tag.map((skill, indx) =>
                            <div className={"skills"} key={indx}><span>{skill.name}</span></div>
                        )}
                    </div>
                </>
                : null
            }
            <div className="bottom_block">
                {(dateCourse && course.dateBegin && course.dateEnd)
                    ? <div className="date_course">
                        <Start/>
                        <span>
                            {variable_8}
                            {course.dateBegin.includes(".")
                                ? <>{" "}{course.dateBegin}</>
                                : <>{" "}{moment(course.dateBegin).format("DD.MM.YY")}</>
                            }
                        </span>
                        <End/>
                        <span>
                            {variable_9}
                            {course.dateEnd.includes(".")
                                ? <>{" "}{course.dateEnd}</>
                                : <>{" "}{moment(course.dateEnd).format("DD.MM.YY")}</>
                            }
                        </span>
                    </div>
                    : null
                }
                {needLink &&
                    <div className="link_row">
                        <div
                            className="copy_link"
                            onClick={() => {
                                if (!callInCreate) {
                                    requests.quests.make_a_link(course.id)
                                        .then((link) => {
                                            setTimeout(async () => {
                                                const url = link.data.link.replace('catalog/corp-preview?ses=', 'course-preview/');
                                                await navigator.clipboard.writeText(url)
                                            })
                                            message.success(variable_10, 0.5);
                                        })
                                        .catch((err) => console.error(err));
                                }
                            }}
                        >
                            <div className="title">{variable_21}</div>
                            <div className="svg"><CopyLink/></div>
                        </div>
                        <div className="view_catalog">
                            <div className={"title"}>
                                {course.isEnabled && !course.isCorporate
                                    ? variable_18
                                    : variable_19
                                }
                            </div>
                            {course.isCorporate
                                ? <div className="corp">
                                    <div>{variable_20}</div>
                                </div>
                                : <>
                                    <div className="checkbox" style={{marginLeft: "auto"}}>
                                        <label className="switch" htmlFor={`checkbox${course.id}`}>
                                            <input
                                                type="checkbox"
                                                id={`checkbox${course.id}`}
                                                checked={course.isEnabled}
                                                onChange={(e) => {
                                                    if (!callInCreate) {
                                                        if (e.currentTarget.checked) {
                                                            if (activeModules?.includes("base") && typeof baseAccess !== "undefined" && baseAccess) {
                                                                setCourse(course.id, e.currentTarget.checked);
                                                                requests.quests
                                                                    .quest_is_enabled({
                                                                        quest_id: course.id,
                                                                        is_enabled: e.currentTarget.checked
                                                                    })
                                                                    .catch((e) => {
                                                                        console.error(e.message, "error quest_is_enabled");
                                                                    });
                                                            } else
                                                                if (typeof setOpenModalInfo === "function")
                                                                    setOpenModalInfo(true)
                                                        } else {
                                                            setCourse(course.id, e.currentTarget.checked);
                                                            requests.quests
                                                                .quest_is_enabled({
                                                                    quest_id: course.id,
                                                                    is_enabled: e.currentTarget.checked
                                                                })
                                                                .catch((e) => {
                                                                    console.error(e.message, "error quest_is_enabled");
                                                                });
                                                        }
                                                    }
                                                }}
                                            />
                                            <div className="slider round"></div>
                                        </label>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }
                {cost && !course.isCorporate
                    ? <div className="cost">
                        <span>{variable_6}</span>
                        {course.participateprice
                            ? <span style={{color: "#30E0A1"}}> {course.participateprice}₽</span>
                            : <span style={{color: "#30E0A1"}}>{variable_7}</span>
                        }
                    </div>
                    : ""
                }
                {course.comments_count > 0 &&
                    <div className="comment_row">
                        <div className="comment_count">
                            <span>
                                {course.comments_count}
                                {language === "en" &&
                                    <>
                                        {course.comments_count > 1 &&
                                            "comments"
                                        }
                                        {course.comments_count === 1 &&
                                            "comment"
                                        }
                                    </>
                                }
                                {language === "ru" &&
                                    <>
                                        {defaultFuncAndData.declension(course.comments_count, [" комментарий", " комментария", " комментариев"])}
                                    </>
                                }
                            </span>
                        </div>
                    </div>
                }
                {!callInCreate
                    ? <div className="group_btn">
                        <div
                            onClick={() => {
                                if (!callInCreate) {
                                    // history("/backoffice/view-course/" + course.ses)
                                    history("/backoffice/current-course/" + course.ses)
                                }
                            }}
                            className="btn_edit-course"
                        >{variable_22}
                        </div>
                        <div
                            className="btn_view"
                            onClick={() => {
                                if (!callInCreate)
                                    history(`/backoffice/course-preview/${course.ses}`)
                            }}
                        >
                            <ViewCourse/>
                        </div>
                    </div>
                    : <div className="btn_edit-course"> Перейти к курсу</div>
                }
            </div>
        </>
    )
};

export default CourseCard;