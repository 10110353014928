import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import requests from "../../axios/requests";

import { Input, message } from "antd";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import PrevBtn from "../../components/reuseComponents/PrevBtn";
import { DemoRow } from "../GroupsList/GroupsList";
import { StorageUploader } from "../../data/StorageUploader";

import { ReactComponent as Like } from "../../../images/icons/like.svg";
import { ReactComponent as Dislike } from "../../../images/icons/dislike.svg";
import fake_photos from "../../../images/icons/fake_photos.png";
import save_file from "../../../images/icons/save_file.png";
import send_comment from "../../../images/icons/send_comment.png";

export const CommentCustom = (props) => {
    let {text, created_at, likes_count, dislikes_count, id, setLike, setDislake, user} = props;
    let parseDateComment = (num) => {
        return moment(num).format("DD.MM.YYYY - HH:mm");
    };
    return (
        <div className="comment">
            <div className="comment_photo">
                <img
                    src={user.profile && user.profile.avatar || fake_photos}
                    style={{maxWidth: 40, height: 40, borderRadius: 13}}
                />
            </div>
            <div className="comment_fio">{user.profile && user.profile.name}</div>
            <div className="comment_date">{parseDateComment(created_at)}</div>
            <div className="comment_text">
                {text}
            </div>
            <div className="comment_like-dislike">
                <Like
                    onClick={() => setLike(id)}
                />
                <span>{likes_count}</span>
                <Dislike
                    onClick={() => setDislake(id)}
                />
                <span>{dislikes_count}</span>
            </div>
        </div>
    );
};
const dateParser = (date, language) => {
    const options = {
        // era: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        timezone: "UTC",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    };
    const actualDate = new Date(date);
    return `${actualDate.toLocaleDateString(language, options)} `;
};
const CheckHomeWork = (props) => {
    const {language} = useSelector(state => state.Reducer);
    const params = useParams();
    const history = useNavigate();

    const variable_1 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_14"});
    const variable_15 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_17"});
    const variable_18 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_18"});
    const variable_20 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_20"});
    const variable_21 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_21"});
    const variable_22 = variableLanguage({keyPage: "checkHomeWork", keyVariable: "variable_22"});

    const [theoriesCurrent, setTheoriesCurrent] = useState([]);
    const [activeBtn, setActiveBtn] = useState(null);
    const [homeworkList, setHomeworkList] = useState([]);
    const [yandexKey, setYandexKey] = useState("");
    const [fileName, setFileName] = useState("");
    const [answerTeatcher, setAnswerTeatcher] = useState("");
    const [comments, setComments] = useState("");
    const [valueComment, setValueComment] = useState("");
    const [task, setTask] = useState({});

    let acceptTask = (record, e) => {
        requests.quests.homework_check({
            ta_id: record.id,
            passed: true,
            teachers_answer: e,
            ...(fileName ? {
                teachers_file_name: fileName,
                teachers_file: `https://storage.yandexcloud.net/skill-storage/` + yandexKey
            } : {})
        })
            .then((v) => {
                message.success(variable_1);
                setTimeout(() => history(-1), 1000);
            });
    };
    let denyTask = (record, e) => {
        requests.quests.homework_check({
            ta_id: record.id,
            passed: false,
            teachers_answer: e,
            ...(fileName ? {
                teachers_file_name: fileName,
                teachers_file: `https://storage.yandexcloud.net/skill-storage/` + yandexKey
            } : {})
        })
            .then((v) => {
                message.success(variable_2);
                setTimeout(() => history(-1), 1000);
            });
    };
    let checkHomeworkHistory = (id) => {
        if (params?.readOnly === "false") {
            requests.quests.homework_history(id)
                .then((v) => {
                setHomeworkList(v.data);
            });
        } else {
            requests.quests.get_homework_history_new(id)
                .then((v) => {
                setHomeworkList([v.data]);
            });
        }
    };
    let getApiComments = () => {
        requests.comments.get_comments({
            contentType: 46,
            objectId: params.taskId
        })
            .then((res) => {
                setComments(res.data);
            })
            .catch((error) => console.error(error));
    };
    let sendComment = () => {
        if (!valueComment) {
            return;
        }
        let obj = {
            user: {
                profile: {
                    avatar: fake_photos
                }
            },
            content_type: 46,
            object_id: params.taskId,
            text: valueComment
        };
        requests.comments.create_comment(obj)
            .then((res) => {
                setComments((prev) => [...prev, res.data]);
            })
            .catch((error) => console.error(error));
        setValueComment("");
    };
    let handleSetLikeDislake = (id, value) => {
        let obj = {
            "content_type": 86,
            "object_id": id,
            "value": value
        };
        requests.comments.like_dislike(obj)
            .then((res) => {
                getApiComments();
            })
            .catch((error) => console.log(error))
            .finally();
    };
    let getCurrentBorderColor = (passed, status) => {
        if (passed && status) {
            return "box_teatcher-answer border_green";
        }
        if (!passed && !status) {
            return "box_teatcher-answer white_blue";
        }
        if (!passed && status) {
            return "box_teatcher-answer border_red";
        }
        return "box_teatcher-answer";
    };

    useEffect(() => {
        /* checkHomeworkHistoryNew(params.taskId);*/
        checkHomeworkHistory(params.id);
        getApiComments();
        requests.stages.get_stage_theories_info(params.taskStageId)
            .then(({data}) => {
                if (data && data.length > 0) {
                    setTheoriesCurrent(data.filter(x => String(x.id) === params.taskId));
                }
            });
    }, []);
    useEffect(() => {
        requests.storage.get_yandex_task_key()
            .then((v) => {
                setYandexKey(v.data.key);
            })
            .catch((err) => console.error(err, " error getYangexKey"));
    }, [activeBtn]);
    return (
        <div className="check_work-parent">
            <div
                className="group_demo-block"
                style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 10}}
            >
                <PrevBtn click={() => history(-1)}/>
                <DemoRow/>
            </div>
            <div className={"check_work"}>
                {/* {homeworkElem && Object.values(homeworkElem).length > 0 &&
                    <>
                        <div className={getCurrentBorderColor(homeworkElem.passed,homeworkElem.check_status)}>
                            <div className="top_row">
                                <div className={"number_answer"}>
                                    {homeworkElem.passed && homeworkElem.check_status &&
                                        <span style={{color: "#30E0A1"}}> ({variable_9}) </span>
                                    }
                                    {!homeworkElem.passed && !homeworkElem.check_status &&
                                        <span style={{color: "#FFD23B"}}> ({variable_10})</span>
                                    }
                                    {!homeworkElem.passed && homeworkElem.check_status &&
                                        <span style={{color: "#FF3434"}}> ({variable_11})</span>
                                    }
                                </div>
                                <div className="time">{variable_12} {dateParser(homeworkElem.time, language)}</div>
                            </div>
                            <div className="bottom_row">
                                <div className="text_row"><span>{variable_13}  </span>
                                    <span> {homeworkElem.text}</span>
                                </div>
                                {homeworkElem?.task_file_name &&
                                    <div className="text_row">
                                        <span>{variable_14}  </span>
                                        <a href={homeworkElem.file}>
                                            <img src={save_file}/>
                                            {homeworkElem.task_file_name}
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="box_teatcher-answer-bottom">
                            <div className="bottom_row">
                                <div className="text_row">
                                    <span>{variable_20} </span>
                                    <span> {homeworkElem.teachers_answer}</span>
                                </div>
                                {homeworkElem.teachers_file_name &&
                                    <div className="text_row">
                                        <span>{variable_14} </span>
                                        <span>{homeworkElem.teachers_file_name}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }*/}
                {homeworkList && homeworkList.length > 0 &&
                    <>
                        <div className="top_info">
                            <div className="image_block">
                                <img
                                    src={homeworkList[0].user?.profile?.avatar || homeworkList[0].user?.avatar || fake_photos}/>
                                <div
                                    className="fio">{homeworkList[0].user?.profile?.name || (homeworkList[0].user?.name + " " + homeworkList[0].user?.last_name)}
                                </div>
                            </div>
                            <div className={"info_row"}>
                                <span>{variable_3} </span>
                                <span>{homeworkList[0].task?.stage?.quest?.name || homeworkList[0].task?.quest}</span>
                            </div>
                            <div className={"info_row"}>
                                <span>{variable_4} </span>
                                <span> {homeworkList[0].task?.stage?.name || homeworkList[0].task?.stage?.name}</span>
                            </div>
                            <div className={"info_row"}>
                                <span>{variable_5} </span>
                                <span>  {homeworkList[0].task.title}</span>
                            </div>
                        </div>
                        <div className="h3">{homeworkList[0].task.stage?.name} </div>
                        {theoriesCurrent && theoriesCurrent.length > 0 &&
                            <>
                                {theoriesCurrent[0].image_file &&
                                    <img src={theoriesCurrent[0].image_file} className={"img_preview"}/>
                                }
                                {theoriesCurrent[0].task_file_name && theoriesCurrent[0].task_file
                                    ? <>
                                        <div className="h4">{variable_14}</div>
                                        <div style={{margin: "0 0 10px 0"}}>
                                            <img className="upload_icon" src={save_file}/>
                                            <a
                                                href={theoriesCurrent[0].task_file}
                                                style={{color: "#A2AACA", fontSize: 16}}
                                            >
                                                {theoriesCurrent[0].task_file_name}
                                            </a>
                                        </div>
                                    </>
                                    : <></>
                                }

                                <div className="h4">{variable_6}</div>
                                <div className="question">{task.title || theoriesCurrent[0].text}</div>
                            </>
                        }
                        {theoriesCurrent && theoriesCurrent.length > 0 && theoriesCurrent[0].urls &&
                            <>
                                <div className="h4">{variable_7}</div>
                                <div style={{color: "#A2AACA", fontSize: 20}}>{theoriesCurrent[0].urls}</div>
                            </>
                        }
                        {homeworkList.map((record, i) =>
                            <>
                                <div
                                    className={getCurrentBorderColor(record.passed, record.check_status)}
                                >
                                    <div className="top_row">
                                        <div className={"number_answer"}>
                                            <span>
                                                {params?.readOnly === "false"
                                                    ? <>{variable_8} {homeworkList.length - i}</>
                                                    : ""
                                                }
                                            </span>
                                            {record.passed && record.check_status &&
                                                <span style={{color: "#30E0A1"}}> ({variable_9}) </span>
                                            }
                                            {!record.passed && !record.check_status &&
                                                <span style={{color: "#B3DAFF"}}> ({variable_10})</span>
                                            }
                                            {!record.passed && record.check_status &&
                                                <span style={{color: "#FF3434"}}> ({variable_11})</span>
                                            }

                                        </div>
                                        <div className="time">{variable_12} {dateParser(record.time, language)}</div>
                                    </div>
                                    <div className="bottom_row">
                                        <div className="text_row"><span>{variable_13}  </span>
                                            <span> {record.text}</span>
                                        </div>
                                        {record?.task_file_name &&
                                            <div className="text_row">
                                                <span>{variable_14}  </span>
                                                <a href={record.file}> <img src={save_file}/>{record.task_file_name}</a>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {(homeworkList.length - homeworkList.length) === i && params.readOnly !== "false"
                                    ? <div className="box_teatcher-answer-bottom">
                                        <div className="bottom_row">
                                            <div className="text_row"><span>{variable_20} </span>
                                                <span> {record.teachers_answer}</span></div>
                                            {record.teachers_file_name &&
                                                <div className="text_row"><span>{variable_14} </span>
                                                    <a href={record.teachers_file} style={{color: 'white'}}>
                                                        <img src={save_file} className="upload_icon"/>
                                                        <span>{record.teachers_file_name}</span>
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    : <></>
                                }

                                {(homeworkList.length - homeworkList.length) === i
                                    ? params.readOnly !== "true"
                                        ? <div className="box_teatcher-answer-response">
                                            <div className="btn_row">
                                                <div
                                                    className={activeBtn === 0 ? "success btn active" : "success btn"}
                                                    onClick={() => setActiveBtn(0)}
                                                >
                                                    <span>{variable_15}</span>
                                                </div>
                                                <div
                                                    className={activeBtn === 1 ? "denied btn active" : "denied btn"}
                                                    onClick={() => setActiveBtn(1)}
                                                >
                                                    <span>{variable_16}</span>
                                                </div>
                                            </div>
                                            {activeBtn !== null &&
                                                <div className={"parent_block"}>
                                                    <div style={{
                                                        margin: "0 0 5px 15px",
                                                        color: "#A2AACA"
                                                    }}>{variable_8}</div>
                                                    <Input
                                                        value={answerTeatcher}
                                                        className={"input_classic"}
                                                        style={{borderColor: "#757A88", marginBottom: 10}}
                                                        placeholder={variable_17}
                                                        onChange={(e) => setAnswerTeatcher(e.target.value)}
                                                    />
                                                    <StorageUploader
                                                        yandexKey={yandexKey}
                                                        count={1}
                                                        setFileName={setFileName}
                                                    />
                                                    {activeBtn === 0 &&
                                                        <div
                                                            onClick={() => acceptTask(record, answerTeatcher)}
                                                            className="btn_success"
                                                        >
                                                            <span>{variable_18}</span>
                                                        </div>
                                                    }
                                                    {activeBtn === 1 &&
                                                        <div
                                                            onClick={() => denyTask(record, answerTeatcher)}
                                                            className="btn_denied"
                                                        >
                                                            <span>{variable_16}</span>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        : <></>
                                    : <div className="box_teatcher-answer-bottom">
                                        <div className="bottom_row">
                                            <div className="text_row"><span>{variable_20} </span>
                                                <span> {record.teachers_answer}</span></div>
                                            {record.teachers_file_name &&
                                                <div className="text_row"><span>{variable_14} </span>
                                                    <span>{record.teachers_file_name}</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </>
                        )}
                    </>
                }
                <div style={{maxWidth: 960, width: "100%", marginTop: "auto", paddingTop: 20}}>
                    {comments && comments.length > 0 &&
                        <div className="comment_title">
                            {variable_21}
                        </div>
                    }
                    {comments && comments.length > 0 &&
                        comments.map((com, indx) =>
                            <div key={indx}>
                                <CommentCustom
                                    {...com}
                                    setLike={(id) => handleSetLikeDislake(id, true)}
                                    setDislake={(id) => handleSetLikeDislake(id, false)}
                                />
                            </div>
                        )
                    }
                    <div className="comment_input">
                        <Input
                            placeholder={variable_22}
                            className={"input_classic"}
                            value={valueComment}
                            onChange={(e) => setValueComment(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) sendComment();
                            }}
                        />
                        <img src={send_comment} onClick={sendComment}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckHomeWork;
