import React, { useRef, useState } from 'react';
import moment from "moment/moment";
import { message, Popconfirm } from "antd";
import ModalReject from "../ModalReject/ModalReject";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

import { ReactComponent as Arrow } from "../../../../images/arrow.svg";
import fake_photos from "../../../../images/icons/fake_photos.png";

const StudentRow = (props) => {
    let {
        openGroupInList, indx, elem, setOpenGroupInList,
        getApiQuestGroup, handleInviteMember, currentQuestGroups,
        activeGroupMember, setActiveGroupMember, buttonNavigate,
        handleRejectMember, handleRejectMemberOld, setIdRemove, idRemove,
    } = props;

    const variable_5 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "innerRequestMember", keyVariable: "variable_11"});

    const [openReject, setOpenReject] = useState(false);
    const rowRef = useRef();

    let getTitle = (buttonNavigate) => {
        if (buttonNavigate === 0)
            return variable_5;
        if (buttonNavigate === 1)
            return variable_6;
    };
    let handleSendReject = (text) => {
        if (buttonNavigate === 0) {
            setIdRemove(elem.id);
            setTimeout(() => {
                handleRejectMember(elem, text);
            }, 111);
        }
        if (buttonNavigate === 1) {
            setIdRemove(elem.id);
            setTimeout(() => {
                handleRejectMemberOld(elem, text);
            }, 111);
        }
        setTimeout(() => {
            message.success("Ученик исключён")
            setOpenReject(false)
        }, 200)
    }

    if (window.innerWidth < 820)
        return (
            <div
                className={openGroupInList === indx
                    ? "table_row row_mobile open"
                    : "table_row row_mobile"
                }
                key={indx}
            >
                <div
                    className={openGroupInList === indx
                        ? "group_block open"
                        : "group_block"
                    }
                >
                    <div className="top_row">
                        <img src={elem?.user?.profile?.avatar || fake_photos}/>
                        <div className="border"/>
                        <div className="fio">{elem?.user?.profile?.name || ""}</div>
                        <div className="border"/>
                        <div className="phone">
                            {elem.user.phone
                                ? elem.user.phone.includes("+")
                                    ? elem.user.phone
                                    : "+" + elem.user.phone
                                : ""
                            }
                        </div>
                    </div>
                    <div className="middle_row">
                        <div
                            className="date">{elem.addDate ? moment(elem.addDate).format("DD.MM.YY HH:mm") : ""}</div>
                        <div className="border" style={{marginRight: 17}}/>
                        <div className="name_course">{elem.quest.name}</div>
                    </div>
                    {buttonNavigate !== 0 &&
                        <div className="bottom_row">
                            <div
                                onClick={() => {
                                    if (openGroupInList === indx) {
                                        setOpenGroupInList(null);
                                    } else {
                                        setOpenGroupInList(indx);
                                        getApiQuestGroup(elem);
                                    }
                                }}
                                className={openGroupInList === indx ? "drop_down-parent open" : "drop_down-parent"}
                            >
                                <div
                                    className="row"
                                    onClick={() => {
                                        setOpenGroupInList(null);
                                    }}
                                >
                                    {buttonNavigate === 1
                                        ? <span>{elem?.quest_group?.name || ""}</span>
                                        : <span>{variable_7}</span>
                                    }
                                    <Arrow/>
                                </div>
                                <div
                                    className={openGroupInList === indx ? "groups_children open" : "groups_children"}>
                                    {currentQuestGroups.length > 0 && currentQuestGroups.map((group, indx) => {
                                            /*  if (buttonNavigate === 0 && group.name === 'Без группы')
                                                 return
                                             else */
                                            return (
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        let arr = [
                                                            {memberId: elem.id},
                                                            {quest_group: group.id, status: 0}
                                                        ];
                                                        setActiveGroupMember(arr);
                                                    }}
                                                    className={
                                                        activeGroupMember.length > 0 && activeGroupMember[1].quest_group === group.id
                                                            ? "group active"
                                                            : elem.quest_group?.id === group.id ? "group active" : "group"
                                                    }
                                                    key={indx}
                                                >{group.name}</div>
                                            );
                                        }
                                    )}
                                </div>
                                {buttonNavigate === 1 &&
                                    <div
                                        className="btn_success"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleInviteMember(true);
                                        }}
                                    >
                                        <span>{variable_9}</span>
                                    </div>
                                }
                                {buttonNavigate === 2 &&
                                    <div
                                        className="btn_success"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleInviteMember(true);
                                        }}
                                    >
                                        <span>{variable_8}</span>
                                    </div>
                                }
                            </div>
                            {buttonNavigate !== 2 &&
                                <Popconfirm
                                    placement="bottom"
                                    title={`${getTitle(buttonNavigate)}?`}
                                    okText={variable_10}
                                    cancelText={variable_11}
                                    onConfirm={() => setOpenReject(true)}
                                >
                                    <div
                                        className="btn red"
                                        style={{marginLeft: 10}}
                                    >{getTitle(buttonNavigate)}
                                    </div>
                                </Popconfirm>
                            }
                        </div>
                    }
                    {(buttonNavigate === 0 && elem.quest?.isCorporate) &&
                        <div className="bottom_row">
                            <div
                                onClick={() => {
                                    if (openGroupInList === indx) {
                                        setOpenGroupInList(null);
                                    } else {
                                        setOpenGroupInList(indx);
                                        getApiQuestGroup(elem);
                                    }
                                }}
                                className={openGroupInList === indx ? "drop_down-parent open" : "drop_down-parent"}
                            >
                                <div
                                    className="row"
                                    onClick={() => {
                                        setOpenGroupInList(null);
                                    }}
                                >
                                    {buttonNavigate === 1
                                        ? <span>{elem?.quest_group?.name || ""}</span>
                                        : <span>{variable_7}</span>
                                    }
                                    <Arrow/>
                                </div>
                                <div
                                    className={openGroupInList === indx ? "groups_children open" : "groups_children"}>
                                    {currentQuestGroups.length > 0 && currentQuestGroups.map((group, indx) => {
                                            /*  if (buttonNavigate === 0 && group.name === 'Без группы')
                                                 return
                                             else */
                                            return (
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        let arr = [
                                                            {memberId: elem.id},
                                                            {quest_group: group.id, status: 0}
                                                        ];
                                                        setActiveGroupMember(arr);
                                                    }}
                                                    className={
                                                        activeGroupMember.length > 0 && activeGroupMember[1].quest_group === group.id
                                                            ? "group active"
                                                            : elem.quest_group?.id === group.id ? "group active" : "group"
                                                    }
                                                    key={indx}
                                                >{group.name}</div>
                                            );
                                        }
                                    )}
                                </div>
                                {buttonNavigate === 0 &&
                                    <div
                                        className="btn_success"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleInviteMember();
                                        }}
                                    >
                                        <span>{variable_8}</span>
                                    </div>
                                }
                                {buttonNavigate === 1 &&
                                    <div
                                        className="btn_success"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleInviteMember(true);
                                        }}
                                    >
                                        <span>{variable_9}</span>
                                    </div>
                                }
                                {buttonNavigate === 2 &&
                                    <div
                                        className="btn_success"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleInviteMember(true);
                                        }}
                                    >
                                        <span>{variable_8}</span>
                                    </div>
                                }
                            </div>
                            {(buttonNavigate !== 0 && buttonNavigate !== 2) &&
                                <Popconfirm
                                    placement="bottom"
                                    title={`${getTitle(buttonNavigate)}?`}
                                    okText={variable_10}
                                    cancelText={variable_11}
                                    onConfirm={() => setOpenReject(true)}
                                >
                                    <div
                                        className="btn red"
                                        style={{marginLeft: 10}}
                                    >
                                        {getTitle(buttonNavigate)}
                                    </div>
                                </Popconfirm>
                            }
                        </div>
                    }
                </div>
                <ModalReject
                    openModalReject={openReject}
                    setOpenModalReject={setOpenReject}
                    handleSendReject={handleSendReject}
                />
            </div>
        );
    else {
        let getActiveFunc = () => {
            if (idRemove && idRemove === elem.id && openGroupInList === indx)
                return "table_row open hide";
            if (openGroupInList === indx)
                return "table_row open";
            if (idRemove && idRemove === elem.id)
                return "table_row hide";
            return "table_row";
        };
        return (
            <div ref={rowRef}
                 className={getActiveFunc()}
                 key={indx}>
                <div className={openGroupInList === indx ? "group_block open" : "group_block"}>
                    <img
                        src={elem.user.profile && elem.user.profile.avatar || fake_photos}/>
                    <div className="border"/>
                    <div className="fio">{elem.user.profile && elem.user.profile.name || ""}</div>
                    <div className="border"/>
                    <div
                        className="phone">{elem.user.phone ? elem.user.phone.includes("+") ? elem.user.phone : "+" + elem.user.phone : ""}</div>
                    <div className="border"/>
                    <div
                        className="date">{elem.addDate ? moment(elem.addDate).format("DD.MM.YY") : ""}</div>
                    <div className="border" style={{marginRight: 17}}/>
                    <div
                        className="name_course"
                        style={buttonNavigate === 0 ? {maxWidth: "100%"} : {}}
                    >
                        {elem.quest.name}
                    </div>
                    {buttonNavigate !== 0 &&
                        <div
                            onClick={() => {
                                if (openGroupInList === indx) {
                                    setOpenGroupInList(null);
                                } else {
                                    setOpenGroupInList(indx);
                                    getApiQuestGroup(elem);
                                }
                            }}
                            className={openGroupInList === indx ? "drop_down-parent open" : "drop_down-parent"}
                        >
                            <div
                                className="row"
                                onClick={() => {
                                    setOpenGroupInList(null);
                                }}
                            >
                                {buttonNavigate === 1
                                    ? <span>{elem?.quest_group?.name || ""}</span>
                                    : <span>{variable_7}</span>
                                }
                                <Arrow/>
                            </div>
                            <div
                                className={openGroupInList === indx ? "groups_children open" : "groups_children"}>
                                {currentQuestGroups.length > 0 && currentQuestGroups.map((group, indx) => {
                                        /*  if (buttonNavigate === 0 && group.name === 'Без группы')
                                             return
                                         else */
                                        return (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    let arr = [
                                                        {memberId: elem.id},
                                                        {quest_group: group.id, status: 0}
                                                    ];
                                                    setActiveGroupMember(arr);
                                                }}
                                                className={
                                                    activeGroupMember.length > 0 && activeGroupMember[1].quest_group === group.id
                                                        ? "group active select"
                                                        : elem.quest_group?.id === group.id ? "group active" : "group"
                                                }
                                                key={indx}
                                            >{group.name}</div>
                                        );
                                    }
                                )}
                            </div>
                            {buttonNavigate === 0 &&
                                <div
                                    className="btn_success"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleInviteMember();
                                    }}
                                >
                                    <span>{variable_8}</span>
                                </div>
                            }
                            {buttonNavigate === 1 &&
                                <div
                                    className="btn_success"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleInviteMember(true);
                                    }}
                                >
                                    <span>{variable_9}</span>
                                </div>
                            }
                            {buttonNavigate === 2 &&
                                <div
                                    className="btn_success"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIdRemove(elem.id);
                                        setTimeout(() => {
                                            handleInviteMember(true);
                                        }, 111);
                                    }}
                                >
                                    <span>{variable_8}</span>
                                </div>
                            }
                        </div>
                    }
                    {buttonNavigate === 0 && elem.quest?.isCorporate
                        ? <div
                            onClick={() => {
                                if (openGroupInList === indx) {
                                    setOpenGroupInList(null);
                                } else {
                                    setOpenGroupInList(indx);
                                    getApiQuestGroup(elem);
                                }
                            }}
                            className={openGroupInList === indx ? "drop_down-parent open" : "drop_down-parent"}
                        >
                            <div
                                className="row"
                                onClick={() => {
                                    setOpenGroupInList(null);
                                }}
                            >
                                {buttonNavigate === 1
                                    ? <span>{elem?.quest_group?.name || ""}</span>
                                    : <span>{variable_7}</span>
                                }
                                <Arrow/>
                            </div>
                            <div
                                className={openGroupInList === indx ? "groups_children open" : "groups_children"}>
                                {currentQuestGroups.length > 0 && currentQuestGroups.map((group, indx) => {
                                        /*  if (buttonNavigate === 0 && group.name === 'Без группы')
                                             return
                                         else */
                                        return (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    let arr = [
                                                        {memberId: elem.id},
                                                        {quest_group: group.id, status: 0}
                                                    ];
                                                    setActiveGroupMember(arr);
                                                }}
                                                className={
                                                    activeGroupMember.length > 0 && activeGroupMember[1].quest_group === group.id
                                                        ? "group active select"
                                                        : elem.quest_group?.id === group.id ? "group active" : "group"
                                                }
                                                key={indx}
                                            >{group.name}</div>
                                        );
                                    }
                                )}
                            </div>
                            <div
                                className="btn_success"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleInviteMember();
                                }}
                            >
                                <span>{variable_8}</span>
                            </div>
                        </div>
                        : <></>
                    }
                    {(buttonNavigate !== 0 && buttonNavigate !== 2) &&
                        <Popconfirm
                            placement="bottom"
                            title={`${getTitle(buttonNavigate)}?`}
                            okText={variable_10}
                            cancelText={variable_11}
                            onConfirm={() => setOpenReject(true)}
                        >
                            <div
                                className="btn red"
                                style={{marginLeft: 10}}
                            >
                                {getTitle(buttonNavigate)}
                            </div>
                        </Popconfirm>
                    }
                </div>
                <ModalReject
                    openModalReject={openReject}
                    setOpenModalReject={setOpenReject}
                    handleSendReject={handleSendReject}
                />
            </div>
        );
    }
};

export default StudentRow;