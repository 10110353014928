import React, { useEffect, useState } from 'react';

import { message, Progress, Spin } from "antd";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import upload_zip from "../../../../images/icons/upload_zip.svg";

const CreateOrEditScorm = (props) => {
    const {
        stage_id, getYandexKey, createScorm, isEditComponent, fileProcess,
        editScorm, setZipFile, flagDisabledBtn, loadingLmsProgress
    } = props;

    const variable_18 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_18"});
    const variable_24 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_24'})
    const variable_26 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_26'})
    const variable_33 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_33"});
    const variable_65 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_65"});
    const variable_81 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_81"});

    const [valuesObject, setValuesObject] = useState({
        stage: stage_id,
        theory_type: 'LMS',
        title: '',
        text: '',
        url_refs: '',
        image_file: '',
        image_file_name: '',
        task_file: '',
        task_file_name: ''
    });

    let customRequest = (file) => {
        if (file?.name !== undefined && file.name.length > 244)
            return message.error(variable_65)
        if ((file?.size / 1000) > 350000)
            return message.error(variable_81)

        setValuesObject(prev => ({...prev, ['task_file_name']: file.name}))
        setZipFile(file)
    };

    useEffect(() => {
        getYandexKey('V', 'taskKey');
    }, [])
    useEffect(() => {
        if (props.stageItems !== undefined)
            setValuesObject(props.stageItems)
    }, [props.stageItems])
    return (
        <div className="scorm_matherial">
            <div className="label">{variable_24}</div>
            <input
                value={valuesObject.title}
                onChange={(e) => setValuesObject(prev => ({...prev, ['title']: e.target.value}))}
                placeholder={variable_26}
                maxLength={99}
            />
            <label
                className="label_scorm"
                htmlFor="scorm"
            >
                <div className="upload_icon">
                    <img src={upload_zip}/>
                </div>
                <span>Загрузить</span>
            </label>
            <input
                style={{display: "none"}}
                key={valuesObject.task_file_name}
                id="scorm"
                type="file"
                accept=".zip"
                onChange={(e) => customRequest(e.target.files[0])}
            />
            {(fileProcess && fileProcess[0].percent !== 100 && fileProcess[0].percent !== 0) &&
                <Progress percent={fileProcess[0].percent}/>
            }
            {valuesObject?.task_file_name &&
                <span className="file_name-scorm">{valuesObject.task_file_name}</span>
            }
            {loadingLmsProgress &&
                <Spin style={{position: "absolute", left: "50%", bottom: "10%", transform: "translate(-50%, 0)"}}/>
            }
            <div className="group_buttons">
                <div
                    className={fileProcess && (fileProcess[0].percent !== 100 || flagDisabledBtn)
                        ? "btn_save disabled"
                        : "btn_save"
                    }
                    onClick={() => {
                        if (!valuesObject.title)
                            return message.error("Введите заголовок материала");
                        if (fileProcess && fileProcess[0].percent !== 100)
                            return;
                        if (flagDisabledBtn)
                            return
                        if (isEditComponent) {
                            valuesObject.stage = stage_id;
                            editScorm(valuesObject)
                        } else
                            createScorm(valuesObject)
                    }}
                >
                    {isEditComponent ? variable_33 : variable_18}
                </div>
            </div>
        </div>
    );
};

export default CreateOrEditScorm;