import React from 'react';
import fake_photos from "../../../../images/icons/fake_photos.png";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

const Journal = (props) => {
    const {displayJournal, parseDate, currentQuest, activeDropJournal,  history} = props;

    const variable_1 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_2"});
    const variable_22 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_22"});
    const variable_29 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_29"});
    const variable_30 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_30"});
    const variable_31 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_31"});
    const variable_34 = variableLanguage({keyPage: "groupsList", keyVariable: "variable_34"});

    return (
        <div className={"journal"}>
            {displayJournal && displayJournal.length > 0
                ? <div className={"request_table"}>
                    {displayJournal.map((elem, indx) => {
                        // если это тест
                        if (typeof elem["correct_answers_count"] !== "undefined") {
                            if (window.innerWidth < 820) {
                                return (
                                    <div
                                        className="table_row test_mobile"
                                        onClick={() => history(`/backoffice/check-test/${elem.quiz.id}/${elem.user.id}`)}
                                        key={indx}
                                    >
                                        <div className="top">
                                            <img
                                                src={(elem.user.profile && elem.user.profile.avatar) || fake_photos}/>
                                            <div className="border"/>
                                            <div className="fio">
                                                {elem.user.profile && elem.user.profile.name}
                                            </div>
                                            <div className="border"/>
                                            <div className="type">
                                                {elem.quiz.title}
                                            </div>
                                        </div>
                                        <div className="middle">
                                            <div className="date">
                                                {parseDate(elem.time)}
                                            </div>
                                            <div
                                                className="border"
                                                style={{marginRight: 17}}
                                            />
                                            <div
                                                className="name_course"
                                                style={{maxWidth: 222}}
                                            >
                                                {currentQuest.name}
                                            </div>
                                        </div>

                                        <div className="parent_group">
                                            <div className="lesson">
                                                {variable_22} {elem.quiz.stage.sortOrder}.
                                            </div>
                                            <div className="border"/>
                                            <div
                                                className="stage">
                                                {elem.quiz.stage.name}
                                            </div>
                                        </div>
                                        <div
                                            className={elem.correct_answers_count !== elem.questions_count
                                                ? "result false"
                                                : "result"}
                                        >
                                                <span>
                                                    {elem.correct_answers_count}
                                                </span>
                                            <span>/</span>
                                            <span>
                                                    {elem.questions_count}
                                                </span>
                                        </div>
                                    </div>
                                );
                            } else
                                return (
                                    <div
                                        className="table_row"
                                        onClick={() => history(`/backoffice/check-test/${elem.quiz.id}/${elem.user.id}`)}
                                        key={indx}
                                    >
                                        <img
                                            src={(elem.user.profile && elem.user.profile.avatar) || fake_photos}/>
                                        <div className="border"/>
                                        <div
                                            className="fio">
                                            {elem.user.profile && elem.user.profile.name}
                                        </div>
                                        <div className="border"/>
                                        <div className="type">
                                            {elem.quiz.title}
                                        </div>
                                        <div className="border"/>
                                        <div className="date">
                                            {parseDate(elem.time)}
                                        </div>
                                        <div
                                            className="border"
                                            style={{marginRight: 17}}
                                        />
                                        <div className="parent_group">
                                            <div className="name_course"
                                                 style={{maxWidth: 222}}>{currentQuest.name}
                                            </div>
                                            <div className="border"/>
                                            <div className="lesson">
                                                {variable_22} {elem.quiz.stage.sortOrder}.
                                            </div>
                                            <div className="border"/>
                                            <div className="stage">
                                                {elem.quiz.stage.name}
                                            </div>
                                        </div>
                                        <div
                                            className={elem.correct_answers_count !== elem.questions_count
                                                ? "result false"
                                                : "result"}
                                        >
                                            <span>{elem.correct_answers_count}</span>
                                            <span>/</span>
                                            <span>{elem.questions_count}</span>
                                        </div>
                                    </div>
                                );
                        } else {
                            // если это дом.задание
                            if (window.innerWidth < 820) {
                                return (
                                    <div
                                        className="table_row home_work-mobile"
                                        onClick={() => {
                                            if (elem.task.task_type === "code") {
                                                history(`/backoffice/check-codework/${elem.id}/${elem.task.id}/${elem.task.stage.id}/true`);
                                            } else
                                                history(
                                                    `/backoffice/check-homework/${elem.id}/${elem.task.id}/${elem.task.stage.id}/true`);
                                        }}
                                        key={elem.id}
                                    >
                                        <div className="top">
                                            <img
                                                src={(elem.user.profile && elem.user.profile.avatar) || fake_photos}
                                            />
                                            <div className="border"/>
                                            <div className="fio">
                                                {elem.user.profile && elem.user.profile.name}
                                            </div>
                                            <div className="border"/>
                                            <div className="type">
                                                {elem.task.title}
                                            </div>
                                        </div>
                                        <div className="middle">
                                            <div className="date">
                                                {parseDate(elem.time)}
                                            </div>
                                            <div
                                                className="border"
                                                style={{marginRight: 17}}
                                            />
                                            <div className="name_course">
                                                {elem.task.stage.quest.name}
                                            </div>
                                        </div>
                                        <div
                                            className="result">
                                            {elem.passed && elem.check_status &&
                                                <div className="btn true">{variable_1}</div>
                                            }
                                            {!elem.passed && !elem.check_status &&
                                                <div className="btn need_check">{variable_34}</div>
                                            }
                                            {!elem.passed && elem.check_status &&
                                                <div className="btn false">{variable_2}</div>
                                            }
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <div
                                    className="table_row"
                                    onClick={() => {
                                        if (elem.task.task_type === "code")
                                            history(`/backoffice/check-codework/${elem.id}/${elem.task.id}/${elem.task.stage.id}/true`);
                                        else
                                            history(`/backoffice/check-homework/${elem.id}/${elem.task.id}/${elem.task.stage.id}/true`);
                                    }}
                                    key={indx}
                                >
                                    <img
                                        src={(elem.user.profile && elem.user.profile.avatar) || fake_photos}/>
                                    <div className="border"/>
                                    <div className="fio">
                                        {elem.user.profile && elem.user.profile.name}
                                    </div>
                                    <div className="border"/>
                                    <div className="type">
                                        {elem.task.title}
                                    </div>
                                    <div className="border"/>
                                    <div className="date">
                                        {parseDate(elem.time)}
                                    </div>
                                    <div
                                        className="border"
                                        style={{marginRight: 17}}
                                    />
                                    <div className="name_course">
                                        {elem.task.stage.quest.name}
                                    </div>
                                    {elem.passed && elem.check_status &&
                                        <div className="btn true">{variable_1}</div>
                                    }
                                    {!elem.passed && !elem.check_status &&
                                        <div className="btn need_check">{variable_34}</div>
                                    }
                                    {!elem.passed && elem.check_status &&
                                        <div className="btn false">{variable_2}</div>
                                    }
                                </div>
                            );
                        }
                    }
                    )
                    }
                </div>
                : activeDropJournal === 0
                    ? <div className={"no_content"}>{variable_29}</div>
                    : activeDropJournal === 1
                        ? <div className={"no_content"}>{variable_30}</div>
                        : <div className={"no_content"}>{variable_31}</div>
            }
                </div>
                );
            };

            export default Journal;