import React, { useEffect, useState } from "react";
import { Image, Input, message, Upload } from "antd";
import ReactPlayer from "react-player";
import requests from "../../../axios/requests";
import { defaultFuncAndData } from "../../../utils/defaultData";

import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import CommentCustom from "../../../components/reuseComponents/CommentCustom";
import send_comment from "../../../../images/icons/send_comment.png";

const Task = (props) => {
    let {text, image_file, url_refs, task_file, task_file_name, block_next, id} = props.taskProps;

    const variable_5 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_7"});
    const variable_9 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_9"});
    const variable_11 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_13"});
    const variable_15 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_16"});

    const [valueComment, setValueComment] = useState("");
    const [comments, setComments] = useState("");
    const [taskFile, setTaskFile] = useState("");
    const [taskFileName, setTaskFileName] = useState("");

    if (url_refs === "undefined") {
        url_refs = undefined
    }

    let uploadProps = {
        onChange({file, fileList}) {
            if (file.status !== 'uploading') {
            }
        },
        listType: "card",
        fileList: [
            {
                uid: "-1",
                name: taskFileName,
                status: "done",
                response: props.variable_26,
                url: taskFile,

            }
        ],
        defaultFileList: [
            {
                uid: '1',
                name: task_file_name,
                status: 'done',
                response: variable_11,
                url: task_file,
            },
        ],
    };
    let getApiComments = () => {
        requests.comments.get_comments({
            contentType: 46,
            objectId: id
        })
            .then((res) => setComments(res.data))
            .catch((error) => console.error(error));
    };
    let sendComment = () => {
        if (!valueComment) {
            return;
        }
        let obj = {
            phone: props.profile.phone,
            user: {
                profile: {
                    avatar: props.profile.avatar || ''
                },
            },
            content_type: 46,
            object_id: id,
            text: valueComment
        }
        requests.comments.create_comment(obj)
            .then((res) => {
                setComments((prev) => [...prev, res.data])
            })
            .catch((error) => console.log(error, "error add_comment"));
        setValueComment("");
    };
    let handleSetLikeDislake = (id, value) => {
        let obj = {
            "content_type": 86,
            "object_id": id,
            "value": value
        }
        requests.comments.like_dislike(obj)
            .then((res) => {
                getApiComments();
            })
            .catch((error) => console.log(error, "error set_like"))
            .finally();
    };
    let handleRemoveComment = (id) => {
        requests.comments.remove_comment(id)
            .then(() => {
                getApiComments();
                message.success(variable_7)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        getApiComments()
    }, [id])
    useEffect(() => {
        if (task_file_name) {
            setTaskFileName(task_file_name);
        }
        if (task_file) {
            setTaskFile(task_file);
        }
    }, [task_file_name, task_file]);
    return (
        <>
            <div className="task_content">
                {image_file && <Image src={image_file} style={{borderRadius: 20}}/>}
                <div className={'h4'}>
                    {variable_12}
                    <span style={{fontSize: 18, color: '#A2AACA', marginLeft: 10, verticalAlign: 'text-bottom'}}>
                        {block_next ? variable_16 : variable_15}
                    </span>
                </div>
                <pre className="description">{defaultFuncAndData.linkChanger(text)}</pre>
                {task_file
                    ? <>
                        <div className={'h4'} style={{fontSize: 22, marginTop: 5}}>
                            {variable_13}
                        </div>
                        <Upload {...uploadProps} disabled></Upload>
                    </>
                    : <></>
                }
                {url_refs &&
                    <>
                        <div className="url_title">{variable_9}</div>
                        <div className="url_ref">{defaultFuncAndData.linkChanger(url_refs)}</div>
                    </>
                }
            </div>
            {props?.show_comments &&
                <div style={{width: '100%', marginTop: 15}}>
                    {comments && comments.length > 0 &&
                        <div className="comment_title">
                            {variable_5}
                        </div>
                    }
                    {comments && comments.length > 0 &&
                        comments.map((com, indx) =>
                            <div key={indx}>
                                <CommentCustom
                                    {...com}
                                    setLike={(id) => handleSetLikeDislake(id, true)}
                                    setDislake={(id) => handleSetLikeDislake(id, false)}
                                    removeComment={handleRemoveComment}
                                />
                            </div>
                        )
                    }
                    <div className="comment_input">
                        <Input
                            placeholder={variable_6}
                            className={"input_classic"}
                            value={valueComment}
                            onChange={(e) => setValueComment(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) sendComment();
                            }}
                        />
                        <img src={send_comment} onClick={sendComment}/>
                    </div>
                </div>
            }
        </>
    )
};
export default Task;
