import React, { useState } from "react";

import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import { Tooltip } from "antd";

import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import locked from '../../../images/icons/locker.svg';

const ReuseDropDown = (props) => {
    const {
        flagOpen, setFlagOpen, arrList, activeArrList, setActiveArrList,
        activeModules = false, onBlur = false, arrow = true
    } = props;

    const variable_83 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_83"});
    const variable_84 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_84"});
    const variable_90 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_90"});

    const [showTypes, setShowTypes] = useState(false);

    let calcActiveRow = (indx) => {
        if (indx === 2 && (activeArrList === 2 || activeArrList === 4)) {
            return "active"
        } else {
            if (activeArrList === indx)
                return "active"
        }
        return ""
    }
    return (
        <div
            className={flagOpen ? "reuse_drop-down open" : "reuse_drop-down"}
            onClick={() => setFlagOpen(!flagOpen)}
            style={!arrow ? {cursor: 'auto'} : {}}
            tabIndex={0}
            onBlur={() => {
                if (onBlur) onBlur()
            }}
        >
            {activeArrList === 4 &&
                <span>{variable_84}</span>
            }
            {activeArrList !== 4 &&
                <span>{arrList.length > 0 && arrList[activeArrList]?.name || arrList[activeArrList] || "SCORM"}</span>
            }
            {arrow && <Arrow className="svg"/>}
            <div className={flagOpen ? "drop_down-category open" : "drop_down-category"}>
                <ul>
                    {arrList.map((elem, indx) => {
                            return (
                                <>
                                    <li
                                        key={indx}
                                        onClick={(e) => {
                                            if (elem !== "Задание") {
                                                setFlagOpen(false);
                                                if (elem === "SCORM") {
                                                    setActiveArrList(5)
                                                } else
                                                    setActiveArrList(indx);
                                            } else {
                                                e.stopPropagation()
                                                setShowTypes(!showTypes)
                                            }

                                        }}
                                        className={calcActiveRow(indx)}
                                    >
                                        {elem.name || elem}
                                        {elem === "Задание" &&
                                            <Arrow className={showTypes ? "active" : ""}/>
                                        }
                                    </li>
                                    {elem === "Задание" &&
                                        <div className={showTypes ? "select_drop active" : "select_drop"}>
                                            <div
                                                style={activeArrList === 2 ? {backgroundColor: '#4B505C'} : {}}
                                                onClick={() => {
                                                    setFlagOpen(false);
                                                    setActiveArrList(2);
                                                    setShowTypes(false)
                                                }}
                                            >{variable_83}</div>
                                            <div
                                                className="programming"
                                                style={activeArrList === 4 ? {backgroundColor: '#4B505C'} : {}}
                                                onClick={(e) => {
                                                    if (!activeModules.includes("code_task")) {
                                                        e.stopPropagation();
                                                        return
                                                    }

                                                    setFlagOpen(false);
                                                    setActiveArrList(4);
                                                    setShowTypes(false)
                                                }}
                                            >
                                                <span> {variable_84}</span>
                                                {!activeModules.includes("code_task")
                                                    ? <Tooltip title={variable_90} align={"top"}>
                                                        <img src={locked}/>
                                                    </Tooltip>
                                                    : <></>
                                                }
                                            </div>
                                        </div>
                                    }
                                </>
                            )
                        }
                    )}
                </ul>
            </div>
        </div>
    );
};

export default ReuseDropDown;