import speciality_logo_1 from "../../images/speciality_id_default/speciality_id_1.png";
import speciality_logo_2 from "../../images/speciality_id_default/speciality_id_2.png";
import speciality_logo_3 from "../../images/speciality_id_default/speciality_id_3.png";
import speciality_logo_4 from "../../images/speciality_id_default/speciality_id_4.png";
import speciality_logo_5 from "../../images/speciality_id_default/speciality_id_5.png";
import speciality_logo_6 from "../../images/speciality_id_default/speciality_id_6.png";
import speciality_logo_7 from "../../images/speciality_id_default/speciality_id_7.png";

export const defaultFuncAndData = {
    declension(number, txt, cases = [2, 0, 1, 1, 1, 2]) {
        // let number = 1;
        // let number = 3;
        // let number = 10;
        return txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
    },
    defaultImageCourse(indx) {
        const specialities = [
            speciality_logo_1,
            speciality_logo_2,
            speciality_logo_3,
            speciality_logo_4,
            speciality_logo_5,
            speciality_logo_6,
            speciality_logo_7
        ]
        return specialities[indx]
    },
    spliceStr(text, countSlice) {
        let sliced = '';
        if (text) {
            sliced = text.slice(0, countSlice);
            if (sliced.length < text.length) {
                sliced += '...';
            }
        }
        return sliced
    },
    calcColorPercentProgress(percent) {
        if (percent >= 66)
            return "#30E0A1";
        if (percent >= 33)
            return "#FF5937";
        return "#FF3434";
    },
    brChanger(text) {
        const arr = []
        const splitArr = text?.split("\n");
        for (let i = 0; i < splitArr?.length; i++) {
            arr.push(splitArr[i])
            arr.push(<br/>)
        }
        arr.pop()
        return arr
    },
    linkChanger(text) {
        /* const arr = []
         //let links = text.match(/https:\/\/[^\s\Z]+/i);
         //let links = text.match(/https:\/\/[^\s\Z]+/g);
         let links = text.match(/(https?:\/\/|ftps?:\/\/|www\.)((?![.,?!;:()]*(\s|$))[^\s]){2,}/gim);
         const splitArr = text?.split("\n");
         for (let i = 0; i < splitArr.length; i++) {
             arr.push(splitArr[i])
             arr.push(<br/>)
         }
         arr.pop();
         if(links?.length) {
             for(let i = 0; i < links.length; i++) {
                 for(let j = 0; j < arr.length; j++) {
                     if(typeof arr[j] !== "object" && arr[j] === links[i]) {
                         arr[j] = <a href={arr[j]}>{arr[j]}</a>
                     }
                 }
             }
         }*/

        //let regEx1 = text.match(/https:\/\/[^\s\Z]+/i);
        //let regEx2 = text.match(/https:\/\/[^\s\Z]+/g);
        //let regEx3 = /(https?:\/\/|ftps?:\/\/|www\.)((?![.,?!;:()]*(\s|$))[^\s]){2,}/gim;
        let regEx = /((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim;
        let arr = [];
        text.replace(
            regEx,
            (m, link, text) => {
                arr.push(link
                    ? <a
                        href={(link[0] === "w" ? "//" : "") + link}
                        key={arr.length}
                    >
                        {link}
                    </a>
                    : text)
            })
        return arr;
    },
    calcColorPercentProgressProfession(procentague) {
        if (procentague >= 66)
            return "#30E0A1";
        if (procentague >= 33)
            return "#FF5937";
        return "#FF3434";
    },
    funcMissingsSkill(firstArr, secondArr) {
        if (firstArr && firstArr.length > 0 && secondArr && secondArr.length > 0) {
            let missingSkills =
                secondArr.filter(elem => firstArr.every(item => item.name.toLowerCase() !== elem.name.toLowerCase()));
            let haveSkills = [];
            for (let i = 0; i < firstArr.length; i++) {
                for (let j = 0; j < secondArr.length; j++) {
                    if (firstArr[i].name.toLowerCase() === secondArr[j].name.toLowerCase()) {
                        haveSkills = [...haveSkills, firstArr[i]]
                    }
                }
            }
            return {
                missingSkills,
                haveSkills
            }
        }
        return [];
    },
    calcSalarys(arrVacancy) {
        if (arrVacancy && arrVacancy.length > 0) {
            let minSalar = 100000;
            let maxSalar = 0;

            for (let i = 0; i < arrVacancy.length; i++) {
                if (arrVacancy[i].salary !== null) {
                    if (arrVacancy[i].salary.currency === "RUR") {
                        if (arrVacancy[i].salary.from && arrVacancy[i].salary.from < minSalar)
                            minSalar = arrVacancy[i].salary.from;

                        if (arrVacancy[i].salary.to && arrVacancy[i].salary.to > maxSalar)
                            maxSalar = arrVacancy[i].salary.to;

                        if (arrVacancy[i].salary.from && arrVacancy[i].salary.from > maxSalar)
                            maxSalar = arrVacancy[i].salary.from;

                    } else {
                        if (arrVacancy[i].salary.from && (arrVacancy[i].salary.from * 60) < minSalar)
                            minSalar = arrVacancy[i].salary.from * 60;

                        if (arrVacancy[i].salary.to && (arrVacancy[i].salary.to * 60) > maxSalar)
                            maxSalar = arrVacancy[i].salary.to * 60;

                        if (arrVacancy[i].salary.from && (arrVacancy[i].salary.from * 60) > maxSalar)
                            maxSalar = arrVacancy[i].salary.from * 60;
                    }
                }
            }
            return {
                minSalary: minSalar,
                maxSalary: maxSalar,
                averageSalary: (minSalar + maxSalar) / 2
            };
        }
    },
    calcSalarysVacancy(elem) {
        let result = "";
        if (elem?.salary) {
            if (elem.salary.from && !elem.salary.to)
                result += "От "
            if (!elem.salary.from && elem.salary.to)
                result += "До "
            if (elem.salary.from)
                result += elem.salary.from
            if (elem.salary.from && elem.salary.to)
                result += " - "
            else
                result += " "
            if (elem.salary.to)
                result += elem.salary.to
            if (elem.salary.to || elem.salary.from) {
                if (elem.salary.currency === "RUR")
                    result += " руб."
                if (elem.salary.currency === "USD")
                    result += " $"
            }
        }
        return result;
    },
    calcActiveFavoriteIcon(elem, favoriteProfOreVacancy) {
        if (favoriteProfOreVacancy && favoriteProfOreVacancy.length > 0
            && elem && Object.keys(elem).length > 0
        )
            for (let i = 0; i < favoriteProfOreVacancy.length; i++)
                if (favoriteProfOreVacancy[i].id == elem.id)
                    return true;
        return false;
    },
    convertSkillId(arr) {
        let result = [];
        if (arr?.length) {
            for (let i = 0; i < arr.length; i++) {
                result = [...result, arr[i].id]
            }
        }
        return result;
    },
    calcProcentague(salary) {
        const maxNum = 250000;
        return salary / maxNum * 100;
    },
    listAnimationDelay() {
        return {
            hidden: {opacity: 0},
            visible: (custom) => ({
                opacity: 1,
                transition: {
                    duration: 0.4,
                    delay: custom
                }
            })
        }
    },
    convertToFontCode(str) { // выделение тегом <code> команд и тегов.
        let sentence = str.replace(/`{3}/g, "`");
        let wordsInQuotes = sentence.match(/`([^`]+)`/g);
        if (wordsInQuotes && wordsInQuotes?.length > 0) {
            wordsInQuotes.forEach((word) =>
                sentence = sentence.replace(word, "*****")
            );
            let splitArr = sentence.split("*****")
            for (let i = 0; i < wordsInQuotes.length; i++) {
                let newElem = <code
                    style={{
                        color: 'lightgreen',
                        background: "#11191e",
                        padding: "0 5px"
                    }}
                >
                    {wordsInQuotes[i].replace(/`/g, "")}
                </code>
                splitArr.splice(i + i + 1, 0, newElem)
            }
            return splitArr || str
        }
        return str
    },
    itemRenderPagination(_, type, originalElement) {
        if (type === "prev")
            return null;
        if (type === "next")
            return;

        return originalElement;
    },
};
