import React from 'react';
import moment from "moment";
import { Popconfirm } from "antd";

import fake_photos from "../../../images/icons/fake_photos.png";
import { ReactComponent as Like } from "../../../images/icons/like.svg";
import { ReactComponent as Dislike } from "../../../images/icons/dislike.svg";
import { ReactComponent as RemoveComment } from "../../../images/icons/remove_question.svg";

const CommentCustom = (props) => {
    let {text, created_at, likes_count, dislikes_count, id, setLike, setDislake, user, removeComment} = props;
    let parseDateComment = (num) => {
        return moment(num).format("DD.MM.YYYY - HH:mm");
    };
    return (
        <div className="comment">
            <div className="comment_photo">
                <img
                    src={user.profile && user.profile.avatar || fake_photos}
                    style={{maxWidth: 40, height: 40, borderRadius: 13}}
                />
            </div>
            <div className="comment_fio">{user.profile && user.profile.name}</div>
            <div className="comment_date">{parseDateComment(created_at)}</div>
            <div className="comment_text">
                {text}
            </div>
            <div className="comment_like-dislike">
                <Like
                    onClick={() => setLike(id)}
                />
                <span>{likes_count}</span>
                <Dislike
                    onClick={() => setDislake(id)}
                />
                <span>{dislikes_count}</span>
                {/*<Reply /> <span>Ответить</span>*/}
            </div>
            <Popconfirm
                placement="left"
                title={`Удалить комментарий?`}
                okText="Да"
                cancelText="Нет"
                onConfirm={() => removeComment(id)}
            >
                <div className="comment_remove"><RemoveComment/></div>
            </Popconfirm>

        </div>
    )
};
export default CommentCustom;