import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import requests from "../../axios/requests";
import cn from "classnames";

import { Popconfirm, Tooltip } from "antd";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import {
    changeLanguage, setActiveModules, setCollapse,
    setEndTrialDate, setStatusTrial
} from "../../../redux/actions/actions";
import Transition from "./Transition/Transition";

import { ReactComponent as Notification } from "../../../images/icons/navbar_notification.svg";
import { ReactComponent as CourseList } from "../../../images/icons/nav_bar-course.svg";
import { ReactComponent as Logo } from "../../../images/icons/logo.svg";
import { ReactComponent as LogoB } from "../../../images/logo.svg";
import { ReactComponent as Logout } from "../../../images/icons/logout.svg";
import { ReactComponent as HistoryPay } from "../../../images/icons/history_pay.svg";
import { ReactComponent as SidebarModule } from "../../../images/icons/sidebar_module.svg";
import { ReactComponent as AcademicPlan } from "../../../images/icons/nav_bar-case.svg";
import { ReactComponent as Collapse } from "../../../images/icons/collapse.svg";
import { ReactComponent as GenerateCourse } from "../../../images/generate_course/generate_course.svg";
import { ReactComponent as MailIcon } from "../../../images/icons/mail.svg";
import fake_photos from "../../../images/icons/fake_photos.png";
import './Sidebar.scss';

const Sidebar = () => {
    const variable_1 = variableLanguage({keyPage: "navbar", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "navbar", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "navbar", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "navbar", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "navbar", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "navbar", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "navbar", keyVariable: "variable_7"});
    const variable_11 = variableLanguage({keyPage: "navbar", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "navbar", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "navbar", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "navbar", keyVariable: "variable_14"});
    const variable_15 = variableLanguage({keyPage: "navbar", keyVariable: "variable_15"});

    const {user, collapse, flagSteps} = useSelector(state => state.Reducer);
    const history = useNavigate();
    const dispatch = useDispatch();

    const [countNotification, setCountNotification] = useState(null);
    const [changeLanguageFlag, setChangeLanguageFlag] = useState(false);

    let getCountNotificationInterval = () => {
        setCountNotification(localStorage.getItem("count_notification"));
    };
    let handleLogOut = async () => {
        await requests.auth.logout()
            .catch(err => console.error(err))
        dispatch(setActiveModules([]))
        dispatch(setEndTrialDate(""))
        dispatch(setStatusTrial(null))
        window.localStorage.removeItem("user_id");
        window.localStorage.removeItem("name");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("photo");
        window.localStorage.removeItem("count_notification");
        history(`/backoffice/login`);
    };
    
    useEffect(() => {
        requests.auth.get_profile_new()
            .then((res) => {
                if (!localStorage.getItem("photo")) {
                    if (res.data.avatar) {
                        localStorage.setItem("photo", res.data.avatar);
                    }
                }
            });
        getCountNotificationInterval();
        setInterval(() => {
            getCountNotificationInterval();
        }, 1000);

        if (localStorage.getItem("photo") === 'null') {
            localStorage.removeItem("photo");
        }
    }, []);
    return (
        <div
            className={cn("sidebar", {
                display_none: location.pathname === "/" && window.innerWidth < 991,
                open: collapse,
                hide: flagSteps
            })}
        >
            <div className="logo">
                <Transition show={collapse} timeout={0}>
                    <LogoB className={"logo_b"} onClick={() => window.open("https://skillometer.ru/", 'blank')}/>
                </Transition>
                {!collapse &&
                    <Transition show={!collapse}>
                        <Logo onClick={() => window.open("https://skillometer.ru/", 'blank')}/>
                    </Transition>
                }
            </div>
            <Tooltip placement="right" title={variable_6}>
                <div
                    className={cn("notification_btn", {
                        active: location?.pathname === ("/notification")
                    })}
                    onClick={() => history("/notification")}
                >
                    <Notification/>
                    <Transition show={collapse}>
                        <div className="link">{variable_6}</div>
                    </Transition>
                    {countNotification && countNotification > 0
                        ? <div className="count_notification">
                                <span>
                                    {countNotification > 99
                                        ? "99+"
                                        : countNotification
                                    }
                                </span></div>
                        : <></>
                    }
                </div>
            </Tooltip>
            <Tooltip placement="right" title={variable_4}>
                <div
                    className={cn("navigation_btn", {
                        active: location?.pathname === ("/backoffice/profile")
                    })}
                    onClick={() => history("/backoffice/profile")}
                >
                    <img src={user?.avatar || fake_photos}/>
                    <Transition show={collapse}>
                        <div className="link">{variable_4}</div>
                    </Transition>
                </div>
            </Tooltip>
            {/*<Tooltip placement="right" title={variable_13}>*/}
            {/*    <div*/}
            {/*        className={cn("navigation_btn", {*/}
            {/*            active: location?.pathname === ("/backoffice/academic-plan")*/}
            {/*        })}*/}
            {/*        onClick={() => history("/backoffice/academic-plan")}*/}
            {/*    >*/}
            {/*        <AcademicPlan/>*/}
            {/*        <Transition show={collapse}>*/}
            {/*            <div className="link">{variable_13}</div>*/}
            {/*        </Transition>*/}
            {/*    </div>*/}
            {/*</Tooltip>*/}
            {/*<Tooltip placement="right" title={variable_15}>*/}
            {/*    <div*/}
            {/*        className={cn("navigation_btn", {*/}
            {/*            active: location?.pathname === ("/backoffice/generate-course")*/}
            {/*                || location?.pathname === ("/generate-course")*/}
            {/*        })}*/}
            {/*        onClick={() => history("/backoffice/generate-course")}*/}
            {/*    >*/}
            {/*        <div className="generate_course-icon">*/}
            {/*            <GenerateCourse/>*/}
            {/*        </div>*/}
            {/*        <Transition show={collapse}>*/}
            {/*            <div className="link">{variable_15}</div>*/}
            {/*        </Transition>*/}
            {/*    </div>*/}
            {/*</Tooltip>*/}
            {/*skillset*/}
            <Tooltip placement="right" title={variable_13}>
                <div
                    className={cn("navigation_btn", {
                        active: location?.pathname === ("/backoffice/skill-set/prof-review")
                    })}
                    onClick={() => history("/backoffice/skill-set/prof-review")}
                >
                    <AcademicPlan/>
                    <Transition show={collapse}>
                        <div className="link">Скилсет</div>
                    </Transition>
                </div>
            </Tooltip>
            {/*skillset*/}
            <Tooltip placement="right" title={variable_5}>
                <div
                    className={cn("navigation_btn", {
                        active: location?.pathname === ("/backoffice/my-course")
                    })}
                    onClick={() => history("/backoffice/my-course")}
                >
                    <CourseList/>
                    <Transition show={collapse}>
                        <div className="link">{variable_5}</div>
                    </Transition>
                </div>
            </Tooltip>
            <Tooltip placement="right" title={variable_11}>
                <div
                    className={cn("navigation_btn", {
                        active: location?.pathname === ("/backoffice/history-pay")
                    })}
                    onClick={() => history("/backoffice/history-pay")}
                >
                    <div className="history_pay">
                        <HistoryPay/>
                    </div>
                    <Transition show={collapse}>
                        <div className="link">{variable_11}</div>
                    </Transition>
                </div>
            </Tooltip>
            <Tooltip placement="right" title={variable_12}>
                <div
                    className={cn("navigation_btn", {
                        active: location?.pathname === ("/backoffice/modules")
                    })}
                    onClick={() => history("/backoffice/modules")}
                >
                    <SidebarModule/>
                    <Transition show={collapse}>
                        <div className="link">{variable_12}</div>
                    </Transition>
                </div>
            </Tooltip>
            <div className="bottom_nav">
                <Tooltip placement="right" title="Техническая поддержка: support@skillometer.ru">
                    <div className="support" tabIndex={0}>
                        <MailIcon className="support-icon" />
                        <Transition show={collapse}>
                            <div className="support-text">
                                <div>Техническая поддержка:</div>
                                <div>support@skillometer.ru</div>
                            </div>
                        </Transition>
                    </div>
                </Tooltip>
                <div
                    className="change_parent"
                    tabIndex={0}
                    onClick={() => setChangeLanguageFlag(!changeLanguageFlag)}
                    onBlur={() => setChangeLanguageFlag(false)}
                >
                    <div
                        className={changeLanguageFlag
                            ? "change_language open"
                            : "change_language"}
                    >
                        {!changeLanguageFlag &&
                            <div className="active">
                                {localStorage.getItem("language") === "en" ? "EN" : "RU"}
                            </div>
                        }
                        {changeLanguageFlag &&
                            <>
                                <div
                                    className={localStorage.getItem("language") === "ru" && "active"}
                                    onClick={() => {
                                        dispatch(changeLanguage("ru"))
                                        localStorage.setItem('language', "ru")
                                        setChangeLanguageFlag(false)
                                    }}
                                >
                                    RU
                                </div>
                                <div
                                    className={localStorage.getItem("language") === "en" && "active"}
                                    onClick={() => {
                                        dispatch(changeLanguage("en"))
                                        localStorage.setItem('language', "en")
                                        setChangeLanguageFlag(false)
                                    }}
                                >
                                    EN
                                </div>
                            </>
                        }
                        {collapse &&
                            <span className="change_language-collapse">{variable_14} </span>
                        }
                    </div>
                </div>
                <Tooltip placement="right" title={variable_7}>
                    <Popconfirm
                        title={variable_1}
                        onConfirm={() => handleLogOut()}
                        okText={variable_2}
                        cancelText={variable_3}
                        placement="right"
                    >
                        <div className={"navigation_btn"}>
                            <Logout/>
                            <Transition show={collapse}>
                                <span className="link">{variable_7}</span>
                            </Transition>
                        </div>
                    </Popconfirm>
                </Tooltip>
            </div>
            <div
                className={cn("collapse_btn", {
                    open: collapse
                })}
                onClick={() => dispatch(setCollapse(!collapse))}
            >
                <Collapse/>
            </div>
        </div>
    );
};

export default Sidebar;