import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import requests from "../../axios/requests";
import moment from "moment";

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';

import { Pagination, Table } from "antd";
import ReuseDropDown from "../../components/reuseComponents/ReuseDropDown";
import InputWsButton from "../../components/reuseComponents/InputButton";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import MobileSidebar from "../../components/SideBar/MobileSidebar/MobileSidebar";
import Trial from "../../components/reuseComponents/Trial";
import { Line } from 'react-chartjs-2';

import { ReactComponent as StatiscticUp } from "../../../images/icons/statistic_up.svg";
import { ReactComponent as StatiscticDown } from "../../../images/icons/statistic_down.svg";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ChartJs = (props) => {
    let {data, diff = 0} = props;
    let options = {
        responsive: true,
        animation: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                displayColors: false,
                titleAlign: 'center',
                bodyAlign: 'center',
                bodyColor: Math.sign(diff) === -1 ? 'red' : '#30E0A1',
                callbacks: {
                    // use label callback to return the desired label
                    label: function (tooltipItem, data) {
                        return tooltipItem.formattedValue
                    },
                    labelColor: function (tooltipItem, chart) {
                        return {
                            backgroundColor: '#30E0A1'
                        }
                    },

                },
                borderColor: '#30E0A1',
            },
        },
        elements: {
            line: {
                tension: 0.4
            }
        },
        scales: {
            y: {
                display: false // Hide Y axis labels
            },
            x: {
                display: false // Hide X axis labels
            }
        },
        title: {
            display: false
        },
    };

    let labels = data?.data?.length > 0 && data?.data.map((elem) => elem.date);
    let dataChart = {
        labels,
        datasets: [
            {
                label: '123',
                data: data?.data?.length > 0 && data?.data.map((elem) => elem.amount),
                borderColor: Math.sign(diff) === -1 ? 'red' : '#30E0A1',
                backgroundColor: 'transparent',
            }
        ]
    };
    return (
        <div>
            <Line options={options} data={dataChart}/>
        </div>
    );
};

const HistoryPay = () => {
    const variable_1 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_1"});
    const variable_4 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_8"});
    const variable_10 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_14"});
    const variable_17 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_17"});
    const variable_18 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_18"});
    const variable_19 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_19"});
    const variable_20 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_20"});
    const variable_21 = variableLanguage({keyPage: "historyPay", keyVariable: "variable_21"});

    const {trial_end_date, trial_status} = useSelector(state => state.Reducer);

    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [innerHeight, setInnerHeight] = useState(0);
    const [innerWidth, setInnerWidth] = useState(0);
    const [progressData, setProgressData] = useState([]);

    const [activePeriod, setActivePeriod] = useState(0);
    const [activeSort, setActiveSort] = useState(0);
    const [searchCourses, setSearchCourses] = useState('');
    const [flagOpenStatisticTiming, setFlagOpenStatisticTiming] = useState(false);
    const [flagOpenStatisticCourse, setFlagOpenStatisticCourse] = useState(false);
    const [flagHiddenFotter, setFlagHiddenFooter] = useState(null);

    const columns = [
        {
            title: variable_17,
            dataIndex: 'quest_member_fio',
        },
        {
            title: variable_18,
            dataIndex: 'created_at',
            render: (record) => moment(record).format('DD.MM.YY HH:mm')
        },
        {
            title: variable_19,
            render: (record) => record.quest.name || ''
        },
        {
            title: variable_20,
            dataIndex: 'amount',
            render: (record) => record / 100
        },
        {
            title: variable_21,
            dataIndex: 'marketplace_fee',
            render: (record) => record / 100
        }
    ];
    const periodList = [variable_1, variable_4, variable_5, variable_6];
    const periodListValue = ["all", 'week', 'month', 'year'];

    const sortList = [variable_8, variable_10, variable_11, variable_12, variable_13]
    const sortListValue = ['created_at', 'quest_name', 'user__profile__name', 'amount', 'fee']

    let itemRender = (_, type, originalElement) => {
        if (type === "prev")
            return null;
        if (type === "next") {
            return;
        }
        return originalElement;
    };
    let removeVariablesInExcel = (arr) => {
        let result = [];
        if (arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                let newObj = {
                    "Фио пользователя": arr[i].quest_member_fio,
                    "Дата и время операции": arr[i].created_at,
                    "Наименование курса": arr[i].quest.name,
                    "Сумма операции (руб.)": arr[i].amount / 100,
                    "Комиссия МП (руб.)": arr[i].marketplace_fee / 100
                }
                result = [...result, newObj];
            }
        }
        return result;
    }
    let exportToCSV = (csvData, fileName) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const newData = removeVariablesInExcel(csvData);
        const ws = XLSX.utils.json_to_sheet(newData);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    let handleResize = () => {
        setInnerHeight(window.innerHeight - 365)
        setInnerWidth(window.innerWidth - 30)
    }
    let apiGetHistory = (activePeriodProps = activePeriod, activeSortProps = activeSort, courseName = searchCourses) => {
        requests.organization.get_payment_history(
            periodListValue[activePeriod], sortListValue[activeSort],
            courseName, currentPage, 12
        )
            .then(res => {
                setData(res.data.results);
                setTotal(res.data.count);
            })
            .catch(err => console.error(err))
    }
    let apiGetPaymentProgress = () => {
        requests.organization.get_payment_progress(periodListValue[activePeriod])
            .then(res => setProgressData(res.data))
            .catch(err => console.error(err))
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])
    useEffect(() => {
        apiGetHistory();
        apiGetPaymentProgress();
    }, [activePeriod, activeSort, searchCourses])
    // up={Math.sign(statisticData?.diff?.hits_count) === -1 ? false : true}
    return (
        <div className="history_pay">
            <div className="inner_block">
                {(trial_status === 0 || trial_status === 1) && <Trial/>}
                <div className="h1"><span>История платежей</span></div>
                <div className="top_sort">
                    <ReuseDropDown
                        flagOpen={flagOpenStatisticTiming}
                        setFlagOpen={(bool) => setFlagOpenStatisticTiming(bool)}
                        arrList={periodList}
                        activeArrList={activePeriod}
                        setActiveArrList={(indx) => setActivePeriod(indx)}
                        onBlur={() => setFlagOpenStatisticTiming(false)}
                    />
                    <ReuseDropDown
                        flagOpen={flagOpenStatisticCourse}
                        setFlagOpen={(bool) => setFlagOpenStatisticCourse(bool)}
                        arrList={sortList}
                        activeArrList={activeSort}
                        setActiveArrList={(indx) => setActiveSort(indx)}
                        onBlur={() => setFlagOpenStatisticCourse(false)}
                    />
                    <div className="search">
                        <InputWsButton
                            value={searchCourses}
                            setValue={(e) => setSearchCourses(e)}
                            placeholder={variable_7}
                        />
                    </div>
                </div>
                <div className="statistic_row">
                    <div className="item">
                        <div className="title">Оплата курсов</div>
                        <div className="chart">
                            <ChartJs data={progressData}/>
                        </div>
                        <div className="info">
                            <div className="left">
                                {Math.sign(progressData?.diff) !== -1
                                    ? <StatiscticUp/>
                                    : <StatiscticDown/>
                                }
                                <span
                                    style={Math.sign(progressData?.diff) !== -1
                                        ? {color: "#30E0A1"}
                                        : {}
                                    }
                                >
                                    {progressData?.diff}%
                                </span>
                            </div>
                            <div className="right">
                                {progressData?.count}
                            </div>
                        </div>
                        <span>{periodList[activePeriod]}</span>
                    </div>
                </div>
                <div className="table_block">
                    <div
                        className="export_btn"
                        onClick={(e) => exportToCSV(data, 'test_file_name')}
                    >
                        Скачать файл Excel
                    </div>
                    <Table
                        columns={columns}
                        dataSource={data}
                        rowKey={'id'}
                        total={12}
                        pagination={false}
                        scroll={{x: window.innerWidth < 1380 ? innerWidth : 0, y: innerHeight}}
                        locale={{emptyText: variable_14}}
                    />
                    {total && total > 12
                        ? <div className="pagination_all">
                            <Pagination
                                itemRender={itemRender}
                                defaultCurrent={1}
                                defaultPageSize={12}
                                total={total}
                                onChange={(e) => setCurrentPage(e)}
                                showTitle={false}
                            />
                        </div>
                        : <></>
                    }
                </div>
            </div>
        </div>
    );
};

export default HistoryPay;