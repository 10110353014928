import React from 'react';
import { useNavigate } from "react-router-dom";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import { useSelector } from "react-redux";

import { Modal } from "antd";
import Button from "../../../components/reuseComponents/Button/Button";

import close_modal_grey from "../../../../images/icons/close_modal-grey.png";

const ModalInfo = ({openModalInfo, setOpenModalInfo}) => {
    const history = useNavigate();
    const {listModules} = useSelector((state) => state.Reducer);

    const variable_1 = variableLanguage({keyPage: "modalInfo", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "modalInfo", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "modalInfo", keyVariable: "variable_3"});

    return (
        <Modal
            open={openModalInfo}
            footer={null}
            className={"modal_info"}
            closable={false}
            onCancel={() => setOpenModalInfo()}
            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
        >
            <div
                className="close"
                onClick={() => setOpenModalInfo()}
            >
                <img src={close_modal_grey}/>
            </div>
            <div className="info">
                {variable_1}
                {listModules?.length > 0 &&
                    <>
                        {`"${listModules.find(elem => elem.id === 4).name}"`}
                    </>
                }
            </div>
            <div className="buttons">
                {/*<Button
                        text={variable_2}
                        click={() => {
                            history("/backoffice/modules")
                        }}
                        maxWidth={"265px"}
                        margin={"0 20px 0 0"}
                        padding={"12px 13px 14px"}
                        zIndex={3}
                    />*/}
                <Button
                    text={variable_3}
                    click={() => history("/backoffice/modules")}
                    maxWidth={"265px"}
                    margin={"0 auto"}
                    padding={"12px 13px 14px"}
                    backgroundColor={"#16151B"}
                    boxShadow={"0px 20px 40px 0px rgba(0, 0, 0, 0.30)"}
                    zIndex={3}
                />
            </div>
        </Modal>
    );
};

export default ModalInfo;