import React, { useEffect, useState } from "react";

import ReuseDropDown from "../../../components/reuseComponents/ReuseDropDown";
import { message, Tooltip } from "antd";
import { StorageUploader } from "../../../data/StorageUploader";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

import question_icon from "../../../../images/icons/question.svg";

const CreateOrEditTask = (props) => {
    let {
        stage_id, getYandexKey, imageKey, createTask,
        editTask, taskKey, propsSetView, isEditComponent = false
    } = props;

    const variable_3 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_3'})
    const variable_18 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_18'})
    const variable_24 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_24'})
    const variable_26 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_26'})
    const variable_33 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_33'})
    const variable_34 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_34'})
    const variable_35 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_35'})
    const variable_36 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_36'})
    const variable_37 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_37'})
    const variable_57 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_57'})
    const variable_70 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_70'})
    const variable_73 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_73'})
    const variable_74 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_74'})
    const variable_75 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_75'})
    const variable_88 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_88'})
    const variable_89 = variableLanguage({keyPage: 'courseManageEditLesson', keyVariable: 'variable_89'})

    const [valuesObject, setValuesObject] = useState({
        stage: stage_id,
        theory_type: "T",
        title: '',
        text: '',
        url_refs: '',
        block_next: false,
        task_type: 'text',
        image_file: '',
        image_file_name: '',
        task_file: '',
        task_file_name: '',
    });

    const [fileProcess, setFileProcess] = useState(null);
    const [flagTypeDropDown, setFlagTypeDropDown] = useState(false)

    useEffect(() => {
        getYandexKey('A', 'imageKey');
        getYandexKey('A', 'taskKey');
    }, [])
    useEffect(() => {
        if (props.stageItems !== undefined) {
            setValuesObject(props.stageItems)
        }
    }, [props.stageItems])
    return (
        <>
            <div className="group_drop-down" style={{margin: '20px 0 0 0'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className="title"> {variable_75}</div>
                    <Tooltip
                        title={valuesObject.block_next === 1 ? variable_89 : variable_88}
                        align={"top"}
                    >
                        <img src={question_icon} style={{marginBottom: 5, marginLeft: 15, cursor: 'pointer'}}/>
                    </Tooltip>
                </div>
                <ReuseDropDown
                    flagOpen={flagTypeDropDown}
                    setFlagOpen={(bool) => setFlagTypeDropDown(bool)}
                    arrList={[variable_73, variable_74]}
                    activeArrList={valuesObject.block_next ? 1 : 0}
                    setActiveArrList={(indx) => {
                        if (indx === 0)
                            setValuesObject(prev => ({...prev, ['block_next']: false}))
                        else
                            setValuesObject(prev => ({...prev, ['block_next']: true}))
                    }}
                    arrow={true}
                    onBlur={() => setFlagTypeDropDown(false)}
                />
            </div>
            <div className="label">{variable_24}</div>
            <input
                value={valuesObject.title}
                onChange={(e) => setValuesObject(prev => ({...prev, ['title']: e.target.value}))}
                placeholder={variable_26}
                maxLength={99}
            />
            <div className="label">{variable_36}</div>
            <textarea
                value={valuesObject.text}
                onChange={(e) => setValuesObject(prev => ({...prev, ['text']: e.target.value}))}
                placeholder={variable_26}
                rows="5"
            />
            <div className="label" style={{marginTop: -5}}>{variable_35}</div>
            <StorageUploader
                yandexKey={imageKey}
                count={1}
                fileTypes={"image/*"}
                setFileName={(name) => setValuesObject(prev => ({...prev, ['image_file_name']: name}))}
                fileWillBeUpload={(fileName) => {
                    setValuesObject(prev =>
                        ({
                            ...prev,
                            ['image_file']: 'https://storage.yandexcloud.net/skill-storage/' + imageKey + "/" + fileName.replace(/\s+/g, "_")
                        })
                    )
                }}
                setFileListProps={(value) => {
                    setFileProcess(value);
                    setValuesObject(prev => ({...prev, ['image_file_name']: value[0].name}))
                }}
                firstRenderImage={props.stageItems !== undefined && valuesObject.image_file && valuesObject.image_file_name
                    ? [{
                        uid: valuesObject.image_file,
                        name: valuesObject.image_file_name,
                        status: "done"
                    }]
                    : []
                }
                removeFile={() => {
                    setValuesObject(prev => ({...prev, ['image_file']: ''}))
                    setValuesObject(prev => ({...prev, ['image_file_name']: ''}))
                }}
            />
            <div className="label">{variable_70}</div>
            <StorageUploader
                yandexKey={taskKey}
                count={1}
                setFileName={(name) => setValuesObject(prev => ({...prev, ['task_file_name']: name}))}
                fileWillBeUpload={(fileName) => {
                    setValuesObject(prev =>
                        ({
                            ...prev,
                            ['task_file']: 'https://storage.yandexcloud.net/skill-storage/' + taskKey + "/" + fileName.replace(/\s+/g, "_")
                        })
                    )
                }}
                setFileListProps={(value) => {
                    setFileProcess(value);
                    setValuesObject(prev => ({...prev, ['task_file_name']: value[0].name}))
                }}
                firstRenderFile={props.stageItems !== undefined && valuesObject.task_file && valuesObject.task_file_name
                    ? [{
                        uid: valuesObject.task_file,
                        name: valuesObject.task_file_name,
                        status: "done"
                    }]
                    : false
                }
                removeFile={() => {
                    setValuesObject(prev => ({...prev, ['task_file']: ''}))
                    setValuesObject(prev => ({...prev, ['task_file_name']: ''}))
                }}
            />
            <div className="label">{variable_37}</div>
            <input
                value={valuesObject.url_refs}
                onChange={(e) => setValuesObject(prev => ({...prev, ['url_refs']: e.target.value}))}
                placeholder={variable_26}
            />
            <div className="group_buttons">
                <div
                    className={fileProcess !== null && fileProcess[0].percent !== 100 ? "btn_save disabled" : "btn_save"}
                    onClick={() => {
                        if (fileProcess !== null && fileProcess[0].percent !== 100) return
                        if (!valuesObject.title) return message.error(variable_3);
                        if (!valuesObject.text) return message.error(variable_57);
                        if (isEditComponent) {
                            valuesObject.stage = stage_id;
                            return editTask(valuesObject)
                        }
                        createTask(valuesObject)
                    }}
                >
                    {isEditComponent ? variable_33 : variable_18}
                </div>
                <div
                    className="btn_cansel btn_save"
                    onClick={() => propsSetView()}
                >
                    {variable_34}
                </div>
            </div>
        </>
    )
};
export default CreateOrEditTask;
