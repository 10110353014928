import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Form, Input, Button, message } from "antd"
import requests from "../../axios/requests";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";

const ForgotPassword = () => {
    const history = useNavigate()
    const {search} = useLocation();

    const variable_6 = variableLanguage({keyPage: "authorization", keyVariable: "variable_6"});
    const variable_11 = variableLanguage({keyPage: "authorization", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "authorization", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "authorization", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "authorization", keyVariable: "variable_14"});
    const variable_15 = variableLanguage({keyPage: "authorization", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "authorization", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "authorization", keyVariable: "variable_17"});
    const variable_18 = variableLanguage({keyPage: "authorization", keyVariable: "variable_18"});
    const variable_19 = variableLanguage({keyPage: "authorization", keyVariable: "variable_19"});

    let passwordRequested = false
    if (search.includes("link")) {
        passwordRequested = true
    }

    const finishForgotPassword = (values) => {
        requests.auth.password_forgotten({mail: values.user.email}).then((v) => {
            message.success(variable_11)
        })
    };
    const finishRestorePassword = (values) => {
        requests.auth.password_restore({
            password: values.password,
            link: search.split("=")[1]
        }).then((v) => {
            if (v.data.result === "success") {
                message.success(variable_12)
                history("/backoffice/login")
            }
        })
    }

    return (
        <div className="login_page">
            <div className={'parent_forgot-login'}>
                {!passwordRequested
                    ? <div>
                        <Form
                            name="change_password"
                            onFinish={finishForgotPassword}
                            layout="vertical"
                            size="large"
                        >
                            <div style={{color: 'white', fontSize: 16, marginBottom: 20, textAlign: 'center'}}>
                                {variable_13}
                            </div>
                            <div className={"title"}>Email</div>
                            <Form.Item
                                name={['user', 'email']}
                                rules={[{type: 'email', required: true}]}
                            >
                                <Input className={"input_custom"}/>
                            </Form.Item>
                            <div className={'group_btn'}>
                                <Button
                                    onClick={() => {
                                        history("/backoffice/login")
                                    }}
                                    className={'btn prev'}
                                >
                                    {variable_14}
                                </Button>
                                <Form.Item name="submit" style={{maxWidth: '200px', width: '100%'}}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className={'btn request'}
                                        style={{maxWidth: 200, marginLeft: 'auto'}}
                                    >
                                        {variable_15}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    : <div>
                        <Form
                            name="restore_password"
                            onFinish={finishRestorePassword}
                            layout="vertical"
                            size="large"
                        >
                            <div className="title">{variable_16}</div>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: variable_6,
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password type={"password"} className={"input_custom"} style={{color: 'white'}}/>
                            </Form.Item>
                            <div className="title">{variable_17}</div>
                            <Form.Item
                                name="confirm"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: variable_17,
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error(variable_18));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password type={"password"} className={"input_custom"} style={{color: 'white'}}/>
                            </Form.Item>
                            <div className={'group_btn'}>
                                <Button
                                    onClick={() => {
                                        history("/backoffice/login")
                                    }}
                                    className={'btn prev'}
                                >
                                    {variable_14}
                                </Button>
                                <Form.Item name="submit" style={{maxWidth: '200px', width: '100%'}}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className={'btn request'}
                                        style={{maxWidth: 200, marginLeft: 'auto'}}
                                    >
                                        {variable_19}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                }
            </div>
        </div>
    );
};
export default ForgotPassword;
