import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    setActiveListProfession, setActiveProfession, setActiveVacancy
} from "../../../../redux/actions/searchProfessionActions";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import { Input, Pagination, Spin } from "antd";
import Profession from "./Profession";

import profession_list_label from "../../../../images/search_profession/profession_list_label.svg";
import search_mobile from "../../../../images/icons/search_icon.svg";
import './ProfessionList.scss';
import Button from "../../../components/reuseComponents/Button/Button";

const ProfessionList = (props) => {
    const {
        searchProfessionInput, setSearchProfessionInput, displayListProfession, setFlagModalProfessionInfo,
        flagModalProfessionInfo, favoriteProfession, handleChangeFavoriteProfession,
        setCurrentPage, loadingProfession, listProfessionOrigin, currentPage, changeIntro,
    } = props;
    const dispatch = useDispatch();
    const { activeProfession, activeListProfession } = useSelector(state => state.SearchProfession);

    const variables_1 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_1" });
    const variables_2 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_2" });
    const variables_3 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_3" });
    const variables_6 = variableLanguage({ keyPage: "searchProfession", keyVariable: "variables_6" });

    let itemRender = (_, type, originalElement) => {
        if (type === "prev")
            return null;
        if (type === "next") {
            return;
        }
        return originalElement;
    };
    return (
        <div className="profession_list">
            <div className="professions">
                <div
                    className="btn_intro"
                    onClick={() => changeIntro(true)}
                >
                    инструкция
                </div>
                <div className="title">
                    <img src={profession_list_label} />
                    <span>{variables_1}</span>
                </div>
                <div className="search_profession">
                    <div className="img">
                        <img src={search_mobile} />
                    </div>
                    <Input
                        className={"input_classic"}
                        placeholder={variables_6}
                        value={searchProfessionInput}
                        onChange={(e) => setSearchProfessionInput(e.target.value)}
                    />
                </div>
                <div className="buttons_select">
                    <Button
                        text={variables_3}
                        click={() => {
                            dispatch(setActiveListProfession(0));
                        }}
                        padding={"4px 15px 6px"}
                        maxWidth={"100%"}
                        margin={"0 15px 10px 0"}
                        boxShadow={"none"}
                        backgroundColor={activeListProfession === 0 ? "" : "transparent"}
                        border={"1px solid var(--text_color_dark)"}
                    />
                    <Button
                        text={variables_2}
                        click={() => {
                            dispatch(setActiveListProfession(1));
                        }}
                        padding={"4px 15px 6px"}
                        maxWidth={"100%"}
                        margin={"0 0 10px 0"}
                        boxShadow={"none"}
                        backgroundColor={activeListProfession === 1 ? "" : "transparent"}
                        border={"1px solid var(--text_color_dark)"}
                    />
                </div>
                <ul className="list_profs">
                    {displayListProfession.length > 0 &&
                        displayListProfession.map((prof, index) =>
                            <Profession
                                key={index}
                                procentague={prof.percent && prof.percent}
                                professionTitle={prof.name}
                                click={(obj) => {
                                    dispatch(setActiveProfession(obj))
                                    dispatch(setActiveVacancy(false))
                                }}
                                activeId={activeProfession?.id}
                                flagModalInfo={flagModalProfessionInfo}
                                setFlagModalProfessionInfo={() => setFlagModalProfessionInfo(!flagModalProfessionInfo)}
                                favoriteProfession={favoriteProfession}
                                changeFavoriteProfession={handleChangeFavoriteProfession}
                                prof={{ ...prof }}
                            />
                        )
                    }
                    {loadingProfession &&
                        <Spin style={{ position: "absolute", left: "50%", top: "10%", transform: "translate(-50%, 0)" }} />
                    }
                </ul>
                {listProfessionOrigin && listProfessionOrigin.length > 12 &&
                    !searchProfessionInput &&
                    <div className="pagination_all">
                        <Pagination
                            current={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={12}
                            total={listProfessionOrigin.length}
                            onChange={(e) => setCurrentPage(e)}
                            showTitle={false}
                            itemRender={itemRender}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default ProfessionList;