import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import { changeLanguage } from "../../../redux/actions/actions";
import requests from "../../axios/requests";
import { Button, Form, Input } from "antd";

import { ReactComponent as ArrowLanguage } from "../../../images/arrow.svg";
import rus from "../../../images/country/russian_icon.png";
import usa from "../../../images/country/usa_icon.png";

const Login = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const {language} = useSelector(state => state.Reducer);

    const variable_1 = variableLanguage({keyPage: "authorization", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "authorization", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "authorization", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "authorization", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "authorization", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "authorization", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "authorization", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "authorization", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "authorization", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "authorization", keyVariable: "variable_10"});

    const [errorMessage, setErrorMessage] = useState("");
    const [flagDropdownLanguage, setFlagDropdownLanguage] = useState(false);
    const [activeLanguage, setActiveLanguage] = useState(0);

    const [form] = Form.useForm();
    const refInputEmail = useRef();
    const refInputPassword = useRef();

    const onFinish = (values) => {
        requests.auth.login({username: values.username, password: values.password})
            .then((response) => {

                if (!response.data.error) {
                    window.localStorage.setItem("name", response.data.username);
                    window.localStorage.setItem("user_id", response.data.id);
                    window.localStorage.setItem("token", response.data.auth_token);
                    if (window.localStorage.getItem("previous_course_link")) {
                        window.location.href = window.localStorage.getItem("previous_course_link");
                        setTimeout(() => {
                            window.localStorage.removeItem("previous_course_link");
                        }, 1000)
                    } else history(`/backoffice/my-course`);
                } else {
                    setErrorMessage(variable_1);
                }
            })
            .catch((e) => {
                console.log(e.status);
                setErrorMessage(variable_1);
            });
    };
    const onEmailChange = (e) => {
        form.setFieldsValue({username: e.target.value.trim()});
    };

    useEffect(() => {
        setActiveLanguage(language === "en" ? 1 : 0);
        if (language)
            localStorage.setItem("language", language);
    }, [language]);
    if (localStorage.getItem("token"))
        history("/backoffice/my-course");
    return (
        <div className="login_page">
            <div className={"parent_login"}>
                <h2>{variable_2}</h2>
                <div
                    className={flagDropdownLanguage ? "drop_down-language open" : "drop_down-language"}
                    tabIndex={0}
                    onClick={() => {
                        setFlagDropdownLanguage(!flagDropdownLanguage);
                    }}
                    onBlur={() => setFlagDropdownLanguage(false)}
                >
                    {activeLanguage === 0
                        ? <img src={rus}/>
                        : <img src={usa}/>
                    }
                    <span> {["RU", "EN"][activeLanguage]}</span>
                    <ArrowLanguage/>
                    <div className={flagDropdownLanguage ? "drop_down open" : "drop_down"}>
                        {["RU", "EN"].map((elem, indx) =>
                            <div
                                className="language"
                                onClick={() => {
                                    dispatch(changeLanguage(indx === 0 ? "ru" : "en"));
                                }}
                                key={indx}
                            >
                                {indx === 0
                                    ? <img src={rus}/>
                                    : <img src={usa}/>
                                }
                                {elem}

                            </div>
                        )}
                    </div>
                </div>
                <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    size="large"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                >
                    <div className={"title"}>{variable_3}</div>
                    <Form.Item
                        name="username"
                        rules={[
                            {required: true, message: variable_4, type: "email"}
                        ]}
                        onClick={() => refInputEmail.current.focus()}
                    >
                        <Input ref={refInputEmail} className={"input_custom"} onChange={onEmailChange}/>
                    </Form.Item>
                    <div className={"title"}>{variable_5}</div>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: variable_6}]}
                        extra={errorMessage}
                        onClick={() => refInputPassword.current.focus()}
                    >
                        <Input.Password ref={refInputPassword} type={"password"} className={"input_custom"}/>
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                        <div className="checkbox_group">
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    id={"remember"}
                                />
                                <label
                                    htmlFor={"remember"}
                                >
                                    <span>{variable_7}</span>
                                </label>
                            </div>
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <Button className={"btn_send"} htmlType="submit">{variable_8}</Button>
                    </Form.Item>
                </Form>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Link to={{pathname: "/backoffice/register"}} className={"a"}>{variable_9}</Link>
                    <Link to={{pathname: "/backoffice/forgot_password"}} className={"a"}>{variable_10}</Link>
                </div>
            </div>
        </div>
    );
};
export default Login;
