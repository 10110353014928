import React from 'react';
import { Transition } from "react-transition-group";
import './Transition.scss';

const TransitionWrapper = ({show, children, marginBottom, timeout = 100}) => {
    return (
        <Transition
            in={show}
            timeout={timeout}
            unmountOnExit
            mountOnEnter
        >
            {(state) =>
                <div
                    className={`parent_transition ${state}`}
                    style={marginBottom ? {marginBottom} : {}}
                >
                    {children}
                </div>
            }
        </Transition>
    );
};

export default TransitionWrapper;