import React, { useEffect, useState } from "react";
import requests from "../../../../axios/requests";
import { Button, Input, Checkbox, message } from "antd";
import ReuseDropDown from "../../../../components/reuseComponents/ReuseDropDown";
import variableLanguage from "../../../../utils/hooks/hookGetVariablesLanguage";

import remove_icon from "../../../../../images/icons/remove_icon.png";
import add_step from "../../../../../images/icons/add_step.png";
import { ReactComponent as AddQuestion } from "../../../../../images/icons/add_question.svg";
import { ReactComponent as RemoveQuestion } from "../../../../../images/icons/remove_question.svg";

const Choice = (props) => {
    let {
        el, setCheckbox, placeholder, removeAnswer,
        index, setText, viewAddAnswerIcon, setAddAnswer, viewRemoveAnswerIcon
    } = props;
    return (
        <>
            <div className={"choice_parent"}>
                <Checkbox
                    className={"checkbox_antd"}
                    style={{alignSelf: "center"}}
                    checked={el.correct}
                    onClick={(e) => {
                        setCheckbox(e.target.checked);
                    }}>
                    <div style={{margin: " 0 5px", color: "white"}}>{index}</div>
                </Checkbox>
                <div className="group">
                    <Input
                        value={el.text}
                        onChange={(e) => setText(e.target.value)}
                        className={"input_classic"}
                        style={{paddingRight: 30}}
                        size="large"
                        placeholder={placeholder}
                    />
                    {viewRemoveAnswerIcon &&
                        <img
                            onClick={() => removeAnswer(el.id)}
                            src={remove_icon}
                        />
                    }
                </div>
                {viewAddAnswerIcon &&
                    <div
                        onClick={() => setAddAnswer()}
                        className="add_answer"
                    >
                        <img src={add_step}/>
                    </div>
                }
            </div>
        </>

    );
};
const EditQuiz = (props) => {
    let {setView, stageItems} = props;

    const variable_38 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_38"});
    const variable_39 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_39"});
    const variable_40 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_40"});
    const variable_41 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_41"});
    const variable_42 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_42"});
    const variable_43 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_43"});
    const variable_44 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_44"});
    const variable_45 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_45"});
    const variable_46 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_46"});
    const variable_47 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_47"});
    const variable_48 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_48"});
    const variable_49 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_49"});
    const variable_50 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_50"});
    const variable_51 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_51"});
    const variable_52 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_52"});
    const variable_53 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_53"});

    const [indexActiveQuestion, setIndexActiveQuestion] = useState(0);
    const [quizTitle, setQuizTitle] = useState("");
    const [activeRecomendation, setActiveRecomendation] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [arrRemoveAnswers, setArrRemoveAnswers] = useState([]);
    const [arrRemoveQuestions, setArrRemoveQuestions] = useState([]);
    const [objRecomendation, setObjRecomendation] = useState([]);
    const [render, setRender] = useState(1);

    const [flagDropEdit, setFlagDropEdit] = useState(false);
    const [flagOpenRecomendation, setFlagOpenRecomendation] = useState(false);

    let apiChangeQuiz = () => {
        if (!quizTitle) return message.error(variable_38);
        if (questions.length === 0) return message.error(variable_39);
        for (let i = 0; i < questions.length; i++) {
            if (!questions[i].title) {
                setIndexActiveQuestion(i);
                return message.error(variable_40);
            }
            let countCorrect = 0;
            for (let j = 0; j < questions[i].answers.length; j++) {
                if (questions[i].answers[j].correct) {
                    countCorrect++;
                }
                if (!questions[i].answers[j].text) {
                    setIndexActiveQuestion(i);
                    return message.error(variable_41);
                }
            }
            if (countCorrect === 0) {
                setIndexActiveQuestion(i);
                return message.error(variable_42);
            }
        }
        requests.stages
            .change_stages_matherial(stageItems.id, {title: quizTitle})
            .then((response) => {
                message.success(variable_43);
                setTimeout(() => {
                    setView();
                }, 1000);
            })
            .catch((e) => console.error(e));

        let arrCreateQuestions = [];
        let arrChangeQuestions = [];
        let objCreateAnswers = {
            answers: [],
            idQuestion: null
        };
        let objChangeAnswers = {
            answers: [],
            idQuestion: null
        };
        for (let i = 0; i < questions.length; i++) {
            if (typeof questions[i].id === "undefined")
                arrCreateQuestions = [...arrCreateQuestions, questions[i]];
            else
                arrChangeQuestions = [...arrChangeQuestions, questions[i]];

            for (let j = 0; j < questions[i].answers.length; j++) {
                if (typeof questions[i].answers[j].id === "undefined") {
                    if (typeof questions[i].id !== "undefined") {
                        questions[i].answers[j].question_id = questions[i].id;
                        objCreateAnswers.answers.push(questions[i].answers[j]);
                        objCreateAnswers.idQuestion = questions[i].id;
                    }
                } else {
                    objChangeAnswers.answers.push(questions[i].answers[j]);
                    objChangeAnswers.idQuestion = questions[i].id;
                }
            }
        }
        // answers create   // создание ответов к вопросам имеющим id
        if (objCreateAnswers.answers.length > 0) {
            let answersArr = [];
            for (let i = 0; i < objCreateAnswers.answers.length; i++) {
                let objAnsw = {
                    "question": objCreateAnswers.answers[i].question_id,
                    "text": objCreateAnswers.answers[i].text,
                    "correct": objCreateAnswers.answers[i].correct,
                };
                answersArr = [...answersArr, objAnsw];
            }
            requests.quiz.create_answers(answersArr)
                .catch((err) => console.error(err));
        }
        // answers change
        if (objChangeAnswers.answers.length > 0) {
            for (let i = 0; i < objChangeAnswers.answers.length; i++) {
                let objAnsw = {
                    "text": objChangeAnswers.answers[i].text,
                    "correct": objChangeAnswers.answers[i].correct,
                };
                requests.quiz.change_answers(objChangeAnswers.answers[i].id, objAnsw)
                    .catch((err) => console.error(err));
            }
        }
        // answers remove
        if (arrRemoveAnswers.length > 0) {
            for (let i = 0; i < arrRemoveAnswers.length; i++) {
                requests.quiz.remove_answers(arrRemoveAnswers[i])
                    .catch(err => console.error(err));
            }
        }
        // questions create
        if (arrCreateQuestions.length > 0) {
            let questionsArr = [];
            let answersArr = [];
            for (let i = 0; i < arrCreateQuestions.length; i++) {
                let obj = {
                    "quiz": stageItems.id,
                    "title": arrCreateQuestions[i].title,
                };
                questionsArr = [...questionsArr, obj];
            }
            requests.quiz.create_question(questionsArr)
                .then((res) => {
                    let idQuestion = res.data;
                    for (let i = 0; i < idQuestion.length; i++) {
                        for (let j = 0; j < arrCreateQuestions[i].answers.length; j++) {
                            let objAnsw = {
                                "question": idQuestion[i].id,
                                "text": arrCreateQuestions[i].answers[j].text,
                                "correct": arrCreateQuestions[i].answers[j].correct,
                            };
                            answersArr = [...answersArr, objAnsw];
                        }
                    }
                    requests.quiz.create_answers(answersArr)
                        .catch((err) => console.error(err));
                });
        }
        // questions change
        if (arrChangeQuestions.length > 0) {
            for (let i = 0; i < arrChangeQuestions.length; i++) {
                let obj = {
                    "title": arrChangeQuestions[i].title,
                };
                requests.quiz.change_question(arrChangeQuestions[i].id, obj)
                    .then((res) => {
                    });
            }
        }
        // questions remove
        if (arrRemoveQuestions.length > 0) {
            for (let i = 0; i < arrRemoveQuestions.length; i++) {
                requests.quiz.remove_questions(arrRemoveQuestions[i])
                    .then((res) => {
                    })
                    .catch(err => console.error(err));
            }
        }
        // recomendation create
        if (activeRecomendation === 1 && typeof objRecomendation[0].id === "undefined") {
            let objRec = {
                "quiz": stageItems.id,
                "low": objRecomendation[0].low,
                "middle": objRecomendation[0].middle,
                "high": objRecomendation[0].high
            };
            requests.quiz.create_recomendations([objRec])
                .then((res) => {
                })
                .catch(err => console.error(err));
        }
        //recomendation change
        if (activeRecomendation === 1 && objRecomendation[0].id) {
            let obj = {
                "low": objRecomendation[0].low,
                "middle": objRecomendation[0].middle,
                "high": objRecomendation[0].high
            };
            requests.quiz.change_recomendations(objRecomendation[0].id, obj)
                .then((res) => {
                })
                .catch(err => console.error(err));
        }
        // recomendation remove
        if (activeRecomendation === 0 && objRecomendation.length > 0) {
            if (typeof objRecomendation[0].id !== "undefined") {
                requests.quiz.remove_recomendations(objRecomendation[0].id)
                    .then((res) => {
                    })
                    .catch(err => console.error(err));
            }
        }
    };

    useEffect(() => {
        if (stageItems.quiz_responses.length > 0) {
            setObjRecomendation(stageItems.quiz_responses);
            setActiveRecomendation(1);
        } else {
            setObjRecomendation([{
                "low": "",
                "middle": "",
                "high": ""
            }]);
        }
    }, [stageItems.quiz_responses]);
    useEffect(() => {
        setQuizTitle(stageItems.title);
        setQuestions(stageItems.questions);
    }, [stageItems]);
    return (
        <div className={"edit_quiz"}>
            <div style={{backgroundColor: "#343843", padding: 20, borderRadius: 16, position: "relative"}}>
                <div style={{color: "#A2AACA", margin: "5px 0 5px 15px"}}>{variable_44}</div>
                <Input
                    className={"input_classic"}
                    value={quizTitle}
                    onChange={(e) => setQuizTitle(e.target.value)}
                    maxLength={99}
                />
                <div style={{color: "#A2AACA", margin: "10px 0 5px 15px"}}>{variable_45}</div>
                <div className="row_navigation-questions">
                    {Array(questions.length).fill(0).map((_, indx) =>
                        <div
                            className={indexActiveQuestion === indx ? "number active" : "number"}
                            key={indx}
                            onClick={() => {
                                setIndexActiveQuestion(indx);
                            }}
                        >
                            <span>{indx + 1}</span>
                        </div>
                    )}
                    <div
                        onClick={() => {
                            let newArr = [...questions];
                            newArr.push(
                                {
                                    answers: [{correct: false, text: ""}, {correct: false, text: ""}],
                                    title: ""
                                }
                            );
                            setQuestions(newArr);
                            setIndexActiveQuestion(prev => {
                                if (questions.length > 0) {
                                    return questions.length;
                                } else return 0;
                            });
                        }}
                        className="create_question"
                    >
                        <AddQuestion/>
                    </div>
                    {questions.length > 0 &&
                        <div
                            onClick={() => {
                                let newArr = [...questions];
                                newArr.splice(indexActiveQuestion, 1);
                                setQuestions(newArr);
                                if (indexActiveQuestion !== 0) {
                                    setIndexActiveQuestion((prev) => prev - 1);
                                }
                                setArrRemoveQuestions((prev) => [...prev, questions[indexActiveQuestion].id]);
                            }}
                            className="remove_question"
                        >
                            <RemoveQuestion/>
                        </div>
                    }
                </div>
                {questions && questions.length > 0 &&
                    <>
                        <div className="question_title" style={{position: "relative"}}>
                            <div style={{color: "#A2AACA", margin: "10px 0 5px 15px"}}>{variable_46}</div>
                            <Input
                                value={questions[indexActiveQuestion].title}
                                onChange={(e) => {
                                    let newArr = [...questions];
                                    questions[indexActiveQuestion].title = e.target.value;
                                    setQuestions(newArr);
                                }}
                                className={"input_classic"}
                                size="large"
                                placeholder={variable_47}
                                style={{marginBottom: "10px"}}
                            />
                        </div>
                        {questions[indexActiveQuestion].answers.map((el, ind) => {
                            let funcViewAddAnswerIcon = () => {
                                if (questions[indexActiveQuestion].answers.length - 1 == ind)
                                    return true;
                            };
                            return (
                                <Choice
                                    el={el}
                                    index={ind + 1}
                                    setCheckbox={(e) => {
                                        let newArr = [...questions];
                                        newArr[indexActiveQuestion].answers[ind].correct = e;
                                        setQuestions(newArr);
                                    }}
                                    setText={(e) => {
                                        let newArr = [...questions];
                                        newArr[indexActiveQuestion].answers[ind].text = e;
                                        setQuestions(newArr);
                                    }}
                                    placeholder={`${variable_48} ` + (ind + 1)}
                                    removeAnswer={(id) => {
                                        let newArr = [...questions];
                                        newArr[indexActiveQuestion].answers.splice(ind, 1);
                                        setQuestions(newArr);
                                        setArrRemoveAnswers((prev) => [...prev, id]);
                                    }}
                                    setAddAnswer={() => {
                                        let newArr = [...questions];
                                        if (questions[indexActiveQuestion].answers.length < 10) {
                                            newArr[indexActiveQuestion].answers.push({correct: false, text: ""});
                                            setQuestions(newArr);
                                        }
                                    }}
                                    viewAddAnswerIcon={funcViewAddAnswerIcon()}
                                    viewRemoveAnswerIcon={questions[indexActiveQuestion].answers.length > 2 ? true : false}
                                />
                            );
                        })}
                    </>
                }
                <div className="recomenditon">
                    <div className="group_drop-down">
                        <div style={{color: "#A2AACA", margin: "15px 0 5px 15px", zIndex: 4}}>{variable_49}</div>
                        <ReuseDropDown
                            flagOpen={flagOpenRecomendation}
                            setFlagOpen={(bool) => setFlagOpenRecomendation(bool)}
                            arrList={[variable_50, variable_51]}
                            activeArrList={activeRecomendation}
                            setActiveArrList={(indx) => setActiveRecomendation(indx)}
                        />
                    </div>

                    {activeRecomendation === 1 && objRecomendation.length > 0
                        && <>
                            <Input.TextArea
                                value={objRecomendation[0].low}
                                onChange={(e) => {
                                    let newArr = [...objRecomendation];
                                    newArr[0].low = e.target.value;
                                    setObjRecomendation(newArr);
                                }}
                                placeholder={`${variable_52} 0 - 33%`}
                                className={"textarea_antd"}
                                style={{marginTop: 0}}
                            />
                            <Input.TextArea
                                value={objRecomendation[0].middle}
                                onChange={(e) => {
                                    let newArr = [...objRecomendation];
                                    newArr[0].middle = e.target.value;
                                    setObjRecomendation(newArr);
                                }}
                                className={"textarea_antd"}
                                placeholder={`${variable_52} 34 - 66%`}
                            />
                            <Input.TextArea
                                value={objRecomendation[0].high}
                                onChange={(e) => {
                                    let newArr = [...objRecomendation];
                                    newArr[0].high = e.target.value;
                                    setObjRecomendation(newArr);
                                }}
                                className={"textarea_antd"}
                                placeholder={`${variable_52} 67 - 100%`}
                            />
                        </>}
                </div>
                <div className="buttonWithCount">
                    <Button
                        className={"btn_save"}
                        onClick={() => apiChangeQuiz()}
                    >
                        {variable_53}
                    </Button>
                </div>
            </div>
            <div className="render" style={{opacity: 0, position: "absolute"}}>{render}</div>
            <div
                className={flagDropEdit ? "overlay open" : "overlay"}
                onClick={() => {
                    setFlagDropEdit(false);
                }}
            />
        </div>
    );
};
export default React.memo(EditQuiz);


