import React from "react";
import gorizontal_line_chart from "../../../../images/search_profession/gorizontal_line-chart.png";
import gorizontal_line_chart_long from "../../../../images/search_profession/gorizontal_line-chart-long.png";

const ParentBlockProgress = (props) => {
    const {children, isFavorite = false, long_line = false, activeCategory = false} = props;
    const arrDiapazonInfoOreFavorite = [
        {salary: "0", left: 0}, {salary: "50 000", left: 110}, {salary: "100 000", left: 235},
        {salary: "150 000", left: 360}, {salary: "200 000", left: 487}, {salary: "250 000+", left: 615}
    ];

    return (
        <div style={{position: "relative"}}>
            <div className="analytics_block-gorizontal">
                {arrDiapazonInfoOreFavorite.map((elem, indx) =>
                    <div key={indx} className={"parent_diapazon"}>
                        <div className="diapazon"
                             style={isFavorite ? {marginLeft: -17} : {left: elem.left}}>{elem.salary}</div>
                        <div className="vertical_line">
                            <img
                                height={activeCategory === 0 ? 170 : "auto"}
                                src={long_line ? gorizontal_line_chart_long : gorizontal_line_chart}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="chart">
                <div
                    className="progress_parent"
                >{children}
                </div>
            </div>
        </div>
    );
};

export default ParentBlockProgress;